import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import Clock from "../../../assets/OwnerAccount/clock.svg";

const TimePicker = ({
  handleTimeConfirm,
  hourShow,
  minuteShow,
  meridiemShow,
  data_type,
  setMinuteShow,
  setMeridiemShow,
  setHourShow,
  isTimeSelected,
  dropdownOpen,
  setdropdownOpen,

  fieldname,
  setShowDatePicker,
  value,
  handleClearTime,
}) => {
  const hours = Array.from({ length: 12 }, (_, i) =>
    String(i + 1).padStart(2, "0")
  );
  const minutes = Array.from({ length: 60 }, (_, i) =>
    String(i).padStart(2, "0")
  );
  const [selectHour, setSelectHour] = useState("01");
  const [selectMinute, setSelectMinute] = useState("00");
  const [selectMeridiem, setSelectMeridiem] = useState("AM");

  const dropdownRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        setdropdownOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`time-picker text-[#f9f9f9] relative mt-[5px] flex flex-col shadow-lg ${
        dropdownOpen ? "open" : ""
      }`}
      onClick={() => {
        setShowDatePicker(false);
        setdropdownOpen(!dropdownOpen);
      }}
      ref={dropdownRef}
    >
      <div className="time-display">
        <span>
          {!dropdownOpen ? value : `${hourShow}:${minuteShow}  ${meridiemShow}`}
        </span>
        <img src={Clock} alt="Clock" className="h-[18px] w-[18px]" />
      </div>

      <div
        className="horizontal_line"
        style={{ display: dropdownOpen ? "block" : "none" }}
      ></div>

      {dropdownOpen && (
        <div className="dropdown-display shadow-lg">
          <div className="time-scroll-container pb-[5px]">
            <div className="time-scroll w-[25px]">
              {hours.map((h) => (
                <div
                  key={h}
                  className={`time-option ${
                    h === selectHour ? "selected" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setHourShow(h);
                    setSelectHour(h);
                  }}
                >
                  {h}
                </div>
              ))}
            </div>

            <div className="time-scroll w-[25px]">
              {minutes.map((m) => (
                <div
                  key={m}
                  className={`time-option ${
                    m === selectMinute ? "selected" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setMinuteShow(m);
                    setSelectMinute(m);
                  }}
                >
                  {m}
                </div>
              ))}
            </div>

            <div className="time-scroll w-auto">
              {["AM", "PM"].map((period) => (
                <div
                  key={period}
                  className={`time-option ${
                    period === selectMeridiem ? "selected" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setMeridiemShow(
                      (period === "AM" && "am") || (period === "PM" && "pm")
                    );
                    setSelectMeridiem(period);
                  }}
                >
                  {period}
                </div>
              ))}
            </div>
          </div>

          <div className="time-picker-buttons text-[10px] flex justify-around mt-[10px]">
            <button
              onClick={() => {
                handleClearTime();
                setSelectHour("01");
                setSelectMinute("00");
                setSelectMeridiem("AM");
              }}
            >
              Clear
            </button>
            <button
              className="text-[#f9f9f9]"
              onClick={() => handleTimeConfirm(fieldname, data_type)}
              disabled={!isTimeSelected} // Disable button if time is not selected
              style={{
                backgroundColor: isTimeSelected ? "#f05a6a" : "#707070",
                border: isTimeSelected
                  ? "1px solid #f05a6a"
                  : "1px solid #707070",
                cursor: isTimeSelected ? "pointer" : "default",
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimePicker;
