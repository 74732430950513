import React, { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import arrowIcon from "../../../assets/TransactionDashboardIcons/arrow_next.png";
import { useSelector } from "react-redux";
import checkIfEnable from "../../../utils/checkIfEnable";
import ServiceIDByLabel from "../../../services/ServiceIDByLabel";

const MenuItemCardmobile = ({ icon, label, labelm, labela }) => {
  const services_list = useSelector((state) => state.refreshToken.services);
  const [showExandArrow, setShowExpandArrow] = useState(false);

  return (
    <Box
      onMouseLeave={() => setShowExpandArrow(false)}
      onMouseEnter={() => setShowExpandArrow(true)}
      sx={[
        checkIfEnable(ServiceIDByLabel, label, labelm, labela, services_list) && {
      },
        !checkIfEnable(ServiceIDByLabel, label, labelm, labela, services_list) && {
      },
      ]}
    >
      <div style={{ width: "49%", float: "left", textAlign: "center",padding:"20px 20px",lineHeight:"20px" }}>
        <div>
          <img
            width={"30%"}
            src={require("../../../assets/TransactionDashboardIcons/" + icon)}
            alt=""
            style={{margin:"10px auto"}}
          />
        </div>
        <div>{labelm}</div>
        <div>{labela}</div>
      </div>
   </Box>
  );
};

export default MenuItemCardmobile;
