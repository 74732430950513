import React from "react";
import "./PendingTransactionAllCards.css";
import formatDate from "../../../../../utils/formatdate";
import { Box } from "@mui/material";
import TransactionBankCardLogo from "../TransactionBankCardLogo/TransactionBankCardLogo";

const PendingTransactionAllCards = ({ pendingTxn }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#B196FA";
      case "denied":
      case "failed":
        return "#ff5860";
      case "abandoned":
        return "#a3a3a3";
      case "rejected":
        return "#ff5860";
      case "hold":
        return "#FBC02D";
      case "queued":
        return "#FBC02D";
      default:
        return "#4ddd37";
    }
  };

  const getDistriStatusColor = (status) => {
    switch (status) {
      case "awaiting_verification":
      case "awaiting_funds":
      case "distribution_initiated":
      case "created":
      case "distribution_pending":
      case "approved":
        return "#FBC02D";
      case "denied":
      case "failed":
      case "rejected":
        return "#ff5860";
      case "abandoned":
        return "#B4B4B4";
      case "distribution_complete":
        return "#4DDD37";
      default:
        return "#F9F9F9";
    }
  };

  return (
    <div
      style={{
        overflowY: "hidden",
        cursor: "default",
      }}
      className={`reject-txn-card`}
    >
      {pendingTxn?.map((account, index) => (
        <div className="wrap-rej-acc-view">
          <Box
            key={index}
            className="multi-select-txn-Card relative 5.6xl:w-[265px]"
            style={{
              backgroundColor: "#3a5686",
              cursor: "default",
            }}
          >
            <div className="viewall-details">
              <div className="bene-view-all-user">
                <div
                  className="bene_viewall_name_div"
                  style={{
                    color: "#30e5e4",
                    marginLeft: "10px",
                  }}
                >
                  ₹{" "}
                  {Number(account?.total_amount)?.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </div>

                <div
                  className="bene_viewall-txn-bank-acc-div"
                  style={{ marginLeft: "10px", marginTop: "-7px" }}
                >
                  {" "}
                  {account?.beneficiary_account_name.toLowerCase()}
                </div>
                <div
                  className="viewall-date-div"
                  style={{ marginLeft: "10px" }}
                >
                  {formatDate(account?.created_on, "")}
                </div>
              </div>
            </div>
            <div className="view-all-txn-card-container_acc absolute right-0 mb-0 flex flex-col justify-end ">
              <TransactionBankCardLogo data={account} type={"account"} />
            </div>
            <div className="bene_txn-dlts_viewall_hover_content_acc">
              <div className="pl-[10px] pt-[5px]">
                <div
                  className="text-sm pb-[4px] capitalize"
                  style={{
                    color: getStatusColor(account?.lifecycle_status),
                  }}
                >
                  {account?.lifecycle_status}
                </div>
                <div className="text-[#DADADA] text-[10px] ">
                  Approval status
                </div>
              </div>
              <div className="pl-[10px] pt-[5px]">
                <div
                  className="text-sm pb-[4px]"
                  style={{
                    color:
                      account?.distribution_lifecycle_status === "N/A"
                        ? "#F9F9F9"
                        : getDistriStatusColor(
                            account?.distribution_lifecycle_status
                          ),
                  }}
                >
                  {account?.distribution_lifecycle_status || "N/A"}
                </div>
                <div className="text-[#DADADA] text-[10px] ">
                  Distribution status
                </div>
              </div>
            </div>
          </Box>
        </div>
      ))}
    </div>
  );
};
export default PendingTransactionAllCards;
