import React, { useState, useRef, useEffect } from "react";
import "./index.css";
import { Button } from "@mui/material";
import cancel from "../../../assets/CloseIcons/close_with_white_circle.svg";
import search from "../../../assets/FilterSearchIcons/search_white.svg";
import enter_ifsc from "../../../assets/Graphics/Enter in search bar, to fetch.svg";
import loader from "../../../assets/LoaderIcons/Loader.gif";
import notFound from "../../../assets/Graphics/No transaction found.svg";
import arrowRight from "../../../assets/ArrowsIcons/arrow_right.svg";
import pan from "../../../assets/VerificationIcons/pan_verification_blue.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  verifyThisPANGet,
  resetGetPanData,
  resetSelectedPan,
  resetGetPanError,
  selectedGst,
  verifyThisPANPost,
} from "../../../redux/features/verifyPAN/verifyPAN";
import { useSelector, useDispatch } from "react-redux";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { checkUsertoken } from "../../../redux/features/login/login";
import { beneVerifyThisGST } from "../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { Tooltip, tooltipClasses } from "@mui/material";

const PanVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [panNo, setPanNo] = useState("");
  const services = useSelector((state) => state.refreshToken.services);

  const handlePan = (e) => {
    if (e.target.value.length === 0) {
      dispatch(resetGetPanData());
    }
    if (e.target.value.length > 10) {
      return;
    }
    if (!specialRegex.test(e.target.value)) {
      return;
    }
    setPanNo(e.target.value.toUpperCase());
    const cursorPosition = e.target.selectionStart;
    setTimeout(() => {
      e.target.focus();
      e.target.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  const inputRef = useRef("");
  const {
    getVerifyPanData,
    getVerifyPanLoading,
    getVerifyPanError,
    selectedPanData,
  } = useSelector((state) => state.verify);

  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  const specialRegex = /^[a-zA-Z0-9]*$/;

  const handlePanClick = async () => {

    if ((!panRegex.test(panNo) && panNo.length === 10) || panNo.length < 10) {
      dispatch(resetGetPanError());
      toast.error("Invalid PAN", toast_position);
      return;
    }

    if (panNo.length === 10) {

      dispatch(checkUsertoken());
      dispatch(resetGetPanData());


      const response = await dispatch(verifyThisPANGet({ search_string: panNo }));

      if (response.payload.gstin_details === undefined) {
        await dispatch(verifyThisPANPost({ search_string: panNo }));
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && panNo.length === 10) {
      handlePanClick();
    }
  };

  useEffect(() => {
    if (services && !services?.includes("VPAN")) {
      navigate("/feature_not_assigned");
    }
    if (selectedPanData) {
      setPanNo(selectedPanData);
    }
    return () => {
      dispatch(resetSelectedPan());
    };
  }, []);

  const handleNavigate = (gst) => {
    dispatch(checkUsertoken());
    dispatch(selectedGst(gst));
    dispatch(beneVerifyThisGST({ search_string: gst }));
    dispatch(resetGetPanData());
    navigate("/settings/GST_Verification");
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [panNo]);

  return (
    <div className="cin_main_div">
      <div className="cin_search">
        <div className="cin_search_1024">
          <div className="cin_input_div">
            <input
              ref={inputRef}
              value={panNo}
              onChange={handlePan}
              placeholder="Enter PAN number"
            />
            {panNo.length === 0 ? (
              <img
                alt="search"
                src={search}
                onClick={() => inputRef.current.focus()}
              />
            ) : (
              <img
                alt="cancel"
                src={cancel}
                onClick={() => {
                  dispatch(resetGetPanData());
                  setPanNo("");
                }}
              />
            )}
          </div>
          <Button
            className="cin_fetch_button"
            onClick={handlePanClick}
            disabled={panNo.length !== 10}
            style={{
              color: panNo.length === 10 ? "#1d3a6d" : "#F9F9F9",
              background: panNo.length === 10 ? "#f9f9f9" : "#707070",
            }}
          >
            Fetch Details
          </Button>
        </div>
      </div>
      {getVerifyPanLoading ? (
        <div className="cin_loading_loader">
          <img
            src={loader}
            alt="inputLoader"
            style={{
              width: "60px",
              height: "42px",
              maxWidth: "60px",
              maxHeight: "42px",
            }}
          />
        </div>
      ) : !getVerifyPanLoading && getVerifyPanData?.status ? (
        <>
          <div className="cin_details_pan">
            <div className="cin_details_1024">
              <div className="cin_details_1_pan">
                <div
                  className="cin_details_1_left_pan"
                  style={{ width: "100%", maxWidth: "876px" }}
                >
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                          [`& .${tooltipClasses.arrow}`]: {
                            color: (theme) => theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={getVerifyPanData?.pan_details?.full_name}
                    arrow
                    placement="bottom-start"
                    PopperProps={{
                      style: {
                        maxWidth: "none",
                      },
                    }}
                  >
                    <h4
                      className="lowercase truncate first-line:capitalize"
                      style={{ width: "100%", maxWidth: "876px" }}
                    >
                      {getVerifyPanData?.pan_details?.full_name
                        .toLowerCase()
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}
                    </h4>
                  </Tooltip>
                  <h6>Full name</h6>
                </div>
                <div className="cin_details_1_right_pan">
                  <div className="cin_img_div">
                    <img src={pan} alt="cin" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cin_tabs">
            <div className="cin_tabs_1024">
              <div className="cin_tabs_button">
                <Button
                  className="cin_tabs_button_1"
                  style={{
                    background: "#F9F9F9",
                    color: "#3A3649",
                    font: "normal normal 600 12px/17px Open Sans",
                    cursor: "default",
                  }}
                >
                  Associated GSTs
                </Button>
              </div>

              {getVerifyPanData?.gstin_details ? (
                <div className="pan_gst_have">
                  {getVerifyPanData?.gstin_details?.map((val) => {
                    return (
                      <div
                        className="pan_gst_have_1"
                        onClick={() => handleNavigate(val?.gstin_no)}
                      >
                        <div className="pan_gst_have_1_details">
                          <h5>{val?.gstin_no}</h5>
                          <h6>GST number</h6>
                        </div>
                        <img src={arrowRight} alt="arrowright" />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="pan_gst_div">
                  <img src={notFound} alt="notfound" />
                  <h5>No associated GSTs found.</h5>
                </div>
              )}
            </div>
          </div>
        </>
      ) : !getVerifyPanLoading && getVerifyPanError === true ? (
        <div className="cin_not_found">
          <img src={notFound} alt="notfound" />
          <p>
            No data found.
            <br /> Please check the PAN number entered above.
          </p>
        </div>
      ) : (
        <div className="cin_default_img">
          <img src={enter_ifsc} alt="bgDefault" />
          <p>
            Enter PAN number in the search bar, to fetch the verified PAN
            details.
          </p>
        </div>
      )}
    </div>
  );
};

export default PanVerification;
