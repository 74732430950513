import React, { useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { useSelector } from "react-redux";
import HolidayDateCardnew from "../HolidayDateCardnew";
import convertTo2DArray from "../../../utils/convertTo2DArray";
import epochToTime from "../../../utils/epochToTime";
import CloseIcon from "../../../assets/ViewAllTransactions/Clear filter.png";
import "../../ViewAllTransactions/FilterDrawer/index.css";

function HolidaysSidebar({ state, setState }) {
  const holiday_list = useSelector((state) => state.date?.holiday_list);

  const holidays = holiday_list?.holidays || [];

  const holiday_all_list = convertTo2DArray(holidays, 3);
  const working_hour = useSelector(
    (state) => state.date?.holiday_list?.working_hour
  );

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const groupByMonth = (holidays) => {
    return holidays.reduce((acc, holiday) => {
      const date = new Date(holiday.date);
      const month = date.toLocaleString("default", { month: "long" });
      if (!acc[month]) {
        acc[month] = [];
      }
      acc[month].push(holiday);
      return acc;
    }, {});
  };

  const holidaysByMonth = groupByMonth(holidays);

  const list = () => (
    <div className="bg-[#1D3A6D] overflow-hidden h-screen ">
      <div className="bg-[#314D7E] pb-6 flex justify-between px-5 pt-10  shadow-lg">
        <div className="text-xl text-white">Bank Holidays</div>
        <div
          className="cursor-pointer w-8 h-8 p-2 bg-[#1D3A6D] flex justify-center items-center rounded-md hover:bg-[#667CA3]"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <img src={CloseIcon} />
        </div>
      </div>
      <div className="p-4 flex flex-col gap-4 justify-end items-center text-white">
        <div className="font-semibold">Upcoming Bank Holidays</div>
      </div>
      <div
        id="holidayscroller"
        onScroll={(e) => e.stopPropagation()}
        className="grid grid-cols-1 divide-y divide-gray-500 p-3 h-5/6 5.6xl:h-auto overflow-scroll pb-20 5.6xl:pb-10"
      >
        <div
          className="Caraouseldatemainm"
          style={{ padding: "0px", color: "#ffffff" }}
        >
          {Object.keys(holidaysByMonth).map((month) => (
            <div key={month}>
              <div className="datehader">{month}</div>
              {holidaysByMonth[month].map((holiday, index) => (
                <React.Fragment key={holiday.id}>
                  <HolidayDateCardnew
                    date={holiday.date}
                    occasion={holiday.occasion}
                  />
                  {index < holidaysByMonth[month].length - 1 && (
                    <hr className="new1"></hr>
                  )}
                </React.Fragment>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="">
      <Drawer
        anchor={"right"}
        open={state}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: 9998 }}
      >
        {list()}
      </Drawer>
    </div>
  );
}

export default HolidaysSidebar;
