import React, { useEffect, useState } from "react";
import "./index.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import BeneIcon from "../../../../assets/CategoryIcons/beneficiary_category_default.svg";
import { beneCategories } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import { useDispatch, useSelector } from "react-redux";
import { viewAccountDetail } from "../../../../redux/features/verifyPAN/verifyPAN";
import BenePanEditModal from "../../../../components/BeneEditAccount/BenePanEditModel";
import BeneEditStepper from "../../../../components/BeneEditAccount/BeneEditStepper";
import { getRefreshTokenNew } from "../../../../redux/features/refreshToken/refreshToken";
import Notselected from "../../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import Selected from "../../../../assets/CheckmarksIcons/selected_with_green_circle.svg";

const BeneEditCategories = () => {
  const steps = ["Assigned beneficiary category"];
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { id } = params;
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [nextButtonEnabled, BenEnableNextButton] = useState(true);
  const [openPopEdit, setOpenPopEdit] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [payload, setPayload] = useState({
    bank_account: "",
    ifsc_code: "",
    contact_no: "",
    email_id: "",
    gstin: "",
    name: "",
    categories: null,
    panNumber: "",
  });

  const [preSelectedCategories, setPreSelectedCategories] = useState([]);
  const { viewAccountDetailData, viewAccountDetailLoading } = useSelector(
    (state) => state.verify
  );
  const sliceCategory = useSelector(
    (state) => state.beneAllVerification.beneTransCategories.categories
  );

  const sliceCategoryLoading = useSelector(
    (state) => state.beneAllVerification.isTransCateLoading
  );

  const { services, isLoading } = useSelector((state) => state.refreshToken);

  useEffect(() => {
    dispatch(checkUsertoken());
    const fetchCategoryData = async () => {
      try {
        let response1 = await dispatch(viewAccountDetail({ id: id }));
        if (
          response1?.error?.message === "Request failed with status code 404"
        ) {
          navigate("*");
          return;
        }

        let response2 = await dispatch(beneCategories(id));
        if (
          response2?.error?.message === "Request failed with status code 404"
        ) {
          navigate("*");
          return;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCategoryData();
  }, [dispatch, id, navigate]);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const preSelectedCategories = sliceCategory.filter((category) =>
      selectedCategories.includes(category.name)
    );
    setPreSelectedCategories(preSelectedCategories);
  }, [sliceCategory]);

  useEffect(() => {
    if (!viewAccountDetailLoading && viewAccountDetailData?.status === true) {
      const beneficiaryData = viewAccountDetailData?.beneficiary_accounts[0];
      const selectedCategoriesArray = beneficiaryData.category
        .split(",")
        .filter((val) => val !== "");
      setSelectedCategories(selectedCategoriesArray);

      setPayload((prevPayload) => ({
        ...prevPayload,
        categories: beneficiaryData.category,
      }));
      dispatch(checkUsertoken());
      dispatch(
        beneCategories({
          categories: beneficiaryData.category,
        })
      );
    }
  }, [dispatch, viewAccountDetailData, viewAccountDetailLoading]);

  useEffect(() => {
    const allSelected =
      sliceCategory.length > 0 &&
      selectedCategories.length === sliceCategory.length;
    setSelectAllChecked(allSelected);
  }, [selectedCategories, sliceCategory]);

  const nonPreSelectedCategories = sliceCategory.filter(
    (category) =>
      !preSelectedCategories.some(
        (selectedCat) => selectedCat.id === category.id
      )
  );

  const sortedCategories = [
    ...preSelectedCategories,
    ...nonPreSelectedCategories,
  ];

  const toggleSelectAll = () => {
    const updatedSelectedCategories = !selectAllChecked
      ? sliceCategory.map((category) => category.name)
      : [];

    setSelectedCategories(updatedSelectedCategories);
    setSelectAllChecked(!selectAllChecked);
  };

  const handleCategorySelect = (categoryId) => {
    const category = sliceCategory.find((cat) => cat.id === categoryId);
    setSelectAllChecked(false);
    if (category) {
      const updatedCategories = selectedCategories.includes(category.name)
        ? selectedCategories.filter((name) => name !== category.name)
        : [...selectedCategories, category.name];
      setSelectedCategories(updatedCategories);
    }
  };

  const handleCategory = () => {
    if (nextButtonEnabled) {
      navigate(`/accounts/beneficiary_account/details/${id}`);
    } else {
      setOpenPopEdit(true);
    }
  };

  useEffect(() => {
    if (!sliceCategoryLoading && !viewAccountDetailLoading) {
      const previousCategories = preSelectedCategories?.map((val) => {
        return val?.name;
      });

      const arraysAreEqual = (array1, array2) => {
        if (array1.length !== array2.length) {
          return false;
        }
        for (let i = 0; i < array1.length; i++) {
          if (array1[i] !== array2[i]) {
            return false;
          }
        }
        return true;
      };

      const isEqual = arraysAreEqual(
        previousCategories.sort(),
        selectedCategories.sort()
      );
      if (isEqual) {
        BenEnableNextButton(true);
      } else {
        BenEnableNextButton(false);
      }
    }
  }, [
    selectedCategories,
    sliceCategoryLoading,
    preSelectedCategories,
    BenEnableNextButton,
  ]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(getRefreshTokenNew());
  }, []);

  useEffect(() => {
    if (!isLoading && services != null) {
      if (services?.includes("BUAccount") || services?.includes("Admin")) {
        dispatch(checkUsertoken());
      } else {
        navigate("/feature_not_assigned", {
          state: { unavailableService: "BUAccount" },
        });
      }
    }
  }, [services, isLoading]);

  const handleMouseEnter = (index) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className="edit_1024_con">
      <div className="edit_category_main">
        {openPopEdit && (
          <BenePanEditModal
            message2={"Are you sure you want to leave this page?"}
            message={"Leaving this page will not update the changes you made"}
            id={id}
            close={setOpenPopEdit}
          />
        )}
        <div className="main_edit_stepper_div">
          <BeneEditStepper
            type={"single"}
            steps={steps}
            activeStep={0}
            id={id}
            nextButtonEnabled={nextButtonEnabled}
            useFor="contact"
            ifsc={viewAccountDetailData?.beneficiary_accounts[0]?.ifsc_code}
            bank={viewAccountDetailData?.beneficiary_accounts[0]?.bank_acc_no}
            pan={viewAccountDetailData?.beneficiary_accounts[0]?.pan_no}
            contact={viewAccountDetailData?.beneficiary_accounts[0]?.contact_no}
            email={viewAccountDetailData?.beneficiary_accounts[0]?.email_id}
            gstin={viewAccountDetailData?.beneficiary_accounts[0]?.gstin}
            category={payload?.category}
            disType={"category"}
            handleBack={handleBack}
            selectedCategories={selectedCategories}
            handleCategory={handleCategory}
          />
        </div>
        <div className="dividecategory">
          <div className="w-1/2 contactright">
            <div className="flex flex-col">
              <div className="bg-[#314C7F] text-[#DADADA] font-semibold text-base flex flex-row justify-between py-4 pb-0 px-8 rounded-t-4xl 4xl:rounded-t-large items-center">
                <p className="text-center">Assign Beneficiary Categories</p>
                <div className="rounded-bl-[32px] rounded-tr-[32px] bg-[#3A5686] flex items-center justify-around mt-[-16px] mr-[-32px] w-36 h-12">
                  <FormControlLabel
                    className="text-white"
                    control={
                      <Checkbox
                        icon={
                          <img
                            className="w-5 h-5"
                            src={Notselected}
                            alt="not-selected"
                          />
                        }
                        checkedIcon={
                          <img
                            className="w-5 h-5"
                            src={Selected}
                            alt="selected"
                          />
                        }
                        className="radioBT"
                        checked={selectAllChecked}
                        onChange={toggleSelectAll}
                        inputProps={{ "aria-label": "A" }}
                      />
                    }
                    label={
                      <div className="text-white text-sm antialiased">
                        Select All
                      </div>
                    }
                    sx={{
                      textAlign: "center",
                      letterSpacing: "0px",
                      color: "#dddddd",
                      ".MuiFormControlLabel-label": {
                        font: "normal normal 600 16px/22px Open Sans",
                      },
                    }}
                    labelPlacement="end"
                  />
                </div>
              </div>
              <p className="py-4 pb-0 px-8 bg-[#314C7F] text-[#DADADA] font-normal text-xs">
                Selecting "Select all" or proceeding without selection means
                that the categories listed below will be assigned to this user.
              </p>
            </div>
            <div
              className="bg-[#314C7F] py-6 h-full flex flex-col rounded-b-4xl "
              style={{ height: "315px" }}
            >
              <div className="w-full flex flex-col pl-8 pr-6 gap-6 overflow-auto h-72 custom-scroll-container">
                <div className="text-justify flex flex-col gap-5">
                  {sortedCategories.map((category, index) => {
                    const isSelected = selectedCategories.includes(
                      category.name
                    );
                    const isHovered = hoveredIndex === index;

                    const backgroundColor = isSelected
                      ? "#4A6D4B"
                      : isHovered
                      ? "#667CA3"
                      : "#3A5686";

                    return (
                      <Tooltip
                        key={index}
                        title={category.name}
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              [`& .${tooltipClasses.arrow}`]: {
                                color: (theme) => theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        arrow
                        PopperProps={{
                          style: {
                            maxWidth: "none",
                          },
                        }}
                      >
                        <Button
                          key={index}
                          size="small"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            backgroundColor: backgroundColor,
                            borderRadius: "14px",
                            color: "#dadada",
                            height: "50px",
                            padding: "0 14px",
                            fontSize: "16px",
                            cursor: "pointer",
                            transition:
                              "background-color 0.3s, box-shadow 0.3s",
                            font: "normal normal 600 14px/19px Open Sans",
                            boxShadow: "5px 5px 11px #00000029",
                          }}
                          onClick={() => handleCategorySelect(category.id)}
                          onMouseEnter={() => handleMouseEnter(index)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <div className="w-[15%] bg-white cateEditParent">
                            <img
                              className="w-9 h-9 cateEditImage"
                              src={
                                category?.name == "Vendor30" ||
                                category?.name == "Vendor40"
                                  ? BeneIcon
                                  : category.logo_url
                                  ? category.logo_url
                                  : BeneIcon
                              }
                              alt="bene"
                            />
                          </div>

                          <span
                            className="pr-4 pl-2 categoryName"
                            style={{ textTransform: "capitalize" }}
                          >
                            {category.name}
                          </span>
                          <div style={{ marginLeft: "auto" }}>
                            <Checkbox
                              icon={
                                <img
                                  className="w-5 h-5"
                                  src={Notselected}
                                  alt=""
                                />
                              }
                              checkedIcon={
                                <img
                                  className="w-5 h-5"
                                  src={Selected}
                                  alt=""
                                />
                              }
                              className="radioBT"
                              value={category.id}
                              name="categoryCheckbox"
                              inputProps={{ "aria-label": "A" }}
                              checked={isSelected}
                              onChange={() =>
                                handleCategorySelect(category.name)
                              }
                            />
                          </div>
                        </Button>
                      </Tooltip>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BeneEditCategories;
