import React from "react";
import "./index.css";
import { useRef } from "react";
import BeneAccViewAll from "../../../../containers/Accounts/BeneficiaryAccount/BeneAccViewAll";

const BeneTxnAccountModal = ({ onClose, onAccountSelect, type }) => {
  const handleButtonClick = (dataItem) => {
    if (type === "viewAll") {
      onAccountSelect(
        dataItem.name.trim(),
        dataItem.bank_acc_no.trim(),
        dataItem.ifsc_code.trim(),
        dataItem.id
      );
    } else {
      onAccountSelect(
        dataItem.name.trim(),
        dataItem.bank_acc_no.trim(),
        dataItem.ifsc_code.trim(),
        dataItem.bank_name,
        dataItem.category,
        dataItem.pan_no,
        dataItem.id
      );
    }
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-screen z-[9998] "
        style={{ backgroundColor: "#000000", opacity: "0.8" }}
      ></div>
      <div className="main_pop_div">
        <BeneAccViewAll
          typepop={"popup"}
          onClosepop={onClose}
          handleButtonClick={handleButtonClick}
        />
      </div>
    </>
  );
};

export default BeneTxnAccountModal;
