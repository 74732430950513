import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import loactionImg from "../../../assets/Beneficiary Icons/location.png";
import LoaderIcon from "../../../assets/Beneficiary Icons/inputloder.gif";

const defaultPosition = [19.043690333072874, 72.8429991];

const ChangeView = ({ center }) => {
  const map = useMap();
  map.setView(center, map.getZoom());
  return null;
};

const customIcon = L.icon({
  iconUrl: loactionImg,
  iconSize: [38, 38],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38],
});

const GeoMapLeaflet = (props) => {
  const [position, setPosition] = useState(defaultPosition);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const address = props.address;
    const geocodeAddress = async (address) => {
      try {
        const response = await fetch(`https://api.radar.io/v1/geocode/forward?query=${address}`, {
          headers: {
            "Authorization": "prj_test_pk_a273c303ac12e9e092cc219f02503293fcb4e4e2",
          },
        });

        const data = await response.json();
        if (data.addresses && data.addresses.length > 0) {
          const { latitude, longitude } = data.addresses[0];
          setPosition([latitude, longitude]);
        }
      } catch (error) {
        console.error("Error fetching geocode:", error);
      } finally {
        setLoading(false); // Stop loading after fetching
      }
    };

    geocodeAddress(address);
  }, [props.address]);

  if (loading) {

    if (props.owner === "ownerDetails") {
      return (
        <div style={{
          width: "40px",
          height: "40px",
          marginLeft: "180px",
          marginTop: "150px",
        }}>
          <img src={LoaderIcon} alt="Loading..." />
        </div>
      );
    } else if (props.owner === "cin_details") {
      return (
        <div style={{
          width: "40px",
          height: "40px",
          marginLeft: "150px",
          marginTop: "100px",
        }}>
          <img src={LoaderIcon} alt="Loading..." />
        </div>
      );
    } else {
      return (
        <div style={{
          width: "40px",
          height: "40px",
          marginLeft: "80px",
          marginTop: "100px",
        }}>
          <img src={LoaderIcon} alt="Loading..." />
        </div>
      );
    }
  }

  return (
    <MapContainer
      center={position}
      zoom={13}
      style={{
        height:
          props.owner === "ownerDetails" ? "310px" :
            props.owner === "beneDetails" ? "200px" :
              props.owner === "gstDetails" ? "225px" : "100%",
        width:
          props.owner === "ownerDetails" ? "425px" :
            props.owner === "beneDetails" ? "332px" :
              props.owner === "gstDetails" ? "348px" : "100%",
      }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={position} icon={customIcon}>
        <Popup>The custom icon is here!</Popup>
      </Marker>
      <ChangeView center={position} />
    </MapContainer>
  );
};

export default GeoMapLeaflet;
