import React, { useEffect, useState } from "react";
import "./index.css";
import { useSelector } from "react-redux";
import AccordionIcon from "../../../../assets/landingPage/ArrowDownWhiteCircle.svg";
import Footer from "../../../../components/LandingPage/Footer";
import BackToTop from "../../../../assets/landingPage/TopArrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function Corporates() {
  const [selectedCorporatesData, setSelectedCorporatesData] = useState([1]);
  const [activeBox, setActiveBox] = useState(1);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);

  const { mobilestatus, webstatus } = useSelector(
    (state) => state.landingpagestate
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const CorporatesSolutions = [
    {
      id: 1,
      heading: "• Register Multiple Owner and Vendor Accounts: ",
      data: "Easily set up accounts with real-time verification using PAN and bank account details.",
    },
    {
      id: 2,
      heading: "• Balance & Statements: ",
      data: "View real-time balances and generate detailed, customizable statements.",
    },
    {
      id: 3,
      heading: "• Payments & Receipts: ",
      data: "Manage transactions, send/receive funds, and track payments securely.",
    },
    {
      id: 4,
      heading: "• Virtual Accounts: ",
      data: "Create and manage digital accounts for simplified fund tracking and management.",
    },
    {
      id: 5,
      heading: "• Mandate API/eNACH: ",
      data: "Implement solutions for electronic National Automated Clearing House (eNACH) mandates for automated payments.",
    },
    {
      id: 6,
      heading: "• UPI API: ",
      data: "Integrate with Unified Payments Interface (UPI) APIs to enable seamless real-time payments and collections for current accounts.",
    },
    {
      id: 7,
      heading: "• Reconciliation: ",
      data: "Automatically gather and verify transaction data across various sources, ensuring accuracy and simplifying reconciliation processes.",
    },
    {
      id: 8,
      heading: "• Notifications & Alerts: ",
      data: "Receive real-time notifications for transactions via email, SMS, push notifications, or in-app alerts.",
    },
    {
      id: 9,
      heading: "• Treasury Management:  ",
      data: "Optimize your financial assets, investments, and risk management.",
    },
    {
      id: 10,
      heading: "• Analytics & Reporting: ",
      data: "Leverage real-time data to drive decision-making and stay ahead of market trends.",
    },
    {
      id: 11,
      heading: "• BBPS Integration: ",
      data: "Centralize your bill payments through the Bharat Bill Payment System.",
    },
    {
      id: 12,
      heading: "• Security",
      data: "Safeguard your financial data with multi-layered protection, including user role authentication, service-based OTPs, and secure identity measures.",
    },
  ];

  const boxData = {
    1: [1],
    2: [2, 3, 6, 10],
    3: [12],
    4: [7],
    5: [6, 11],
    6: [1, 2, 3],
    7: [4, 5, 7, 10],
    8: [12],
    9: [7, 8, 9],
    10: [7],
  };

  const handleBoxClick = (id) => {
    setActiveBox(id);
    setSelectedCorporatesData(boxData[id] || []);
  };

  const isBoxActive = (id) => activeBox === id;

  const sections = [
    {
      title: "Excessive paperwork",
      description: "Hinders efficiency and delays processes.",
      solution: [1],
    },
    {
      title: "Time-taking processes",
      description:
        "Slows down transactions and reduces operational effectiveness.",
      solution: [2, 3, 6, 10],
    },
    {
      title: "Less security",
      description:
        "Inadequate measures leave financial systems vulnerable to breaches.",
      solution: [12],
    },
    {
      title: "Lack of standardisation",
      description: "Complicates data integration and reconciliation processes.",
      solution: [7],
    },
    {
      title: "Connectivity concerns",
      description: "Disrupts data flow and hampers services.",
      solution: [6, 11],
    },
    {
      title: "Multiple bank connections",
      description:
        "Managing multiple banks increases complexity and resource consumption.",
      solution: [1, 2, 3],
    },
    {
      title: "Lack of automation",
      description:
        "Reliance on manual systems leads to inefficiencies and errors.",
      solution: [4, 5, 7, 10],
    },
    {
      title: "Security concerns",
      description: "Weak protocols expose businesses to cyber threats.",
      solution: [12],
    },
    {
      title: "Inefficient resource management",
      description:
        "Mismanaged resources cause operational delays and extra costs.",
      solution: [7, 8, 9],
    },
    {
      title: "Manual reconciliation processes",
      description:
        "Relying on manual reconciliation is time-consuming, prone to errors, and delays the overall financial close process, impacting efficiency and accuracy.",
      solution: [7],
    },
  ];

  const handleAccordionClick = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20 || document.documentElement.scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {isVisible && (
        <div
          onClick={scrollToTop}
          className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
        >
          <CircularProgressWithLabel value={scrollPercent} />
        </div>
      )}
      {isMobile ? (
        <div
          style={{
            overflowY: mobilestatus && "hidden",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            WebkitOverflowScrolling: "touch",
          }}
          className="bg-[#031f4f]"
        >
          <div className="p-[30px] mt-[60px] flex flex-col gap-2.5 text-[#F9F9F9] bg-[#1d3a6d]">
            <div className="text-lg font-semibold">
              Simplify your payments,
              <br />
              Empower your business.
            </div>
            <div className="text-sm font-normal">
              <p className="font-medium">
                Xpentra 360 is an all-in-one banking platform for corporates
                that links all your bank accounts, manages payments, and
                streamlines your financial operations from a single platform
                across different banks.
              </p>
              <p className="font-bold mt-[5px]">
                Optimise your corporate treasury and financial operations with
                Xpentra
              </p>
              <p className="mt-[5px]">
                Optimise your corporate treasury and financial operations with
                XpentraEnhance efficiency, security, and scalability in your
                financial processes with our integrated solutions.
              </p>
            </div>
          </div>

          <div className="bg-[#031F4F] px-[30px]">
            <p className="text-[#F9F9F9] text-[16px] font-semibold mb-[20px] mt-[30px] text-left sm:text-center">
              <span className="text-[#31E5E5]">Challenges</span> faced by
              Corporates
            </p>
            <p className="text-[#F9F9F9] text-xs font-normal">
              <span>
                Corporates today face a range of operational hurdles that hinder
                productivity and security. Xpentra helps you tackle these
                challenges effectively.
              </span>
            </p>
          </div>

          <div className="p-[30px] flex flex-col gap-[20px] bg-[#031f4f] mb-[-16px]">
            {sections.map((section, index) => (
              <div key={index}>
                <div
                  className={`${
                    expandedIndex === index
                      ? "rounded-t-[10px] bg-[#314D7E]"
                      : "rounded-[10px] bg-[#102C5D]"
                  } justify-between h-[95px] mx-auto p-[20px] flex items-center cursor-pointer gap-[10px]`}
                  onClick={() => handleAccordionClick(index)}
                >
                  <div className="text-[#F9F9F9]">
                    <p className="font-semibold text-[14px]">{section.title}</p>
                    <p className="font-normal text-[12px]">
                      {section.description}
                    </p>
                  </div>
                  <div
                    className={`transition-transform duration-500 ease-in-out ${
                      expandedIndex === index ? "-rotate-180" : ""
                    }`}
                    style={{ flexShrink: "0" }}
                  >
                    <img src={AccordionIcon} alt="AccordionIcon" />
                  </div>
                </div>

                <div
                  className={`h-[1px] bg-[#F9F9F9] w-[95%] mx-auto relative z-[1] ${
                    expandedIndex === index ? "visible" : "invisible"
                  }`}
                ></div>

                <div
                  className={`rounded-b-[10px] bg-[#314D7E] mt-[-1px] mx-auto overflow-hidden transition-all duration-500 ease-in-out ${
                    expandedIndex === index
                      ? "h-[max-content] py-[20px]"
                      : "h-0"
                  } px-[20px] flex flex-col gap-[4px]`}
                >
                  <p className="text-[12px] text-[#31E5E5] font-semibold">
                    Solutions
                  </p>
                  {section.solution.map((solutionId) => {
                    const matchedSolution = CorporatesSolutions.find(
                      (sol) => sol.id === solutionId
                    );
                    return (
                      matchedSolution && (
                        <div key={matchedSolution.id}>
                          <p className="text-[14px] text-[#F9F9F9] font-semibold">
                            {matchedSolution.heading}
                          </p>
                          <p className="text-[12px] text-[#F9F9F9] font-normal">
                            {matchedSolution.data}
                          </p>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            ))}
          </div>
          <Footer />
        </div>
      ) : (
        <div
          className="bg-[#031F4F]"
          style={{
            overflow: webstatus ? "hidden" : "",
            height: "100vh",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            WebkitOverflowScrolling: "touch",
          }}
        >
          <div className="bg-[#1d3a6d]">
            <div className="h-[462px] max-w-[1440px] mx-auto w-screen flex bg-no-repeat bg-center bg-cover bg-[#1D3A6D]">
              <div className="w-auto pl-[120px] flex flex-col justify-center">
                <p
                  className="text-[#F9F9F9] text-[32px] p-0 font-semibold tracking-wider"
                  style={{ lineHeight: "1.2" }}
                >
                  Simplify your payments, <br />
                  Empower your business.
                </p>
                <p className="text-[#F9F9F9] text-[14px] mt-[10px] p-0">
                  Xpentra 360 is an all-in-one banking platform for corporates
                  that links all your bank accounts, manages
                  <br /> payments, and streamlines your financial operations
                  from a single platform across different banks.
                </p>
                <p className="text-[#F9F9F9] text-[14px] mt-[20px] p-0">
                  <span className="text-[16px] font-semibold">
                    Optimise your corporate treasury and financial operations
                    with Xpentra
                  </span>
                  <br />
                  Enhance efficiency, security, and scalability in your
                  financial processes with our integrated solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="card-section">
            <div className="max-w-[1024px] mx-auto">
              <div className="flex flex-col items-center justify-center">
                <div className="header-content flex flex-col items-center pt-2">
                  <h2>
                    <span className="text-[#31E5E5]">Challenges</span> faced by
                    corporates
                  </h2>
                  <p className="text-xs text-[#fff] px-[100px] py-[20px] text-center">
                    Corporates today face a range of operational hurdles that
                    hinder productivity and security. Xpentra helps you tackle
                    these challenges effectively.
                  </p>
                </div>
                <div className="grid-container">
                  <div
                    className={`box text-right rounded-tl-lg cursor-pointer ${
                      isBoxActive(1) ? "bg-[#466398]" : "bg-[#102C5D]"
                    }`}
                    onClick={() => handleBoxClick(1)}
                  >
                    <span className="title-sec">Excessive paperwork</span>{" "}
                    <br />
                    <p className="p-0">
                      Hinders efficiency and delays processes.
                    </p>
                  </div>

                  <div className="box text-center text-[28px] font-semibold bg-[#314D7E]">
                    Xpentra’s <span className="text-[#31E5E5]">solutions</span>
                    <br /> for corporates
                  </div>

                  <div
                    className={`box rounded-tr-lg cursor-pointer ${
                      isBoxActive(6)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(6)}
                  >
                    <span className="title-sec">Multiple bank connections</span>{" "}
                    <br />
                    <p className="p-0">
                      Managing multiple banks increases complexity and resource
                      consumption.
                    </p>
                  </div>

                  <div
                    className={`box text-right cursor-pointer ${
                      isBoxActive(2)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(2)}
                  >
                    <span className="title-sec">Time-taking processes</span>{" "}
                    <br />
                    <p className="p-0">
                      Slows down transactions and reduces operational
                      effectiveness.
                    </p>
                  </div>

                  <div className="box row-span-4 bg-gradient-to-b from-[#314d7e] to-[#082455] ">
                    <ul>
                      {selectedCorporatesData.map((fe) => {
                        const matchedData = CorporatesSolutions.find(
                          (data) => data.id === fe
                        );
                        return matchedData ? (
                          <li key={matchedData.id} className="mb-[20px]">
                            <span className="title-sec">
                              {matchedData.heading}
                            </span>
                            <p>{matchedData.data}</p>
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </div>

                  <div
                    className={`box cursor-pointer ${
                      isBoxActive(7)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(7)}
                  >
                    <span className="title-sec">Lack of automation</span> <br />
                    <p className="p-0">
                      Reliance on manual systems leads to inefficiencies and
                      errors.
                    </p>
                  </div>

                  <div
                    className={`box text-right cursor-pointer ${
                      isBoxActive(3)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(3)}
                  >
                    <span className="title-sec">Less security</span> <br />
                    <p className="p-0">
                      Inadequate measures leave financial systems vulnerable to
                      breaches.
                    </p>
                  </div>

                  <div
                    className={`box cursor-pointer ${
                      isBoxActive(8)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(8)}
                  >
                    <span className="title-sec">Security concerns</span> <br />
                    <p className="p-0">
                      Weak protocols expose businesses to cyber threats.
                    </p>
                  </div>

                  <div
                    className={`box text-right cursor-pointer ${
                      isBoxActive(4)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(4)}
                  >
                    <span className="title-sec">Lack of standardisation</span>{" "}
                    <br />
                    <p className="p-0">
                      Complicates data integration and reconciliation processes.
                    </p>
                  </div>

                  <div
                    className={`box cursor-pointer ${
                      isBoxActive(9)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(9)}
                  >
                    <span className="title-sec">
                      Inefficient resource management
                    </span>
                    <br />
                    <p className="p-0">
                      Mismanaged resources cause operational delays and extra
                      costs.
                    </p>
                  </div>

                  <div
                    className={`box text-right rounded-bl-lg cursor-pointer ${
                      isBoxActive(5)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(5)}
                  >
                    <span className="title-sec">Connectivity concerns</span>{" "}
                    <br />
                    <p className="p-0">
                      Disrupts data flow and hampers services.
                    </p>
                  </div>

                  <div
                    className={`box rounded-br-lg cursor-pointer ${
                      isBoxActive(10)
                        ? "bg-[#466398]"
                        : "bg-[#102C5D] hover:bg-[#466398] hover:text-[#fff] hover:opacity-70"
                    }`}
                    onClick={() => handleBoxClick(10)}
                  >
                    <span className="title-sec">
                      Manual reconciliation processes
                    </span>
                    <br />
                    <p className="p-0">
                      Relying on manual reconciliation is time-consuming, prone
                      to errors, and delays the overall financial close process,
                      impacting efficiency and accuracy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
}

export default Corporates;
