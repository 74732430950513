import React, { useState, useRef, useEffect } from "react";
import { Button, Box } from "@mui/material";

import cancel from "../../../../assets/Settings/Dashboard/cancel.png";
import NotSelect from "../../../../assets/Approve transactions/not selected.png";

import Selected from "../../../../assets/Approve transactions/selected.png";
import search_icon from "../../../../assets/Approve transactions/search-white.png";
import arrowup from "../../../../assets/OwnerAccount/arrowup.png";
import arrowdown from "../../../../assets/OwnerAccount/arrowdown1.png";
import "./index.css";

const OwnerDetailsSelectTag = (props) => {
  const [tagSearchName, setTagSearchName] = useState("");
  const [icon, setIcon] = useState(arrowup);
  const [selectedTagValue, setSelectedTagValue] = useState([]);
  const [tagSelection, setTagSelection] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [message, setMessage] = useState(false);

  const tagInputRef = useRef("");

  const handleClose = () => {
    props.onClose();
  };

  let tagselection = [];
  let selectedTagvalue = [];

  useEffect(() => {
    let data = [];
    let newData = [];
    if (props?.ownerExistingTag) {
      data = props?.ownerExistingTag?.map((res) => {
        return {
          selected: props?.existingTags.includes(res)
            ? false
            : props?.ownerviewall
            ? true
            : false,
          value: res,
          type: "ownerTags",
        };
      });
    }

    const availableTags = sessionStorage?.getItem("AvailableTags");
    if (
      availableTags &&
      availableTags !== undefined &&
      availableTags !== null
    ) {
      const parsedTags = JSON.parse(availableTags);

      parsedTags?.forEach((tag) => {
        if (!props?.ownerExistingTag?.includes(tag)) {
          newData?.push({ selected: true, value: tag, type: "existingTag" });
        }
      });
    }

    props?.setNewTags([...data, ...newData]);
  }, []);

  const handleClick = () => {
    setIcon(icon === arrowup ? arrowdown : arrowup);
  };

  const handleConfirmTag = async () => {
    if (props?.ownerviewall) {
      props?.handlefilterPayload((data) => {
        return {
          ...data,
          ownerTags: props?.newTags
            .filter((tag) => tag.selected === false)
            .map((tag) => tag.value),
        };
      });
      props.onClose();
    } else {
      let flag1 = false;
      let flag2 = false;

      props.newTags.forEach((tag) => {
        if (tag.selected) {
          flag2 = true;
        } else {
          flag1 = true;
        }
      });

      try {
        if (flag1) {
          await props?.handleAddTags();
        }

        if (flag2) {
          await props?.handleRemoveTags();
        }
      } catch (error) {
        console.error("Error updating tags: ", error);
      }
    }
  };

  const handleNewTag = () => {
    const tagExists = props?.newTags.some(
      (tag) => tag.value.toLowerCase() === tagSearchName.toLowerCase()
    );

    if (tagExists) {
    } else {
      props?.setNewTags([
        ...props?.newTags,

        { selected: false, value: tagSearchName, type: "newTag" },
      ]);
    }
    setTagSearchName("");
  };

  const handleSelectandDeselect = (selectedTag) => {
    const updatedTags = props?.newTags?.reduce((acc, res) => {
      if (res.value === selectedTag && res.type === "newTag") {
        if (!res.selected) {
          return acc;
        } else {
          return [...acc, { ...res, selected: true }];
        }
      } else {
        return [...acc, res];
      }
    }, []);

    props?.setNewTags(updatedTags);
  };

  useEffect(() => {
    props?.newTags?.forEach((tag) => {
      if (tag.type === "ownerTags") {
        selectedTagvalue.push(tag.value);
        setIsDisabled(props?.ownerviewall ? true : false);
      }

      if (tag.type === "newTag" || tag.type === "ownerTags") {
        selectedTagvalue.push(tag.value);
        setIsDisabled(props?.ownerviewall ? false : true);
      }

      if (tag.type === "newTag" || tag.type === "existingTag") {
        selectedTagvalue.push(tag.value);
        setIsDisabled(false);
      }
    });
    setIsDisabled(false);
  }, [props.newTags]);

  useEffect(() => {
    props.newTags?.forEach((tag) => {
      if (tag.selected == (props?.ownerviewall ? false : true)) {
        tagselection.push(tag.value);
      }

      if (!props?.ownerviewall) {
        if (
          tagselection.length >= selectedTagvalue.length &&
          (tag.type === "ownerTags" || tag.type === "existingTag")
        ) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      } else {
        if (tagselection.length >= 1 && tag.type === "ownerTags") {
          setIsDisabled(true);
        } else {
          setIsDisabled(false);
        }
      }
    });
  }, [props.newTags]);

  const handleExistingTag = (selectedTag) => {
    const updatedTags = props?.newTags.map((tag) => {
      if (tag.value === selectedTag && tag.type !== "newTag") {
        return { ...tag, selected: !tag.selected };
      } else {
        return tag;
      }
    });

    props?.setNewTags(updatedTags);
  };

  const filteredExistingTags = props?.newTags?.filter((tag) => {
    return (
      (tag.type === "existingTag" || tag.type === "ownerTags") &&
      tag?.value?.toLowerCase().includes(tagSearchName?.toLowerCase())
    );
  });

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !props?.ownerviewall) {
      const inputValue = e.target.value;
      if (inputValue.trim()) {
        handleNewTag();
      }
    }
  };

  const InputChange = (e) => {
    const valueChange = e.target.value;
    const limitedValue = valueChange.substring(0, 30);
    const ProcessChange = limitedValue.replace(/\s+/g, "").toLowerCase();

    setTagSearchName(ProcessChange);

    const dett = props?.newTags?.filter((tag) => {
      return tag?.value?.toLowerCase().includes(ProcessChange?.toLowerCase());
    });

    if (dett.length === 0 && ProcessChange.length > 0) {
      setMessage(true);
    } else {
      setMessage(false);
    }
  };

  const renderTagMessages = () => {
    if (props?.newTags?.length === 0) {
      return (
        <p>
          {props?.ownerviewall ? (
            "No existing tags found."
          ) : (
            <>
              No existing tags found.
              <br />
              To assign a tag, Kindly enter a new tag.
            </>
          )}
        </p>
      );
    } else if (filteredExistingTags?.length === 0 && tagSearchName.length > 0) {
      return <p>No data found, for this entry.</p>;
    } else if (
      filteredExistingTags?.length === 0 &&
      tagSearchName.length === 0
    ) {
      return (
        <p>
          {props?.ownerviewall ? (
            "No existing tags found."
          ) : (
            <>
              No existing tags found.
              <br />
              To assign a tag, Kindly enter a new tag.
            </>
          )}
        </p>
      );
    }
    return null;
  };

  useEffect(() => {
    if (props?.newTags?.length === 0 && props?.newTags?.type === "newTag") {
      setMessage(false);
    } else {
      setMessage(true);
    }
  }, [props.newTags]);

  return (
    <>
      <div
        className="flex  w-full h-full justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] p-3 overflow-y-auto"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className="h-fit flex flex-col relative mt-10 w-[561px] h-[605px] bg-[#1D3A6D] rounded-2xl"
          onClick={(e) => e.stopPropagation()}
        >
          <div className=" pl-[30px] pt-[30px] ">
            <div className="flex justify-between pb-5">
              <h6 className="text-[#DADADA] pb-1 md:font-bold ">
                {props?.ownerviewall ? "Select tags:" : "Assign tags:"}
              </h6>
            </div>

            <div className=" tag-search mt-5 ">
              <div
                className="mx-2 my-0"
                onClick={() => tagInputRef.current.focus()}
              >
                <img className="tag-down-search" src={search_icon} alt="" />
              </div>
              <div className="tag-partition-one label-left">
                <input
                  ref={tagInputRef}
                  className="input-custom-search"
                  type="text"
                  value={tagSearchName}
                  placeholder={
                    props?.ownerviewall
                      ? "Search new tag"
                      : "Search/Enter new tag"
                  }
                  onChange={InputChange}
                  onKeyDown={handleKeyPress}
                  autoFocus
                />
              </div>
              <div
                className="tag-partition-two"
                onClick={() => tagInputRef.current.focus()}
              >
                {tagSearchName.length > 0 ? (
                  <>
                    <img
                      className="w-[14px] h-[14px] cursor-pointer m-auto"
                      alt="cancel"
                      src={cancel}
                      onClick={(e) => {
                        e.stopPropagation();
                        setTagSearchName("");
                      }}
                    />
                  </>
                ) : null}
              </div>
              {!props?.ownerviewall && (
                <>
                  <div className=" border-l-2  border-[#1D3A6D] h-[24px] mr-[0.5px]"></div>
                  <div className=" ">
                    <Button
                      className={`${
                        tagSearchName === ""
                          ? "btn-custom-primary "
                          : "btn-custom-secondary "
                      } `}
                      disabled={tagSearchName === ""}
                      onClick={() => handleNewTag()}
                    >
                      Assign new tag
                    </Button>
                  </div>
                </>
              )}
            </div>

            <div className=" overflow-y-scroll h-[390px] pr-[30px] pb-12 Owner-detail mt-3   ">
              {props?.newTags?.filter((fil) => fil?.type === "newTag")?.length >
                0 && filteredExistingTags ? (
                <>
                  <div className=" tag-list h-auto flex mt-[20px]  ">
                    <div className="tag-list ">
                      <p>New assigned tags</p>
                    </div>
                    <div className="flex flex-wrap flex-row ">
                      {props?.newTags?.length > 0 &&
                        props?.newTags
                          ?.filter((fil) => fil?.type === "newTag")
                          ?.map((tag) => {
                            return (
                              <>
                                <Box
                                  className="tag-box-item relative"
                                  key={tag?.value}
                                  style={{
                                    backgroundColor:
                                      tag.selected === true ? "" : "#4A6D4B",
                                  }}
                                  onClick={() =>
                                    handleSelectandDeselect(tag.value)
                                  }
                                >
                                  <>
                                    <span
                                      id="id-textTag"
                                      style={{
                                        display: "inline-block",
                                        fontSize: "12px",
                                        paddingRight: "5px",
                                        color: "#F9F9F9",
                                      }}
                                    >
                                      {tag.value}
                                    </span>

                                    <span className="w-[17px] h-[17]">
                                      {tag.selected ? (
                                        <img
                                          className=""
                                          src={NotSelect}
                                          alt=""
                                        />
                                      ) : (
                                        <img
                                          className=""
                                          src={Selected}
                                          alt=""
                                        />
                                      )}
                                    </span>
                                  </>
                                </Box>
                              </>
                            );
                          })}
                    </div>
                  </div>
                </>
              ) : null}

              <div className="tag-list mt-[20px]  ">
                <p>Existing tags</p>
              </div>
              <div className=" h-auto w-full">
                {props?.newTags?.filter(
                  (fil) =>
                    fil?.type === "existingTag" || fil?.type === "ownerTags"
                )?.length > 0 && filteredExistingTags?.length > 0 ? (
                  <div>
                    <div className="flex flex-wrap">
                      {props?.newTags?.length > 0 &&
                        props?.newTags &&
                        filteredExistingTags
                          ?.filter(
                            (fil) =>
                              fil?.type === "existingTag" ||
                              fil?.type === "ownerTags"
                          )
                          ?.map((tag) => {
                            return (
                              <>
                                <Box
                                  key={tag?.value}
                                  className={"px-4 tag-box-item relative"}
                                  style={{
                                    backgroundColor:
                                      tag.selected == false ? "#4A6D4B" : "",
                                  }}
                                  onClick={() => handleExistingTag(tag.value)}
                                >
                                  <div>
                                    <>
                                      <span
                                        id="id-textTag"
                                        style={{
                                          display: "inline-block",
                                          fontSize: "12px",
                                          paddingRight: "5px",
                                        }}
                                      >
                                        {tag.value}
                                      </span>

                                      <span className="w-[17px] h-[17]">
                                        {tag.selected == false ? (
                                          <img
                                            className=""
                                            src={Selected}
                                            alt=""
                                          />
                                        ) : (
                                          <img
                                            className=""
                                            src={NotSelect}
                                            alt=""
                                          />
                                        )}
                                      </span>
                                    </>
                                  </div>
                                </Box>
                              </>
                            );
                          })}
                    </div>
                  </div>
                ) : (
                  <div className="existing-tag mt-3">{renderTagMessages()}</div>
                )}
              </div>
            </div>
          </div>

          {icon == arrowdown ? (
            <div
              className="tag-bottom-expand px-[30px]  overflow-y-auto mt-4   "
              style={{
                display: `${
                  props?.newTags?.filter((fil) => !fil.selected).length > 0
                    ? ""
                    : ""
                }`,
              }}
            >
              {props?.newTags?.filter((fil) => !fil.selected).length > 0 ? (
                props?.newTags?.map((tag) => {
                  return (
                    <>
                      {!tag.selected && (
                        <>
                          <Box
                            className=" px-4 Assign-box-item relative"
                            key={tag?.value}
                          >
                            <div className=" flex items-center ">
                              <>
                                <span
                                  className="make-first-letter-small  "
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {tag.value}
                                </span>
                              </>
                            </div>
                          </Box>
                        </>
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  <div className=" h-[40px] flex items-center ">
                    <span className=" text-[#DADADA] h-[19px] ml-[5px] mb-2 ">
                      {" "}
                      {props?.ownerviewall
                        ? "No tags selected"
                        : "No tags selected for this account"}{" "}
                    </span>
                  </div>
                </>
              )}
            </div>
          ) : null}
          <div
            className="tag-bottom mt-5 "
            style={{
              borderRadius: icon == arrowdown ? "0 0 16px 16px" : "16px",
            }}
          >
            <div className="flex p-[18px]   ">
              <div className=" bg-[#102C5D] text-[#FFFFFF] flex justify-center items-center rounded-[50%] text-[14px] w-[40px] h-[40px]">
                <p className=" text-[20px]">
                  {props?.newTags?.filter((fil) => !fil.selected)?.length}
                </p>
              </div>
              <span className="  items-center mt-[14px] ml-[10px]  text-[16px] font-normal leading-[14px] text-[#F9F9F9]">
                Tags selected
              </span>

              <div className="show-btn">
                <div className="show_overbtn" onClick={handleClick}>
                  <div style={{ flex: "50%" }}>
                    {icon == arrowup ? "Show" : "Hide"}
                  </div>
                  <div style={{ flex: "50%" }}>
                    <img
                      src={icon}
                      alt="Status Icon"
                      className=" h-[12px] w-[12px] ml-[2px] "
                    />
                  </div>
                </div>
              </div>
              <div className="border-l-2 border-[#102C5D] p-[2px]"></div>
              <div className="main-btn">
                <div className="cancel_overbtn" onClick={handleClose}>
                  Cancel
                </div>
                <div
                  className="confirm_overbtn"
                  style={{
                    backgroundColor:
                      isDisabled || props?.existingTags.length > 0
                        ? "#69B76F"
                        : "#707070",
                    cursor:
                      isDisabled || props?.existingTags.length > 0
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() =>
                    (isDisabled || props?.existingTags.length > 0) &&
                    handleConfirmTag()
                  }
                >
                  Confirm
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerDetailsSelectTag;
