import React from "react";
import BeneTxnDetails from "../../../containers/BeneficiaryTransactions/BeneTxnDetails";
import "./index.css";

function BeneTxnDetailPagePopup({
  closeBeneTxnDetailModal,
  bene_txn_id,
  type,
}) {
  return (
    <>
      <div
        className="beneTxnDetailPop_div fixed overflow-hidden rounded-[20px] z-[999] bg-[#1D3A6D]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="h-full w-full">
          <BeneTxnDetails
            bene_txn_id={bene_txn_id}
            closeBeneTxnDetailModal={closeBeneTxnDetailModal}
            type={type}
          />
        </div>
      </div>
    </>
  );
}

export default BeneTxnDetailPagePopup;
