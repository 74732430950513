import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import UnifiedFinancialManagement from "../../../../src/assets/landingPage/UnifiedFinancialManagement.svg";
import Analytics from "../../../../src/assets/landingPage/Analytics.svg";
import Security from "../../../../src/assets/landingPage/Security.svg";
import AuthorizationMatrix from "../../../../src/assets/landingPage/AuthorizationMatrix.svg";
import multi_approve_mp4 from "../../../assets/landingPage/multi_approve_mp4.mp4";

const UniSecCarosel = () => {
  const sections = [
    {
      title: "Security",
      description:
        "Xpentra ensures the highest level of security with multi-factor authentication, encryption, and audit trails, safeguarding your financial data and transactions at every step.",
      image: Security,
    },
    {
      title: "Unified Financial Management",
      description:
        "Xpentra brings all your banking and financial operations-payments, balances, reconciliation, and more- into one streamlined platform, simplifying workflows and boosting efficiency.",
      image: UnifiedFinancialManagement,
    },
    {
      title: "Advanced Analytics",
      description:
        "Our platform provides real-time analytics and customizable reports, offering deep insights into transaction data, cash flow, and performance to support smarter, data-driven decisions.",
      image: Analytics,
    },

    {
      title: "Authorization Matrix",
      description:
        "The customizable authorization matrix allows you to define roles, permissions, and approval workflows, ensuring that only authorized personnel have access to critical financial actions.",
      image: AuthorizationMatrix,
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isPaused, setIsPaused] = useState(false);
  const [pausedTransform, setPausedTransform] = useState(null);
  const intervalTime = 2000;

  const handleMouseOver = () => {
    setIsPaused(true);
    const currentTransform = `translateX(- ${currentIndex * (300 + 20)}px)`;
    setPausedTransform(currentTransform);
  };
  const handleMouseOut = () => {
    setIsPaused(false);
    setPausedTransform(null);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % sections.length);
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [isPaused, sections.length]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      <div>
        {isMobile ? (
          <div className="bg-[#031f4f] pt-[50px] pb-5  flex flex-col gap-4">
            <p className="text-[#F9F9F9] text-[16px] font-semibold pl-[5px] xs:pl-[30px]">
              What makes us <span className="text-[#31E5E5]">unique?</span>
            </p>
            <div className="max-w-[300px] rounded-[10px] mx-auto">
              <div className="rounded-t-[10px]">
                <video
                  className="rounded-[10px]"
                  width="600"
                  autoPlay
                  loop
                  playsInline
                  muted
                >
                  <source src={multi_approve_mp4} type="video/mp4" />
                </video>
              </div>
              <div>
                <div className="overflow-hidden h-[218px] rounded-[10px] relative mt-[20px]">
                  <div
                    className="flex gap-[20px]"
                    style={{
                      transform: isPaused
                        ? pausedTransform
                        : `translateX(-${currentIndex * (300 + 20)}px)`,
                      transition: isPaused
                        ? "none"
                        : "transform 700ms ease-in-out",
                    }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    {sections.map((section, index) => (
                      <div
                        key={index}
                        className={`max-w-[300px] flex-shrink-0 h-[218px] p-[20px] rounded-[10px] ${
                          index % 2 === 0 ? "bg-[#244277]" : "bg-[#102C5D]"
                        }`}
                      >
                        <div className="flex flex-col gap-[10px]">
                          <div
                            className={`h-[40px] w-[40px] rounded-[50%] ${
                              index % 2 === 0 ? "bg-[#102C5D]" : "bg-[#244277]"
                            }`}
                          >
                            <img
                              className="h-[40px] w-[40px]"
                              src={section.image}
                              alt="Icon"
                            />
                          </div>
                          <div className="text-[16px] font-semibold text-[#F9F9F9]">
                            {section.title}
                          </div>
                          <div className="text-[12px] font-normal text-[#F9F9F9]">
                            {section.description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="max-w-[230px] mx-auto mt-[10px] flex gap-[10px] justify-center">
                  {sections.map((_, index) => (
                    <div
                      key={index}
                      className={`rounded-[18px] bg-white h-[4px] transition-all duration-500 ease-in-out ${
                        isPaused
                          ? index === currentIndex
                            ? "w-[80px] opacity-100"
                            : "w-[40px] opacity-50"
                          : index === currentIndex
                          ? "w-[80px] opacity-100"
                          : "w-[40px] opacity-50"
                      }`}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-[#031F4F] p-[30px]">
            <div className="max-w-[1024px] mx-auto rounded-[15px] bg-[#1D3A6D] flex">
              <div className="flex flex-col bg-[#3a5686] rounded-l-[15px]">
                <div className="text-[28px] pl-[30px] py-[77px] max-w-[300px] h-[230px] rounded-tl-[15px]">
                  <p className="text-[#F9F9F9] font-semibold">
                    What makes us{" "}
                    <span className="text-[#31E5E5]">unique ?</span>
                  </p>
                </div>
                <div className="flex">
                  <div className="flex flex-col gap-2.5 h-[230px] max-w-[205px] bg-[#102C5D] rounded-bl-[15px] py-[20px] pl-[15px] pr-[5px]">
                    <div className="h-[60px] w-[60px] rounded-[50%]">
                      <img
                        className="h-[60px] w-[60px]"
                        src={UnifiedFinancialManagement}
                        alt="UnifiedFinancialManagement"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5">
                      <p className="text-[#F9F9F9] text-[12px] font-semibold">
                        Unified Financial Management
                      </p>
                      <p className="text-[#F9F9F9] text-[10px] font-normal">
                        Xpentra brings all your banking and financial
                        operations-payments, balances, reconciliation, and more-
                        into one streamlined platform, simplifying workflows and
                        boosting efficiency.
                      </p>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2.5 h-[230px] max-w-[205px] bg-[#244277] py-[20px] pl-[15px] pr-[10px]">
                    <div className="h-[60px] w-[60px] rounded-[50%]">
                      <img
                        className="h-[60px] w-[60px]"
                        src={Analytics}
                        alt="Analytics"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5">
                      <p className="text-[#F9F9F9] text-[12px] font-semibold">
                        Advanced Analytics
                      </p>
                      <p className="text-[#F9F9F9] text-[10px] font-normal">
                        Our platform provides real-time analytics and
                        customizable reports, offering deep insights into
                        transaction data, cash flow, and performance to support
                        smarter, data-driven decisions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex">
                <div className="h-[460px] max-w-[409px] text-center flex bg-[#1D3A6D] pt-[20px] pr-[23px] pb-[19px] pl-[22px]">
                  <video
                    className="rounded-[10px]"
                    width="600"
                    autoPlay
                    playsInline
                    loop
                    muted
                  >
                    <source src={multi_approve_mp4} type="video/mp4" />
                  </video>
                </div>
                <div>
                  <div className="flex flex-col gap-2.5 h-[230px] max-w-[205px] bg-[#244277] rounded-tr-[15px] py-[20px] pl-[15px] pr-[5px]">
                    <div className="h-[60px] w-[60px] rounded-[50%]">
                      <img
                        className="h-[60px] w-[60px]"
                        src={Security}
                        alt="Security"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5">
                      <p className="text-[#F9F9F9] text-[12px] font-semibold">
                        Security
                      </p>
                      <p className="text-[#F9F9F9] text-[10px] font-normal">
                        Xpentra ensures the highest level of security with
                        multi-factor authentication, encryption, and audit
                        trails, safeguarding your financial data and
                        transactions at every step.
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col gap-2.5 h-[230px] max-w-[205px] bg-[#102C5D] rounded-br-[15px] py-[20px] pl-[15px] pr-[5px]">
                    <div className="h-[60px] w-[60px] rounded-[50%]">
                      <img
                        className="h-[60px] w-[60px]"
                        src={AuthorizationMatrix}
                        alt="AuthorizationMatrix"
                      />
                    </div>
                    <div className="flex flex-col gap-2.5">
                      <p className="text-[#F9F9F9] text-[12px] font-semibold">
                        Authorization Matrix
                      </p>
                      <p className="text-[#F9F9F9] text-[10px] font-normal">
                        The customizable authorization matrix allows you to
                        define roles, permissions, and approval workflows,
                        ensuring that only authorized personnel have access to
                        critical financial actions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UniSecCarosel;
