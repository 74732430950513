import React, { useState, useEffect } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { tooltipClasses, Tooltip } from "@mui/material";

import dataLoading from "../../../../assets/LoaderIcons/Loader.gif";
import Bank from "../../../../assets/BankandRupeeIcons/bank.svg";
import Phone from "../../../../assets/ContactIcons/Contact.svg";
import Clock from "../../../../assets/GeneralIcons/clock.svg";
import Micr from "../../../../assets/GeneralIcons/approval_hierarchy.svg";

import GeoMapLeaflet from "../../../../components/_utils/GeoMapLeaflet";
import { checkUsertoken } from "../../../../redux/features/login/login";
import formatdate from "../../../../utils/formatdate";
import { getOwnerAccountIFSCDetails } from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";

const OwnerAccountIFSC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [ownerDetailIfscData, setOwnerdetailIfscdata] = useState([]);

  const { ownerAccIfscData, ownerAccIfscLoading } = useSelector(
    (state) => state.ownerAllVerification
  );

  useEffect(() => {
    async function fetchIFSCData() {
      setLoading(true);
      try {
        dispatch(checkUsertoken());
        const response = await dispatch(
          getOwnerAccountIFSCDetails({ search_string: id })
        );
        if (response?.error?.message == "Request failed with status code 404") {
          navigate("*");
        }
        setOwnerdetailIfscdata(response.payload.ifsc_details[0]);
      } catch (error) {
        console.error("Error", error);
      } finally {
        setLoading(false);
      }
    }

    fetchIFSCData();
  }, [id]);

  const {
    bank,
    ifsc,
    contact,
    micr,
    state,
    last_updated,
    address,
    branch,
    city,
  } = ownerDetailIfscData;

  return (
    <div
      style={{ marginTop: "120px", position: "absolute", top: "-35px" }}
      className="w-full"
    >
      {/* <div className="ownerIfscMain">
        <div className="ownerIfscBankSearch">
          <SelectBank
            owner={owner}
            setSelectIfscBank={setSelectIfscBank}
            selectIfscBank={selectIfscBank}
          />
          <span className="midSection">OR</span>
          <div className="input_ifsc_search">
            <img
              src={Search}
              alt="search"
              className="search_owner_ifsc"
              onClick={(e) => {
                e.stopPropagation();
                searchRef.current.focus();
              }}
            />
            <input
              type="text"
              name="ifsccode"
              placeholder="Enter 10 digit IFSC code"
            />
          </div>
          <div className="button_clear">
            <p className="button_clear_text">Clear</p>
          </div>
        </div>
      </div> */}

      {loading ? (
        <>
          <div className="owner-loader-container m-auto justify-center items-center flex">
            <img src={dataLoading} alt="" />
          </div>
        </>
      ) : (
        <div className="ownerIfsclayout">
          <div className="ownerIfscSection">
            <div className="ownerIfscSection1">
              <div className="owner_parent_div" style={{ marginTop: "20px" }}>
                <div className="bank-ifsclogo-details h-full">
                  <div className="ifsclogo-bank-container">
                    <img
                      src={require("../../../../assets/IfscLogo/" +
                        IfscCodeMapper(ifsc))}
                      alt="bank"
                      className="detailed-ifscbank-logo"
                    />
                  </div>
                </div>

                <div className="ownerbankname">
                  <span className="bank_name_owner">{ifsc}</span>
                  <p className="ifsc_code_text">IFSC code</p>
                </div>
              </div>

              <div className="mid_border_content"></div>
              <div className="owner_parent_div">
                <div className="owner_bg_img">
                  <img
                    src={Bank}
                    alt="bank"
                    style={{
                      height: "25px",
                      width: "30px",
                      marginTop: "28px",
                      marginLeft: "39px",
                    }}
                  />
                </div>

                <div className="ownerbankname" style={{ width: "20%" }}>
                  <Tooltip
                    componentsProps={{
                      popper: {
                        sx: {
                          [`& .${tooltipClasses.tooltip}`]: {
                            backgroundColor: (theme) =>
                              theme.palette.common.black,
                          },
                        },
                      },
                    }}
                    title={bank}
                  >
                    <p
                      className="bank_name_owner"
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {bank}
                    </p>
                  </Tooltip>

                  <p className="ifsc_code_text">Bank name</p>
                </div>
              </div>
              <div className="mid_border_content"></div>
              <div className="owner_parent_div">
                <div className="owner_bg_img">
                  <img
                    src={Phone}
                    alt="bank"
                    style={{
                      height: "25px",
                      width: "30px",
                      marginTop: "28px",
                      marginLeft: "39px",
                    }}
                  />
                </div>
                <div className="ownerbankname">
                  <p className="bank_name_owner">
                    {contact
                      ? `+${contact.substring(0, 2)} - ${contact.substring(2)}`
                      : `Not Available`}
                  </p>
                  <p className="ifsc_code_text">Contact number</p>
                </div>
              </div>
              <div className="mid_border_content"></div>

              <div className="owner_parent_div">
                <div className="owner_bg_img">
                  <img
                    src={Micr}
                    alt="bank"
                    style={{
                      height: "35px",
                      width: "33px",
                      marginTop: "28px",
                      marginLeft: "39px",
                    }}
                  />
                </div>
                <div className="ownerbankname">
                  <p className="bank_name_owner">
                    {micr ? micr : "Not Available"}
                  </p>
                  <p className="ifsc_code_text">MICR</p>
                </div>
              </div>
              <div className="mid_border_content"></div>
              <div className="owner_parent_div">
                <div className="owner_bg_img">
                  <img
                    src={Clock}
                    alt="bank"
                    style={{
                      height: "25px",
                      width: "30px",
                      marginTop: "28px",
                      marginLeft: "39px",
                    }}
                  />
                </div>
                <div className="ownerbankname">
                  <p className="bank_name_owner">{formatdate(last_updated)}</p>
                  <p className="ifsc_code_text">Last updated</p>
                </div>
              </div>
              <div className="mid_border_content"></div>
            </div>
            <div className="ownerIfscSection2">
              <div className="ownerIfsccontainer">
                <p className="ownerMaptext">Corporate Address</p>
                <div className="addressLabelMain capitalize">
                  <p>{address?.toLowerCase()}</p>
                </div>

                <div className="ownerMapPadding">
                  <>
                    <GeoMapLeaflet owner={"ownerDetails"} address={address} />
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OwnerAccountIFSC;
