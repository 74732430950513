import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import Footer from "../../../../components/LandingPage/Footer";
import { toast } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import emailjs from "@emailjs/browser";
import invalid from "../../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import { useSelector } from "react-redux";

const BookDemo = () => {
  const fullNameRef = useRef();
  const emailRef = useRef();
  const contactRef = useRef();
  const companyNameRef = useRef();
  const requirementRef = useRef();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [requirement, setRequirement] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(null);
  const [isValidContact, setIsValidContact] = useState(null);
  const [errors, setErrors] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;

  const { mobilestatus, webstatus } = useSelector(
    (state) => state.landingpagestate
  );

  useEffect(() => emailjs.init("BYAIWqAyYJKmVXOHA"), []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const validateForm = () => {
    const newErrors = {};
    if (!emailRegex.test(email)) {
      newErrors.email = "Please enter a valid email address.";
    }
    if (!/^\d{10}$/.test(contact)) {
      newErrors.contact =
        "Please enter a valid 10-digit contact number with +91.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleEmailChange = (e) => {
    let emailValue = e.target.value.replace(/\s/g, "").toLowerCase();
    setEmail(emailValue);
    if (emailValue === "") {
      setIsValidEmail(null);
      toast.dismiss();
    } else if (emailRegex.test(emailValue)) {
      setIsValidEmail(true);
      toast.dismiss();
    } else {
      setIsValidEmail(false);
    }
  };

  const handleInputChange = (e) => {
    let contactNumber = e.target.value;
    if (/^\d{0,10}$/.test(contactNumber)) {
      setContact(contactNumber);
    }
    if (
      contactNumber.length > 0 &&
      contactNumber.length === 10 &&
      /^\d{0,10}$/.test(contactNumber)
    ) {
      setIsValidContact(true);
      toast.dismiss();
    } else {
      setIsValidContact(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const serviceId = "service_bt8vaud";
    const templateId = "template_qkqs9w6";
    try {
      await emailjs.send(serviceId, templateId, {
        fullName: fullNameRef.current.value,
        email: emailRef.current.value,
        contact: contactRef.current.value,
        companyName: companyNameRef.current.value,
        requirement: requirementRef.current.value,
      });
      toast.success(
        "Your request has been submitted successfully",
        toast_position
      );
      setFullName("");
      setEmail("");
      setContact("");
      setCompanyName("");
      setRequirement("");
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  const handleInvalidIconClick = (type) => {
    let message;
    switch (type) {
      case "email":
        if (!isValidEmail) {
          message = "The email you entered is invalid.";
        }
        break;
      case "contact":
        if (!isValidContact) {
          message = "The contact number you entered is invalid.";
        }
        break;
      default:
        console.error("Invalid type passed :", type);
        return;
    }
    if (message) {
      toast.error(message, toast_position);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " " || e.code === "Space") {
      e.preventDefault();
    }
  };
  const buttonDisabled =
    !fullName.trim() ||
    !email.trim() ||
    !contact.trim() ||
    !companyName.trim() ||
    !requirement.trim() ||
    !emailRegex.test(email.trim()) ||
    !/^\d{10}$/.test(contact.trim());

  return (
    <>
      <div>
        {isMobile ? (
          <div>
            <div
              style={{
                overflowY: mobilestatus ? "hidden" : "auto",
                height: "100vh",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
              }}
              className="bg-[#031f4f]"
            >
              <div className="px-[30px] py-[30px] mt-[60px] flex flex-col gap-2.5 text-[#F9F9F9] bg-[#1d3a6d]">
                <div className="h-full max-w-[1440px] w-full flex justify-center mx-auto">
                  <div className="w-full md:w-[80%] lg:w-[50%] flex flex-col justify-center px-4 lg:pl-[125px]">
                    <h3 className="text-[16px] font-semibold text-[#F9F9F9]">
                      Request a <span className="text-[#31E5E5]">demo</span>
                    </h3>
                    <div className="rounded-2xl md:p-6">
                      <form className="mt-5" onSubmit={handleSubmit}>
                        <p className="text-[#DADADA] text-sm font-semibold">
                          Request a personalised demo to explore how it can
                          streamline your operations!
                        </p>
                        <div className="grid grid-cols-1 gap-x-6 gap-y-3 md:gap-y-8 md:grid-cols-2 mt-4">
                          <div className="w-full">
                            <label
                              htmlFor="fullName"
                              className="text-xs font-medium leading-6 text-[#F9F9F9]"
                            >
                              Full name
                            </label>
                            <input
                              ref={fullNameRef}
                              id="fullName"
                              name="fullName"
                              type="text"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                              placeholder="Enter your name"
                              className="custom-autofill block w-full rounded-md border border-[#DADADA] bg-transparent py-1.5 px-2.5 text-xs placeholder:text-[16px] text-[#dadada]"
                            />
                          </div>

                          <div className="w-full">
                            <label
                              htmlFor="email"
                              className={`text-xs font-medium leading-6 text-[#dadada]`}
                            >
                              Email ID
                            </label>
                            <div
                              className={`flex justify-between rounded-md border py-1.5 px-2.5 border-[#dadada]`}
                            >
                              <input
                                ref={emailRef}
                                id="email"
                                name="email"
                                type="email"
                                value={email}
                                onKeyDown={handleKeyDown}
                                onChange={handleEmailChange}
                                placeholder="Enter your email ID"
                                className="custom-autofill block w-full text-xs placeholder:text-[16px] bg-transparent text-[#dadada]"
                              />
                              {isValidEmail !== null && !isValidEmail && (
                                <img
                                  src={invalid}
                                  alt="Invalid"
                                  className="w-[16px] h-[16px] ml-[10px] cursor-pointer"
                                  onClick={() =>
                                    handleInvalidIconClick("email")
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:gap-y-8 md:grid-cols-2 mt-2">
                          <div className="w-full">
                            <label
                              htmlFor="contact"
                              className={`text-xs font-medium leading-6 text-[#f9f9f9]`}
                            >
                              Contact number
                            </label>
                            <div
                              className={`flex justify-between rounded-md border py-1.5 px-2.5 border-[#dadada]`}
                            >
                              <p className="text-xs placeholder:text-[16px] text-[#dadada] mr-[2px]">
                                +91{" "}
                              </p>
                              <input
                                ref={contactRef}
                                id="contact"
                                name="contact"
                                type="text"
                                max={10}
                                value={contact}
                                onChange={handleInputChange}
                                placeholder="Enter your contact number"
                                className="custom-autofill block w-full bg-transparent text-xs placeholder:text-[16px] text-[#dadada]"
                              />
                              {contact.length < 10 && contact.length > 0 && (
                                <img
                                  src={invalid}
                                  alt="Invalid"
                                  className="w-[16px] h-[16px] cursor-pointer"
                                  onClick={() =>
                                    handleInvalidIconClick("contact")
                                  }
                                />
                              )}
                            </div>
                          </div>

                          <div className="w-full">
                            <label
                              htmlFor="companyName"
                              className="text-xs font-medium leading-6 text-[#F9F9F9]"
                            >
                              Company name
                            </label>
                            <input
                              ref={companyNameRef}
                              id="companyName"
                              name="companyName"
                              type="text"
                              value={companyName}
                              onChange={(e) => setCompanyName(e.target.value)}
                              placeholder="Enter your company's name"
                              className="custom-autofill block w-full rounded-md border border-[#DADADA] bg-transparent py-1.5 px-2.5 text-xs placeholder:text-[16px] text-[#dadada]"
                            />
                          </div>
                        </div>

                        <div className="w-full mt-2">
                          <label
                            htmlFor="requirement"
                            className="text-xs font-medium leading-6 text-[#F9F9F9]"
                          >
                            Requirement
                          </label>
                          <textarea
                            ref={requirementRef}
                            rows={8}
                            id="requirement"
                            name="requirement"
                            value={requirement}
                            onChange={(e) => setRequirement(e.target.value)}
                            placeholder="Describe your requirement"
                            className="custom-autofill block w-full rounded-md border border-[#DADADA] bg-transparent py-1.5 px-2.5 text-xs placeholder:text-[16px] text-[#dadada]"
                          />
                        </div>

                        <div className="mt-6 flex items-center justify-center md:justify-end gap-x-6">
                          <button
                            type="submit"
                            disabled={buttonDisabled}
                            className={`rounded-[50px] px-7 py-2 text-sm font-semibold ${
                              buttonDisabled
                                ? "bg-[#707070] text-[#dadada]"
                                : "bg-[#F9F9F9] text-[#1D3A6D] hover:bg-[#506994] hover:text-[#f9f9f9]"
                            }`}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        ) : (
          <div
            style={{
              overflow: webstatus ? "hidden" : "",
              height: "100vh",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              WebkitOverflowScrolling: "touch",
            }}
          >
            <div className="flex justify-center items-center my-[30px]">
              <div className="h-full max-w-[1440px] w-full flex justify-center mx-auto">
                <div className="w-[50%] flex flex-col justify-center">
                  <h3 className="text-center pt-[60px] pb-5 text-[28px] font-semibold text-[#F9F9F9]">
                    Request a <span className="text-[#31E5E5]">demo</span>
                  </h3>
                  <div className="bg-[#314D7E] rounded-2xl">
                    <form className="p-5 mt-1" onSubmit={handleSubmit}>
                      <p className="text-[#DADADA] text-sm font-semibold">
                        Request a personalised demo to explore how it can
                        streamline your operations!
                      </p>
                      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 mt-4">
                        <div className="w-full">
                          <label
                            htmlFor="fullName"
                            className="block text-[10px] font-medium leading-6 text-[#DADADA]"
                          >
                            Full name
                          </label>
                          <input
                            ref={fullNameRef}
                            id="fullName"
                            name="fullName"
                            type="text"
                            value={fullName}
                            onChange={(e) => setFullName(e.target.value)}
                            placeholder="Enter your name"
                            className="block w-full rounded-md border border-[#DADADA] bg-transparent py-1.5 px-2.5 text-xs placeholder:text-xs text-[#dadada]"
                          />
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor="email"
                            className={`block text-[10px] font-medium leading-6 text-[#DADADA]`}
                          >
                            Email ID
                          </label>
                          <div
                            className={`flex justify-between rounded-md border py-1.5 px-2.5 border-[#DADADA]`}
                          >
                            <input
                              ref={emailRef}
                              id="email"
                              name="email"
                              type="text"
                              value={email}
                              onKeyDown={handleKeyDown}
                              onChange={handleEmailChange}
                              placeholder="Enter your email ID"
                              className="block w-full bg-transparent text-xs placeholder:text-xs text-[#dadada]"
                            />
                            {isValidEmail !== null && !isValidEmail && (
                              <img
                                src={invalid}
                                alt="Invalid"
                                className="w-[16px] h-[16px] ml-[10px] cursor-pointer"
                                onClick={() => handleInvalidIconClick("email")}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-2 mt-2">
                        <div className="w-full">
                          <label
                            htmlFor="contact"
                            className="block text-[10px] font-medium leading-6 text-[#DADADA]"
                          >
                            Contact number
                          </label>
                          <div className="flex justify-between rounded-md border py-1.5 px-2.5 border-[#DADADA]">
                            <p className="bg-transparent text-xs placeholder:text-xs text-[#dadada] mr-[2px]">
                              +91{" "}
                            </p>
                            <input
                              ref={contactRef}
                              id="contact"
                              name="contact"
                              type="text"
                              value={contact}
                              max={10}
                              onChange={handleInputChange}
                              placeholder="Enter your contact number"
                              className="block w-full bg-transparent text-xs placeholder:text-xs text-[#dadada]"
                            />
                            {contact.length < 10 && contact.length > 0 && (
                              <img
                                src={invalid}
                                alt="Invalid"
                                className="w-[16px] h-[16px] cursor-pointer"
                                onClick={() =>
                                  handleInvalidIconClick("contact")
                                }
                              />
                            )}
                          </div>
                        </div>

                        <div className="w-full">
                          <label
                            htmlFor="companyName"
                            className="block text-[10px] font-medium leading-6 text-[#DADADA]"
                          >
                            Company name
                          </label>
                          <input
                            ref={companyNameRef}
                            id="companyName"
                            name="companyName"
                            type="text"
                            value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            placeholder="Enter your company's name"
                            className="block w-full rounded-md border border-[#DADADA] bg-transparent py-1.5 px-2.5 text-xs placeholder:text-xs text-[#dadada]"
                          />
                        </div>
                      </div>

                      <div className="w-full mt-2">
                        <label
                          htmlFor="requirement"
                          className="block text-[10px] font-medium leading-6 text-[#DADADA]"
                        >
                          Requirement
                        </label>
                        <textarea
                          rows={8}
                          ref={requirementRef}
                          id="requirement"
                          name="requirement"
                          type="text"
                          value={requirement}
                          onChange={(e) => setRequirement(e.target.value)}
                          placeholder="Describe your requirement"
                          className="block w-full rounded-md border border-[#DADADA] bg-transparent py-1.5 px-2.5 text-xs placeholder:text-xs text-[#dadada]"
                        />
                      </div>
                      <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                          type="submit"
                          disabled={buttonDisabled}
                          // onClick={handleSubmit}
                          className={`rounded-[50px] px-7 py-2 text-sm font-semibold ${
                            buttonDisabled
                              ? "bg-[#707070] text-[#dadada] cursor-default"
                              : "bg-[#F9F9F9] text-[#1D3A6D] hover:bg-[#506994] hover:text-[#f9f9f9] cursor-pointer"
                          }`}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        )}
      </div>
    </>
  );
};

export default BookDemo;
