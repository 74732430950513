import React, { useState, useEffect, useRef } from "react";
import { Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import noaccount from "../../../../assets/Beneficiary Icons/noaccfound.png";
import { useDispatch, useSelector } from "react-redux";
import AllStatusHistoryModal from "../AllStatusTypeButtonModal/index";
import SearchBar from "../../../_utils/search/SearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import OwnerTransactionViewAllCard from "../OwnerTransactionViewAllCard/index";

import { beneTxnViewAllAcc } from "../../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import formatCurrency from "../../../../utils/CurrencyFormater";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import { checkUsertoken } from "../../../../redux/features/login/login";

const OwnerTransactionHistoryModal = (props) => {
  const location = useLocation();
  const approvalStatus = location.state?.approvalStatus
    ? location.state.approvalStatus
    : "all";
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchUserText, setSearchUserText] = useState("");
  const [mainData, setMainData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const [accumulatedData, setAccumulatedData] = useState([]);
  const [currentStatus, setCurrentStatus] = useState(approvalStatus);
  const [beneAccountId, setBeneAccountId] = useState(
    props.bene_account_id ? props.bene_account_id : ""
  );
  const [pageSize, setPageSize] = useState(
    window.innerWidth >= 3840 ? 192 : window.innerWidth >= 2560 ? 102 : 50
  );

  const dispatch = useDispatch();

  const { beneTxnViewAllAccData } = useSelector(
    (state) => state.beneTxnViewAccData
  );

  const handleClose = () => {
    props.onClose();
  };
  useEffect(() => {
    setCurrentStatus(approvalStatus);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        if (currentPage === 1) {
          setLoading(true);
        }

        let queryParams = {
          page_no: currentPage,
          page_item_count: pageSize,
          beneficiary_account_id: beneAccountId,
        };

        const addParamsBasedOnSelection = () => {
          if (currentStatus !== "all") {
            queryParams.lifecycle_status = currentStatus;
          }
        };

        addParamsBasedOnSelection();
        dispatch(checkUsertoken());
        const response = await dispatch(beneTxnViewAllAcc(queryParams));

        const newData = response?.payload?.transactions || [];
        setAccumulatedData((prevData) =>
          currentPage === 1 ? newData : [...prevData, ...newData]
        );
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }

    fetchData();
  }, [dispatch, currentPage, currentStatus, beneAccountId]);
  useEffect(() => {}, [beneTxnViewAllAccData]);

  const fetchMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const getAccStatus = (item) => {
    setCurrentStatus(item);
  };

  function handleWindowSizeChange() {
    const screenWidth = window.innerWidth;

    if (screenWidth >= 1140 && screenWidth < 2560) {
      setPageSize(50);
    } else if (screenWidth >= 2560 && screenWidth < 3840) {
      setPageSize(102);
    } else if (screenWidth >= 3840) {
      setPageSize(192);
    }
  }

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      {/* <div className="fixed  top-0 left-0 w-screen h-screen z-[9998]"></div> */}
      <div
        className="flex w-full h-full justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] p-3"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className="flex flex-col py-4 mt-10 bg-custom-appBackLight rounded-2xl w-[95vw] md:w-[67vw]"
          style={{
            // width: "67vw",
            // maxWidth: "1024px",
            height: "622px",
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between mx-4 p-2">
            <h6 className="text-white pb-1 md:font-bold">
              Transaction history
            </h6>
            <IconButton
              onClick={handleClose}
              style={{
                backgroundColor: "#031F4F",
                borderRadius: "5px",
                height: "22px",
                width: "22px",
              }}
            >
              <Close className="text-white  hover:bg-[#667CA3] hover:rounded-lg" />
            </IconButton>
          </div>
          {beneTxnViewAllAccData && (
            <div className="main_div_container">
              <div className={`${"opacity-100"} main-gradient`}></div>
              <div className={"main_div"}>
                <div>
                  <div className=" balance-containers ">
                    <div className={` owner-txn-div`}>
                      <div className="account-main">
                        <span className="count-symbol-element">M </span>
                        {(beneTxnViewAllAccData.total_count &&
                          beneTxnViewAllAccData.total_count?.toLocaleString()) ||
                          0}
                      </div>
                      <div className="view-owner-txn">Total transactions</div>
                    </div>
                    <div className="border-div"></div>

                    <div className="ownerTransactionAmountSection">
                      <p className="ownerTransactionTotalAmount">
                        <span className="rupee-count-owner">₹ </span>
                        <span>
                          {Number(
                            beneTxnViewAllAccData.total_amount
                          )?.toLocaleString("en-IN", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </span>
                      </p>
                      <p className="ownerTransactionAmountTitle">
                        Total balance
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="center_section_container">
            <AllStatusHistoryModal
              data={accumulatedData}
              //   // setData={setFilteredData}
              getAccStatus={getAccStatus}
              selectedStatus={currentStatus}
              //   // approvalStatus={approvalStatus}
              setPage={setCurrentPage}
            />
            <div className="bene-search-div">
              <SearchBar
                handleSearch={setSearchUserText}
                showSearchBar={showSearchBar}
                setSearchUserText={setSearchUserText}
                // onPaste={handlePaste}
                toggleShowSearchBar={() => {
                  setShowSearchBar(!showSearchBar);
                  // if (showSearchBar === false) {
                  //   setLoading(true);
                  //   setAllDataLoaded(true);
                  // }
                }}
                bgColor={"bg-custom-card"}
                bgHover={"hover:bg-custom-cardHover"}
              />
            </div>
          </div>
          <div id="infinityScrollContainerOwnerTransaction">
            {loading ? (
              <div className="loader-container m-auto justify-center items-center flex">
                <img src={dataLoading} alt="" />
              </div>
            ) : accumulatedData?.length > 0 ? (
              <div
                className="scroll w-full flex flex-col items-center justify-center m-auto"
                // style={{ height: "100%" }}
              >
                <InfiniteScroll
                  style={{ height: "inherit" }}
                  dataLength={accumulatedData.length || []}
                  next={fetchMoreData}
                  hasMore={
                    currentPage <
                    Math.ceil(beneTxnViewAllAccData?.total_count / pageSize)
                  }
                  // loader={
                  //   <div className="loader-container m-auto justify-center items-center flex">
                  //     <img src={dataLoading} alt="" />
                  //   </div>
                  // }
                  scrollableTarget="infinityScrollContainerOwnerTransaction"
                  scrollThreshold={0.3}
                >
                  <OwnerTransactionViewAllCard
                    data={accumulatedData}
                    closeModal={handleClose}
                  />
                </InfiniteScroll>
              </div>
            ) : searchUserText && accumulatedData?.length === 0 ? (
              <div className="flex flex-col items-center justify-center m-auto">
                <img
                  src={noaccount}
                  alt="No accounts found"
                  className="not-found-acc-image"
                />
                <p className="no-beneaccount-text">No Data found.</p>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center m-auto">
                <img
                  src={noaccount}
                  alt="No accounts found"
                  className="not-found-acc-image"
                />
                <p className="no-beneaccount-text">No Transaction found.</p>
                {/* <p className="no-beneaccount-text2">
                  All Transaction History will appear here.
                </p> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerTransactionHistoryModal;
