import React from "react";
import { toast } from "react-toastify";
import LoaderIcon from "../../../assets/Beneficiary Icons/inputloder.gif";

const width = window.innerWidth;

const paragraphStyle = {
  width: "160px",
  height: "19px",
  textAlign: "left",
  font: "normal normal normal 14px/19px Open Sans",
  letterSpacing: "0px",
  color: "#FFFFFF",
  opacity: 1,
};
const divStyle = {
  display: "flex",
  justifyContent: width < 480 ? "left" : "center",
  alignItems: "center",
};
const imageStyle = {
  width: "60px",
  height: "40px",
  opacity: 1,
  paddingRight: "5px",
};

const RefreshToast = (type) => {
  return toast(
    <div style={divStyle}>
      <img src={LoaderIcon} alt="LoaderIcon" style={imageStyle} />
      <p style={paragraphStyle}>
        {type === "accountsRefresh"
          ? "Rejecting Account"
          : type === "archive"
          ? "Archiving Account"
          : type === "unarchive"
          ? "Unarchiving Account"
          : type === "approvalaccountsRefresh"
          ? "Approving Account"
          : "Rejecting transaction"}
      </p>
    </div>,
    {
      position: "bottom-right",
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      fontSize: "20px",
      style: {
        width: width < 480 ? `${width}px` : "310px",
        height: "50px",
        borderRadius: "10px",
        backgroundColor: "#AE9041",
        color: "#fff",
        zIndex: "9999999",
      },
      autoClose:
        type === "archive" ||
        type === "unarchive" ||
        type === "approvalaccountsRefresh"
          ? 500
          : 5000,
    }
  );
};

export default RefreshToast;
