import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import IfscCodeMapper from "../../_utils/IfscLogoMapper";
import salary from "../../../assets/Beneficiary Icons/salaries.png";

const BeneTxnListView = ({
  data,
  isSidebarOpen,
  page,
  numberOfPages,
  isDistributionPopoverOpen,
  isPopoverOpen,
  currentStatus,
}) => {
  const [columns, setColumns] = useState(3);
  const [cardWidth, setCardWidth] = useState("425px");
  const navigate = useNavigate();

  const listdata = data || [];
  // const maxColumn = Math.floor(window.innerWidth / 480);
  // const column = Math.ceil(listdata.length / maxColumn) + 1;
  // const mappColumn = Math.min(maxColumn, column);
  useEffect(() => {
    const updateLayout = () => {
      const width = window.innerWidth;
      let newColumns;
      let newCardWidth;

      if (width >= 3840) {
        newColumns = 5;
        newCardWidth = "720px";
      } else if (width >= 2560) {
        newColumns = 4;
        newCardWidth = "600px";
      } else if (width >= 1920) {
        newColumns = 3;
        newCardWidth = "425px";
      } else {
        newColumns = 3;
        newCardWidth = "425px";
      }

      setColumns(newColumns);
      setCardWidth(newCardWidth);
    };

    updateLayout();

    window.addEventListener("resize", updateLayout);

    return () => window.removeEventListener("resize", updateLayout);
  }, []);
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#B196FA";
      case "denied":
      case "failed":
        return "#ff5860";
      case "abandoned":
        return "#a3a3a3";
      case "rejected":
        return "#ff5860";
      case "hold":
        return "#FBC02D";
      case "queued":
        return "#FBC02D";
      default:
        return "#4ddd37";
    }
  };

  const getDistriStatusColor = (status) => {
    switch (status) {
      case "awaiting_verification":
      case "awaiting_funds":
      case "distribution_initiated":
      case "created":
      case "distribution_pending":
      case "approved":
        return "#FBC02D";
      case "denied":
      case "failed":
      case "rejected":
        return "#ff5860";
      case "abandoned":
        return "#B4B4B4";
      case "distribution_complete":
        return "#4DDD37";
      default:
        return "#F9F9F9";
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div
      className="bene-txn-list-container"
      style={{
        paddingBottom: "20px",
        display: "grid",
        gridTemplateColumns: isMobile
          ? "repeat(1, 1fr)"
          : `repeat(${columns}, 1fr)`,
        columnGap: "30px",
        opacity:
          isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
            ? "0.4"
            : "1",
        background:
          isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
            ? "#000"
            : "",
      }}
    >
      {listdata.map((item, index) => (
        <div
          key={item.id}
          style={{
            // width: cardWidth,
            height: "49px",
            margin: "3px",
            cursor:
              isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                ? "default"
                : "pointer",
            pointerEvents:
              isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                ? "none"
                : "",
            opacity:
              isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                ? "0.8"
                : "1",
            background:
              isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                ? "#000"
                : "",
          }}
        >
          <div
            onClick={async () => {
              let selection = window.getSelection();
              if (selection.toString().length === 0) {
                if (!isSidebarOpen) {
                  navigate(
                    `/transaction/beneficiary_transaction/details/${item.txn_id}`
                  );
                }
              }
            }}
            style={{
              opacity:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "0.8"
                  : "1",
              background:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "#000"
                  : "",
              cursor:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "default"
                  : "pointer",
              pointerEvents:
                isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                  ? "none"
                  : "",
            }}
            className={`bene_txn_list_section relative hover:bg-[#506994] ${
              isSidebarOpen || isPopoverOpen || isDistributionPopoverOpen
                ? "disabled"
                : ""
            }`}
          >
            <div className="bene_txn_listview_bank_div_img">
              <img
                src={require("../../../assets/IfscLogo/" +
                  IfscCodeMapper(item.beneficiary_account_ifsc_code))}
                alt="bank"
                //   className="beneTxn_viewall_paymentmode_image"
              />
            </div>
            <div className="bene_txn_list_accounts_div font-normal w-[180px] overflow-hidden whitespace-nowrap overflow-ellipsis text-[#F9F9F9]">
              <p
                className="bene_txn_list_name  overflow-hidden whitespace-nowrap overflow-ellipsis"
                style={{
                  padding: "0px 8px 0px 8px",
                  font: "normal normal normal 12px/17px Open Sans",
                  width: isMobile ? "170px" : "200px",
                  // textTransform: "capitalize",
                }}
              >
                {" "}
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                          marginRight: "20px",
                          zIndex: 999999,

                          // textTransform: "capitalize !important",
                        },
                      },
                    },
                  }}
                  title={
                    <span style={{ textTransform: "capitalize" }}>
                      {item.beneficiary_account_name}
                    </span>
                  }
                >
                  <span>{item.beneficiary_account_name}</span>
                </Tooltip>
              </p>

              <p
                className="font-normal pt-1 text-[10px] leading-19 text-[#DADADA] opacity-100"
                style={{
                  padding: "2px 8px",
                  font: "normal normal normal 10px/14px Open Sans",
                }}
              >
                <span>Beneficiary name</span>
              </p>
            </div>

            <div className="bene_txn_list_amount_div">
              <p
                className="overflow-hidden whitespace-nowrap overflow-ellipsis text-[#4DE659]"
                style={{
                  padding: "0px 3px 0px 8px",
                  font: "normal normal normal 12px/17px Open Sans",
                }}
              >
                <span
                  style={{
                    display: "inline-block",
                    fontSize: "13px",
                    paddingRight: "5px",
                  }}
                >
                  ₹
                </span>
                <span>
                  {Number(item.amount)?.toLocaleString("en-IN", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              </p>
              <p
                className="font-normal pt-1 text-[10px] leading-19 text-[#DADADA] opacity-100"
                style={{
                  padding: "2px 8px",
                  font: "normal normal normal 10px/14px Open Sans",
                }}
              >
                <span>Amount</span>
              </p>
            </div>
            <div className="bene_txn_list_last_div">
              {" "}
              <img
                className="w-[26px] h-[24px]"
                //   style={{
                //     maxWidth: "100%",
                //     maxHeight: "100%",
                //     objectFit: "contain",
                //   }}
                src={salary}
                alt="category"
              />
            </div>
            <div className="bene_txn_viewallList_hover_content">
              <div className="bene_txn_list_status_div font-normal w-[180px] overflow-hidden whitespace-nowrap overflow-ellipsis text-[#F9F9F9]">
                <p
                  className="bene_txn_list_name w-[200px] overflow-hidden whitespace-nowrap overflow-ellipsis"
                  style={{
                    padding: "0px 8px 0px 8px",
                    font: "normal normal normal 12px/17px Open Sans",
                    color: getStatusColor(item.lifecycle_status),
                  }}
                >
                  <span>
                    {/* {item.lifecycle_status === "pending" &&
                    (currentStatus === "pending" ||
                      currentStatus === "all" ||
                      currentStatus === null)
                      ? "Awaiting approval"
                      : item.lifecycle_status === "pending" &&
                        currentStatus === "require approval"
                      ? "Require approval"
                      : item.lifecycle_status || "N/A"} */}

                    {currentStatus === "require approval"
                      ? "Require approval"
                      : item.lifecycle_status || "N/A"}
                  </span>
                </p>

                <p
                  className="font-normal pt-1 text-[10px] leading-19 text-[#DADADA] opacity-100"
                  style={{
                    padding: "2px 8px",
                    font: "normal normal normal 10px/14px Open Sans",
                  }}
                >
                  <span>Approval status</span>
                </p>
                <div className="bene_txn_list_distribution_div">
                  <p
                    className="overflow-hidden whitespace-nowrap overflow-ellipsis"
                    style={{
                      padding: "0px 3px 0px 8px",
                      font: "normal normal normal 12px/17px Open Sans",
                      color:
                        item.distribution_lifecycle_status === "N/A"
                          ? "#F9F9F9"
                          : getDistriStatusColor(
                              item.distribution_lifecycle_status
                            ),
                    }}
                  >
                    <span>
                      {
                        <>
                          {item.distribution_lifecycle_status === "created"
                            ? "Distribution created"
                            : item.distribution_lifecycle_status ===
                              "awaiting_funds"
                            ? "Awaiting funds"
                            : item.distribution_lifecycle_status ===
                              "distribution_initiated"
                            ? "Distribution initiated"
                            : item.distribution_lifecycle_status === "abandoned"
                            ? "Distribution abandoned"
                            : item.distribution_lifecycle_status === "rejected"
                            ? "Distribution rejected"
                            : item.distribution_lifecycle_status === "failed"
                            ? "Distribution failed"
                            : item.distribution_lifecycle_status ===
                              "distribution_complete"
                            ? "Distribution complete"
                            : item.distribution_lifecycle_status === "approved"
                            ? "Distribution approved"
                            : item.distribution_lifecycle_status || "N/A"}
                        </>
                      }
                    </span>
                  </p>
                  <p
                    className="font-normal pt-1 text-[10px] leading-19 text-[#DADADA] opacity-100"
                    style={{
                      padding: "2px 8px",
                      font: "normal normal normal 10px/14px Open Sans",
                    }}
                  >
                    <span>Distribution status</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default BeneTxnListView;
