import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { toast_position } from "../../redux/features/Settings/Authorization";
import HomeBannerGif from "../../../src/assets/landingPage/HomeBannerGif.gif";
import QuestionMarkWeb from "../../../src/assets/landingPage/QuestionMarkWeb.png";
import QuestionMarkMob from "../../../src/assets/landingPage/QuestionMarkMob.svg";
import ArrowRight from "../../../src/assets/landingPage/Arrowright.svg";
import ArrowRightWhite from "../../../src/assets/landingPage/HoverArrowRight.svg";
import IntegrationBank from "../../../src/assets/landingPage/IntegrationBank.svg";
import AccessAccountBalanace from "../../../src/assets/landingPage/AccessAccountBalanace.svg";
import PersonalisedStatements from "../../../src/assets/landingPage/PersonalisedStatements.svg";
import AuthMatrix from "../../../src/assets/landingPage/AuthMatrix.svg";
import BeneficiaryVerification from "../../../src/assets/landingPage/BeneficiaryVerification.svg";
import SecurityProtocols from "../../../src/assets/landingPage/SecurityProtocols.svg";
import FullyResponsive from "../../../src/assets/landingPage/FullyResponsive.svg";
import ErpSystem from "../../../src/assets/landingPage/ErpSystem.svg";
import Flexibility from "../../../src/assets/landingPage/Flexibility.svg";
import Automation from "../../../src/assets/landingPage/Automation.svg";
import Transparency from "../../../src/assets/landingPage/Transparency.svg";
import Security from "../../../src/assets/landingPage/Security.svg";
import AMC from "../../../src/assets/landingPage/AMC.svg";
import Corporates from "../../../src/assets/landingPage/Corporates.svg";
import Footer from "../../components/LandingPage/Footer";
import UniSecCarosel from "../../components/LandingPage/UniqueSecCarosel";
import designElement1 from "../../assets/landingPage/designElement1.svg";
import designElement2 from "../../assets/landingPage/designElement2.svg";
import designElement3 from "../../assets/landingPage/designElement3.svg";
import BackToTop from "../../../src/assets/landingPage/TopArrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

const LandingPage = () => {
  const productSections = [
    {
      title: "Flexibility",
      description:
        "Whether you’re a small business or a large enterprise, Xpentra adapts to your specific requirements, offering scalable features that evolve as your business grows and changes.",
      image: Flexibility,
    },
    {
      title: "Automation",
      description:
        "Our platform automates repetitive tasks such as payments, reconciliation, and reporting, freeing up your team to focus on more strategic initiatives and reducing the risk of human error.",
      image: Automation,
    },
    {
      title: "Security",
      description:
        "With industry-leading encryption, multi-factor authentication, and audit trails, Xpentra guarantees that your sensitive financial information is safeguarded at every step of the transaction process.",
      image: Security,
    },
    {
      title: "Transparency",
      description:
        "Xpentra delivers instant access to up-to-date data, offering detailed reports and analytics that give you full visibility into your financial operations and help you make confident, data-driven decisions.",
      image: Transparency,
    },
  ];
  const navigate = useNavigate();
  const [bookArrow, setBookArrow] = useState(ArrowRight);
  const [faqArrow, setFaqArrow] = useState(ArrowRight);
  const [AMCArrow, setAMCArrow] = useState(ArrowRight);
  const [corporateArrow, setCorporateArrow] = useState(ArrowRight);
  const [active, setActive] = useState("amcs");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const [pausedTransform, setPausedTransform] = useState(null);
  const intervalTime = 2000;

  const { mobilestatus, webstatus } = useSelector(
    (state) => state.landingpagestate
  );

  const handleMouseOver = () => {
    setIsPaused(true);
    const currentTransform = `translateX(calc(50% - ${
      currentIndex * (280 + 20) + 140
    }px))`;
    setPausedTransform(currentTransform);
  };
  const handleMouseOut = () => {
    setIsPaused(false);
    setPausedTransform(null);
  };

  const showToast = () => {
    toast.info("Feature coming soon!", toast_position);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused) {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % productSections.length
        );
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [isPaused, productSections.length]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20 || document.documentElement.scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <div>
        {isVisible && (
          <div
            onClick={scrollToTop}
            className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
          >
            <CircularProgressWithLabel value={scrollPercent} />
          </div>
        )}
        {isMobile ? (
          <div>
            <div
              style={{
                overflowY: mobilestatus && "hidden",
                height: "100vh",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
              }}
              className="bg-[#031f4f]"
            >
              <div className="p-[30px] mt-[60px] flex flex-col gap-2.5 text-[#F9F9F9] bg-[#1f3969]">
                <div className="mx-auto">
                  <img src={HomeBannerGif} alt="HeroSectionBannerMobile" />
                </div>
                <div className="text-lg font-semibold">
                  Your all-in-one platform for streamlined financial management
                </div>
                <div className="text-sm font-normal">
                  Xpentra is a sophisticated platform designed to simplify
                  complex financial processes, using robust tools to offer
                  advanced capabilities in managing accounts and transactions
                  efficiently. Xpentra acts as a powerful CXO enabler, with its
                  intuitive dashboards and analytics delivering expert insights
                  into cash and liquidity management, financial projections, and
                  forecasting.
                </div>
                <div className="w-[112px] h-9 rounded-[5px] p-2.5 bg-[#F9F9F9] flex justify-between">
                  <div
                    className="text-[#031F4F] text-[12px] h-4 font-semibold"
                    onClick={() => navigate("/book_demo")}
                  >
                    Book a demo
                  </div>
                  <div className="h-3.5 w-3.5 my-auto">
                    <img src={ArrowRight} alt="arrowRight" />
                  </div>
                </div>
              </div>

              <div className="p-[30px] bg-[#031f4f] flex flex-col gap-4">
                <p className="text-[16px] text-[#F9F9F9] font-semibold">
                  <span className="text-[#31E5E5]">Key features</span> of
                  Xpentra
                </p>

                <div className="flex flex-wrap gap-4 justify-center">
                  <div className="rounded-[10px] bg-[#314D7E] flex flex-col gap-2.5 h-[138px] w-full xs:w-[140px] sm:w-[140px] md:w-[180px] lg:w-[200px] p-5">
                    <div className="h-[40px] w-[40px] rounded-[50%] bg-[#102C5D]">
                      <img src={IntegrationBank} alt="IntegrationBank" />
                    </div>
                    <div className="text-[12px] text-[#F9F9F9] font-normal h-[48px]">
                      Seamless integration with all banks
                    </div>
                  </div>

                  <div className="rounded-[10px] bg-[#314D7E] flex flex-col gap-2.5 h-[138px] w-full xs:w-[140px] sm:w-[140px] md:w-[180px] lg:w-[200px] p-5">
                    <div className="h-[40px] w-[40px] rounded-[50%] bg-[#102C5D]">
                      <img
                        src={AccessAccountBalanace}
                        alt="AccessAccountBalanace"
                      />
                    </div>
                    <div className="text-[12px] text-[#F9F9F9] font-normal h-[48px]">
                      Instant access to all account balances
                    </div>
                  </div>

                  <div className="rounded-[10px] bg-[#314D7E] flex flex-col gap-2.5 h-[138px] w-full xs:w-[140px] sm:w-[140px] md:w-[180px] lg:w-[200px] p-5">
                    <div className="h-[40px] w-[40px] rounded-[50%] bg-[#102C5D]">
                      <img
                        src={PersonalisedStatements}
                        alt="PersonalisedStatements"
                      />
                    </div>
                    <div className="text-[12px] text-[#F9F9F9] font-normal h-[48px]">
                      Personalised bank account statements
                    </div>
                  </div>

                  <div className="rounded-[10px] bg-[#314D7E] flex flex-col gap-2.5 h-[138px] w-full xs:w-[140px] sm:w-[140px] md:w-[180px] lg:w-[200px] p-5">
                    <div className="h-[40px] w-[40px] rounded-[50%] bg-[#102C5D]">
                      <img src={AuthMatrix} alt="AuthMatrix" />
                    </div>
                    <div className="text-[12px] text-[#F9F9F9] font-normal h-[48px]">
                      Multi- factor authentication matrix
                    </div>
                  </div>

                  <div className="rounded-[10px] bg-[#314D7E] flex flex-col gap-2.5 h-[138px] w-full xs:w-[140px] sm:w-[140px] md:w-[180px] lg:w-[200px] p-5">
                    <div className="h-[40px] w-[40px] rounded-[50%] bg-[#102C5D]">
                      <img
                        src={BeneficiaryVerification}
                        alt="BeneficiaryVerification"
                      />
                    </div>
                    <div className="text-[12px] text-[#F9F9F9] font-normal h-[48px]">
                      Real-time vendor verification & beneficiary setup
                    </div>
                  </div>

                  <div className="rounded-[10px] bg-[#314D7E] flex flex-col gap-2.5 h-[138px] w-full xs:w-[140px] sm:w-[140px] md:w-[180px] lg:w-[200px] p-5">
                    <div className="h-[40px] w-[40px] rounded-[50%] bg-[#102C5D]">
                      <img src={SecurityProtocols} alt="SecurityProtocols" />
                    </div>
                    <div className="text-[12px] text-[#F9F9F9] font-normal h-[48px]">
                      Advanced security protocols
                    </div>
                  </div>

                  <div className="rounded-[10px] bg-[#314D7E] flex flex-col gap-2.5 h-[138px] w-full xs:w-[140px] sm:w-[140px] md:w-[180px] lg:w-[200px] p-5">
                    <div className="h-[40px] w-[40px] rounded-[50%] bg-[#102C5D]">
                      <img src={FullyResponsive} alt="FullyResponsive" />
                    </div>
                    <div className="text-[12px] text-[#F9F9F9] font-normal h-[48px]">
                      Full functionality accessible via mobile app
                    </div>
                  </div>

                  <div className="rounded-[10px] bg-[#314D7E] flex flex-col gap-2.5 h-[138px] w-full xs:w-[140px] sm:w-[140px] md:w-[180px] lg:w-[200px] p-5">
                    <div className="h-[40px] w-[40px] rounded-[50%] bg-[#102C5D]">
                      <img src={ErpSystem} alt="ErpSystem" />
                    </div>
                    <div className="text-[12px] text-[#F9F9F9] font-normal h-[48px]">
                      Easy integration with ERP systems
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="py-5 px-[30px] sm:px-[30px]"
                style={{
                  background:
                    "linear-gradient(90deg, #031F4F 0%, #224074 50%, #031F4F 100%)",
                }}
              >
                <p className="text-[#F9F9F9] text-[16px] font-semibold mb-[20px] text-left sm:text-center">
                  Product <span className="text-[#31E5E5]">advantages</span>
                </p>
                <div className="relative overflow-hidden h-[218px] w-full">
                  <div
                    className="flex gap-[20px]"
                    style={{
                      transform: isPaused
                        ? pausedTransform
                        : `translateX(calc(50% - ${
                            currentIndex * (280 + 20) + 140
                          }px))`,
                      transition: isPaused
                        ? "none"
                        : "transform 700ms ease-in-out",
                    }}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    {productSections.map((section, index) => (
                      <div
                        key={index}
                        className="max-w-[280px] flex-shrink-0 h-[218px] p-[20px] rounded-[10px] border-[1px] border-[#31E5E5]"
                      >
                        <div className="flex flex-col gap-[10px]">
                          <div className="h-[40px] w-[40px] rounded-[50%]">
                            <img src={section.image} alt="FullyResponsive" />
                          </div>
                          <div className="text-[16px] font-semibold text-[#F9F9F9]">
                            {section.title}
                          </div>
                          <div className="text-[12px] font-normal text-[#F9F9F9]">
                            {section.description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="max-w-[230px] mx-auto mt-[10px] flex gap-[10px] justify-center">
                  {productSections.map((_, index) => (
                    <div
                      key={index}
                      className={`rounded-[18px] bg-white h-[4px] transition-all duration-500 ease-in-out ${
                        isPaused
                          ? index === currentIndex
                            ? "w-[80px] opacity-100"
                            : "w-[40px] opacity-50"
                          : index === currentIndex
                          ? "w-[80px] opacity-100"
                          : "w-[40px] opacity-50"
                      }`}
                    ></div>
                  ))}
                </div>
              </div>

              <UniSecCarosel />

              <div className="bg-[#031f4f] py-[30px]">
                <div
                  className="py-[20px]"
                  style={{
                    background:
                      "linear-gradient(90deg, #031F4F 0%, #224074 50%, #031F4F 100%)",
                  }}
                >
                  <p className="text-[#F9F9F9] text-[16px] font-semibold px-[30px] text-center">
                    <span className="text-[#31E5E5]">Tailored solutions</span>{" "}
                    for business needs
                  </p>
                  <div className="flex flex-col gap-4 px-[20px] mt-[20px]">
                    <div className="text-[#F9F9F9] text-[12px] font-semibold text-center">
                      Our custom solutions are designed to address the unique
                      challenges faced by AMC’s and corporates.
                    </div>
                    <div className="rounded-[170px] w-full h-[48px] bg-[#031F4F] p-[8px] flex text-[#F9F9F9] text-[12px] font-semibold text-center">
                      <div
                        className={`w-1/2 flex items-center justify-center cursor-pointer ${
                          active === "amcs"
                            ? "bg-[#314d7e] rounded-[500px]"
                            : ""
                        }`}
                        onClick={() => setActive("amcs")}
                      >
                        Xpentra for AMCs
                      </div>
                      <div
                        className={`w-1/2 flex items-center justify-center cursor-pointer ${
                          active === "corporates"
                            ? "bg-[#314d7e] rounded-[500px]"
                            : ""
                        }`}
                        onClick={() => setActive("corporates")}
                      >
                        Xpentra for Corporates
                      </div>
                    </div>
                    <div className="h-auto w-auto rounded-[10px] py-[20px] px-[10px] border-[1px] border-[#31E5E5] text-[#F9F9F9] flex gap-2.5">
                      <div className="w-full">
                        <p className="text-[12px] font-semibold">
                          Challenges :
                        </p>
                        <ul
                          className="text-[10px] pl-[15px] mt-[20px]"
                          style={{ listStyle: "disc", lineHeight: "2" }}
                        >
                          {active === "amcs" ? (
                            <>
                              <li>Navigating multiple banking platforms</li>
                              <li>Complex reconciliation processes</li>
                              <li>
                                Lack of real-time insights and transaction
                                visibility
                              </li>
                              <li>
                                Limited flexibility in transaction approvals
                              </li>
                              <li>Constraints in report generation</li>
                              <li>Expensive ERP integrations</li>
                              <li>
                                Inability to switch Registrar and Transfer Agent
                                (RTA)
                              </li>
                            </>
                          ) : (
                            <>
                              <li>Lack of automation</li>
                              <li>
                                Inefficient transaction approval processes
                              </li>
                              <li>Limited treasury investment visibility</li>
                              <li>Expensive ERP integrations</li>
                              <li>
                                Manual reconciliation and transaction tracking
                              </li>
                              <li>Inadequate security measures</li>
                              <li>
                                Delays in beneficiary and vendor transactions
                              </li>
                              <li>Limited visibility on account collections</li>
                            </>
                          )}
                        </ul>
                      </div>
                      <div className="border-[1px] border-[#31E5E5]"></div>
                      <div className="w-full">
                        <p className="text-[12px] font-semibold">
                          Solutions we provide :
                        </p>
                        <ul
                          className="text-[10px] pl-[15px] mt-[20px]"
                          style={{ listStyle: "disc", lineHeight: "2" }}
                        >
                          {active === "amcs" ? (
                            <>
                              <li>Centralised banking dashboard</li>
                              <li>End-to-end reconciliation</li>
                              <li>Real-time analytics</li>
                              <li>Customisable authorisation matrix</li>
                              <li>Advanced MIS reporting</li>
                              <li>
                                Cost-efficient ERP integration with Xpentra
                              </li>
                              <li>Flexible adaptation to any RTA</li>
                            </>
                          ) : (
                            <>
                              <li>Automated transaction workflows</li>
                              <li>Streamlined payables management</li>
                              <li>Comprehensive treasury visibility</li>
                              <li>Cost-efficient ERP integrations</li>
                              <li>End-to-end reconciliation processes</li>
                              <li>
                                Complete transaction trails with real-time
                                visibility
                              </li>
                              <li>Advanced security mechanisms</li>
                              <li>
                                Real-time vendor and beneficiary registration
                              </li>
                              <li>
                                Custom notifications and real-time collections
                                tracking
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="w-[112px] h-[36px] rounded-[5px] p-[10px] bg-[#F9F9F9] flex justify-between">
                      <div
                        className="text-[#031F4F] text-[12px] h-[16px] font-semibold"
                        onClick={() => {
                          navigate(active === "amcs" ? "/amc" : "/corporates");
                        }}
                      >
                        Learn more
                      </div>
                      <div className="h-[14px] w-[14px] my-auto">
                        <img src={ArrowRight} alt="arrowRight" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="h-[186px] w-full py-[20px] px-[30px] flex flex-col gap-4 relative"
                style={{
                  background:
                    "linear-gradient(87.27deg, #031F4F 3.97%, #224074 56.2%)",
                }}
              >
                <p className="text-[#F9F9F9] text-[16px] font-semibold">
                  Get your questions
                  <span className="text-[#31E5E5]"> answered</span>
                </p>
                <p className="text-[#F9F9F9] text-[12px] font-normal">
                  For frequently asked questions, Xpentra has a help centre page
                  and user manual. Also, client can raise a support ticket
                  via&nbsp;
                  <a
                    href="https://mail.google.com/mail/?view=cm&fs=1&to=support%40q2pay.in"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cursor-pointer border-b border-current hover:border-[#31E5E5] hover:text-[#31E5E5]"
                  >
                    support@q2pay.in
                  </a>
                </p>
                <div
                  className="w-[96px] h-[36px] rounded-[5px] p-[10px] bg-[#F9F9F9] flex justify-between"
                  onClick={() => {
                    showToast();
                  }}
                >
                  <div className="text-[#031F4F] text-[12px] h-[16px] font-semibold">
                    For FAQs
                  </div>
                  <div className="h-[14px] w-[14px] my-auto">
                    <img src={ArrowRight} alt="arrowRight" />
                  </div>
                </div>
                <div className="absolute bottom-0 right-0">
                  <img src={QuestionMarkMob} alt="QuestionMarkMob" />
                </div>
              </div>

              <Footer />
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                overflow: webstatus ? "hidden" : "",
                height: "100vh",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
              }}
            >
              <div className="flex relative mt-[60px] bg-[#1f3969]">
                <div className="h-[492px] max-w-[1440px] w-screen flex justify-between mx-auto relative">
                  <div className="w-[50%] pl-[125px] flex flex-col justify-center">
                    <p
                      className="text-[#F9F9F9] text-[32px] font-semibold"
                      style={{ lineHeight: "1.2" }}
                    >
                      Your all-in-one platform for streamlined financial
                      management
                    </p>
                    <p className="text-[#F9F9F9] text-[14px] mt-[10px]">
                      Xpentra is a sophisticated platform designed to simplify
                      complex financial processes, using robust tools to offer
                      advanced capabilities in managing accounts and
                      transactions efficiently. Xpentra acts as a powerful CXO
                      enabler, with its intuitive dashboards and analytics
                      delivering expert insights into cash and liquidity
                      management, financial projections, and forecasting.
                    </p>
                    <div className="mt-[20px]">
                      <div
                        onClick={() => {
                          navigate("/book_demo");
                        }}
                        onMouseEnter={() => setBookArrow(ArrowRightWhite)}
                        onMouseLeave={() => setBookArrow(ArrowRight)}
                        className="cursor-pointer rounded-[5px] w-[125px] text-center font-semibold text-[12px] py-[10px] flex gap-2.5 items-center justify-center text-[#1D3A6D] bg-[#F9F9F9] hover:bg-[#506994] hover:text-[#F9F9F9]"
                      >
                        Book a demo
                        <img
                          className="h-[12px]"
                          src={bookArrow}
                          alt="arrow-right"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-[640px] h-[492px] pl-[125px] py-[15px]">
                    <div className="h-[432px] w-[391px]">
                      <img src={HomeBannerGif} alt="HomeBannerGif" />
                    </div>
                  </div>
                  <div className="absolute h-[62px] w-[65px] ml-[150px] mt-[30px]">
                    <img src={designElement3} alt="" />
                  </div>
                  <div className="absolute h-[62px] w-[65px] ml-[50px] mt-[395px]">
                    <img src={designElement3} alt="" />
                  </div>
                </div>

                <div className="absolute h-[100px] w-[218px] left-[-60px] top-[25%]">
                  <img src={designElement1} alt="" />
                </div>

                <div className="absolute h-[62px] w-[65px] right-[50px] mt-[75px]">
                  <img src={designElement3} alt="" />
                </div>

                <div className="absolute h-[62px] w-[65px] right-[0] mt-[225px] rotate-90">
                  <img src={designElement2} alt="" />
                </div>

                <div className="absolute h-[62px] w-[65px] right-[25px] mt-[375px]">
                  <img src={designElement2} alt="" />
                </div>
              </div>

              <div className="bg-[#031F4F] py-[30px] px-[171px]">
                <div className="max-w-[1024px] mx-auto flex flex-col gap-2.5">
                  <div className="flex gap-2.5">
                    <div className="w-[334px] h-[147px] bg-[#112C5D] px-[20px] py-[36px] rounded-tl-[10px] text-[28px] font-semibold justify-center content-center text-[#F9F9F9]">
                      <span className="text-[#31E5E5]">Key features</span> of
                      Xpentra
                    </div>
                    <div className="w-[334px] h-[147px] bg-[#244277] px-[20px] py-[36px]">
                      <div className="h-[60px] w-[60px] rounded-[50%]">
                        <img
                          className="h-[60px] w-[60px]"
                          src={IntegrationBank}
                          alt="IntegrationBank"
                        />
                      </div>
                      <p className="text-[#F9F9F9] text-[12px] mt-[10px] font-semibold">
                        Seamless integration with all banks
                      </p>
                    </div>
                    <div className="w-[334px] h-[147px] bg-[#244277] px-[20px] py-[36px] rounded-tr-[10px]">
                      <div className="h-[60px] w-[60px] rounded-[50%]">
                        <img
                          className="h-[60px] w-[60px]"
                          src={AccessAccountBalanace}
                          alt="AccessAccountBalanace"
                        />
                      </div>
                      <p className="text-[#F9F9F9] text-[12px] mt-[10px] font-semibold">
                        Instant access to all account balances
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2.5">
                    <div className="w-[334px] h-[147px] bg-[#244277] px-[20px] py-[36px]">
                      <div className="h-[60px] w-[60px] rounded-[50%]">
                        <img
                          className="h-[60px] w-[60px]"
                          src={PersonalisedStatements}
                          alt="PersonalisedStatements"
                        />
                      </div>
                      <p className="text-[#F9F9F9] text-[12px] mt-[10px] font-semibold">
                        Personalised bank account statements
                      </p>
                    </div>
                    <div className="w-[334px] h-[147px] bg-[#244277] px-[20px] py-[36px]">
                      <div className="h-[60px] w-[60px] rounded-[50%]">
                        <img
                          className="h-[60px] w-[60px]"
                          src={AuthMatrix}
                          alt="group9850"
                        />
                      </div>
                      <p className="text-[#F9F9F9] text-[12px] mt-[10px] font-semibold">
                        Multi-factor authentication matrix
                      </p>
                    </div>
                    <div className="w-[334px] h-[147px] bg-[#244277] px-[20px] py-[36px]">
                      <div className="h-[60px] w-[60px] rounded-[50%]">
                        <img
                          className="h-[60px] w-[60px]"
                          src={BeneficiaryVerification}
                          alt="BeneficiaryVerification"
                        />
                      </div>
                      <p className="text-[#F9F9F9] text-[12px] mt-[10px] font-semibold">
                        Real-time vendor verification & beneficiary setup
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2.5">
                    <div className="w-[334px] h-[147px] bg-[#244277] px-[20px] py-[36px] rounded-bl-[10px]">
                      <div className="h-[60px] w-[60px] rounded-[50%]">
                        <img
                          className="h-[60px] w-[60px]"
                          src={SecurityProtocols}
                          alt="SecurityProtocols"
                        />
                      </div>
                      <p className="text-[#F9F9F9] text-[12px] mt-[10px] font-semibold">
                        Advanced security protocols
                      </p>
                    </div>
                    <div className="w-[334px] h-[147px] bg-[#244277] px-[20px] py-[36px]">
                      <div className="h-[60px] w-[60px] rounded-[50%]">
                        <img
                          className="h-[60px] w-[60px]"
                          src={FullyResponsive}
                          alt="FullyResponsive"
                        />
                      </div>
                      <p className="text-[#F9F9F9] text-[12px] mt-[10px] font-semibold">
                        Full functionality accessible via mobile app
                      </p>
                    </div>
                    <div className="w-[334px] h-[147px] bg-[#244277] px-[20px] py-[36px] rounded-br-[10px]">
                      <div className="h-[60px] w-[60px] rounded-[50%]">
                        <img
                          className="h-[60px] w-[60px]"
                          src={ErpSystem}
                          alt="ErpSystem"
                        />
                      </div>
                      <p className="text-[#F9F9F9] text-[12px] mt-[10px] font-semibold">
                        Easy integration with ERP systems
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="bg-transparent m-0 p-0 text-[28px] pt-[20px] pb-[30px] text-[#F9F9F9]"
                style={{
                  background:
                    "linear-gradient(270deg, #031F4F 0%, #1C3A6D 27%, #224074 78%, #031F4F 100%, #234176 100%) 0% 0% no-repeat padding-box",
                }}
              >
                <p className="mb-[20px] text-center font-semibold">
                  Product <span className="text-[#31E5E5]">advantages</span>
                </p>
                <div className="flex flex-col gap-4">
                  <div className="flex gap-4 max-w-[1024px] mx-auto">
                    <div
                      className="flex flex-col gap-4 h-[200px] w-[502px] rounded-[20px] px-[30px] py-[18px]"
                      style={{ border: "1px solid #31E5E5" }}
                    >
                      <div className="h-[60px] w-[60px] rounded-[50%]">
                        <img
                          className="h-[60px] w-[60px]"
                          src={Flexibility}
                          alt="Flexibility"
                        />
                      </div>
                      <div className="flex flex-col gap-[5px]">
                        <p className="text-[#FFFFFF] text-[16px] font-semibold">
                          Flexibility
                        </p>
                        <p className="text-[#FFFFFF] text-[12px]">
                          Whether you’re a small business or a large enterprise,
                          Xpentra adapts to your specific requirements, offering
                          scalable features that evolve as your business grows
                          and changes.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex flex-col gap-4 h-[200px] w-[502px] rounded-[20px] px-[30px] py-[18px]"
                      style={{ border: "1px solid #31E5E5" }}
                    >
                      <div className="h-[60px] w-[60px] rounded-[50%]">
                        <img
                          className="h-[60px] w-[60px]"
                          src={Automation}
                          alt="Automation"
                        />
                      </div>
                      <div className="flex flex-col gap-[5px]">
                        <p className="text-[#FFFFFF] text-[16px] font-semibold">
                          Automation
                        </p>
                        <p className="text-[#FFFFFF] text-[12px]">
                          Our platform automates repetitive tasks such as
                          payments, reconciliation, and reporting, freeing up
                          your team to focus on more strategic initiatives and
                          reducing the risk of human error.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="flex gap-4 max-w-[1024px] mx-auto">
                    <div
                      className="flex flex-col gap-4 h-[200px] w-[502px] rounded-[20px] px-[30px] py-[18px]"
                      style={{ border: "1px solid #31E5E5" }}
                    >
                      <div className="h-[60px] w-[60px] rounded-[50%]">
                        <img
                          className="h-[60px] w-[60px]"
                          src={Security}
                          alt="Security"
                        />
                      </div>
                      <div className="flex flex-col gap-[5px]">
                        <p className="text-[#FFFFFF] text-[16px] font-semibold">
                          Security
                        </p>
                        <p className="text-[#FFFFFF] text-[12px]">
                          With industry-leading encryption, multi-factor
                          authentication, and audit trails, Xpentra guarantees
                          that your sensitive financial information is
                          safeguarded at every step of the transaction process.
                        </p>
                      </div>
                    </div>
                    <div
                      className="flex flex-col gap-4 h-[200px] w-[502px] rounded-[20px] px-[30px] py-[18px]"
                      style={{ border: "1px solid #31E5E5" }}
                    >
                      <div className="h-[60px] w-[60px] rounded-[50%]">
                        <img
                          className="h-[60px] w-[60px]"
                          src={Transparency}
                          alt="Transparency"
                        />
                      </div>
                      <div className="flex flex-col gap-[5px]">
                        <p className="text-[#FFFFFF] text-[16px] font-semibold">
                          Transparency
                        </p>
                        <p className="text-[#FFFFFF] text-[12px]">
                          Xpentra delivers instant access to up-to-date data,
                          offering detailed reports and analytics that give you
                          full visibility into your financial operations and
                          help you make confident, data-driven decisions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <UniSecCarosel />

              <div
                style={{
                  background:
                    "transparent linear-gradient(270deg, #031F4F 0%, #224074 50%, #031F4F 100%, #234176 100%) 0% 0% no-repeat padding-box",
                }}
              >
                <div className="max-w-[1024px] mx-auto py-[20px] flex flex-col gap-2.5">
                  <div>
                    <p className="text-[28px] text-[#F9F9F9] text-center font-semibold">
                      <span className="text-[#31E5E5]">Tailored solutions</span>{" "}
                      for business needs
                    </p>
                    <p className="text-[12px] text-[#F9F9F9] text-center">
                      Our custom solutions are designed to address the unique
                      challenges faced by AMC’s and corporates.
                    </p>
                  </div>

                  <div>
                    <div className="flex flex-col mt-[20px] border-[1px] border-[#31E5E5] rounded-[20px]">
                      <div className="flex border-b-[1px] border-b-[#31E5E5]">
                        <div className="w-[342px] flex flex-col gap-[5px] p-5 my-[20px] border-r-[1px] border-r-[#31E5E5]">
                          <div className="h-full">
                            <div className="w-[150px] h-[150px] rounded-[20px] mx-auto">
                              <img src={AMC} alt="AMC" />
                            </div>
                            <p className="text-[12px] w-[150px] text-[#F9F9F9] text-center mt-[10px] mx-auto">
                              Xpentra for AMCs
                            </p>
                            <div
                              onClick={() => {
                                navigate("/amc");
                              }}
                              onMouseEnter={() => setAMCArrow(ArrowRightWhite)}
                              onMouseLeave={() => setAMCArrow(ArrowRight)}
                              className="cursor-pointer rounded-[5px] w-[150px] mt-[10px] mx-auto text-center text-[12px] font-semibold py-[10px] flex gap-2.5 items-center justify-center text-[#1D3A6D] bg-[#F9F9F9] hover:bg-[#506994] hover:text-[#F9F9F9]"
                            >
                              Learn More
                              <img
                                className="h-[12px]"
                                src={AMCArrow}
                                alt="arrow-right"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="w-[683px]">
                          <div className="h-[398px] py-[20px] px-[10px] flex justify-around">
                            <div className="text-[#F9F9F9] w-[310px] flex flex-col gap-2.5 border-r-[1px] border-r-[#31E5E5]">
                              <p className="text-[16px] font-semibold">
                                Challenges :
                              </p>
                              <ul
                                className="text-[12px] pl-[5px]"
                                style={{ listStyle: "disc", lineHeight: "3" }}
                              >
                                <li>Navigating multiple banking platforms</li>
                                <li>Complex reconciliation processes</li>
                                <li>
                                  Lack of real-time insights and transaction
                                  visibility
                                </li>
                                <li>
                                  Limited flexibility in transaction approvals
                                </li>
                                <li>Constraints in report generation</li>
                                <li>Expensive ERP integrations</li>
                                <li>
                                  Inability to switch Registrar and Transfer
                                  Agent (RTA)
                                </li>
                              </ul>
                            </div>
                            <div className="text-[#F9F9F9] w-[310px] flex flex-col gap-2.5">
                              <p className="text-[16px] font-semibold">
                                Solutions we provide :
                              </p>
                              <ul
                                className="text-[12px] pl-[5px]"
                                style={{ listStyle: "disc", lineHeight: "3" }}
                              >
                                <li>Centralised banking dashboard</li>
                                <li>End-to-end reconciliation</li>
                                <li>Real-time analytics</li>
                                <li>Customisable authorisation matrix</li>
                                <li>Advanced MIS reporting</li>
                                <li>
                                  Cost-efficient ERP integration with Xpentra
                                </li>
                                <li>Flexible adaptation to any RTA</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="w-[342px] flex flex-col gap-[5px] p-5 my-[20px] border-r-[1px] border-r-[#31E5E5]">
                          <div className="h-full">
                            <div className="w-[150px] h-[150px] rounded-[20px] mx-auto">
                              <img src={Corporates} alt="corporates" />
                            </div>
                            <p className="text-[12px] w-[150px] text-[#F9F9F9] text-center mt-[10px] mx-auto">
                              Xpentra for Corporates
                            </p>
                            <div
                              onClick={() => {
                                navigate("/corporates");
                              }}
                              onMouseEnter={() =>
                                setCorporateArrow(ArrowRightWhite)
                              }
                              onMouseLeave={() => setCorporateArrow(ArrowRight)}
                              className="cursor-pointer rounded-[5px] w-[150px] mx-auto mt-[10px] text-center text-[12px] font-semibold py-[10px] flex gap-2.5 items-center justify-center text-[#1D3A6D] bg-[#F9F9F9] hover:bg-[#506994] hover:text-[#F9F9F9]"
                            >
                              Learn More
                              <img
                                className="h-[12px]"
                                src={corporateArrow}
                                alt="arrow-right"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="w-[683px]">
                          <div className="h-[398px] py-[20px] px-[10px] flex justify-around">
                            <div className="text-[#F9F9F9] w-[310px] flex flex-col gap-2.5 border-r-[1px] border-r-[#31E5E5]">
                              <p className="text-[16px] font-semibold">
                                Challenges :
                              </p>
                              <ul
                                className="text-[12px] pl-[5px]"
                                style={{ listStyle: "disc", lineHeight: "3" }}
                              >
                                <li>Lack of automation</li>
                                <li>
                                  Inefficient transaction approval processes
                                </li>
                                <li>Limited treasury investment visibility</li>
                                <li>Expensive ERP integrations</li>
                                <li>
                                  Manual reconciliation and transaction tracking
                                </li>
                                <li>Inadequate security measures</li>
                                <li>
                                  Delays in beneficiary and vendor transactions
                                </li>
                                <li>
                                  Limited visibility on account collections{" "}
                                </li>
                              </ul>
                            </div>
                            <div className="text-[#F9F9F9] w-[310px] flex flex-col gap-2.5">
                              <p className="text-[16px] font-semibold">
                                Solutions we provide :
                              </p>
                              <ul
                                className="text-[12px] pl-[5px]"
                                style={{ listStyle: "disc", lineHeight: "3" }}
                              >
                                <li>Automated transaction workflows</li>
                                <li>Streamlined payables management</li>
                                <li>Comprehensive treasury visibility</li>
                                <li>Cost-efficient ERP integrations</li>
                                <li>End-to-end reconciliation processes</li>
                                <li>
                                  Complete transaction trails with real-time
                                  visibility
                                </li>
                                <li>Advanced security mechanisms</li>
                                <li>
                                  Real-time vendor and beneficiary registration
                                </li>
                                <li>
                                  Custom notifications and real-time collections
                                  tracking
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bg-[#031F4F] pt-[35px]">
                <div
                  className="h-[217px]"
                  style={{
                    background:
                      "linear-gradient(90deg, #224074 0%, #031F4F 100%) no-repeat padding-box",
                  }}
                >
                  <div className="max-w-[1024px] mx-auto py-[20px] flex gap-4 justify-between">
                    <div>
                      <div className="text-[#F9F9F9] flex flex-col gap-2.5">
                        <p
                          className="text-[28px] font-semibold"
                          style={{ lineHeight: "1.2" }}
                        >
                          Get your questions <br />
                          <span className="text-[#31E5E5]">answered</span>
                        </p>
                        <p className="text-[12px]">
                          For frequently asked questions, Xpentra has a help
                          centre page and user manual.
                          <br /> Also, clients can raise a support ticket
                          via&nbsp;
                          <a
                            href="https://mail.google.com/mail/?view=cm&fs=1&to=support@q2pay.in"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cursor-pointer border-b border-current hover:border-[#31E5E5] hover:text-[#31E5E5]"
                          >
                            support@q2pay.in
                          </a>
                        </p>
                      </div>
                      <div className="mt-[20px]">
                        <div
                          onClick={() => {
                            showToast();
                          }}
                          onMouseEnter={() => setFaqArrow(ArrowRightWhite)}
                          onMouseLeave={() => setFaqArrow(ArrowRight)}
                          className="cursor-pointer rounded-[5px] w-[109px] text-center text-[12px] font-semibold py-[10px] flex gap-2.5 items-center justify-center text-[#1D3A6D] bg-[#F9F9F9] hover:bg-[#506994] hover:text-[#F9F9F9]"
                        >
                          For FAQs
                          <img
                            className="h-[12px]"
                            src={faqArrow}
                            alt="arrow-right"
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <img src={QuestionMarkWeb} alt="questionMark" />
                    </div>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LandingPage;
