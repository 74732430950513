import React, { useState, useEffect, useRef, useCallback } from "react";
import "./index.css";
import OwnerViewAllCard from "../OwnerViewAllCard/index";
import ListView from "../../../../components/Accounts/OwnerAccounts/listView/index";
import { Box, Button, tooltipClasses, Tooltip, TextField } from "@mui/material";
import arrowDown from "../../../../assets/ArrowsIcons/arrow_down_white.svg";
import CheckIcon from "../../../../assets/CheckmarksIcons/approve_white.svg";
import filterIcon from "../../../../assets/FilterSearchIcons/filter.svg";
import CloseIcon from "../../../../assets/Beneficiary Icons/cancelrecent.png";
import calendarIcon_blue from "../../../../../src/assets/CalendarIcons/Calender_blue.svg";
import calendarIcon_white from "../../../../../src/assets/CalendarIcons/Calender.svg";
import SearchIcon from "../../../../assets/FilterSearchIcons/search_white.svg";
import SearchBlue from "../../../../assets/FilterSearchIcons/search_blue.svg";
import ArrowLeft from "../../../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import AdditionalDetailsIcon_blue from "../../../../../src/assets/GeneralIcons/additional_fields_blue.svg";
import AdditionalDetailsIcon_white from "../../../../../src/assets/GeneralIcons/additional_fields_white.svg";
import selectWithWhiteFilledCircle from "../../../../../src/assets/CheckmarksIcons/select_all_with_white_filled_circle.svg";
import selectWithGreenFilledCircle from "../../../../../src/assets/CheckmarksIcons/selected_with_green_circle.svg";
import ArrowRight from "../../../../assets/ArrowsIcons/ChevronRightWhite.svg";
import ArrowDown from "../../../../assets/ArrowsIcons/arrow_down_white.svg";
import ArrowUp from "../../../../assets/ArrowsIcons/arrow_up_white.svg";
import ArrowDown8 from "../../../../assets/ArrowsIcons/arrow_down_blue.svg";
import ArrowDown5 from "../../../../assets/ArrowsIcons/arrow_down_white_filled.svg";
import ArrowUp4 from "../../../../assets/ArrowsIcons/arrow_up_blue.svg";
import FilterBlue from "../../../../assets/FilterSearchIcons/filter_blue.svg";
import icici_im from "../../../../assets/ownerAllStatement/ICICI-Bank.svg";
import icici_img from "../../../../assets/BankLogos/axis.png";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { toast } from "react-toastify";
import {
  getOwnerAccStatement,
  resetStatementData,
} from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import noaccount from "../../../../assets/Beneficiary Icons/noaccfound.png";
import DatePickerComponent from "../../../../components/_utils/Calendar";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import SelectBank from "../../../../components/_utils/SelectBank";
import TypeOfAccountDropdown from "../../../../components/_utils/TypeOfAccountDropdown/index";
import ConnectedBankingDropdown from "../../../../components/_utils/connectedBankingDropdopwn/selectConnectedBanking";
import Validation from "../../../../utils/validation";
import SelectAccountBalance from "../../../../components/SelectAccountBalance/index";
import RectangleSearchBar from "../../../../components/_utils/RectangleSearchBar/index";
import clearFilterImg from "../../../../assets/CloseIcons/close_with_blue_circle.svg";
import arrowforward from "../../../../assets/ArrowsIcons/arrow_right_white – 1.svg";
import arrow_down_red from "../../../../assets/TransactionDashboardIcons/arrow_down_red.png";
import { useDispatch, useSelector } from "react-redux";
import TypeDropdown from "../../../../components/_utils/typeDropdown/typedropdown";
import download_icon from "../../../../assets/GeneralIcons/download_statement.svg";
import PaginationComponent from "../../../../components/_utils/PaginationComponent";

const OwnerAllStatement = (
  props,
  isOpen,
  onClose,
  dateRef,
  isViewModalOpen,
  paymentMethod,
  beneAccountId,
  sendCategory,
  setPage,
  sendDateRange,
  sendPaymentMethod,
  setSelectedBeneAccId,
  setDropdownOpen
) => {
  const typeDivRef = useRef(null);
  const amtDivRef = useRef(null);
  const dropdownOptions = [50, 100];
  const [selectStatement, setSelectStatement] = useState(false);
  const [showStatementType, setShowStatementType] = useState(false);
  const [showAmtFilterDiv, setShowAmtFilterDiv] = useState(false);
  const [selectStatementType, setSelectStatementType] = useState("Type");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageText, setCurrentPageText] = useState("01");
  const [isAmtFilterApplied, setIsAmtFilterApplied] = useState(false);

  const yearDropdownRef = useRef(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [showAdditionalDetails, setShowAdditionalDetails] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const searchButtonRef = useRef(null);
  const calenderButtonRef = useRef(null);
  const additionalDetailsRef = useRef(null);
  const [searchUserText, setSearchUserText] = useState("");
  const [isPerPageOpen, setIsPerPageOpen] = useState(false);
  const filterButtonRef = useRef(null);
  const location = useLocation();
  const [filterApply, setFilterApply] = useState(location.state ? true : false);
  const [selectTypeOfAccount, setSelectTypeofAccount] = useState(false);
  const [selectSortAmt, setSelectSortAmt] = useState(false);
  const [mainData, setMainData] = useState([]);
  const dropdownRef = useRef(null);
  const [pageSize, setPageSize] = useState(dropdownOptions[0]);
  const [selectedValue, setSelectedValue] = useState(dropdownOptions[0]);
  const [accumulatedData, setAccumulatedData] = useState([]);
  const dispatch = useDispatch();
  const filterRef = useRef(null);
  const [showSelectBank, setShowSelectBank] = useState(false);
  const [selectConnectedBanking, setSelectConnectedBanking] = useState(false);
  const [connectedbankingstate, setConnectedbankingstate] = useState(
    location.state ? true : false
  );
  const [bankstate, setbankstate] = useState("");
  const [typeaccountstate, settypeaccountstate] = useState("");
  const [accountbalancestate, setaccountbalancestate] = useState("");
  const [accountnumberstate, setaccountnumberstate] = useState("");
  const [ifscstate, setifscstate] = useState("");
  const [panstate, setpanstate] = useState("");
  const searchRef = useRef(null);
  const [selectedType, setSelectedType] = useState("PDF");
  const fileTypeRef = useRef(null);
  const [activeSection, setActiveSection] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [categorySearch, setCategorySearch] = useState("");
  const [initCategory, setInitCategory] = useState("");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [totalPages, setTotalPages] = useState(20);
  const [inputValue, setInputValue] = useState(1);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleClick = (name) => {
    setInitCategory(name);
    setPage(1);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleConfirm = () => {
    setShowDatePicker(false);
  };

  const handleCancel = () => {
    setShowDatePicker(false);
    setStartDate(null);
    setEndDate(null);
  };

  const handleApplyEnable = () => {
    if (
      filterPayload.pannumber.length > 0 &&
      filterPayload.pannumber.length !== 10
    ) {
      return false;
    } else if (
      filterPayload.accountnumber.length > 0 &&
      filterPayload.ifscnumber.length !== 11
    ) {
      return false;
    } else if (
      filterPayload.accountnumber.length < 4 &&
      filterPayload.ifscnumber.length > 10
    ) {
      return false;
    } else if (
      filterPayload.accountnumber.length === 0 &&
      filterPayload.ifscnumber.length > 0
    ) {
      return false;
    } else if (
      filterPayload.sortAccountNo.length == 0 &&
      filterPayload.pannumber.length == 0 &&
      filterPayload.connectedBanking.length == 0 &&
      filterPayload.typeofaccount.length == 0 &&
      filterPayload.bankNameInput.length == 0 &&
      filterPayload.accountnumber.length == 0 &&
      filterPayload.ifscnumber.length == 0
    ) {
      return false;
    }
    return true;
  };

  const handleInput = (e) => {
    let { name, value } = e.target;
    let val = value.replaceAll(" ", "");
    if (allowedValue(name, val)) {
      const newObj = {
        ...filterPayload,
        [name]: val.toUpperCase(),
      };
      if ((name = "accountnumber") && value == "") {
        newObj.ifscnumber = "";
        setFilterPayload(newObj);
      } else {
        setFilterPayload(newObj);
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    setSearchUserText(e.target.value);
  };

  const allowedValue = (name, value) => {
    if (name === "accountnumber") return Validation.allowedBankAccNum(value);
    if (name === "ifscnumber") return Validation.allowedBankIfsc(value);
    if (name === "pannumber") return Validation.allowedPAN(value);
    return true;
  };

  const handleSelect = (value) => {
    setCurrentPage(1);
    setSelectedValue(value);
    setPageSize(value);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };
  const handleClose = () => {
    props.onClose();
    dispatch(resetStatementData());
  };
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
    let val = Number(currentPageText) + 1;
    setCurrentPageText(val > 9 ? val : "0" + val);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
    let val = Number(currentPageText) - 1;
    setCurrentPageText(val > 9 ? val : "0" + val);
  };

  // useEffect(() => {
  //     const handleClickOutside = (event) => {
  //     if (dateRef.current && !dateRef.current.contains(event.target)) {
  //         setShowDatePicker(false);
  //     }
  //     if (
  //         categoryDropdownRef.current &&
  //         !categoryDropdownRef.current.contains(event.target)
  //     ) {
  //         setDropdownOpen(false);
  //     }
  //     if (
  //         settlementDropdownRef.current &&
  //         !settlementDropdownRef.current.contains(event.target)
  //     ) {
  //         setIsDropdownOpen(false);
  //     }
  //     };
  //     document.addEventListener("mousedown", handleClickOutside);
  //     return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //     };
  // }, [categoryDropdownRef, settlementDropdownRef, dateRef]);

  const clearFilter = () => {
    setInitCategory("");
    // setIsPaymentMethod("");
    // setPaymentMethod("");
    // setIsDropdownOpen(false);
    setCategorySearch("");
    // setSettlementSearch("");
    setIsSidebarOpen(false);
    setShowDatePicker(false);
    setStartDate(null);
    setEndDate(null);
    // sendPaymentMethod("");
    sendCategory("");
    // setStartDateFilter("");
    // setEndDateFilter("");
    // setTxnAmount("");
    // setSelectedBeneAccId("");
    // setBeneAccountId("");
    // setAccountName("");
    // setIsFilterApplied(false);
  };

  const formatDate = (date) => {
    return `${date.getDate()} ${date.toLocaleString("default", {
      month: "short",
    })}, ${date.getFullYear()}`;
  };

  const convertDate = (dateString) => {
    const date = new Date(dateString);
    const timestamp = Math.floor(date.getTime() / 1000);

    return timestamp;
  };

  const filterCategory = () => {
    debugger;
    sendCategory(initCategory);
    setPage(1);
    if (startDate && endDate) {
      sendDateRange(convertDate(startDate), convertDate(endDate));
    }
    sendPaymentMethod(paymentMethod);
    setSelectedBeneAccId(beneAccountId);
    // setTxnAmount(sanitizedAmount);
    setShowDatePicker(false);
    setIsSidebarOpen(false);
  };

  const handlepage = debounce((page) => {
    if (Number(page) <= mainData?.title?.total_pages && page !== "00") {
      let value = page.length > 1 ? page.substring(1) : page;
      if (currentPage != value) {
        setCurrentPage(Number(value));
        setCurrentPageText(page);
      }
    } else if (page > mainData?.title?.total_pages) {
      setCurrentPage(mainData?.title?.total_pages);
      setCurrentPageText("0" + mainData?.title?.total_pages);
    } else {
      setCurrentPage(1);
      setCurrentPageText("01");
    }
  }, 100);

  const handleBlur = () => {
    if (currentPageText.length === 0 || currentPageText === "0") {
      setCurrentPageText(currentPage > 1 ? currentPage : "01");
    } else {
      if (currentPageText.length === 1) {
        setCurrentPageText("0" + currentPage);
      }
    }
  };

  const [filterPayload, setFilterPayload] = useState({
    pagination: 0,
    accNoInput: "",
    sortBank: "",
    sortAccountNo: "",
    bankNameInput: "",
    connectedBanking: location.state ? location.state.approvalStatus : "",
    typeofaccount: "",
    accountnumber: "",
    ifscnumber: "",
    pannumber: "",
    panFilter: false,
    BankFilter: false,
    TypeAccFilter: false,
    ConnectedFilter: location.state ? true : false,
    AccIfscFilter: false,
    SortBankFilter: false,
  });

  const {
    ownerAccBalanceLoading,
    ownerAccBalanceError,
    toggle,
    showSuccessToast,
    storedPage,
  } = useSelector((state) => state.ownerAllVerification);

  const [amountLimit, setAmountLimit] = useState({
    min: "",
    max: "",
  });

  const handleAmtFilterApply = () => {
    if (Object.values(amountLimit).includes("")) return;
    setIsAmtFilterApplied(true);
    setShowAmtFilterDiv(false);
  };

  const handleClearAmts = () => {
    setAmountLimit((prev) => ({
      ...prev,
      min: "",
      max: "",
    }));
    setIsAmtFilterApplied(false);
  };

  const handleAmtInput = (e) => {
    const { name, value } = e.target;

    const regex = /^\d*\.?\d{0,2}$/;

    if (regex.test(value)) {
      setAmountLimit((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleToastClick = () => {
    toast.info("Currently in Development", toast_position);
  };

  const debouncedHandlePageChange = debounce((value) => {
    handlePageChange(value);
  }, 1000);

  const handleChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      const numericValue = Number(value);
      if (value === "") {
        setInputValue("");
        setCurrentPage(1);
      } else if (numericValue > totalPages) {
        setInputValue(
          String(totalPages).padStart(String(totalPages).length, "0")
        );
        setCurrentPage(totalPages);
      } else if (numericValue < 1) {
        setInputValue(1);
        setCurrentPage(1);
      } else {
        setInputValue(value);
        debouncedHandlePageChange(value);
      }
    }
  };

  const handlePageChange = useCallback(
    debounce((page) => {
      const pageNumber = Number(page);
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
      } else if (pageNumber > totalPages) {
        setCurrentPage(totalPages);
      } else {
        setCurrentPage(1);
      }
    }, 300),

    [totalPages]
  );

  return (
    <div>
      {
        <div
          style={{
            zIndex: filterOpen ? "0" : "9999",
            width: "100%",
            bottom: "0",
          }}
          className="fixed w-full bg-[#244277] h-[50px]"
        >
          <PaginationComponent
            handleSelect={handleSelect}
            selectedValue={selectedValue}
            data={mainData}
            pageSize={pageSize}
            dropdownOptions={dropdownOptions}
            handlePagination={handlePagination}
            currentPage={currentPage}
          />
        </div>
      }
      <div
        className={`bg-custom-appBack flex flex-col overflow-y-hidden h-screen`}
        style={{ overflow: filterOpen && "hidden" }}
      >
        <div
          className={`OAS-owner_div_container ${
            showSearchBar && "OAS-owner_div_height"
          }`}
        >
          <div className={"OAS-owner_div"}>
            <div style={{ position: "relative", top: "20px" }}>
              <p className="text-[16px] text-[#F9F9F9]">Total Entries : 560</p>
              <p className="text-[10px] text-[#DADADA]">
                As on 28 Aug, 2024 - Statement update depends on availability
                from the bank
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "270px",
                position: "relative",
                top: "22px",
              }}
            >
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title="Open Calendar"
              >
                <div
                  ref={calenderButtonRef}
                  style={{
                    backgroundColor: showCalendar ? "#fff" : "#244277",
                  }}
                  className="OAS-owner_search_div"
                  onClick={() => setShowCalendar(!showCalendar)}
                >
                  <img
                    className="h-[15px] w-[15px]"
                    src={showCalendar ? calendarIcon_blue : calendarIcon_white} // Use your calendar icon here
                    alt="calendar-image"
                  />
                  <img
                    style={{
                      transform: showCalendar ? "rotate(180deg)" : "none",
                    }}
                    src={showCalendar ? ArrowDown8 : ArrowDown} // Same arrow icon logic
                    alt=""
                    className="OAS-filter-images"
                  />
                </div>
              </Tooltip>

              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title="Additional Details"
              >
                <div
                  ref={additionalDetailsRef}
                  style={{
                    backgroundColor: showAdditionalDetails ? "#fff" : "#244277", // Adjust background based on visibility
                  }}
                  className="OAS-owner_search_div"
                  onClick={() => {
                    setShowAdditionalDetails(!showAdditionalDetails); // Toggle visibility
                  }}
                >
                  <img
                    className="h-[15px] w-[15px]"
                    src={
                      showAdditionalDetails
                        ? AdditionalDetailsIcon_blue
                        : AdditionalDetailsIcon_white
                    }
                    alt="additional-details"
                  />
                  <img
                    style={{
                      transform: showAdditionalDetails && "rotate(180deg)", // Rotate icon based on visibility
                    }}
                    src={showAdditionalDetails ? ArrowDown8 : ArrowDown}
                    alt=""
                    className="OAS-filter-images"
                  />
                </div>
              </Tooltip>

              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title="Search"
              >
                <div
                  ref={searchButtonRef}
                  style={{
                    backgroundColor:
                      searchUserText.length > 0 ? "#fff" : "#244277",
                  }}
                  className="OAS-owner_search_div"
                  onClick={() => setShowSearchBar(!showSearchBar)}
                >
                  <img
                    className="h-[15px] w-[15px]"
                    src={searchUserText.length > 0 ? SearchBlue : SearchIcon}
                    alt="search-image"
                  />
                  <img
                    style={{
                      transform: showSearchBar && "rotate(180deg)",
                    }}
                    src={searchUserText.length > 0 ? ArrowDown8 : ArrowDown}
                    alt=""
                    className="OAS-filter-images"
                  />
                </div>
              </Tooltip>

              <div
                ref={filterButtonRef}
                onClick={() => {
                  setFilterOpen(!filterOpen);
                  setSelectTypeofAccount(false);
                  setSelectSortAmt(false);
                }}
              >
                <Tooltip
                  componentsProps={{
                    popper: {
                      sx: {
                        [`& .${tooltipClasses.tooltip}`]: {
                          backgroundColor: (theme) =>
                            theme.palette.common.black,
                        },
                      },
                    },
                  }}
                  title="Filters"
                >
                  <div
                    style={{
                      backgroundColor:
                        (filterPayload.typeofaccount.length > 0 ||
                          filterPayload.ifscnumber.length > 0 ||
                          filterPayload.accountnumber.length > 0 ||
                          filterPayload.bankNameInput.length > 0 ||
                          filterPayload.connectedBanking.length > 0 ||
                          filterPayload.pannumber.length > 0 ||
                          filterPayload.sortAccountNo.length > 0) &&
                        filterApply
                          ? "#F9F9F9"
                          : "",
                    }}
                    onClick={() => {
                      setFilterOpen(!filterOpen);
                      setSelectTypeofAccount(false);
                      setSelectSortAmt(false);
                    }}
                    className="OAS-filter-containers"
                  >
                    <Box className="filters-box">
                      <img
                        src={
                          (filterPayload.typeofaccount.length > 0 ||
                            filterPayload.ifscnumber.length > 0 ||
                            filterPayload.accountnumber.length > 0 ||
                            filterPayload.connectedBanking.length > 0 ||
                            filterPayload.bankNameInput.length > 0 ||
                            filterPayload.pannumber.length > 0 ||
                            filterPayload.sortAccountNo.length > 0) &&
                          filterApply
                            ? FilterBlue
                            : filterIcon
                        }
                        alt=""
                        className="OAS-filter-images"
                      />
                    </Box>
                    <Box className="filter-box">
                      <img
                        src={
                          filterOpen
                            ? (filterPayload.typeofaccount.length > 0 ||
                                filterPayload.ifscnumber.length > 0 ||
                                filterPayload.connectedBanking.length > 0 ||
                                filterPayload.accountnumber.length > 0 ||
                                filterPayload.bankNameInput.length > 0 ||
                                filterPayload.pannumber.length > 0 ||
                                filterPayload.sortAccountNo.length > 0) &&
                              filterApply
                              ? ArrowUp4
                              : ArrowUp
                            : (filterPayload.typeofaccount.length > 0 ||
                                filterPayload.ifscnumber.length > 0 ||
                                filterPayload.accountnumber.length > 0 ||
                                filterPayload.bankNameInput.length > 0 ||
                                filterPayload.connectedBanking.length > 0 ||
                                filterPayload.pannumber.length > 0 ||
                                filterPayload.sortAccountNo.length > 0) &&
                              filterApply
                            ? ArrowDown8
                            : ArrowDown
                        }
                        alt=""
                        className="OAS-filter-images"
                      />
                    </Box>
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <div
            className={`OAS-filter-transition-${filterOpen ? "open" : "close"}`}
          >
            {filterOpen && (
              <div
                style={{
                  maxWidth: "1024px",
                  margin: "0 auto",
                  borderTop: "1px solid #031f4f",
                  paddingTop: "10px",
                }}
                ref={filterRef}
              >
                <span className="text-[#F05A6A] font-normal ml-[10px]">
                  Filter{" "}
                </span>
                <div className="OAS-owner_filterdropdown">
                  <div
                    style={{
                      paddingTop: "10px",
                      display: "flex",
                      flexDirection: "column",
                      // justifyContent: "space-evenly",
                      width: "25%",
                      gap: "35px",
                      height: "194px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <div>
                      <p className="OAS-owner_account_number_text">
                        Account number
                      </p>

                      <input
                        className="OAS-owner_account_number_text_input"
                        type="text"
                        value={filterPayload.accountnumber.toUpperCase()}
                        onChange={handleInput}
                        name="accountnumber"
                        placeholder="Enter account number"
                      />
                    </div>
                    <SelectBank
                      option={mainData?.summary?.available_banks || []}
                      filterPayload={filterPayload}
                      setFilterPayload={setFilterPayload}
                      selectBank={showSelectBank}
                      setSelectBank={setShowSelectBank}
                      owner={true}
                    />
                  </div>
                  <div className="OAS-owner-border-div-line"></div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "25%",
                      gap: "25px",
                      height: "170px",
                      paddingTop: "10px",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                    }}
                  >
                    <div>
                      <TypeDropdown
                        filterPayload={filterPayload}
                        setFilterPayload={setFilterPayload}
                        selectConnectedBanking={selectConnectedBanking}
                        setSelectConnectedBanking={setSelectConnectedBanking}
                        owner={true}
                      />
                    </div>

                    <div>
                      <p className="OAS-owner_account_number_text">Amount</p>
                      <div ref={amtDivRef} className="OAS-amtDiv_type">
                        <div
                          className="OAS-amount-main-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowAmtFilterDiv(!showAmtFilterDiv);
                            setShowStatementType(false);
                          }}
                          style={{
                            height: showAmtFilterDiv ? "250px" : "37px",
                            cursor: "default",
                          }}
                        >
                          <div
                            style={{
                              height: "37px",
                              backgroundColor: "#667ca3",
                              display: "flex",
                              justifyContent: "space-between",
                              gap: "10px",
                              cursor: "pointer",
                              zIndex: "9999",
                            }}
                            className="OAS-bank_high_to_low_select_select"
                          >
                            <span className="text-[#dadada] text-[12px] pl-[14px]">
                              Set range
                            </span>
                            {isAmtFilterApplied ? (
                              <img
                                src={filterIcon}
                                alt="Status Icon"
                                style={{
                                  height: "12px",
                                  width: "12px",
                                }}
                                className="status_icon-img"
                              />
                            ) : (
                              <img
                                src={ArrowDown5}
                                alt="Status Icon"
                                style={{
                                  height: "12px",
                                  width: "12px",

                                  transform: showAmtFilterDiv
                                    ? "rotate(180deg)"
                                    : "",
                                }}
                                className="status_icon-img"
                              />
                            )}
                          </div>

                          <div
                            style={{
                              position: "absolute",
                              width: "176px",
                              margin: "0 20px",
                              top: "37px",
                              borderBottom: "1px solid #102C5D",
                            }}
                          ></div>
                          <div
                            onClick={(e) => e.stopPropagation()}
                            className="OAS-amount-inputs-div"
                          >
                            <div className="OAS-input-container">
                              <label for="min">Minimum</label>
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <p
                                  style={{
                                    position: "absolute",
                                    left: "8px",
                                    top: "5px",
                                    fontSize: "12px",
                                    display:
                                      amountLimit.min === "" ? "none" : "block",
                                  }}
                                >
                                  ₹
                                </p>

                                <input
                                  id="min"
                                  onChange={handleAmtInput}
                                  type="number"
                                  value={amountLimit.min}
                                  name="min"
                                  placeholder="Enter amount"
                                />
                              </div>
                            </div>

                            <div className="OAS-input-container">
                              <label for="max">Maximum</label>
                              <div
                                style={{
                                  position: "relative",
                                }}
                              >
                                <p
                                  style={{
                                    position: "absolute",
                                    left: "8px",
                                    top: "5.5px",
                                    fontSize: "12px",
                                    display:
                                      amountLimit.max === "" ? "none" : "block",
                                  }}
                                >
                                  ₹
                                </p>

                                <input
                                  id="max"
                                  onChange={handleAmtInput}
                                  type="number"
                                  value={amountLimit.max}
                                  name="max"
                                  placeholder="Enter amount"
                                />
                              </div>
                            </div>
                            <p
                              style={{
                                textAlign: "center",
                                margin: "10px 0",
                              }}
                            >
                              * Specify the amount to filter by.
                            </p>
                            <div className="OAS-inputs-div-btns">
                              <button
                                className={`OAS-clear-button ${
                                  amountLimit.min === "" ||
                                  amountLimit.max === ""
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={handleClearAmts}
                              >
                                Clear
                              </button>

                              <button
                                onClick={handleAmtFilterApply}
                                className={`OAS-apply-button ${
                                  amountLimit.min === "" ||
                                  amountLimit.max === ""
                                    ? "disabled"
                                    : ""
                                }`}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="pt-[20px] pb-[20px]"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    width: "100%%",
                    // height: "290px",
                    // paddingTop: "40px",
                  }}
                >
                  <div className="OAS-buttoncontainer">
                    <div
                      style={{
                        cursor: handleApplyEnable() ? "pointer" : "default",
                      }}
                      onClick={() => {
                        if (
                          filterPayload.typeofaccount.length > 0 ||
                          filterPayload.accountnumber.length > 0 ||
                          filterPayload.connectedBanking.length > 0 ||
                          filterPayload.bankNameInput.length > 0 ||
                          filterPayload.ifscnumber.length > 0 ||
                          filterPayload.pannumber.length > 0 ||
                          filterPayload.sortAccountNo.length > 0
                        ) {
                          setConnectedbankingstate("");
                          setbankstate("");
                          settypeaccountstate("");
                          setaccountbalancestate("");
                          setaccountnumberstate("");
                          setifscstate("");
                          setpanstate("");
                          setFilterPayload({
                            pagination: 0,
                            connectedBanking: "",
                            accNoInput: "",
                            sortBank: "",
                            sortAccountNo: "",
                            bankNameInput: "",
                            accountnumber: "",
                            pannumber: "",
                            ifscnumber: "",
                            typeofaccount: "",
                            panFilter: false,
                            BankFilter: false,
                            TypeAccFilter: false,
                            ConnectedFilter: false,
                            AccIfscFilter: false,
                            SortBankFilter: false,
                          });
                          setCurrentPage(1);
                          setCurrentPageText("01");
                        }
                      }}
                      className={`clear ${
                        handleApplyEnable() ? "hover:bg-[#506994]" : ""
                      }`}
                    >
                      <p className="OAS-buttoncontainer-text">Clear</p>
                    </div>
                    <div
                      onClick={() => {
                        if (handleApplyEnable()) {
                          let obj = filterPayload;
                          if (
                            filterPayload.accountnumber.length > 3 &&
                            filterPayload.ifscnumber.length === 11
                          ) {
                            setaccountnumberstate(filterPayload.accountnumber);
                            setifscstate(filterPayload.ifscnumber);
                            obj = {
                              ...obj,
                              AccIfscFilter: true,
                            };
                          }
                          if (filterPayload.pannumber.length === 10) {
                            setpanstate(filterPayload.pannumber);
                            obj = {
                              ...obj,
                              panFilter: true,
                            };
                          }
                          if (filterPayload.bankNameInput.length > 0) {
                            setbankstate(filterPayload.bankNameInput);
                            obj = {
                              ...obj,
                              BankFilter: true,
                            };
                          }
                          if (filterPayload.typeofaccount.length > 0) {
                            settypeaccountstate(filterPayload.typeofaccount);
                            obj = {
                              ...obj,
                              TypeAccFilter: true,
                            };
                          }
                          if (filterPayload.sortAccountNo.length > 0) {
                            setaccountbalancestate(filterPayload.sortAccountNo);
                            obj = {
                              ...obj,
                              SortBankFilter: true,
                            };
                          }
                          if (filterPayload.connectedBanking.length > 0) {
                            setConnectedbankingstate(
                              filterPayload.connectedBanking
                            );
                            obj = {
                              ...obj,
                              ConnectedFilter: true,
                            };
                          }
                          setFilterPayload(obj);
                          setFilterApply(true);
                          setCurrentPage(1);
                          setCurrentPageText("01");
                          setFilterOpen(false);
                        }
                      }}
                      className={`apply ${
                        handleApplyEnable() ? "hover:opacity-90" : ""
                      }`}
                      style={{
                        cursor: handleApplyEnable() ? "pointer" : "default",
                        backgroundColor: handleApplyEnable()
                          ? "#FB5963"
                          : "#707070",
                      }}
                    >
                      <p className="OAS-buttoncontainer-text">Apply</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div
            // onClose={toggleSidebar(false)}
            className={`OAS-beneTxnviewallsidebar ${
              showCalendar ? "open" : "closed"
            }`}
            style={{
              marginTop: "200px",
              backgroundColor: isViewModalOpen ? "#000" : "#3a5686",
              pointerEvents: isViewModalOpen ? "none" : "",
            }}
          >
            <div
              className="OAS-beneTxnviewallFilterDiv justify-between"
              style={{
                visibility: showCalendar ? "visible" : "hidden",
                transition: `opacity 0.2s ease-in ${
                  showCalendar ? "0s" : "0.2s"
                }`,
                opacity: showCalendar ? "1" : "0",
                borderTop: "1px solid rgb(29, 58, 109)",
              }}
            >
              <div className="OAS-beneTxnFilterSection1">
                <span className="text-[#F05A6A] font-normal">Calender </span>
                <div className="OAS-beneTxnFilterSection3 mt-[15px]">
                  <span
                    style={{
                      color: "#C9C9C9",
                      width: "62px",
                      height: "17px",
                      font: "normal normal 12px/19px Open Sans",
                      marginBottom: "10px",
                    }}
                  >
                    Date range
                  </span>
                  <div
                    className="OAS-bene_Txn_Calendar_main_div relative flex flex-col items-center bg-[#314D7E] cursor-pointer"
                    // style={{ opacity: dropdownOpen ? 0 : 1 }}
                    ref={dateRef}
                    style={{
                      width: "305px",
                      height: showDatePicker ? "" : "35px",
                      border: showDatePicker ? "1px solid #ff5860" : "none",
                      // borderRadius: showDatePicker ? "10px" : "auto",
                    }}
                  >
                    <div
                      className="flex flex-row items-center justify-between rounded-[10px] bg-[#314D7E] cursor-pointer"
                      style={{
                        width: "305px",
                        height: showDatePicker ? "" : "35px",
                        border: showDatePicker ? "none" : "1px solid #ff5860",
                      }}
                      onClick={() => {
                        setShowDatePicker(!showDatePicker);

                        setActiveSection("date");
                      }}
                      // style={{ opacity: dropdownOpen ? 0 : 1 }}
                    >
                      <div
                        className="OAS-beneTxn_date_heading_div cursor-pointer"
                        style={{
                          borderTopLeftRadius: showDatePicker ? "7px" : "none",
                          borderLeft: showDatePicker
                            ? "1px solid #ff5860"
                            : "none",
                        }}
                        // onClick={() => setShowDatePicker((prev) => !prev)}
                      >
                        <div className="OAS-beneTxn_date_heading_left">
                          {startDate
                            ? formatDate(startDate)
                            : "Select from date"}
                        </div>
                      </div>
                      <img
                        src={arrowforward}
                        className="OAS-bene_txn_viewall_calendar_icon"
                      />
                      <div
                        className="OAS-beneTxn_date_select_div cursor-pointer"
                        style={{
                          borderTopRightRadius: showDatePicker ? "7px" : "none",
                          borderRight: showDatePicker
                            ? "1px solid #ff5860"
                            : "none",
                        }}
                        // onClick={() => setShowDatePicker((prev) => !prev)}
                      >
                        <div className="OAS-beneTxn_date_heading_right">
                          {" "}
                          {endDate ? formatDate(endDate) : "Select to date"}
                        </div>
                      </div>
                    </div>
                    {showDatePicker && (
                      <DatePickerComponent
                        startDate={startDate}
                        endDate={endDate}
                        handleDateChange={handleDateChange}
                        handleCancel={handleCancel}
                        handleConfirm={handleConfirm}
                        showDatePicker={showDatePicker}
                        yearDropdownRef={yearDropdownRef}
                        dateRef={dateRef}
                        setShowDatePicker={setShowDatePicker}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="OAS-beneTxnFilterbuttonContainer">
                <div
                  className="OAS-acc-viewall-clear-filter border border-[#F9F9F9] text-[#FFFFFF] text-xs text-center hover:opacity-80 w-[87px] h-[25px]"
                  onClick={() => {
                    if (
                      initCategory ||
                      paymentMethod ||
                      startDate ||
                      endDate ||
                      beneAccountId
                    ) {
                      clearFilter();
                    }
                  }}
                  style={{
                    cursor:
                      initCategory ||
                      paymentMethod ||
                      (startDate && endDate) ||
                      beneAccountId
                        ? "pointer"
                        : "default",
                    // opacity: dropdownOpen ? 0 : 1,
                  }}
                >
                  Clear
                </div>
                <div
                  className="OAS-acc-viewall-apply-filter text-center text-[#FFFFFF] text-xs ml-[10px] w-[87px] h-[25px] hover:opacity-80"
                  onClick={() => {
                    if (
                      initCategory ||
                      paymentMethod ||
                      (startDate && endDate) ||
                      beneAccountId
                    ) {
                      filterCategory();
                    }
                  }}
                  style={{
                    backgroundColor:
                      initCategory ||
                      paymentMethod ||
                      (startDate && endDate) ||
                      beneAccountId
                        ? "#F05A6A"
                        : "#727272",
                    cursor:
                      initCategory ||
                      paymentMethod ||
                      (startDate && endDate) ||
                      beneAccountId
                        ? "pointer"
                        : "default",
                    // opacity: dropdownOpen ? 0 : 1,
                  }}
                >
                  Apply
                </div>
              </div>
            </div>
          </div>

          <div
            // onClose={toggleSidebar(false)}
            className={`OAS-beneTxnviewallsidebar ${
              showAdditionalDetails ? "open" : "closed"
            }`}
            style={{
              marginTop: "200px",
              backgroundColor: isViewModalOpen ? "#000" : "#3a5686",
              pointerEvents: isViewModalOpen ? "none" : "",
            }}
          >
            <div
              className="max-w-[1024px] mx-auto justify-between pt-[10px]"
              style={{
                visibility: showAdditionalDetails ? "visible" : "hidden",
                transition: `opacity 0.2s ease-in ${
                  showAdditionalDetails ? "0s" : "0.2s"
                }`,
                opacity: showAdditionalDetails ? "1" : "0",
                borderTop: "1px solid rgb(29, 58, 109)",
              }}
            >
              <div className="h-auto">
                <span className="text-[#F05A6A] font-normal">
                  Additional Fields{" "}
                </span>
                <div className="flex justify-between text-[12px] text-[#DADADA]">
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    <div className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]">
                      <p>Bank</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={selectWithGreenFilledCircle}
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]">
                      <p>Bank refference no.</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={selectWithGreenFilledCircle}
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]">
                      <p>Source account number</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={selectWithWhiteFilledCircle}
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="OAS-border-mid-div"></div>
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    <div className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]">
                      <p>Account number</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={selectWithGreenFilledCircle}
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]">
                      <p>Narration</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={selectWithGreenFilledCircle}
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]">
                      <p>Sender's name</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={selectWithWhiteFilledCircle}
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="OAS-border-mid-div"></div>
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    <div className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]">
                      <p>Transaction date and time</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={selectWithGreenFilledCircle}
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]">
                      <p>Type</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={selectWithGreenFilledCircle}
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]">
                      <p>IFSC code</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={selectWithWhiteFilledCircle}
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="OAS-border-mid-div"></div>
                  <div className="flex flex-col gap-[15px] mt-[15px]">
                    <div className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]">
                      <p>Value date</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={selectWithGreenFilledCircle}
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]">
                      <p>Closing balance</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={selectWithGreenFilledCircle}
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                    <div className="w-[216px] h-[36px] bg-[#667CA3] rounded-[10px] items-center justify-between flex px-[10px]">
                      <p>UPI ID</p>
                      <div className="h-[15px] w-[15px]">
                        <img
                          src={selectWithWhiteFilledCircle}
                          alt="white-filled-check"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex pt-[20px] pb-[20px] justify-end">
                <div
                  className="OAS-acc-viewall-clear-filter border border-[#F9F9F9] text-[#FFFFFF] text-xs text-center hover:opacity-80 w-[87px] h-[25px]"
                  onClick={() => {
                    if (
                      initCategory ||
                      paymentMethod ||
                      startDate ||
                      endDate ||
                      beneAccountId
                    ) {
                      clearFilter();
                    }
                  }}
                  style={{
                    cursor:
                      initCategory ||
                      paymentMethod ||
                      (startDate && endDate) ||
                      beneAccountId
                        ? "pointer"
                        : "default",
                    // opacity: dropdownOpen ? 0 : 1,
                  }}
                >
                  Clear
                </div>
                <div
                  className="OAS-acc-viewall-apply-filter text-center text-[#FFFFFF] text-xs ml-[10px] w-[87px] h-[25px] hover:opacity-80"
                  onClick={() => {
                    if (
                      initCategory ||
                      paymentMethod ||
                      (startDate && endDate) ||
                      beneAccountId
                    ) {
                      filterCategory();
                    }
                  }}
                  style={{
                    backgroundColor:
                      initCategory ||
                      paymentMethod ||
                      (startDate && endDate) ||
                      beneAccountId
                        ? "#F05A6A"
                        : "#727272",
                    cursor:
                      initCategory ||
                      paymentMethod ||
                      (startDate && endDate) ||
                      beneAccountId
                        ? "pointer"
                        : "default",
                    // opacity: dropdownOpen ? 0 : 1,
                  }}
                >
                  Apply
                </div>
              </div>
            </div>
          </div>

          <div className="w-full mx-auto">
            {showSearchBar && (
              <RectangleSearchBar
                handleSearch={setSearchUserText}
                showSearchBar={showSearchBar}
                searchUserText={searchUserText}
                setSearchUserText={setSearchUserText}
                onPaste={handlePaste}
                toggleShowSearchBar={() => {
                  setFilterOpen(false);
                  setShowSearchBar(!showSearchBar);
                }}
                bgColor={"bg-[#1D3A6D]"}
                bgHover={"hover:bg-custom-cardHover"}
                setCurrentPage={setCurrentPage}
                setCurrentPageText={setCurrentPageText}
                searchRef={searchRef}
              />
            )}
          </div>
        </div>
        {filterApply &&
          (filterPayload.AccIfscFilter ||
            filterPayload.BankFilter ||
            filterPayload.ConnectedFilter ||
            filterPayload.TypeAccFilter ||
            filterPayload.SortBankFilter ||
            filterPayload.panFilter) && (
            <div className="OAS-filter_applied_div w-full">
              <div
                style={{
                  borderTop: "1px solid #031F4F",
                }}
                className="w-[1024px] mx-auto py-[20px] flex justify-between"
              >
                <div className="flex">
                  <p className="filterby_text">Filtered by :</p>
                  <div className="filter_badge_div">
                    {typeaccountstate.length > 0 && (
                      <div className="OAS-filter_badge">
                        <p className="filter_badge_text">{typeaccountstate}</p>
                        <img
                          onClick={() => {
                            settypeaccountstate("");
                            let obj = filterPayload;
                            if (filterPayload.ifscnumber.length !== 11) {
                              obj = {
                                ...obj,
                                typeofaccount: "",
                                ifscnumber: "",
                                TypeAccFilter: false,
                              };
                            }
                            if (filterPayload.pannumber.length != 10) {
                              obj = {
                                ...obj,
                                typeofaccount: "",
                                pannumber: "",
                                TypeAccFilter: false,
                              };
                            }
                            if (filterPayload.accountnumber.length < 4) {
                              obj = {
                                ...obj,
                                typeofaccount: "",
                                accountnumber: "",
                                ifscnumber: "",
                                TypeAccFilter: false,
                              };
                            } else {
                              obj = {
                                ...obj,
                                typeofaccount: "",
                                TypeAccFilter: false,
                              };
                            }
                            setFilterPayload(obj);
                          }}
                          className="filter_badge_img"
                          src={clearFilterImg}
                          alt="X"
                        />
                      </div>
                    )}
                    {accountbalancestate.length > 0 && (
                      <div className="OAS-filter_badge">
                        <p className="filter_badge_text">
                          {accountbalancestate}
                        </p>
                        <img
                          onClick={() => {
                            setaccountbalancestate("");
                            let obj = filterPayload;
                            if (filterPayload.ifscnumber.length !== 11) {
                              obj = {
                                ...obj,
                                sortAccountNo: "",
                                ifscnumber: "",
                              };
                            }
                            if (filterPayload.pannumber.length != 10) {
                              obj = {
                                ...obj,
                                sortAccountNo: "",
                                pannumber: "",
                              };
                            }
                            if (filterPayload.accountnumber.length < 4) {
                              obj = {
                                ...obj,
                                sortAccountNo: "",
                                accountnumber: "",
                                ifscnumber: "",
                              };
                            } else {
                              obj = {
                                ...obj,
                                sortAccountNo: "",
                              };
                            }
                            setFilterPayload(obj);
                          }}
                          className="filter_badge_img"
                          src={clearFilterImg}
                          alt="X"
                        />
                      </div>
                    )}
                    {connectedbankingstate.length > 0 && (
                      <div className="OAS-filter_badge">
                        <p className="filter_badge_text">
                          {connectedbankingstate}
                        </p>
                        <img
                          onClick={() => {
                            setConnectedbankingstate("");
                            let obj = filterPayload;
                            if (filterPayload.ifscnumber.length !== 11) {
                              obj = {
                                ...obj,
                                connectedBanking: "",
                                ifscnumber: "",
                                ConnectedFilter: false,
                              };
                            }
                            if (filterPayload.pannumber.length != 10) {
                              obj = {
                                ...obj,
                                connectedBanking: "",
                                pannumber: "",
                                ConnectedFilter: false,
                              };
                            }
                            if (filterPayload.accountnumber.length < 4) {
                              obj = {
                                ...obj,
                                connectedBanking: "",
                                accountnumber: "",
                                ifscnumber: "",
                                ConnectedFilter: false,
                              };
                            } else {
                              obj = {
                                ...obj,
                                connectedBanking: "",
                                ConnectedFilter: false,
                              };
                            }
                            setFilterPayload(obj);
                          }}
                          className="filter_badge_img"
                          src={clearFilterImg}
                          alt="X"
                        />
                      </div>
                    )}
                    {bankstate.length > 0 && (
                      <div className="OAS-filter_badge">
                        <p className="filter_badge_text">{bankstate}</p>
                        <img
                          onClick={() => {
                            setbankstate("");
                            let obj = filterPayload;
                            if (filterPayload.ifscnumber.length !== 11) {
                              obj = {
                                ...obj,
                                bankNameInput: "",
                                ifscnumber: "",
                                BankFilter: false,
                              };
                            }
                            if (filterPayload.pannumber.length != 10) {
                              obj = {
                                ...obj,
                                bankNameInput: "",
                                pannumber: "",
                                BankFilter: false,
                              };
                            }
                            if (filterPayload.accountnumber.length < 4) {
                              obj = {
                                ...obj,
                                bankNameInput: "",
                                accountnumber: "",
                                ifscnumber: "",
                                BankFilter: false,
                              };
                            } else {
                              obj = {
                                ...obj,
                                bankNameInput: "",
                                BankFilter: false,
                              };
                            }
                            setFilterPayload(obj);
                          }}
                          className="filter_badge_img"
                          src={clearFilterImg}
                          alt="X"
                        />
                      </div>
                    )}
                    {accountnumberstate.length > 3 &&
                      ifscstate.length === 11 && (
                        <div className="OAS-filter_badge">
                          <p className="filter_badge_text">
                            {accountnumberstate}
                          </p>
                          <img
                            onClick={() => {
                              setaccountnumberstate("");
                              setifscstate("");
                              if (filterPayload.pannumber.length != 10) {
                                const obj = {
                                  ...filterPayload,
                                  accountnumber: "",
                                  ifscnumber: "",
                                  pannumber: "",
                                  AccIfscFilter: false,
                                };
                                setFilterPayload(obj);
                              } else {
                                const obj = {
                                  ...filterPayload,
                                  accountnumber: "",
                                  ifscnumber: "",
                                  AccIfscFilter: false,
                                };
                                setFilterPayload(obj);
                              }
                            }}
                            className="filter_badge_img"
                            src={clearFilterImg}
                            alt="X"
                          />
                        </div>
                      )}
                  </div>
                </div>

                <div className="flex">
                  <div className="OAS-border-mid-div"></div>

                  <div className="flex justify-between h-[36px] ml-[20px]">
                    <div className=" flex bg-[#F05A6A] rounded-[5px] h-[36px]">
                      <img
                        className="w-[20px] h-[20px] m-auto ml-2"
                        src={download_icon}
                        alt="download statement"
                      />
                      <a
                        onClick={handleToastClick}
                        // href={dataLoading}
                        // download={`statement.${selectedType}`}
                        className="py-2 pt-[0.6rem] px-2 h-[36px] w-[136px] OAS-download_statement"
                      >
                        Download Statement
                      </a>
                      <div
                        style={{
                          borderRight: "1px solid #ffffff",
                          margin: "5px 0px",
                        }}
                      ></div>
                      <div ref={fileTypeRef} className="OAS-statement-type">
                        <Button
                          className="OAS-pdf-main-btn"
                          onClick={() => {
                            setSelectStatement(!selectStatement);
                          }}
                          style={{
                            height: selectStatement ? "216px" : "36px",
                            outline: selectStatement ? "1px solid #E25869" : "",
                          }}
                        >
                          <div
                            style={{
                              height: "36px",
                              width: "85px",
                            }}
                            className="OAS-bank_high_to_low_select_select"
                          >
                            <span
                              className="font-semibold pt-[0.1rem]"
                              style={{
                                fontSize: "12px",
                                paddingLeft: "10px",
                                color: "#f9f9f9",
                              }}
                            >
                              {selectedType}
                            </span>
                            <img
                              src={arrowDown}
                              alt="Status Icon"
                              style={{
                                height: "12px",
                                width: "12px",
                                transform: selectStatement
                                  ? "rotate(180deg)"
                                  : "",
                              }}
                              className="status_icon-img"
                            />
                          </div>
                          <div
                            style={{
                              position: "absolute",
                              width: "60px",
                              top: "36px",
                              borderBottom: "1px solid #102C5D",
                            }}
                          ></div>
                          <div
                            style={{
                              padding: "8px",
                              top: "26px",
                              width: "85px",
                            }}
                            className="OAS-bank_high_to_low_select_scroll"
                          >
                            <Button
                              sx={{
                                width: "70px",
                                backgroundColor:
                                  selectedType == "PDF" && "#6D3034",
                              }}
                              className="OAS-statement_select_scroll_com"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedType("PDF");
                                setSelectStatement(false);
                              }}
                            >
                              PDF
                              <div
                                style={{
                                  width: "70px",
                                  display:
                                    selectedType == "PDF" ? "flex" : "none",
                                  textAlign: "right",
                                  justifyContent: "end",
                                  paddingRight: "5px",
                                }}
                              >
                                <img
                                  style={{
                                    height: "13px",
                                    width: "11px",
                                  }}
                                  src={CheckIcon}
                                  alt="check"
                                />
                              </div>
                            </Button>
                            <Button
                              sx={{
                                width: "70px",
                                backgroundColor:
                                  selectedType == "XLS" && "#6D3034",
                              }}
                              className="OAS-statement_select_scroll_com"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedType("XLS");

                                setSelectStatement(false);
                              }}
                            >
                              XLS
                              <div
                                style={{
                                  paddingRight: "5px",
                                  width: "70px",
                                  display:
                                    selectedType == "XLS" ? "flex" : "none",
                                  textAlign: "right",
                                  justifyContent: "end",
                                }}
                              >
                                <img
                                  style={{
                                    height: "13px",
                                    width: "11px",
                                  }}
                                  src={CheckIcon}
                                  alt="check"
                                />
                              </div>
                            </Button>

                            <Button
                              sx={{
                                width: "70px",
                                backgroundColor:
                                  selectedType == "TXT" && "#6D3034",
                              }}
                              className="OAS-statement_select_scroll_com"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedType("TXT");

                                setSelectStatement(false);
                              }}
                            >
                              TXT
                              <div
                                style={{
                                  paddingRight: "5px",
                                  width: "70px",
                                  display:
                                    selectedType == "TXT" ? "flex" : "none",
                                  textAlign: "right",
                                  justifyContent: "end",
                                }}
                              >
                                <img
                                  style={{
                                    height: "13px",
                                    width: "11px",
                                  }}
                                  src={CheckIcon}
                                  alt="check"
                                />
                              </div>
                            </Button>
                            <Button
                              sx={{
                                width: "70px",
                                backgroundColor:
                                  selectedType == "CSV" && "#6D3034",
                              }}
                              className="OAS-statement_select_scroll_com"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedType("CSV");

                                setSelectStatement(false);
                              }}
                            >
                              CSV
                              <div
                                style={{
                                  paddingRight: "5px",
                                  width: "70px",
                                  display:
                                    selectedType == "CSV" ? "flex" : "none",
                                  textAlign: "right",
                                  justifyContent: "end",
                                }}
                              >
                                <img
                                  style={{
                                    height: "13px",
                                    width: "11px",
                                  }}
                                  src={CheckIcon}
                                  alt="check"
                                />
                              </div>
                            </Button>
                            <Button
                              sx={{
                                width: "70px",
                                backgroundColor:
                                  selectedType == "MT 940" && "#6D3034",
                              }}
                              className="OAS-statement_select_scroll_com"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedType("MT 940");

                                setSelectStatement(false);
                              }}
                            >
                              MT 940
                              <div
                                style={{
                                  width: "20px",
                                  display:
                                    selectedType == "MT 940" ? "flex" : "none",
                                  textAlign: "right",
                                  justifyContent: "end",
                                }}
                              >
                                <img
                                  style={{
                                    height: "13px",
                                    width: "11px",
                                  }}
                                  src={CheckIcon}
                                  alt="check"
                                />
                              </div>
                            </Button>
                          </div>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        <div className="overflow-x-auto w-screen">
          <div className="sticky top-0">
            <div className="w-max z-10 mt-[25px]">
              <div className="OAS-table-div-container mb-1">
                <p className="OAS-label-div-trans OAS-label-bank-width w-[130px]">
                  Bank
                </p>
                <div className="OAS-border-mid-div"></div>
                <p className="OAS-label-div-trans OAS-label-account-width">
                  Account Number
                </p>
                <div className="OAS-border-mid-div"></div>
                <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                  Transaction date <br />
                  and time
                </p>
                <div className="OAS-border-mid-div"></div>
                <p className="OAS-label-div-val">Value date</p>
                <div className="OAS-border-mid-div"></div>
                <p className="OAS-label-div-ref">Bank reference no.</p>
                <div className="OAS-border-mid-div"></div>
                <p className="OAS-label-div-narr">Narration</p>
                <div className="OAS-border-mid-div"></div>
                <div className="OAS-label-div-type">Type</div>
                <div className="OAS-border-mid-div"></div>
                <div className="OAS-label-div-amt">Amount</div>
                <div className="OAS-border-mid-div"></div>
                <p className="OAS-label-div-clamt">Closing Balance</p>
                <div className="OAS-border-mid-div"></div>
                <p className="OAS-label-div-trans">Source account number</p>
                <div className="OAS-border-mid-div"></div>
                <p className="OAS-label-div-ref">Sender's name</p>
                <div className="OAS-border-mid-div"></div>
                <p className="OAS-label-div-ref">IFSC code</p>
                <div className="OAS-border-mid-div"></div>
                <p className="OAS-label-div-upi w-[210px]">UPI ID</p>
              </div>
            </div>
          </div>

          <div
            className="overflow-auto w-max h-100"
            style={{ padding: "0 10px 40px 10px" }}
          >
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
            <div className="OAS-table-div-content mb-1">
              <p className="OAS-label-div-trans OAS-label-bank-width">
                <img
                  className="w-[70px] h-[26px]"
                  src={icici_img}
                  alt="ICICI-Bank"
                />
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-account-width">
                3431 3115 3513 1243
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans OAS-label-TimeDate-width">
                01-08-2024, 07:30
              </p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-val">01-08-2024</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">VDICCJO578900HJ7</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-narr">
                Cash paid from ICICI bank user Cash paid from ICICI bank user
                Cash paid from ICICI
              </p>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-type">Credit</div>
              <div className="OAS-border-mid-div"></div>
              <div className="OAS-label-div-amt" style={{ color: "#4ddd37" }}>
                ₹ 2,13,495.38
              </div>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-clamt">₹ 3,495.38</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-trans">3431 3115 3513 1243</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">Mr. Bhaskar Divakar</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-ref">ICICI00020020</p>
              <div className="OAS-border-mid-div"></div>
              <p className="OAS-label-div-upi">bhaskardivakar-1@okhdfcbank</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    //   <div>OwnerAllStatement</div>
  );
};

export default OwnerAllStatement;
