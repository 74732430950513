import React, { useRef, useState, useEffect } from "react";
import greenCheck from "../../../../assets/CheckmarksIcons/verified_with_white_circle_green.svg";
import redCheck from "../../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import addstatus from "../../../../assets/ErrorIcons/error_with_white_circle.svg";
import "./index.css";
import { Button } from "@mui/material";
import {
  Divider,
  Drawer,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import inputLoader from "../../../../assets/Beneficiary Icons/inputloder.gif";
import Validation from "../../../../utils/validation";
import CloseIcon from "../../../../assets/CloseIcons/rejected_white.svg";
import skippedVerification from "../../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ViewDetails from "../../../../assets/GeneralIcons/detail_page_edit detail.svg";
import InfoDetails from "../../../../assets/InfoIcons/Info.svg";
import BeneficiaryPanModal from "../../../../components/BeneCreateAccount/BeneficiaryPanModal";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import {
  beneLookUp,
  resetPanArchived,
  resetPanUnarchived,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import { beneVerifyThisGST } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { toast } from "react-toastify";
import { checkUsertoken } from "../../../../redux/features/login/login";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import selected from "../../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import not_selected from "../../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import ViewDetailsIcon from "../../../../assets/ArrowsIcons/arrow_right_blue_filled.svg";
import RejectingTransactionPopup from "../BeneAcountApproval/RejectingTransactionPopup/RejectingTransactionPopup";
import BeneDetailModal from "../../../../components/Accounts/BeneDetailModal";
import unarchiveImg from "../../../../assets/ArchieveUnArchieveIcons/unarchive.svg";
import archiveImg from "../../../../assets/ArchieveUnArchieveIcons/archive.svg";

const BenePanDetails = ({
  test,
  verifiedPan,
  panNumber,
  handlePanChange,
  panDetails,
  beneLookData,
  beneLookLoading,
  setLookupNext,
  lookupNext,
  BenEnableNextButton,
  verifyPANisLoading,
  isAchived,
  setIsArchived,
  isApproved,
  setIsApproved,
  handleNext,
  isDennied,
  setIsDennied,
  setIsPending,
  isPending,
  accountType,
  setAccountType,
  shutViewAll,
  setShutViewAll,
  setDisableCreateNext,
  setIsFocus,
  isFocus,
  type,
  setShowAction,
  previousId,
  setIsActive,
  isActive,
  beneLookError,
  setNewMessage,
  newMessage,
  setHideBlock,
  setHideCreate,
  hideBlock,
  hideCreate,
  setNonArchive,
  nonArchive,
  previousBankNo,
  setPreviousBankNo,
  isLinkedAccount,
  setIsLinkedAccount,
  unachivedOpenPop,
  setUnachivedOpenPop,
  achivedOpenPop,
  setAchivedOpenPop,
  isButtonDisabled,
  alreadyLinked,
  setAlreadyLinked,
  haveLinkNew,
  allData,
  setPayload,
  payload,
  gstin,
  nextButtonEnabled,
  showAccInfo,
  setShowAccInfo,
  associatedPan,
  setGSTPanExist,
  GSTPanExist,
  showRejectTnxPopup,
  setRejectTxnPopup,
}) => {
  const [isApprovedState, setIsApprovedState] = useState([]);
  const [haveLinkedDisabled, setHaveLinkedDisabled] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [gstVerified, setGstVerified] = useState(null);
  const [GSTStatus, setGSTStatus] = useState(null);
  const [isblur, setisblur] = useState(false);
  const [focusState, setFocusState] = useState({});
  const associatedAccountRef = useRef(null);
  const dispatch = useDispatch();
  const [GSTLinked, setGSTLinked] = useState(true);
  const [GSTLinkedMessage, setGSTLinkedMessage] = useState(null);
  const [showRejectAllTxnReson, setShowRejectAllTxnReson] = useState(false);
  const [newData, setNewData] = useState({
    gstin: "",
  });
  const { viewAccountDetailData, viewAccountDetailLoading } = useSelector(
    (state) => state.verify
  );

  const { beneVerifyGST, isGSTError, isGSTLoading } = useSelector(
    (state) => state.beneAllVerification
  );

  useEffect(() => {
    if (payload?.gstin?.length === 15 && !customErrors["gstin"]) {
      setGSTStatus(Validation.allowedGstin(payload.gstin));
      dispatch(checkUsertoken());
      dispatch(beneVerifyThisGST({ search_string: payload.gstin }));
    } else if (payload?.gstin?.length === 15 && customErrors["gstin"]) {
      setGSTStatus(Validation.allowedGstin(payload.gstin));
    }
  }, [payload.gstin]);

  useEffect(() => {
    if (!isGSTLoading) {
      if (beneVerifyGST?.status === true) {
        setGstVerified(true);
      } else if (beneVerifyGST?.status === false) {
        setGstVerified(false);
      } else {
        setGstVerified(null);
      }
    }
  }, [beneVerifyGST, isGSTLoading]);

  useEffect(() => {
    let gstStoredData = sessionStorage.getItem("PAN GST Edit NewValue");
    let ActualGstno =
      viewAccountDetailData?.beneficiary_accounts[0]?.gstin_detail;
    let ActualPANno = viewAccountDetailData?.beneficiary_accounts[0]?.pan_no;
    let aa = payload?.gstin?.substring(2, payload?.gstin?.length - 3);
    if (viewAccountDetailLoading == false && viewAccountDetailData != null) {
      if (
        viewAccountDetailData?.beneficiary_accounts[0]?.gstin_detail ==
        undefined
      ) {
        if (!isGSTLoading && gstStoredData != null && payload.gstin != "") {
          if (payload.gstin?.length == 15 && GSTStatus) {
            let aa = payload.gstin.substring(2, payload.gstin.length - 3);
            if (aa === panNumber) {
              BenEnableNextButton(false);
              setGSTLinked(true);
            } else {
              BenEnableNextButton(true);
              setGSTLinked(false);
              setGSTLinkedMessage(false);
            }
          } else {
            BenEnableNextButton(true);
          }
        } else if (
          verifiedPan &&
          ActualPANno != panNumber &&
          (GSTStatus == null || !GSTStatus) &&
          payload.gstin?.length === 0
        ) {
          BenEnableNextButton(false);
        } else if (
          verifiedPan &&
          ActualPANno != panNumber &&
          GSTStatus &&
          payload.gstin?.length === 15
        ) {
          BenEnableNextButton(false);
        } else if (
          verifiedPan &&
          ActualPANno != panNumber &&
          payload.gstin?.length === 0
        ) {
          BenEnableNextButton(false);
        } else {
          BenEnableNextButton(true);
          setGSTLinked(false);
          setGSTLinkedMessage(false);
        }
      } else if (viewAccountDetailData?.beneficiary_accounts[0]?.gstin_detail) {
        if (!isGSTLoading) {
          if (ActualGstno != gstStoredData && gstStoredData != null) {
            if (payload.gstin?.length == 15 && GSTStatus) {
              let aa = payload.gstin.substring(2, payload.gstin.length - 3);
              if (aa === panNumber && GSTStatus) {
                BenEnableNextButton(false);
                setGSTLinked(true);
                setGSTLinkedMessage(true);
              } else if (aa !== panNumber && GSTStatus) {
                setGSTLinked(false);
                setGSTLinkedMessage(true);
                BenEnableNextButton(true);
              } else {
                BenEnableNextButton(true);
              }
            } else if (panNumber != ActualPANno && verifiedPan) {
              if (!GSTStatus) {
                BenEnableNextButton(true);
              } else {
                let aa = payload.gstin.substring(2, payload.gstin.length - 3);
                if (aa === panNumber && GSTStatus) {
                  BenEnableNextButton(false);
                  setGSTLinked(true);
                  setGSTLinkedMessage(true);
                } else if (aa !== panNumber && GSTStatus) {
                  setGSTLinked(false);

                  BenEnableNextButton(true);
                } else {
                  BenEnableNextButton(true);
                }
              }
            } else {
              BenEnableNextButton(true);
              setGSTLinked(false);
              setGSTLinkedMessage(false);
            }
          } else if (
            GSTStatus == null &&
            !isGSTLoading &&
            panNumber != ActualPANno &&
            verifiedPan
          ) {
            BenEnableNextButton(false);
          } else if (GSTStatus && aa !== panNumber) {
            setGSTLinked(false);
          } else if ((GSTStatus || aa === panNumber) && gstVerified) {
            setGSTLinked(true);
            setGSTLinkedMessage(true);
          }
        } else if (ActualGstno == gstStoredData) {
          BenEnableNextButton(true);
          setGSTLinked(true);
          setGSTLinkedMessage(true);
        }
      }
    }
  }, [
    sessionStorage,
    payload,
    GSTLinked,
    GSTStatus,
    gstVerified,
    isGSTLoading,
    viewAccountDetailData,
    viewAccountDetailLoading,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      event.stopPropagation();
      if (
        associatedAccountRef.current &&
        !associatedAccountRef.current.contains(event.target)
      ) {
        setShowAccInfo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [associatedAccountRef]);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(open);
  };

  const checkAllValidations = () => {
    return GSTStatus;
  };

  const modifyValue = (type, value) => {
    if (type === "name") {
      return value?.replace(/(\w)(\w*)/g, function (g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      });
    } else if (type === "gstin") {
      return value?.toUpperCase();
    }
    return value.trim();
  };

  const allowedValue = (name, value) => {
    if (name === "gstin") return Validation.allowedGstin(value);
    return true;
  };
  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };

    if (name === "gstin")
      errors = Validation.validateGstin(name, value, errors);
    setCustomErrors(errors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = modifyValue(name, value.replace(/[^a-zA-Z0-9]/g, ""));
    if (value) {
      setNewData({ [name]: value });
    }
    setNewData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    if (allowedValue(name, value)) {
      validateAndRecordError(name, newValue);
      setPayload((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));

      if (value.trim() === "" && value?.length === 1) {
        return;
      }

      if (name === "gstin") {
        if (name === "gstin" && newValue?.length < 15 && newValue?.length > 0) {
          setGSTStatus(false);
        } else {
          if (newValue?.length == 15) {
            sessionStorage.setItem("PAN GST Edit NewValue", newValue);
            setGSTStatus(true);
          } else {
            sessionStorage.removeItem("PAN GST Edit NewValue");
            setGSTStatus(null);
          }
        }
      }
    }
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }
    Object.entries(errorMessage)?.forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], toast_position);
    });
  };

  useEffect(() => {
    if (payload.gstin?.length > 0 && beneVerifyGST?.status === true) {
      let errors = { ...customErrors };
      delete errors["gstin"];
      setCustomErrors(errors);
      setGSTStatus(true);
    }

    if (payload.gstin?.length > 0 && beneVerifyGST?.errors) {
      let errors = { ...customErrors };
      errors["gstin"] = beneVerifyGST.errors;
      setCustomErrors(errors);
      setGSTStatus(false);
    }
  }, [beneVerifyGST]);

  const getGSTInputElementDiv = (
    name,
    status,
    label,
    value,
    maxLength,
    xvalue,
    gstLinked
  ) => {
    const getColor = () => {
      if (value?.length === 15) {
        if (status && gstLinked) return "#00FF14";
        if ((!gstLinked && status) || !status) return "#FF5860";
      }
      if (focusState[name]) return "#31E5E5";
      return "#DADADA";
    };

    const color = getColor();

    const handleFocus = () => {
      setFocusState((prev) => ({ ...prev, [name]: true }));
    };

    const handleBlur = () => {
      setFocusState((prev) => ({ ...prev, [name]: false }));
      setisblur(true);
    };

    return (
      <div className="w-full flex flex-row items-center self-center text-center gap-4">
        <TextField
          className="w-full antialiased text-xl"
          id={`outlined-size-small-${name}`}
          size="small"
          variant="outlined"
          sx={{
            "& label.Mui-focused": {
              color: color,
            },
            "& label": {
              color: color,
            },
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              "& fieldset": {
                borderColor: color,
                outline: "none !important",
                borderRadius: "10px",
              },
              "&:hover fieldset": {
                borderColor: color,
              },
              "&.Mui-focused fieldset": {
                borderColor: color,
              },
            },
          }}
          inputProps={{
            style: { color: "#DADADA" },
            maxLength: maxLength,
          }}
          InputLabelProps={{ style: { color: color } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div className="w-[20px] h-[20px]">
                  {name === "gstin" && (
                    <>
                      {payload.gstin?.length === 0 ? null : (
                        <>
                          {panNumber?.length >= 10 ? (
                            gstVerified && payload.gstin?.length === 0 ? (
                              <img
                                src={skippedVerification}
                                alt="Skipped Verification Icon"
                                onClick={() => showErrors(name)}
                                className="cursor-pointer w-4 h-4"
                              />
                            ) : (
                              gstVerified &&
                              GSTLinked &&
                              payload.gstin?.length === 15 && (
                                <img
                                  className="self-center w-5 h-5"
                                  src={greenCheck}
                                  alt="verified logo"
                                />
                              )
                            )
                          ) : (
                            <img
                              src={skippedVerification}
                              alt="Skipped Verification Icon"
                              onClick={() => showErrors(name)}
                              className="cursor-pointer w-4 h-4"
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </InputAdornment>
            ),
          }}
          label={label}
          name={name}
          onBlurCapture={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  const getSideDraweContent = () => {
    return (
      <div className="w-fit">
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
          sx={{ zIndex: 100 }}
          PaperProps={{
            style: {
              width: "24vw",
              height: "auto",
            },
          }}
        >
          <div className="bg-[#1D3A6D] custom-scroll-container  overflow-auto h-screen">
            <div className="bg-[#314D7E] py-3 flex justify-between p-4 pt-28 shadow-lg h-44">
              <div className="text-xl text-white mt-4 mx-2">
                GST Verification Details
              </div>
              <div
                className="cursor-pointer w-8 h-8 p-2 my-3 bg-[#1D3A6D] flex justify-center hover:bg-[#A3A3A3] items-center rounded-md"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <img src={CloseIcon} alt="Close" />
              </div>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start mt-2"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  color: "#fafafa",
                }}
                variant="body1"
                className="text-base"
              >
                {beneVerifyGST?.gstin_details?.gstin_no}
              </Typography>
              <label htmlFor="Quest" className="text-xs label-container">
                GSTIN number
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start mt-2"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  color: "#fafafa",
                }}
                variant="body1"
                className="text-base"
              >
                {beneVerifyGST?.gstin_details?.business_name}
              </Typography>
              <label htmlFor="Quest" className="text-xs label-container">
                Company name
              </label>
            </div>
            <div
              className="p-4 flex flex-col gap-0 justify-end items-start"
              style={{ paddingLeft: "25px" }}
            >
              <Typography sx={{ color: "#fafafa" }} className="text-base">
                {beneVerifyGST?.gstin_details?.date_of_registration}
              </Typography>
              <label htmlFor="Quest" className="text-xs label-container">
                ESTD. on
              </label>
            </div>
            <Divider
              className="text-white"
              sx={{
                borderTop: "1px solid",
                borderColor: "#031F4F",
                my: 1,
                width: "21vw",
                mx: "auto",
              }}
            />
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px", marginTop: "0" }}
            >
              <Typography
                sx={{ color: "#fafafa" }}
                variant="body1"
                className="text-base"
              >
                {beneVerifyGST?.gstin_details?.pan_no}
              </Typography>
              <label htmlFor="Quest" className="text-xs label-container">
                PAN number
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{ color: "#fafafa" }}
                variant="body1"
                className="text-base"
              >
                {beneVerifyGST?.gstin_details?.constitution_of_business}
              </Typography>
              <label htmlFor="Quest" className="text-xs label-container">
                Corporate listing
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{ color: "#fafafa" }}
                variant="body1"
                className="text-base"
              >
                {beneVerifyGST?.gstin_details?.status}
              </Typography>
              <label htmlFor="Quest" className="text-xs label-container">
                Company status
              </label>
            </div>
            <Divider
              className="text-white"
              sx={{
                borderTop: "1px solid",
                borderColor: "#031F4F",
                my: 1,
                width: "21vw",
                mx: "auto",
              }}
            />
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fafafa",
                }}
              >
                {beneVerifyGST?.gstin_details?.address}
              </Typography>

              <label htmlFor="Quest" className="text-xs label-container">
                Corporate address
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fafafa",
                }}
              >
                {beneVerifyGST?.gstin_details?.state}
              </Typography>

              <label htmlFor="Quest" className="text-xs label-container">
                State
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fafafa",
                }}
              >
                {beneVerifyGST?.gstin_details?.state_code}
              </Typography>

              <label htmlFor="Quest" className="text-xs label-container">
                State code
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fafafa",
                }}
              >
                {beneVerifyGST?.gstin_details?.field_visit_conducted}
              </Typography>

              <label htmlFor="Quest" className="text-xs label-container">
                Field visit conducted
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fafafa",
                }}
              >
                {beneVerifyGST?.gstin_details?.state_jurisdiction}
              </Typography>

              <label htmlFor="Quest" className="text-xs label-container">
                State jurisdiction
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fafafa",
                }}
              >
                {beneVerifyGST?.gstin_details?.taxpayer_type}
              </Typography>

              <label htmlFor="Quest" className="text-xs label-container">
                Taxpayer type
              </label>
            </div>
          </div>
        </Drawer>
      </div>
    );
  };

  const addSpacesToNumber = (number) => {
    return number.replace(/(.{4})/g, "$1 ").trim();
  };

  const navigate = useNavigate();
  const {
    unachivedLoadingPan,
    achivedLoadingPan,
    unachivedDataPan,
    achivedDataPan,
  } = useSelector((state) => state.verify);

  let haveAcheived = beneLookData?.beneficiary_accounts?.find((val) => {
    return val?.approval_status === "archived";
  });
  let haveApproved = beneLookData?.beneficiary_accounts?.find((val) => {
    return val?.approval_status === "approved";
  });
  let haveDennied = beneLookData?.beneficiary_accounts?.find((val) => {
    return val?.approval_status === "denied";
  });
  let haveRejected = beneLookData?.beneficiary_accounts?.find((val) => {
    return val?.approval_status === "rejected";
  });
  let havePending = beneLookData?.beneficiary_accounts?.find((val) => {
    return val?.approval_status === "pending";
  });
  let haveBlock = beneLookData?.beneficiary_accounts?.find((val) => {
    return val?.approval_status === "blocked";
  });

  useEffect(() => {
    let haveApprovedLinked = beneLookData?.beneficiary_accounts?.filter(
      (val) => {
        return val?.id === previousId;
      }
    );

    let haveApprovedNotLinked = beneLookData?.beneficiary_accounts?.filter(
      (val) => {
        return val?.id !== previousId;
      }
    );

    if (haveApprovedLinked && haveApprovedNotLinked) {
      const newData = [...haveApprovedLinked, ...haveApprovedNotLinked];
      setIsApprovedState(newData);
    }
  }, [beneLookData?.beneficiary_accounts]);

  const handleBlur = () => {
    if (panNumber?.length === 0) {
      setIsFocus(false);
    }
  };

  const handleFocus = () => {
    setIsFocus(true);
  };

  useEffect(() => {
    beneLookData?.beneficiary_accounts?.find((val) => {
      if (val?.id === previousId) {
        setPreviousBankNo(val?.bank_acc_no);
      }
    });
  }, [beneLookData?.beneficiary_accounts]);

  useEffect(() => {
    beneLookData?.beneficiary_accounts?.find((val) => {
      if (val?.id === previousId) {
        setGSTPanExist(val?.gstin_detail ? true : false);
      }
    });
  }, [beneLookData?.beneficiary_accounts]);

  useEffect(() => {
    if (!achivedLoadingPan) {
      if (achivedDataPan?.status === true) {
        dispatch(checkUsertoken());
        dispatch(beneLookUp({ pan_number: panNumber }));
        toast.success("Account archived successfully", toast_position);
        dispatch(resetPanArchived());
      } else if (achivedDataPan?.status === false) {
        if (achivedDataPan?.errors?.user_unauthorized) {
          navigate("/feature_not_assigned");
        } else {
          toast.error(
            Object.values(achivedDataPan?.errors)[0][0],
            toast_position
          );
          dispatch(resetPanArchived());
        }
      }
    }
  }, [achivedDataPan]);

  useEffect(() => {
    if (!unachivedLoadingPan) {
      if (unachivedDataPan?.status === true) {
        dispatch(checkUsertoken());
        dispatch(beneLookUp({ pan_number: panNumber }));
        toast.success("Account unarchived successfully", toast_position);

        setAccountType({ type: "", id: "" });
        dispatch(resetPanUnarchived());
      } else if (unachivedDataPan?.status === false) {
        if (unachivedDataPan?.errors?.user_unauthorized) {
          navigate("/feature_not_assigned");
        } else {
          toast.error(
            Object.values(unachivedDataPan?.errors)[0][0],
            toast_position
          );
          dispatch(resetPanUnarchived());
        }
      }
    }
  }, [unachivedDataPan]);

  let haveLink = beneLookData?.beneficiary_accounts?.some((val) => {
    return (
      val?.id === accountType.id &&
      val?.bank_acc_no === previousBankNo &&
      val?.id !== previousId &&
      type === "editFlow"
    );
  });

  useEffect(() => {
    if (beneVerifyGST?.gstin_details?.business_name) {
      setPayload({
        ...payload,
        gst_org_name: beneVerifyGST?.gstin_details?.business_name,
      });
    }
  }, [beneVerifyGST]);

  if (GSTPanExist && payload?.gstin?.length == 0) {
    BenEnableNextButton(true);
  }

  const handleClosePopup = () => {
    setRejectTxnPopup(false);
    setShowRejectAllTxnReson(false);
  };

  return (
    <div className="benePan_Main">
      {showRejectTnxPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
          <RejectingTransactionPopup
            type={"ArchiveAccount"}
            accountId={accountType.id}
            handleClosePopup={handleClosePopup}
            setShowRejectAllTxnReson={setShowRejectAllTxnReson}
            setRejectTxnPopup={setRejectTxnPopup}
            flow={"edit"}
          />
        </div>
      )}
      {showRejectAllTxnReson && (
        <div className="fixed top-0 left-0 w-full h-full  flex items-center justify-center z-[9998] bg-opacity-80">
          <BeneDetailModal
            type="Reject-All-Transaction"
            onClose={handleClosePopup}
            id={accountType.id}
            createEdit={"createEdit"}
          />
        </div>
      )}
      {achivedOpenPop && (
        <BeneficiaryPanModal
          close={setAchivedOpenPop}
          id={accountType.id}
          type={"archived"}
          setAccountType={setAccountType}
          accountType={accountType}
          message={"Are you sure you want to archive this account?"}
          note="The account will become inactive."
          panNumber={panNumber}
          setIsArchived={setIsArchived}
          setShutViewAll={setShutViewAll}
          createFlow={"pan"}
          setShowAction={setShowAction}
          message2={"Archive Account"}
          setRejectTxnPopup={setRejectTxnPopup}
          accountId={viewAccountDetailData?.beneficiary_accounts[0]?.id}
        />
      )}

      {unachivedOpenPop && (
        <BeneficiaryPanModal
          close={setUnachivedOpenPop}
          id={accountType.id}
          type={"unarchived"}
          note="The account will become active."
          setAccountType={setAccountType}
          accountType={accountType}
          message={"Are you sure you want to unarchive this account?"}
          panNumber={panNumber}
          setIsArchived={setIsArchived}
          setShutViewAll={setShutViewAll}
          createFlow={"pan"}
          setShowAction={setShowAction}
          message2={"Unarchive Account"}
          setRejectTxnPopup={setRejectTxnPopup}
          accountId={viewAccountDetailData?.beneficiary_accounts[0]?.id}
        />
      )}

      <div style={{ marginTop: type != "createFlow" ? "-30px" : "0px" }}>
        {(isLinkedAccount || alreadyLinked) && verifiedPan ? (
          <div className="new_message_text_block_nonarchiveEdit mt-[30px]">
            <img src={addstatus} alt="info" />
            <p className="mt-[12px]">
              Looks like this PAN is already linked to this account. Please
              change provided PAN details to proceed.
            </p>
          </div>
        ) : nonArchive ? (
          <div className="new_message_text_block_nonarchiveEdit mt-[30px]">
            <img src={addstatus} alt="info" />
            <p className="mt-[12px]">
              Archiving this account is disabled. As you're currently editing
              this account, archiving cannot be performed.
            </p>
          </div>
        ) : hideBlock ? (
          <div className="new_message_text_block mt-[30px]">
            <img src={addstatus} alt="info" />
            <p>To unblock this account, please contact customer support</p>
          </div>
        ) : hideCreate ? (
          <div className="new_message_text_block mt-[30px]">
            <img src={addstatus} alt="info" />
            <p>
              Creating a new account adds it to the active accounts for the
              provided PAN
            </p>
          </div>
        ) : beneLookData?.status &&
          panNumber?.length === 10 &&
          verifiedPan &&
          !associatedPan &&
          viewAccountDetailData?.beneficiary_accounts[0]?.pan_no !==
            panNumber ? (
          <div className="new_message_text_block_nonarchiveEdit_associate mt-[30px]">
            <img src={addstatus} alt="info" />
            <p>
              Associated accounts have been found, clicking "update" will add
              this account to the active accounts associated with the provided
              PAN details.
            </p>
          </div>
        ) : null}

        {GSTLinkedMessage ? (
          <div className="gstMsg mt-[30px]">
            <h5>Since GST and PAN are linked</h5>
            <ul className="list-disc text-xs pl-4 ">
              <li className="my-2.5">
                To edit PAN, the associated GST must also be provided.
              </li>
              <li>
                GST can only be replaced with other GSTs that are associated
                with the provided PAN.
              </li>
            </ul>
          </div>
        ) : null}

        <div
          className="dividepan pan_Enter"
          style={{
            backgroundColor:
              verifiedPan && !verifyPANisLoading
                ? "#4cb537"
                : verifiedPan === false && !verifyPANisLoading
                ? "#CE362A"
                : "transparent",
          }}
        >
          <div className="pan_InputEdit">
            <div className="contain_det">
              <p>Beneficiary's PAN Details</p>
              <div
                className="input_Field"
                style={{
                  borderColor:
                    verifiedPan && !verifyPANisLoading
                      ? "#00FF14"
                      : verifiedPan === false &&
                        panNumber?.length === 10 &&
                        !verifyPANisLoading
                      ? "#FF5860"
                      : isFocus
                      ? "#00FFF5"
                      : "#dadada",
                }}
              >
                <label
                  htmlFor="panid"
                  style={{
                    top: isFocus ? "-12px" : "8px",
                    fontSize: isFocus ? "12px" : "16px",
                    color:
                      panNumber?.length > 0 &&
                      verifiedPan === false &&
                      panNumber?.length === 10 &&
                      !verifyPANisLoading
                        ? "#FF5860"
                        : verifiedPan && !verifyPANisLoading
                        ? "#00FF14"
                        : isFocus
                        ? "#00FFF5"
                        : "#dadada",
                  }}
                >
                  PAN number *
                </label>
                {verifiedPan && !verifyPANisLoading ? (
                  <img src={greenCheck} alt="check" />
                ) : verifiedPan === false &&
                  panNumber?.length === 10 &&
                  !verifyPANisLoading ? (
                  <img src={redCheck} alt="check" />
                ) : verifyPANisLoading ? (
                  <img
                    src={inputLoader}
                    style={{ width: "20px", height: "20px" }}
                  />
                ) : null}
                <input
                  value={panNumber}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handlePanChange(e);
                  }}
                  type="text"
                  className="input_Box"
                  spellCheck={false}
                  id="panid"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          {!verifyPANisLoading && verifiedPan ? (
            <div className="panEdit_Legal">
              <div
                className="mergeEdit_name"
                style={{ alignItems: "flex-start" }}
              >
                <img src={greenCheck} alt="check" />
                <span className="panEdit_Name">
                  {panDetails?.full_name.toLowerCase()}
                </span>
              </div>
              <span>Legal name</span>
            </div>
          ) : verifiedPan === false ? (
            <div className="wrong_panEdit">
              <img src={redCheck} alt="redcheck" />
              <span>PAN number is invalid</span>
            </div>
          ) : null}
        </div>
        <div
          className="dividepan pan_Enter"
          disabled={type == "editFlow" && !verifiedPan}
          style={{
            backgroundColor:
              type == "editFlow" && !verifiedPan ? "#1d3a6d" : "",
            opacity: type == "editFlow" && !verifiedPan ? 0.5 : 1,
          }}
        >
          <div
            className={`${
              GSTStatus && gstVerified && GSTLinked
                ? "bg-custom-lightGreen"
                : GSTStatus === false || GSTStatus === true || !GSTLinked
                ? "bg-custom-red"
                : ""
            } flex flex-col relative rounded-[31px] mt-[20px]`}
          >
            <div className="py-5 h-full bg-[#314C7F] flex flex-col rounded-[30px]">
              <div className=" w-full flex flex-col px-4 md:px-6 lg:px-8 pb-1 gap-5">
                <div className="w-full flex flex-col gap-4">
                  <div className="text-[#DADADA] font-semibold text-base">
                    Beneficiary's GSTIN Details
                  </div>
                  {getGSTInputElementDiv(
                    "gstin",
                    GSTStatus,
                    GSTPanExist ? "GST number *" : "GST number (optional)",
                    payload.gstin,
                    20,
                    false,
                    GSTLinked
                  )}
                </div>
              </div>
            </div>
            {getSideDraweContent()}

            {verifiedPan === true &&
              GSTStatus &&
              GSTLinked &&
              payload?.gstin?.length > 0 && (
                <div className="-mx-2">
                  {gstVerified === true ? (
                    <div className="w-full flex flex-col pl-4 pr-2">
                      <div className="panEdit_GST">
                        <div
                          className="mergeEdit_name"
                          style={{ alignItems: "flex-start" }}
                        >
                          <img src={greenCheck} alt="check" />
                          <span className="panEdit_Name">
                            {beneVerifyGST?.gstin_details?.business_name?.toLowerCase()}
                          </span>
                        </div>
                        <span>Company name</span>
                      </div>
                      <div className="flex justify-end cursor-pointer">
                        <div
                          className="flex justify-center text-center bg-white ml-7"
                          style={{ borderRadius: "30px 0px" }}
                          onClick={() => setShowDrawer(true)}
                        >
                          <div className="h-[47px] flex items-center justify-between px-2">
                            <p className="text-[#1D3A6D] pl-4 font-semibold leading-[14px] text-xs">
                              View details
                            </p>
                            <span className="w-[14px] mt-[3px] flex-shrink-0 ml-[8px] mr-[8px]">
                              <img
                                src={ViewDetailsIcon}
                                alt="View details icon"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}

            {((GSTStatus === false && payload?.gstin?.length === 15) ||
              (GSTStatus === true &&
                payload?.gstin?.length === 15 &&
                !GSTLinked)) && (
              <div className="ml-3">
                {(gstVerified === false ||
                  (!GSTLinked && gstVerified === true)) && (
                  <div className="rounded-l-large flex w-full  flex-col justify-center items-start">
                    <div className="flex flex-col h-full">
                      <div className="pl-3 pr-2 pb-2 flex rounded-b-4xl ">
                        <div className="pl-1 pr-2 pb-2 pt-[10px] flex flex-row items-start gap-2">
                          <div className="flex flex-col w-[22px] h-[22px] pt-[4px]">
                            <img
                              className=""
                              src={skippedVerification}
                              alt="verified logo"
                            />
                          </div>

                          <div className="h-full flex flex-col justify-between pt-1 pb-1 pr-1 pl-1 text-white text-base">
                            {!GSTLinked && beneVerifyGST?.status === true
                              ? "GST number is not linked."
                              : "GST number is invalid."}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ml-[23px] relative bottom-[18px] mb-4">
                      <p className="top_con_gst font-semibold">
                        {GSTPanExist
                          ? "To proceed enter a valid GST number associated with the PAN above."
                          : "To proceed either enter a valid GST number associated with the PAN above or clear the field of GST details."}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!beneLookLoading || verifiedPan === true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {
            <div
              className="dividebank bank_Details"
              style={{
                height: "140px",

                display:
                  !beneLookLoading &&
                  !verifyPANisLoading &&
                  beneLookError === true &&
                  verifiedPan &&
                  panNumber?.length === 10
                    ? ""
                    : "none",
              }}
            >
              <div className="bank-no-assacc-found">
                <p className="bene_top_account">
                  Associated Accounts{""}
                  <img
                    ref={associatedAccountRef}
                    src={InfoDetails}
                    alt="info"
                    onClick={() => {
                      setShowAccInfo(!showAccInfo);
                    }}
                  />
                  {showAccInfo ? (
                    <>
                      <div className="associate-acc-info-container">
                        <p className="associate-acc-info">
                          {type === "editFlow" ? (
                            <p className="associate-panacc-info">
                              Associated accounts include all active (approved,
                              pending) and inactive (rejected, archived,
                              blocked) accounts linked to the provided PAN
                              details. Click on the accounts to manage them, or
                              continue updating your PAN details.
                            </p>
                          ) : null}
                        </p>
                      </div>
                    </>
                  ) : null}
                </p>
                <div className="bank_hidden_accounts">
                  {type === "editFlow" ? (
                    <p>
                      <span>No associated accounts found</span> <br /> There are
                      no existing accounts that are associated with the PAN
                      details provided. You can proceed to update the PAN.
                    </p>
                  ) : (
                    <p>
                      <span>No associated accounts found</span> <br /> There are
                      no existing accounts that are associated with the PAN
                      details provided. You can proceed to create a new account.
                    </p>
                  )}
                </div>
              </div>
            </div>
          }
          {type != "editFlow" ? (
            <div
              className="pan_new_crt_acc_bottom"
              style={{
                visibility: !isButtonDisabled ? "visible" : "hidden",
                marginBottom: "20px",
                marginLeft: "20px",
              }}
            >
              <div style={{ flex: "20%", paddingTop: "0px" }}>
                <Button
                  className="bank_new_create_btn"
                  style={{
                    color: lookupNext ? "#fefefe" : "#fefefe",
                    background: lookupNext
                      ? "#506994 0% 0% no-repeat padding-box"
                      : "#1D3A6D 0% 0% no-repeat padding-box",
                    opacity: isButtonDisabled ? 0.4 : 1,
                    borderRadius: "20px",
                  }}
                  disabled={isButtonDisabled}
                  onClick={() => {
                    setIsApproved(false);
                    setLookupNext(true);
                    setIsArchived(false);
                    setIsDennied(false);
                    setIsPending(false);
                    setDisableCreateNext(false);
                    setHideBlock(false);
                    if (lookupNext) {
                      setHideCreate(false);
                      handleNext();
                    } else {
                      setHideCreate(true);
                    }
                    setAccountType({ type: "", id: "" });
                    setDisableCreateNext(true);
                  }}
                >
                  <span style={{ paddingRight: "2px" }}>
                    <img
                      src={lookupNext ? selected : not_selected}
                      alt="btn-icon"
                    />
                  </span>
                  <span style={{ paddingLeft: "2px" }}>Confirm</span>
                </Button>
              </div>
            </div>
          ) : null}
          <div
            className="dividepan pan_Details"
            style={{
              visibility:
                beneLookData?.status && panNumber?.length === 10 && verifiedPan
                  ? "visible"
                  : "hidden",
            }}
          >
            <div className="ass_top">
              <p className="bene_top_account">
                Associated Accounts{" "}
                <img
                  ref={associatedAccountRef}
                  src={InfoDetails}
                  alt="info"
                  onClick={() => {
                    setNewMessage(!newMessage);
                    setShowAccInfo(!showAccInfo);

                    if (
                      !lookupNext &&
                      accountType.type !== "blocked" &&
                      previousId !== accountType.id &&
                      !haveLink
                    ) {
                      setNewMessage(!newMessage);
                    } else if (lookupNext) {
                      setHideCreate(!hideCreate);
                    } else if (accountType.type === "blocked") {
                      setHideBlock(!hideBlock);
                    } else if (
                      previousId === accountType.id &&
                      type === "editFlow"
                    ) {
                      setNonArchive(!nonArchive);
                    } else if (haveLink) {
                      setIsLinkedAccount(!isLinkedAccount);
                    }
                  }}
                />
                {showAccInfo ? (
                  <>
                    {type === "editFlow" ? (
                      <div className="associate-panacc-info-container">
                        <p className="associate-panacc-info">
                          Associated accounts include all active (approved,
                          pending) and inactive (rejected, archived, blocked)
                          accounts linked to the provided PAN details. Click on
                          the accounts to manage them, or continue updating your
                          PAN details.
                        </p>
                      </div>
                    ) : (
                      <div className="associate-panacc-info-container">
                        <p className="associate-panacc-info">
                          Associated accounts include all active (approved,
                          pending) and inactive (rejected, archived, blocked)
                          accounts linked to the provided PAN details. Click on
                          the accounts to manage them, or continue creating
                          account.
                        </p>
                      </div>
                    )}
                  </>
                ) : null}
              </p>

              <p className="top_con" style={{ marginBottom: "20px" }}>
                The following existing accounts are associated with the PAN
                provided:
              </p>
              <div className="swap_butt">
                <Button
                  className="swap_butt_1"
                  onClick={() => {
                    setIsActive(false);
                    setIsDennied(false);
                    setIsApproved(false);
                    setIsArchived(false);
                    setIsPending(false);
                    setNonArchive(false);
                    if (haveLinkNew && type === "editFlow") {
                      setIsLinkedAccount(true);
                      setNewMessage(false);
                    } else {
                      setIsLinkedAccount(false);
                    }
                    setAccountType({
                      type: "",
                      id: "",
                    });
                    setHideBlock(false);
                    setShowAccInfo(false);
                  }}
                  style={{
                    backgroundColor: !isActive ? "#ffffff" : "#242424",
                    color: !isActive ? "#1d1d1d" : "#f9f9f9",
                  }}
                >
                  Active
                </Button>
                <Button
                  className="swap_butt_2"
                  onClick={() => {
                    setIsActive(true);
                    setIsDennied(false);
                    setIsApproved(false);
                    setIsArchived(false);
                    setIsPending(false);
                    setNonArchive(false);
                    if (haveLinkNew && type === "editFlow") {
                      setIsLinkedAccount(true);
                      setNewMessage(false);
                    } else {
                      setIsLinkedAccount(false);
                    }
                    setShowAccInfo(false);
                    setAccountType({
                      type: "",
                      id: "",
                    });
                  }}
                  style={{
                    backgroundColor: isActive ? "#ffffff" : "#242424",
                    color: isActive ? "#1d1d1d" : "#f9f9f9",
                  }}
                >
                  Inactive
                </Button>
              </div>
              {isActive ? (
                <div className="scroll_All_approval">
                  {haveAcheived || haveDennied || haveBlock || haveRejected ? (
                    <>
                      {(haveDennied || haveRejected) && (
                        <div className="achived_main">
                          <p style={{ whiteSpace: "nowrap" }}>
                            Rejected accounts
                          </p>
                          <div className="achived_scroll">
                            {isApprovedState?.map((val, i) => {
                              if (
                                val?.approval_status === "rejected" ||
                                val?.approval_status === "denied"
                              ) {
                                return (
                                  <Button
                                    style={{
                                      backgroundColor:
                                        accountType.id == val?.id
                                          ? "#7185A8"
                                          : "#1d3a6d",
                                      opacity:
                                        previousId === val?.id &&
                                        type === "editFlow"
                                          ? "0.4"
                                          : "1",
                                      outline:
                                        val?.bank_acc_no === previousBankNo &&
                                        val?.id !== previousId &&
                                        type === "editFlow"
                                          ? "2px solid #CE720F"
                                          : "",
                                    }}
                                    className="child_scroll"
                                    key={i}
                                    onClick={() => {
                                      if (type === "editFlow") {
                                        if (haveLinkNew) {
                                          setAlreadyLinked(true);
                                        } else {
                                          setAlreadyLinked(false);
                                        }
                                      }
                                      setIsLinkedAccount(false);
                                      if (val?.id === previousId) {
                                        setHaveLinkedDisabled(true);
                                      } else {
                                        setHaveLinkedDisabled(false);
                                      }
                                      setShutViewAll(true);
                                      setIsApproved(false);
                                      setIsArchived(false);
                                      setIsPending(false);
                                      setLookupNext(false);
                                      setHideBlock(false);
                                      setHideCreate(false);
                                      setDisableCreateNext(false);
                                      if (
                                        previousId === val?.id &&
                                        type === "editFlow"
                                      ) {
                                        setNonArchive(true);
                                      } else {
                                        setNonArchive(false);
                                      }
                                      if (val?.id === accountType.id) {
                                        setAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setIsDennied(false);
                                        setNonArchive(false);
                                      } else {
                                        setAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                        setIsDennied(true);
                                      }
                                      if (type === "editFlow") {
                                        setShowAction(true);
                                      } else {
                                        BenEnableNextButton(true);
                                      }
                                    }}
                                  >
                                    <div className="bank_name">
                                      <img
                                        src={require("../../../../assets/IfscLogo/" +
                                          IfscCodeMapper(val?.ifsc_code))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="client_name">
                                      <p
                                        className="name_client2"
                                        style={{ color: "#FF5860" }}
                                      >
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={val?.name.toLowerCase()}
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          {val?.name.toLowerCase()}
                                        </Tooltip>
                                      </p>
                                      <p className="bank_clientEdit">
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={addSpacesToNumber(
                                            val?.bank_acc_no
                                          )}
                                          arrow
                                          placement="bottom"
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          {addSpacesToNumber(val?.bank_acc_no)}

                                          <span className="bank_no">
                                            - Bank account number
                                          </span>
                                        </Tooltip>
                                      </p>
                                    </div>
                                    <div className="viewProfile">
                                      <button
                                        onClick={(e) => {
                                          if (previousId !== val?.id) {
                                            e.stopPropagation();
                                            navigate(
                                              `/accounts/beneficiary_account/details/${val?.id}`
                                            );
                                          }
                                        }}
                                      >
                                        <img
                                          style={{
                                            cursor:
                                              previousId === val?.id
                                                ? "default"
                                                : "pointer",
                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                      {haveBlock && (
                        <div className="achived_main">
                          <p style={{ whiteSpace: "nowrap" }}>
                            Blocked accounts
                          </p>
                          <div className="achived_scroll">
                            {isApprovedState?.map((val, i) => {
                              if (val?.approval_status === "blocked") {
                                return (
                                  <Button
                                    style={{
                                      backgroundColor:
                                        accountType.id == val?.id
                                          ? "#7185A8"
                                          : "#1d3a6d",
                                      outline:
                                        val?.bank_acc_no === previousBankNo &&
                                        val?.id !== previousId &&
                                        type === "editFlow"
                                          ? "2px solid #CE720F"
                                          : "",
                                    }}
                                    className="child_scroll"
                                    key={i}
                                    onClick={() => {
                                      if (type === "editFlow") {
                                        if (haveLinkNew) {
                                          setAlreadyLinked(true);
                                        } else {
                                          setAlreadyLinked(false);
                                        }
                                      }
                                      setIsLinkedAccount(false);
                                      setLookupNext(false);
                                      setIsDennied(false);
                                      setIsPending(false);
                                      setIsApproved(false);
                                      setIsArchived(false);
                                      setHideCreate(false);
                                      setHideBlock(!hideBlock);
                                      if (accountType.type === "blocked") {
                                        setHideBlock(false);
                                      } else {
                                        setHideBlock(!hideBlock);
                                      }
                                      if (
                                        previousId === val?.id &&
                                        type === "editFlow"
                                      ) {
                                        setNonArchive(true);
                                      } else {
                                        setNonArchive(false);
                                      }

                                      if (val?.id === accountType.id) {
                                        setAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setNonArchive(false);
                                      } else {
                                        setAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                      }
                                    }}
                                  >
                                    <div className="bank_name">
                                      <img
                                        src={require("../../../../assets/IfscLogo/" +
                                          IfscCodeMapper(val?.ifsc_code))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="client_name">
                                      <p
                                        className="name_client2"
                                        style={{ color: "#FF5860" }}
                                      >
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={val?.name.toLowerCase()}
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          {val?.name.toLowerCase()}
                                        </Tooltip>
                                      </p>
                                      <p className="bank_clientEdit">
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={addSpacesToNumber(
                                            val?.bank_acc_no
                                          )}
                                          arrow
                                          placement="bottom"
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          {addSpacesToNumber(val?.bank_acc_no)}

                                          <span className="bank_no">
                                            - Bank account number
                                          </span>
                                        </Tooltip>
                                        <span className="bank_no">
                                          - Bank account number
                                        </span>
                                      </p>
                                    </div>
                                    <div className="viewProfile">
                                      <button
                                        onClick={(e) => {
                                          if (previousId !== val?.id) {
                                            e.stopPropagation();
                                            navigate(
                                              `/accounts/beneficiary_account/details/${val?.id}`
                                            );
                                          }
                                        }}
                                      >
                                        <img
                                          style={{
                                            cursor:
                                              previousId === val?.id
                                                ? "default"
                                                : "pointer",

                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                      {haveAcheived && (
                        <div className="achived_main">
                          <p>Archived accounts</p>
                          <div className="achived_scroll">
                            {isApprovedState?.map((val, i) => {
                              if (val?.approval_status === "archived") {
                                return (
                                  <Button
                                    className="child_scroll"
                                    style={{
                                      backgroundColor:
                                        accountType.id == val?.id
                                          ? "#7185A8"
                                          : "#1d3a6d",
                                      outline:
                                        val?.bank_acc_no === previousBankNo &&
                                        val?.id !== previousId &&
                                        type === "editFlow"
                                          ? "2px solid #CE720F"
                                          : "",
                                    }}
                                    key={i}
                                    id={val?.id}
                                    onClick={() => {
                                      if (type === "editFlow") {
                                        if (haveLinkNew) {
                                          setAlreadyLinked(true);
                                        } else {
                                          setAlreadyLinked(false);
                                        }
                                      }
                                      setIsLinkedAccount(false);
                                      setLookupNext(false);
                                      setDisableCreateNext(false);
                                      setIsDennied(false);
                                      setIsPending(false);
                                      setIsApproved(false);
                                      setHideBlock(false);
                                      setHideCreate(false);
                                      setHaveLinkedDisabled(false);
                                      if (
                                        previousId === val?.id &&
                                        type === "editFlow"
                                      ) {
                                        setNonArchive(true);
                                      } else {
                                        setNonArchive(false);
                                      }
                                      if (val?.id === accountType.id) {
                                        setAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setIsArchived(false);
                                        setNonArchive(false);
                                      } else {
                                        setAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                        setIsArchived(true);
                                      }
                                      if (type === "editFlow") {
                                        setShowAction(true);
                                      } else {
                                        BenEnableNextButton(true);
                                      }
                                    }}
                                  >
                                    <div className="bank_name">
                                      <img
                                        src={require("../../../../assets/IfscLogo/" +
                                          IfscCodeMapper(val?.ifsc_code))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="client_name">
                                      <p className="name_client2">
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={val?.name.toLowerCase()}
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          {val?.name.toLowerCase()}
                                        </Tooltip>
                                      </p>
                                      <p className="bank_clientEdit">
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={addSpacesToNumber(
                                            val?.bank_acc_no
                                          )}
                                          arrow
                                          placement="bottom"
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          {addSpacesToNumber(val?.bank_acc_no)}
                                        </Tooltip>
                                        <span className="bank_no">
                                          - Bank account number
                                        </span>
                                      </p>
                                    </div>
                                    <div className="viewProfile">
                                      <button
                                        onClick={(e) => {
                                          if (previousId !== val?.id) {
                                            e.stopPropagation();
                                            navigate(
                                              `/accounts/beneficiary_account/details/${val?.id}`
                                            );
                                          }
                                        }}
                                      >
                                        <img
                                          style={{
                                            cursor:
                                              previousId === val?.id
                                                ? "default"
                                                : "pointer",

                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="hidden_accounts">
                      <p>
                        <span>No inactive associated accounts found</span>{" "}
                        <br /> There are no existing inactive accounts that are
                        associated with the PAN provided.
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div className="scroll_All_approval">
                  {haveApproved || havePending ? (
                    <>
                      {haveApproved && (
                        <div className="achived_main">
                          <p style={{ whiteSpace: "nowrap" }}>
                            Approved accounts
                          </p>
                          <div className="achived_scroll">
                            {isApprovedState?.map((val, i) => {
                              if (val?.approval_status === "approved") {
                                return (
                                  <Button
                                    style={{
                                      backgroundColor:
                                        accountType.id == val?.id
                                          ? "#7185A8"
                                          : "#1d3a6d",
                                      opacity:
                                        previousId === val?.id &&
                                        type === "editFlow"
                                          ? "0.4"
                                          : "1",
                                      outline:
                                        val?.bank_acc_no === previousBankNo &&
                                        val?.id !== previousId &&
                                        type === "editFlow"
                                          ? "2px solid #CE720F"
                                          : "",
                                    }}
                                    className="child_scroll"
                                    key={i}
                                    onClick={() => {
                                      if (type === "editFlow") {
                                        if (haveLinkNew) {
                                          setAlreadyLinked(true);
                                        } else {
                                          setAlreadyLinked(false);
                                        }
                                      }
                                      setIsLinkedAccount(false);
                                      if (val?.id === previousId) {
                                        setHaveLinkedDisabled(true);
                                      } else {
                                        setHaveLinkedDisabled(false);
                                      }
                                      setShutViewAll(true);
                                      setIsArchived(false);
                                      setIsDennied(false);
                                      setIsPending(false);
                                      setLookupNext(false);
                                      setHideBlock(false);
                                      setHideCreate(false);
                                      setDisableCreateNext(false);
                                      if (
                                        previousId === val?.id &&
                                        type === "editFlow"
                                      ) {
                                        setNonArchive(true);
                                      } else {
                                        setNonArchive(false);
                                      }
                                      if (val?.id === accountType.id) {
                                        setAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setNonArchive(false);
                                        setIsApproved(false);
                                      } else {
                                        setAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                        setIsApproved(true);
                                      }
                                      if (type === "editFlow") {
                                        setShowAction(true);
                                      } else {
                                        BenEnableNextButton(true);
                                      }
                                    }}
                                  >
                                    <div className="bank_name">
                                      <img
                                        src={require("../../../../assets/IfscLogo/" +
                                          IfscCodeMapper(val?.ifsc_code))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="client_name">
                                      <p
                                        className="name_client2"
                                        style={{
                                          color: "#4DDD37",
                                        }}
                                      >
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={val?.name.toLowerCase()}
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          {val?.name.toLowerCase()}
                                        </Tooltip>
                                      </p>
                                      <p className="bank_clientEdit">
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={addSpacesToNumber(
                                            val?.bank_acc_no
                                          )}
                                          arrow
                                          placement="bottom"
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          {addSpacesToNumber(val?.bank_acc_no)}
                                        </Tooltip>
                                        <span className="bank_no">
                                          - Bank account number
                                        </span>
                                      </p>
                                    </div>
                                    <div className="viewProfile">
                                      <button
                                        onClick={(e) => {
                                          if (previousId !== val?.id) {
                                            e.stopPropagation();
                                            navigate(
                                              `/accounts/beneficiary_account/details/${val?.id}`
                                            );
                                          }
                                        }}
                                      >
                                        <img
                                          style={{
                                            cursor:
                                              previousId === val?.id
                                                ? "default"
                                                : "pointer",

                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                      {havePending && (
                        <div className="achived_main">
                          <p style={{ whiteSpace: "nowrap" }}>
                            Pending accounts
                          </p>
                          <div className="achived_scroll">
                            {isApprovedState?.map((val, i) => {
                              if (val?.approval_status === "pending") {
                                return (
                                  <Button
                                    style={{
                                      backgroundColor:
                                        accountType.id == val?.id
                                          ? "#7185A8"
                                          : "#1d3a6d",
                                      opacity:
                                        previousId === val?.id &&
                                        type === "editFlow"
                                          ? "0.4"
                                          : "1",
                                      outline:
                                        val?.bank_acc_no === previousBankNo &&
                                        val?.id !== previousId &&
                                        type === "editFlow"
                                          ? "2px solid #CE720F"
                                          : "",
                                    }}
                                    className="child_scroll"
                                    key={i}
                                    onClick={() => {
                                      if (type === "editFlow") {
                                        if (haveLinkNew) {
                                          setAlreadyLinked(true);
                                        } else {
                                          setAlreadyLinked(false);
                                        }
                                      }
                                      setIsLinkedAccount(false);
                                      if (val?.id === previousId) {
                                        setHaveLinkedDisabled(true);
                                      } else {
                                        setHaveLinkedDisabled(false);
                                      }
                                      setShutViewAll(true);
                                      setIsDennied(false);
                                      setIsApproved(false);
                                      setIsArchived(false);
                                      setLookupNext(false);
                                      setDisableCreateNext(false);
                                      setHideBlock(false);
                                      setHideCreate(false);
                                      if (
                                        previousId === val?.id &&
                                        type === "editFlow"
                                      ) {
                                        setNonArchive(true);
                                      } else {
                                        setNonArchive(false);
                                      }
                                      if (val?.id === accountType.id) {
                                        setAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setIsPending(false);
                                        setNonArchive(false);
                                      } else {
                                        setAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                        setIsPending(true);
                                      }
                                      if (type === "editFlow") {
                                        setShowAction(true);
                                      } else {
                                        BenEnableNextButton(true);
                                      }
                                    }}
                                  >
                                    <div className="bank_name">
                                      <img
                                        src={require("../../../../assets/IfscLogo/" +
                                          IfscCodeMapper(val?.ifsc_code))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="client_name">
                                      <p
                                        className="name_client2"
                                        style={{ color: "#00FFF5" }}
                                      >
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={val?.name.toLowerCase()}
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          {val?.name.toLowerCase()}
                                        </Tooltip>
                                      </p>
                                      <p className="bank_clientEdit">
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={addSpacesToNumber(
                                            val?.bank_acc_no
                                          )}
                                          arrow
                                          placement="bottom"
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          {addSpacesToNumber(val?.bank_acc_no)}
                                        </Tooltip>
                                        <span className="bank_no">
                                          - Bank account number
                                        </span>
                                      </p>
                                    </div>
                                    <div className="viewProfile">
                                      <button
                                        onClick={(e) => {
                                          if (previousId !== val?.id) {
                                            e.stopPropagation();
                                            navigate(
                                              `/accounts/beneficiary_account/details/${val?.id}`
                                            );
                                          }
                                        }}
                                      >
                                        <img
                                          style={{
                                            cursor:
                                              previousId === val?.id
                                                ? "default"
                                                : "pointer",

                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="hidden_accounts">
                      <p>
                        <span>No active associated accounts found</span> <br />{" "}
                        There are no existing active accounts that are
                        associated with the PAN provided.
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
            {type === "editFlow" &&
            previousId &&
            accountType.id &&
            accountType.type !== "blocked" ? (
              <div className="ass_bottom_editFlow">
                <p>Account action</p>
                {isAchived ? (
                  <Button
                    className="ass_next"
                    onClick={() => {
                      if (accountType.type === "archived") {
                        setUnachivedOpenPop(true);
                      }
                    }}
                  >
                    <img src={unarchiveImg} alt="unarc" /> Unarchive account
                  </Button>
                ) : (isApproved || isDennied || isPending) && shutViewAll ? (
                  <Button
                    className="ass_arc"
                    style={{
                      color: lookupNext ? "#031F4F" : "#fefefe",
                      background:
                        (previousId === accountType.id) === nonArchive
                          ? `#69B76F 0% 0% no-repeat padding-box`
                          : "#69B76F 0% 0% no-repeat padding-box",
                      opacity: previousId === accountType.id ? 0.4 : 1,
                    }}
                    disabled={
                      previousId === accountType.id && type === "editFlow"
                    }
                    onClick={() => {
                      setAchivedOpenPop(!achivedOpenPop);
                    }}
                  >
                    <img src={archiveImg} alt="arc" />
                    Archive Account
                  </Button>
                ) : null}
              </div>
            ) : type === "createFlow" ? (
              <>
                <div
                  className="ass_bottom"
                  style={{
                    paddingBottom:
                      isAchived || isPending || isApproved || isDennied
                        ? "15px"
                        : "20px",
                    display:
                      isAchived ||
                      ((isApproved || isDennied || isPending) && shutViewAll)
                        ? "block"
                        : "none",
                  }}
                >
                  <p>Account action</p>
                  {isAchived ? (
                    <Button
                      className="ass_next"
                      onClick={() => {
                        if (accountType.type === "archived") {
                          setUnachivedOpenPop(true);
                        }
                      }}
                    >
                      <img src={unarchiveImg} alt="unarc" /> Unarchive account
                    </Button>
                  ) : (isApproved || isDennied || isPending) && shutViewAll ? (
                    <Button
                      className="ass_arc"
                      style={{
                        color: "#fefefe",
                        backgroundColor: "#69B76F",
                      }}
                      onClick={() => {
                        setAchivedOpenPop(!achivedOpenPop);
                      }}
                    >
                      <img src={archiveImg} alt="arc" />
                      Archive Account
                    </Button>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <div
          className="dividepan pan_Details"
          style={{
            visibility: "hidden",
          }}
        ></div>
      )}
    </div>
  );
};

export default BenePanDetails;
