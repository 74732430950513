import React, { useRef, useState, useEffect } from "react";
import greenCheck from "../../../../assets/CheckmarksIcons/verified_with_white_circle_green.svg";
import redCheck from "../../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import addstatus from "../../../../assets/ErrorIcons/error_with_white_circle.svg";
import "./index.css";
import { Button } from "@mui/material";
import {
  Divider,
  Drawer,
  InputAdornment,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import inputLoader from "../../../../assets/Beneficiary Icons/inputloder.gif";
import Validation from "../../../../utils/validation";
import CloseIcon from "../../../../assets/CloseIcons/rejected_white.svg";
import skippedVerification from "../../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ViewDetails from "../../../../assets/GeneralIcons/detail_page_edit detail.svg";
import InfoDetails from "../../../../assets/InfoIcons/Info.svg";
import BeneficiaryPanModal from "../../../../components/BeneCreateAccount/BeneficiaryPanModal";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import {
  beneLookUp,
  resetPanArchived,
  resetPanUnarchived,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import { beneVerifyThisGST } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { toast } from "react-toastify";
import { checkUsertoken } from "../../../../redux/features/login/login";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import selected from "../../../../assets/CheckmarksIcons/selected_with_green_circle.svg";
import not_selected from "../../../../assets/CheckmarksIcons/not_selected_with_grey_circle.svg";
import viewIcon from "../../../../assets/ArrowsIcons/arrow_right_blue_filled.svg";
import RejectingTransactionPopup from "../BeneAcountApproval/RejectingTransactionPopup/RejectingTransactionPopup";
import BeneDetailModal from "../../../../components/Accounts/BeneDetailModal";
import unarchiveImg from "../../../../assets/ArchieveUnArchieveIcons/unarchive.svg";
import archiveImg from "../../../../assets/ArchieveUnArchieveIcons/archive.svg";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";

const CreateBeneAccPanDetails = ({
  verifiedPan,
  panNumber,
  handlePanChange,
  panDetails,
  beneLookData,
  beneLookLoading,
  setLookupNext,
  lookupNext,
  BenEnableNextButton,
  verifyPANisLoading,
  isAchived,
  setIsArchived,
  isApproved,
  setIsApproved,
  isDennied,
  setIsDennied,
  setIsPending,
  isPending,
  accountType,
  setAccountType,
  shutViewAll,
  setShutViewAll,
  disableCreateNext,
  setDisableCreateNext,
  setIsFocus,
  isFocus,
  type,
  setShowAction,
  previousId,
  setIsActive,
  isActive,
  beneLookError,
  setNewMessage,
  newMessage,
  setHideBlock,
  setHideCreate,
  hideBlock,
  hideCreate,
  setNonArchive,
  nonArchive,
  previousBankNo,
  setPreviousBankNo,
  isLinkedAccount,
  setIsLinkedAccount,
  unachivedOpenPop,
  setUnachivedOpenPop,
  achivedOpenPop,
  setAchivedOpenPop,
  isButtonDisabled,
  alreadyLinked,
  setAlreadyLinked,
  haveLinkNew,
  setPayload,
  payload,
  showAccInfo,
  setShowAccInfo,
  handleDeSelectConfirm,
  showRejectTnxPopup,
  setRejectTxnPopup,
  showRejectAllTxnReson,
  setShowRejectAllTxnReson,
  GSTStatus,
  setGSTStatus,
}) => {
  const [isApprovedState, setIsApprovedState] = useState([]);

  const [haveLinkedDisabled, setHaveLinkedDisabled] = useState(false);

  const [showDrawer, setShowDrawer] = useState(false);
  const [customErrors, setCustomErrors] = useState({});
  const [gstVerified, setGstVerified] = useState(null);
  const [GSTLinked, setGSTLinked] = useState(false);
  const [focusState, setFocusState] = useState({});

  const dispatch = useDispatch();
  const associatedAccountRef = useRef(null);
  const noAssociatedAccountRef = useRef(null);
  const [showNoAccountInfo, setShowNoAccountInfo] = useState(false);
  const [newData, setNewData] = useState({
    gstin: "",
  });
  const { viewAccountDetailData, viewAccountDetailLoading } = useSelector(
    (state) => state.verify
  );
  const { beneVerifyGST, isGSTError, isGSTLoading } = useSelector(
    (state) => state.beneAllVerification
  );

  useEffect(() => {
    if (payload?.gstin?.length === 15) {
      let aa = payload.gstin.substring(2, payload.gstin.length - 3);
      if (aa === panNumber && beneVerifyGST?.status === true) {
        setGSTStatus(true);
        setGstVerified(true);
        setGSTLinked(true);
      } else {
        setGSTStatus(false);
        setGstVerified(false);
        setGSTLinked(false);
        BenEnableNextButton(true);
      }
    } else if (
      payload?.gstin?.length === 0 &&
      verifiedPan &&
      !verifyPANisLoading
    ) {
      setGSTStatus(null);
    }
  }, [
    beneVerifyGST,
    GSTStatus,
    gstVerified,
    payload.gstin,
    verifyPANisLoading,
  ]);

  useEffect(() => {
    if (!beneLookData?.status) {
      let aa = payload.gstin.substring(2, payload.gstin.length - 3);
      if (!GSTStatus && GSTStatus != null) {
        BenEnableNextButton(true);
      } else if (GSTStatus && disableCreateNext && aa === panNumber) {
        BenEnableNextButton(false);
      }
    }
  }, [GSTStatus]);

  useEffect(() => {
    if (!beneLookData?.status) {
      let aa = payload.gstin.substring(2, payload.gstin.length - 3);
      if (
        verifiedPan === true &&
        GSTStatus &&
        payload?.gstin?.length > 0 &&
        payload?.gstin?.length === 15 &&
        gstVerified === true &&
        aa === panNumber
      ) {
        BenEnableNextButton(false);
      } else if (
        !beneLookLoading &&
        !verifyPANisLoading &&
        beneLookError === true &&
        verifiedPan &&
        panNumber?.length === 10 &&
        payload?.gstin?.length === 0
      ) {
        BenEnableNextButton(false);
      } else {
        BenEnableNextButton(true);
      }
    }
  }, [gstVerified, GSTStatus]);

  useEffect(() => {
    if (!viewAccountDetailLoading && viewAccountDetailData?.status === true) {
      const beneficiaryData = viewAccountDetailData?.beneficiary_accounts[0];
      if (beneficiaryData) {
        setPayload((prevPayload) => ({
          ...prevPayload,

          gstin: beneficiaryData.gstin_detail || "",
        }));

        if (beneficiaryData.gstin) {
          dispatch(checkUsertoken());
          dispatch(
            beneVerifyThisGST({
              gstin: beneficiaryData.gstin,
            })
          );
        }
      }
    }
  }, [viewAccountDetailData, viewAccountDetailLoading, dispatch]);

  useEffect(() => {
    if (payload?.gstin?.length === 15 && !customErrors["gstin"]) {
      setGSTStatus(Validation.allowedGstin(payload.gstin));
      dispatch(checkUsertoken());
      dispatch(beneVerifyThisGST({ search_string: payload.gstin }));
    }
  }, [payload.gstin]);

  useEffect(() => {
    if (!isGSTLoading) {
      if (beneVerifyGST?.status === true) {
        setGstVerified(true);
      } else if (beneVerifyGST?.status === false) {
        setGstVerified(false);
      } else {
        setGstVerified(null);
      }
    }
  }, [beneVerifyGST, isGSTLoading]);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setShowDrawer(open);
  };

  useEffect(() => {
    if (newData.gstin?.length !== 15) {
      setLookupNext(false);
    }
  }, [payload.gstin]);

  const checkAllValidations = () => {
    return GSTStatus;
  };

  const modifyValue = (type, value) => {
    if (type === "name") {
      return value?.replace(/(\w)(\w*)/g, function (g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      });
    } else if (type === "gstin") {
      return value?.toUpperCase();
    }
    return value.trim();
  };

  const allowedValue = (name, value) => {
    if (name === "gstin") return Validation.allowedGstin(value);
    return true;
  };
  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };

    if (name === "gstin")
      errors = Validation.validateGstin(name, value, errors);
    setCustomErrors(errors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newValue = modifyValue(name, value.replace(/[^a-zA-Z0-9]/g, ""));
    if (value) {
      setNewData({ [name]: value });
    }
    setNewData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));

    if (allowedValue(name, value)) {
      validateAndRecordError(name, newValue);
      setPayload((prevState) => ({
        ...prevState,
        [name]: newValue,
      }));

      if (value.trim() === "" && value.length === 1) {
        return;
      }

      if (newValue.length < 15 && newValue.length > 0) {
        setGstVerified(false);
        setGSTStatus(false);
      }

      if (name === "gstin") {
        setLookupNext(false);
        if (
          name === "gstin" &&
          newValue.length < 15 &&
          newValue.length > 0 &&
          !gstVerified &&
          GSTStatus
        ) {
          setGSTStatus(false);
        } else if (newValue.length === 15 && gstVerified && !GSTStatus) {
          setGSTStatus(true);
        }
      }
    }
  };

  const showErrors = (name) => {
    let errorMessage = customErrors[name];
    if (typeof errorMessage === "string") {
      errorMessage = { "Wrong input": [errorMessage] };
    }
    Object.entries(errorMessage)?.forEach(([errorType, errorMsg]) => {
      toast.error(errorMsg[0], toast_position);
    });
  };

  useEffect(() => {
    if (
      payload.gstin?.length > 0 &&
      payload.gstin?.length === 15 &&
      beneVerifyGST?.status === true
    ) {
      let errors = { ...customErrors };
      delete errors["gstin"];
      setCustomErrors(errors);
      setGSTStatus(true);
    }

    if (payload.gstin?.length > 0 && beneVerifyGST?.errors) {
      let errors = { ...customErrors };
      errors["gstin"] = beneVerifyGST.errors;
      setCustomErrors(errors);
      setGSTStatus(false);
    }
  }, [beneVerifyGST]);

  const getGSTInputElementDiv = (name, status, label, value, maxLength) => {
    const getColor = () => {
      if (value?.length === 15) {
        if (status) return "#00FF14";
        if (!status) return "#FF5860";
      }
      if (focusState[name]) return "#31E5E5";
      return "#DADADA";
    };

    const color = getColor();

    const handleFocus = () => {
      setFocusState((prev) => ({ ...prev, [name]: true }));
    };

    const handleBlur = () => {
      setFocusState((prev) => ({ ...prev, [name]: false }));
    };

    return (
      <div className="w-full flex flex-row items-center self-center text-center gap-4">
        <TextField
          className="w-full antialiased text-xl"
          id={`outlined-size-small-${name}`}
          size="small"
          variant="outlined"
          sx={{
            "& label.Mui-focused": {
              color: color,
            },
            "& label": {
              color: color,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color,
                outline: "none !important",
              },
              "&:hover fieldset": {
                borderColor: color,
              },
              "&.Mui-focused fieldset": {
                borderColor: color,
              },
            },
          }}
          inputProps={{
            style: { color: "#DADADA" },
            maxLength: maxLength,
          }}
          InputLabelProps={{ style: { color: color } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <div className="w-4 h-4">
                  {name === "gstin" && (
                    <>
                      {payload.gstin?.length === 0 ? null : (
                        <>
                          {panNumber?.length >= 10 ? (
                            gstVerified && payload.gstin?.length === 0 ? (
                              <img
                                src={skippedVerification}
                                alt="Skipped Verification Icon"
                                onClick={() => showErrors(name)}
                                className="cursor-pointer w-4 h-4"
                              />
                            ) : (
                              gstVerified && payload.gstin?.length === 15
                            )
                          ) : (
                            <img
                              src={skippedVerification}
                              alt="Skipped Verification Icon"
                              onClick={() => showErrors(name)}
                              className="cursor-pointer w-4 h-4"
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </InputAdornment>
            ),
          }}
          label={label}
          name={name}
          onBlurCapture={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  const getSideDraweContent = () => {
    return (
      <div className="w-fit">
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={toggleDrawer(false)}
          sx={{ zIndex: 100 }}
          PaperProps={{
            style: {
              width: "24vw",
              height: "auto",
            },
          }}
        >
          <div className="bg-[#1D3A6D] custom-scroll-container  overflow-auto h-screen">
            <div className="bg-[#314D7E] py-3 flex justify-between p-4 pt-28 shadow-lg h-44">
              <div className="text-xl text-white mt-4 mx-2">
                GST Verification Details
              </div>
              <div
                className="cursor-pointer w-8 h-8 p-2 my-3 bg-[#1D3A6D] flex justify-center items-center rounded-md crt-gst-close-btn"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <img
                  src={CloseIcon}
                  alt="Close"
                  className="crt-gst-close-btn"
                />
              </div>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start mt-2"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  color: "#fafafa",
                }}
                variant="body1"
                className="capitalize text-base"
              >
                {beneVerifyGST?.gstin_details?.gstin_no}
              </Typography>
              <label htmlFor="Quest" className="text-xs label-container">
                GSTIN number
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start mt-2"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  color: "#fafafa",
                }}
                variant="body1"
                className="text-base"
              >
                {beneVerifyGST?.gstin_details?.business_name}
              </Typography>
              <label htmlFor="Quest" className="text-xs label-container">
                Company name
              </label>
            </div>
            <div
              className="p-4 flex flex-col gap-0 justify-end items-start"
              style={{ paddingLeft: "25px" }}
            >
              <Typography sx={{ color: "#fafafa" }} className="text-base">
                {beneVerifyGST?.gstin_details?.date_of_registration}
              </Typography>
              <label htmlFor="Quest" className="text-xs label-container">
                ESTD. on
              </label>
            </div>
            <Divider
              className="text-white"
              sx={{
                borderTop: "1px solid",
                borderColor: "#031F4F",
                my: 1,
                width: "21vw",
                mx: "auto",
              }}
            />
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px", marginTop: "0" }}
            >
              <Typography
                sx={{ color: "#fafafa" }}
                variant="body1"
                className="text-base"
              >
                {beneVerifyGST?.gstin_details?.pan_no}
              </Typography>
              <label htmlFor="Quest" className="text-xs label-container">
                PAN number
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{ color: "#fafafa" }}
                variant="body1"
                className="text-base"
              >
                {beneVerifyGST?.gstin_details?.constitution_of_business}
              </Typography>
              <label htmlFor="Quest" className="text-xs label-container">
                Corporate listing
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{ color: "#fafafa" }}
                variant="body1"
                className="text-base"
              >
                {beneVerifyGST?.gstin_details?.status}
              </Typography>
              <label htmlFor="Quest" className="text-xs label-container">
                Company status
              </label>
            </div>
            <Divider
              className="text-white"
              sx={{
                borderTop: "1px solid",
                borderColor: "#031F4F",
                my: 1,
                width: "21vw",
                mx: "auto",
              }}
            />
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fafafa",
                }}
              >
                {beneVerifyGST?.gstin_details?.address}
              </Typography>

              <label htmlFor="Quest" className="text-xs label-container">
                Corporate address
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fafafa",
                }}
              >
                {beneVerifyGST?.gstin_details?.state}
              </Typography>

              <label htmlFor="Quest" className="text-xs label-container">
                State
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fafafa",
                }}
              >
                {beneVerifyGST?.gstin_details?.state_code}
              </Typography>

              <label htmlFor="Quest" className="text-xs label-container">
                State code
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fafafa",
                }}
              >
                {beneVerifyGST?.gstin_details?.field_visit_conducted}
              </Typography>

              <label htmlFor="Quest" className="text-xs label-container">
                Field visit conducted
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fafafa",
                }}
              >
                {beneVerifyGST?.gstin_details?.state_jurisdiction}
              </Typography>

              <label htmlFor="Quest" className="text-xs label-container">
                State jurisdiction
              </label>
            </div>
            <div
              className="p-2 flex flex-col gap-0 justify-end items-start text-white mb-2"
              style={{ paddingLeft: "25px" }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  color: "#fafafa",
                }}
              >
                {beneVerifyGST?.gstin_details?.taxpayer_type}
              </Typography>

              <label htmlFor="Quest" className="text-xs label-container">
                Taxpayer type
              </label>
            </div>
          </div>
        </Drawer>
      </div>
    );
  };

  const addSpacesToNumber = (number) => {
    return number.replace(/(.{4})/g, "$1 ").trim();
  };

  const navigate = useNavigate();
  const {
    unachivedLoadingPan,
    achivedLoadingPan,
    unachivedDataPan,
    achivedDataPan,
  } = useSelector((state) => state.verify);

  let haveAcheived = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "archived";
  });
  let haveApproved = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });
  let haveDennied = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "denied";
  });
  let havePending = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending";
  });
  let haveBlock = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "blocked";
  });
  let haveRejected = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "rejected";
  });

  useEffect(() => {
    let haveApprovedLinked = beneLookData?.beneficiary_accounts?.filter(
      (val) => {
        return val?.id === previousId;
      }
    );

    let haveApprovedNotLinked = beneLookData?.beneficiary_accounts?.filter(
      (val) => {
        return val?.id !== previousId;
      }
    );

    if (haveApprovedLinked && haveApprovedNotLinked) {
      const newData = [...haveApprovedLinked, ...haveApprovedNotLinked];
      setIsApprovedState(newData);
    }
  }, [beneLookData]);

  const handleBlur = () => {
    if (panNumber?.length === 0) {
      setIsFocus(false);
    }
  };

  const handleFocus = () => {
    setIsFocus(true);
  };

  useEffect(() => {
    beneLookData?.beneficiary_accounts?.find((val) => {
      if (val?.approval_status === "approved") {
        if (val?.id === previousId) {
          setPreviousBankNo(val?.bank_acc_no);
        }
      }
    });
  }, [beneLookData]);

  useEffect(() => {
    if (!achivedLoadingPan) {
      if (achivedDataPan?.status === true) {
        dispatch(checkUsertoken());
        dispatch(beneLookUp({ pan_number: panNumber }));
        toast.success("Account archived successfully", toast_position);
        dispatch(resetPanArchived());
      } else if (achivedDataPan?.status === false) {
        if (achivedDataPan?.errors?.user_unauthorized) {
          navigate("/feature_not_assigned");
        } else {
          toast.error(
            Object.values(achivedDataPan?.errors)[0][0],
            toast_position
          );
          dispatch(resetPanArchived());
        }
      }
    }
  }, [achivedDataPan]);

  useEffect(() => {
    if (!unachivedLoadingPan) {
      if (unachivedDataPan?.status === true) {
        dispatch(checkUsertoken());
        dispatch(beneLookUp({ pan_number: panNumber }));
        toast.success("Account unarchived successfully", toast_position);

        setAccountType({ type: "", id: "" });
        dispatch(resetPanUnarchived());
      } else if (unachivedDataPan?.status === false) {
        if (unachivedDataPan?.errors?.user_unauthorized) {
          navigate("/feature_not_assigned");
        } else {
          toast.error(
            Object.values(unachivedDataPan?.errors)[0][0],
            toast_position
          );
          dispatch(resetPanUnarchived());
        }
      }
    }
  }, [unachivedDataPan]);

  useEffect(() => {
    if (beneVerifyGST?.gstin_details?.business_name) {
      setPayload({
        ...payload,
        gst_org_name: beneVerifyGST?.gstin_details?.business_name,
      });
    }
  }, [beneVerifyGST]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      event.stopPropagation();
      if (
        associatedAccountRef.current &&
        !associatedAccountRef.current.contains(event.target)
      ) {
        setShowAccInfo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [associatedAccountRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      event.stopPropagation();
      if (
        noAssociatedAccountRef.current &&
        !noAssociatedAccountRef.current.contains(event.target)
      ) {
        setShowNoAccountInfo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [noAssociatedAccountRef]);

  const handleClosePopup = () => {
    setRejectTxnPopup(false);
    setShowRejectAllTxnReson(false);
  };

  return (
    <div className="benePan_Main">
      {showRejectTnxPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
          <RejectingTransactionPopup
            type={"ArchiveAccount"}
            accountId={accountType.id}
            handleClosePopup={handleClosePopup}
            setShowRejectAllTxnReson={setShowRejectAllTxnReson}
            setRejectTxnPopup={setRejectTxnPopup}
            flow={"edit"}
            setIsDennied={setIsDennied}
            setIsPending={setIsPending}
            setIsApproved={setIsApproved}
            setIsArchived={setIsArchived}
            setShutViewAll={setShutViewAll}
          />
        </div>
      )}
      {showRejectAllTxnReson && (
        <div className="fixed top-0 left-0 w-full h-full  flex items-center justify-center z-[9998] bg-opacity-80">
          <BeneDetailModal
            type="Reject-All-Transaction"
            onClose={handleClosePopup}
            id={accountType.id}
            createEdit={"createEdit"}
          />
        </div>
      )}
      {achivedOpenPop && (
        <BeneficiaryPanModal
          close={setAchivedOpenPop}
          id={accountType.id}
          type={"archived"}
          setAccountType={setAccountType}
          accountType={accountType}
          message={"Are you sure you want to archive this account?"}
          note="The account will become inactive."
          panNumber={panNumber}
          setIsArchived={setIsArchived}
          setShutViewAll={setShutViewAll}
          createFlow={"pan"}
          setShowAction={setShowAction}
          message2={"Archive Account"}
          setRejectTxnPopup={setRejectTxnPopup}
          accountId={viewAccountDetailData?.beneficiary_accounts[0]?.id}
        />
      )}
      {unachivedOpenPop && (
        <BeneficiaryPanModal
          close={setUnachivedOpenPop}
          id={accountType.id}
          type={"unarchived"}
          note="The account will become active."
          setAccountType={setAccountType}
          accountType={accountType}
          message={"Are you sure you want to unarchive this account?"}
          panNumber={panNumber}
          setIsArchived={setIsArchived}
          setShutViewAll={setShutViewAll}
          createFlow={"pan"}
          setShowAction={setShowAction}
          message2={"Unarchive Account"}
          setRejectTxnPopup={setRejectTxnPopup}
          accountId={viewAccountDetailData?.beneficiary_accounts[0]?.id}
        />
      )}
      <div>
        {isLinkedAccount || alreadyLinked ? (
          <div className="new_message_text_block_nonarchive">
            <img src={addstatus} alt="info" />
            <p>
              Looks like this PAN is already linked to this account. Please
              change provided PAN details to proceed.
            </p>
          </div>
        ) : nonArchive ? (
          <div className="new_message_text_block_nonarchive">
            <img src={addstatus} alt="info" />
            <p>
              Archiving this account is disabled. As you're currently editing
              this account, archiving cannot be performed.
            </p>
          </div>
        ) : hideBlock ? (
          <div className="new_message_text_block">
            <img src={addstatus} alt="info" />
            <p>To unblock this account, please contact customer support</p>
          </div>
        ) : null}

        <div
          className="dividepan pan_Enter"
          style={{
            backgroundColor:
              verifiedPan && !verifyPANisLoading
                ? "#4cb537"
                : verifiedPan === false && !verifyPANisLoading
                ? "#CE362A"
                : "transparent",
          }}
        >
          <div className="pan_Input">
            <div className="contain_det">
              <p>Beneficiary's PAN Details</p>
              <div
                className="input_Field"
                style={{
                  borderColor:
                    verifiedPan && !verifyPANisLoading
                      ? "#00FF14"
                      : verifiedPan === false &&
                        panNumber?.length === 10 &&
                        !verifyPANisLoading
                      ? "#FF5860"
                      : isFocus
                      ? "#00FFF5"
                      : "#dadada",
                }}
              >
                <label
                  htmlFor="panid"
                  style={{
                    top: isFocus ? "-12px" : "8px",
                    fontSize: isFocus ? "12px" : "16px",
                    color:
                      panNumber?.length > 0 &&
                      verifiedPan === false &&
                      panNumber?.length === 10 &&
                      !verifyPANisLoading
                        ? "#FF5860"
                        : verifiedPan && !verifyPANisLoading
                        ? "#00FF14"
                        : isFocus
                        ? "#00FFF5"
                        : "#dadada",
                  }}
                >
                  PAN number *
                </label>
                {verifiedPan && !verifyPANisLoading ? (
                  <img src={greenCheck} alt="check" />
                ) : verifiedPan === false &&
                  panNumber?.length === 10 &&
                  !verifyPANisLoading ? (
                  <img src={redCheck} alt="check" />
                ) : verifyPANisLoading ? (
                  <img
                    src={inputLoader}
                    style={{ width: "20px", height: "20px" }}
                  />
                ) : null}
                <input
                  value={panNumber}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handlePanChange(e);
                  }}
                  type="text"
                  className="input_Box"
                  spellCheck={false}
                  id="panid"
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          {!verifyPANisLoading && verifiedPan ? (
            <div>
              <div className="pan_gst_name_div">
                <div
                  className="merge_name"
                  style={{ alignItems: "flex-start" }}
                >
                  <img
                    className="w-5 h-5"
                    style={{ marginTop: "2px" }}
                    src={greenCheck}
                    alt="check"
                  />
                  <span className="pan_gst_name">
                    {panDetails?.full_name.toLowerCase()}
                    {""}
                  </span>
                </div>
              </div>
              <div className="pan_gst_span_div">
                <span className="pan_gst_span ml-[2px]">Legal name</span>
              </div>
            </div>
          ) : verifiedPan === false ? (
            <div className="wrong_pan">
              <img
                src={redCheck}
                style={{
                  width: "20px",
                  height: "20px",
                  marginTop: "10px",
                  marginBottom: "20px",
                }}
                alt="redcheck"
              />
              <span style={{ margin: "10px 0px 20px 0px" }}>
                PAN number is invalid
              </span>
            </div>
          ) : null}
        </div>

        <div
          className="dividepan pan_Enter"
          style={{
            display: type == "createFlow" && !verifiedPan ? "none" : "",
          }}
        >
          <div
            className={`${
              GSTStatus && gstVerified
                ? "bg-custom-lightGreen"
                : GSTStatus === false
                ? "bg-custom-red"
                : ""
            } flex flex-col relative rounded-[31px] mt-[30px]`}
          >
            <div className="py-5 h-full bg-[#314C7F] flex flex-col rounded-[30px]">
              <div className=" w-full flex flex-col px-4 md:px-6 lg:px-8 pb-1 gap-5">
                <div className="w-full flex flex-col gap-4">
                  <div className="text-[#DADADA] font-semibold text-base">
                    Beneficiary's GSTIN Details
                  </div>
                  {getGSTInputElementDiv(
                    "gstin",
                    GSTStatus,
                    "GST number (optional)",
                    payload.gstin,
                    20,
                    false
                  )}
                </div>
              </div>
            </div>
            {getSideDraweContent()}

            {verifiedPan === true &&
              GSTStatus &&
              payload?.gstin?.length > 0 && (
                <div>
                  {gstVerified === true ? (
                    <div>
                      <div className="pan_gst_name_div">
                        <div
                          className="merge_name"
                          style={{ alignItems: "flex-start" }}
                        >
                          <img
                            className="w-5 h-5 mt-1"
                            style={{ marginTop: "3px" }}
                            src={greenCheck}
                            alt="check"
                          />
                          <span className="pan_gst_name">
                            {beneVerifyGST?.gstin_details?.business_name.toLowerCase()}
                          </span>
                        </div>
                      </div>
                      <div className="pan_gst_span_div ">
                        <span className="pan_gst_span ml-[2px]">
                          Company name
                        </span>
                      </div>
                    </div>
                  ) : null}

                  {gstVerified === true ? (
                    <div
                      className="rounded-br-[31px] rounded-tl-[32px] bg-[#F9F9F9] flex items-center justify-around mt-[-29px] mr-[-32px]  h-12"
                      style={{
                        position: "relative",
                        left: 340,
                        bottom: 0,
                        width: "132px",
                        height: "47px",
                      }}
                    >
                      <FormControlLabel
                        className=""
                        style={{ color: "#1D3A6D" }}
                        control={
                          <Checkbox
                            icon={
                              <img
                                className="w-3 h-3"
                                src={viewIcon}
                                alt="not-selected"
                              />
                            }
                            checkedIcon={
                              <img
                                className="w-3 h-3"
                                src={viewIcon}
                                alt="selected"
                              />
                            }
                            className="radioBT"
                            onClick={() => setShowDrawer(true)}
                          />
                        }
                        label={
                          <div
                            className="antialiased"
                            style={{ fontSize: "12px" }}
                          >
                            View details
                          </div>
                        }
                        sx={{
                          textAlign: "center",
                          letterSpacing: "0px",
                          color: "#dddddd",
                          ".MuiFormControlLabel-label": {
                            font: "normal normal 600 16px Open Sans",
                          },
                        }}
                        labelPlacement="start"
                      />
                    </div>
                  ) : null}
                </div>
              )}

            {GSTStatus === false && payload?.gstin?.length === 15 && (
              <div>
                {gstVerified === false && (
                  <div className="pb-[10px]">
                    <div className="gst_name_div">
                      <div
                        className="merge_gst_name"
                        style={{ alignItems: "flex-start" }}
                      >
                        <img
                          className="w-5 h-5 mt-1"
                          style={{ marginTop: "3px" }}
                          src={skippedVerification}
                          alt="verified logo"
                        />
                        <span className="gst_name">
                          {!GSTLinked && beneVerifyGST?.status === true
                            ? "GST number is not linked."
                            : "GST number is invalid."}
                        </span>
                      </div>
                    </div>
                    <div className="gst_span_div">
                      <p className="gst_span font-semibold">
                        To proceed either enter a valid GST number associated
                        with the PAN above or clear the field of GST details
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {verifiedPan === true ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {
            <div
              className="dividebank bank_Details"
              style={{
                height: "140px",

                display:
                  !beneLookLoading &&
                  !verifyPANisLoading &&
                  beneLookError === true &&
                  verifiedPan &&
                  panNumber?.length === 10
                    ? ""
                    : "none",
              }}
            >
              <div className="bank-no-assacc-found">
                <p className="bene_top_account">
                  Associated Accounts{""}
                  <img
                    ref={noAssociatedAccountRef}
                    src={InfoDetails}
                    alt="info"
                    onClick={() => {
                      setShowNoAccountInfo(!showNoAccountInfo);
                    }}
                  />
                  {showNoAccountInfo ? (
                    <>
                      <div className="associate-acc-info-container">
                        <p className="associate-acc-info">
                          <p className="associate-panacc-info">
                            Associated accounts include all active (approved,
                            pending) and inactive (rejected, archived, blocked)
                            accounts linked to the provided PAN details. Click
                            on the accounts to manage them, or continue creating
                            account.
                          </p>
                        </p>
                      </div>
                    </>
                  ) : null}
                </p>
                <div className="bank_hidden_accounts">
                  <p>
                    <span>No associated accounts found</span> <br /> There are
                    no existing accounts that are associated with the PAN
                    details provided. You can proceed to create a new account.
                  </p>
                </div>
              </div>
            </div>
          }

          <div
            className="crt-pan_new_crt_acc_bottom"
            style={{
              display: beneLookData?.status ? "flex" : "none",
              marginBottom: "20px",
              marginLeft: "20px",
            }}
          >
            <div style={{ flex: "80%", padding: "0px 10px 0px 0px" }}>
              <div className="crt-Confirm-info">
                Confirm to continue account creation
              </div>
              <div className="h-3"></div>
              <div className="crt-Confirm-sub-info">
                Confirm and click next to proceed. Note that creating a new
                account adds it to the active accounts for the provided PAN.
              </div>
            </div>
            <div style={{ flex: "20%", alignContent: "center" }}>
              <Button
                className="bank_new_create_btn"
                style={{
                  color: lookupNext && GSTStatus ? "#fefefe" : "#FFFFFF",
                  background:
                    lookupNext && (GSTStatus == null || GSTStatus)
                      ? "#7185a8 0% 0% no-repeat padding-box"
                      : "#1D3A6D 0% 0% no-repeat padding-box",
                  opacity: isButtonDisabled ? 0.4 : 1,
                  borderRadius: "20px",
                }}
                disabled={
                  isGSTLoading
                    ? true
                    : !GSTStatus && GSTStatus != null
                    ? true
                    : isButtonDisabled
                }
                onClick={() => {
                  setIsApproved(false);
                  setLookupNext(true);
                  setIsArchived(false);
                  setIsDennied(false);
                  setIsPending(false);
                  setDisableCreateNext(false);
                  setHideBlock(false);
                  if (lookupNext) {
                    setHideCreate(false);
                    handleDeSelectConfirm();
                  } else {
                    setHideCreate(true);
                  }
                  setAccountType({ type: "", id: "" });
                  setDisableCreateNext(true);
                }}
              >
                <span style={{ paddingRight: "2px" }}>
                  <img
                    src={
                      lookupNext && (GSTStatus == null || GSTStatus)
                        ? selected
                        : not_selected
                    }
                    alt="btn-icon"
                  />
                </span>
                <span
                  className="confirm-btn-style"
                  style={{ paddingLeft: "2px" }}
                >
                  Confirm
                </span>
              </Button>
            </div>
          </div>

          <div
            className="dividepan pan_Details"
            style={{
              visibility:
                beneLookData?.status && panNumber?.length === 10
                  ? "visible"
                  : "hidden",
            }}
          >
            <div className="ass_top">
              <p className="bene_top_account">
                Associated Accounts{" "}
                <img
                  ref={associatedAccountRef}
                  src={InfoDetails}
                  alt="info"
                  onClick={() => {
                    setNewMessage(!newMessage);
                    setShowAccInfo(!showAccInfo);

                    if (
                      !lookupNext &&
                      accountType.type !== "blocked" &&
                      previousId !== accountType.id
                    ) {
                      setNewMessage(!newMessage);
                    } else if (lookupNext) {
                      setHideCreate(!hideCreate);
                    } else if (accountType.type === "blocked") {
                      setHideBlock(!hideBlock);
                    }
                  }}
                />
                {showAccInfo ? (
                  <>
                    <div className="associate-panacc-info-container">
                      <p className="associate-panacc-info">
                        Associated accounts include all active (approved,
                        pending) and inactive (rejected, archived, blocked)
                        accounts linked to the provided PAN details. Click on
                        the accounts to manage them, or continue creating
                        account.
                      </p>
                    </div>
                  </>
                ) : null}
              </p>

              <p
                className="px-4 ml-[15px] text-[#F9F9F9] text-xs"
                style={{ marginBottom: "20px" }}
              >
                The following existing accounts are associated with the PAN
                provided:
              </p>
              <div className="swap_butt">
                <Button
                  className="swap_butt_1"
                  onClick={() => {
                    setIsActive(false);
                    setIsDennied(false);
                    setIsApproved(false);
                    setIsArchived(false);
                    setIsPending(false);
                    setNonArchive(false);
                    setIsLinkedAccount(false);
                    setShowAccInfo(false);
                    setAccountType({
                      type: "",
                      id: "",
                    });
                    setHideBlock(false);
                  }}
                  style={{
                    backgroundColor: !isActive ? "#ffffff" : "#242424",
                    color: !isActive ? "#1d1d1d" : "#f9f9f9",
                  }}
                >
                  Active
                </Button>
                <Button
                  className="swap_butt_2"
                  onClick={() => {
                    setIsActive(true);
                    setIsDennied(false);
                    setIsApproved(false);
                    setIsArchived(false);
                    setIsPending(false);
                    setNonArchive(false);
                    setIsLinkedAccount(false);
                    setShowAccInfo(false);
                    setAccountType({
                      type: "",
                      id: "",
                    });
                  }}
                  style={{
                    backgroundColor: isActive ? "#ffffff" : "#242424",
                    color: isActive ? "#1d1d1d" : "#f9f9f9",
                  }}
                >
                  Inactive
                </Button>
              </div>
              {isActive ? (
                <div
                  className="scroll_All_approval"
                  style={{ height: type === "createFlow" ? "250px" : "305px" }}
                >
                  {haveAcheived || haveDennied || haveBlock || haveRejected ? (
                    <>
                      {haveBlock && (
                        <div className="achived_main">
                          <p style={{ whiteSpace: "nowrap" }}>
                            Blocked accounts
                          </p>
                          <div className="achived_scroll">
                            {isApprovedState?.map((val, i) => {
                              if (val?.approval_status === "blocked") {
                                return (
                                  <Button
                                    style={{
                                      backgroundColor:
                                        accountType.id == val?.id
                                          ? "#7185A8"
                                          : "#1d3a6d",
                                    }}
                                    className="child_scroll"
                                    key={i}
                                    onClick={() => {
                                      setIsLinkedAccount(false);
                                      setLookupNext(false);
                                      setIsDennied(false);
                                      setIsPending(false);
                                      setIsApproved(false);
                                      setIsArchived(false);
                                      setHideCreate(false);
                                      setHideBlock(!hideBlock);
                                      if (accountType.type === "blocked") {
                                        setHideBlock(false);
                                      } else {
                                        setHideBlock(!hideBlock);
                                      }
                                      setNonArchive(false);

                                      if (val?.id === accountType.id) {
                                        setAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setNonArchive(false);
                                      } else {
                                        setAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                      }
                                    }}
                                  >
                                    <div className="bank_name">
                                      <img
                                        src={require("../../../../assets/IfscLogo/" +
                                          IfscCodeMapper(val?.ifsc_code))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="client_name">
                                      <p
                                        className="name_client2"
                                        style={{ color: "#FF5860" }}
                                      >
                                        {val?.name.toLowerCase()}
                                      </p>
                                      <p className="bank_client2">
                                        {formatAccountNumber(val?.bank_acc_no)}
                                        <span className="bank_no">
                                          - Bank account number
                                        </span>
                                      </p>
                                    </div>
                                    <div className="viewProfile">
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigate(
                                            `/accounts/beneficiary_account/details/${val?.id}`
                                          );
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                      {(haveRejected || haveDennied) && (
                        <div className="achived_main">
                          <p style={{ whiteSpace: "nowrap" }}>
                            Rejected accounts
                          </p>
                          <div className="achived_scroll">
                            {isApprovedState?.map((val, i) => {
                              if (
                                val?.approval_status === "rejected" ||
                                val?.approval_status === "denied"
                              ) {
                                return (
                                  <Button
                                    style={{
                                      backgroundColor:
                                        accountType.id == val?.id
                                          ? "#7185A8"
                                          : "#1d3a6d",
                                    }}
                                    className="child_scroll"
                                    key={i}
                                    onClick={() => {
                                      setIsLinkedAccount(false);
                                      if (val?.id === previousId) {
                                        setHaveLinkedDisabled(true);
                                      } else {
                                        setHaveLinkedDisabled(false);
                                      }
                                      setShutViewAll(true);
                                      setIsArchived(false);
                                      setIsDennied(false);
                                      setIsPending(false);
                                      setLookupNext(false);
                                      setHideBlock(false);
                                      setHideCreate(false);
                                      setDisableCreateNext(false);
                                      setNonArchive(false);
                                      if (val?.id === accountType.id) {
                                        setAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setNonArchive(false);
                                        setIsApproved(false);
                                      } else {
                                        setAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                        setIsApproved(true);
                                      }
                                      BenEnableNextButton(true);
                                    }}
                                  >
                                    <div className="bank_name">
                                      <img
                                        src={require("../../../../assets/IfscLogo/" +
                                          IfscCodeMapper(val?.ifsc_code))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="client_name">
                                      <p
                                        className="name_client2"
                                        style={{ color: "#FF5860" }}
                                      >
                                        {val?.name.toLowerCase()}
                                      </p>
                                      <p className="bank_client2">
                                        {formatAccountNumber(val?.bank_acc_no)}
                                        <span className="bank_no">
                                          - Bank account number
                                        </span>
                                      </p>
                                    </div>
                                    <div className="viewProfile">
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigate(
                                            `/accounts/beneficiary_account/details/${val?.id}`
                                          );
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                      {haveAcheived && (
                        <div className="achived_main">
                          <p>Archived accounts</p>
                          <div className="achived_scroll">
                            {isApprovedState?.map((val, i) => {
                              if (val?.approval_status === "archived") {
                                return (
                                  <Button
                                    className="child_scroll"
                                    style={{
                                      backgroundColor:
                                        accountType.id == val?.id
                                          ? "#7185A8"
                                          : "#1d3a6d",
                                    }}
                                    key={i}
                                    id={val?.id}
                                    onClick={() => {
                                      setIsLinkedAccount(false);
                                      setLookupNext(false);
                                      setDisableCreateNext(false);
                                      setIsDennied(false);
                                      setIsPending(false);
                                      setIsApproved(false);
                                      setHideBlock(false);
                                      setHideCreate(false);
                                      setHaveLinkedDisabled(false);
                                      setNonArchive(false);
                                      if (val?.id === accountType.id) {
                                        setAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setIsArchived(false);
                                        setNonArchive(false);
                                      } else {
                                        setAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                        setIsArchived(true);
                                      }
                                      BenEnableNextButton(true);
                                    }}
                                  >
                                    <div className="bank_name">
                                      <img
                                        src={require("../../../../assets/IfscLogo/" +
                                          IfscCodeMapper(val?.ifsc_code))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="client_name">
                                      <p className="name_client2">
                                        {val?.name.toLowerCase()}
                                      </p>
                                      <p className="bank_client2">
                                        {formatAccountNumber(val?.bank_acc_no)}
                                        <span className="bank_no">
                                          - Bank account number
                                        </span>
                                      </p>
                                    </div>
                                    <div className="viewProfile">
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigate(
                                            `/accounts/beneficiary_account/details/${val?.id}`
                                          );
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="hidden_accounts">
                      <p>
                        <span>No inactive associated accounts found</span>{" "}
                        <br /> There are no existing inactive accounts that are
                        associated with the PAN provided.
                      </p>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  className="scroll_All_approval"
                  style={{ height: type === "createFlow" ? "250px" : "305px" }}
                >
                  {haveApproved || havePending ? (
                    <>
                      {haveApproved && (
                        <div className="achived_main">
                          <p style={{ whiteSpace: "nowrap" }}>
                            Approved accounts
                          </p>
                          <div className="achived_scroll">
                            {isApprovedState?.map((val, i) => {
                              if (val?.approval_status === "approved") {
                                return (
                                  <Button
                                    style={{
                                      backgroundColor:
                                        accountType.id == val?.id
                                          ? "#7185A8"
                                          : "#1d3a6d",
                                      opacity: "1",
                                    }}
                                    className="child_scroll"
                                    key={i}
                                    onClick={() => {
                                      setIsLinkedAccount(false);
                                      if (val?.id === previousId) {
                                        setHaveLinkedDisabled(true);
                                      } else {
                                        setHaveLinkedDisabled(false);
                                      }
                                      setShutViewAll(true);
                                      setIsArchived(false);
                                      setIsDennied(false);
                                      setIsPending(false);
                                      setLookupNext(false);
                                      setHideBlock(false);
                                      setHideCreate(false);
                                      setDisableCreateNext(false);
                                      setNonArchive(false);
                                      if (val?.id === accountType.id) {
                                        setAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setNonArchive(false);
                                        setIsApproved(false);
                                      } else {
                                        setAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                        setIsApproved(true);
                                      }
                                      BenEnableNextButton(true);
                                    }}
                                  >
                                    <div className="bank_name">
                                      <img
                                        src={require("../../../../assets/IfscLogo/" +
                                          IfscCodeMapper(val?.ifsc_code))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="client_name">
                                      <p
                                        className="name_client2"
                                        style={{
                                          color: "#4DDD37",
                                        }}
                                      >
                                        {val?.name.toLowerCase()}
                                      </p>
                                      <p className="bank_client2">
                                        {formatAccountNumber(val?.bank_acc_no)}
                                        <span className="bank_no">
                                          - Bank account number
                                        </span>
                                      </p>
                                    </div>
                                    <div className="viewProfile">
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigate(
                                            `/accounts/beneficiary_account/details/${val?.id}`
                                          );
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                      {havePending && (
                        <div className="achived_main">
                          <p style={{ whiteSpace: "nowrap" }}>
                            Pending accounts
                          </p>
                          <div className="achived_scroll">
                            {isApprovedState?.map((val, i) => {
                              if (val?.approval_status === "pending") {
                                return (
                                  <Button
                                    style={{
                                      backgroundColor:
                                        accountType.id == val?.id
                                          ? "#7185A8"
                                          : "#1d3a6d",
                                      opacity: "1",
                                    }}
                                    className="child_scroll"
                                    key={i}
                                    onClick={() => {
                                      setIsLinkedAccount(false);
                                      if (val?.id === previousId) {
                                        setHaveLinkedDisabled(true);
                                      } else {
                                        setHaveLinkedDisabled(false);
                                      }
                                      setShutViewAll(true);
                                      setIsDennied(false);
                                      setIsApproved(false);
                                      setIsArchived(false);
                                      setLookupNext(false);
                                      setDisableCreateNext(false);
                                      setHideBlock(false);
                                      setHideCreate(false);
                                      setNonArchive(false);
                                      if (val?.id === accountType.id) {
                                        setAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setIsPending(false);
                                        setNonArchive(false);
                                      } else {
                                        setAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                        setIsPending(true);
                                      }
                                      BenEnableNextButton(true);
                                    }}
                                  >
                                    <div className="bank_name">
                                      <img
                                        src={require("../../../../assets/IfscLogo/" +
                                          IfscCodeMapper(val?.ifsc_code))}
                                        alt="bank"
                                      />
                                    </div>
                                    <div className="client_name">
                                      <p
                                        className="name_client2"
                                        style={{ color: "#00FFF5" }}
                                      >
                                        {val?.name.toLowerCase()}
                                      </p>
                                      <p className="bank_client2">
                                        {formatAccountNumber(val?.bank_acc_no)}
                                        <span className="bank_no">
                                          - Bank account number
                                        </span>
                                      </p>
                                    </div>
                                    <div className="viewProfile">
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigate(
                                            `/accounts/beneficiary_account/details/${val?.id}`
                                          );
                                        }}
                                      >
                                        <img
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="hidden_accounts">
                      <p>
                        <span>No active associated accounts found</span> <br />{" "}
                        There are no existing active accounts that are
                        associated with the PAN provided.
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
            {type === "createFlow" ? (
              <>
                <div
                  className="crt-ass_bottom"
                  style={{
                    paddingBottom:
                      isAchived || isPending || isApproved || isDennied
                        ? ""
                        : "",
                    display:
                      isAchived ||
                      ((isApproved || isDennied || isPending) && shutViewAll)
                        ? "block"
                        : "none",
                  }}
                >
                  <p>Account action</p>
                  {isAchived ? (
                    <Button
                      className="ass_next"
                      onClick={() => {
                        if (accountType.type === "archived") {
                          setUnachivedOpenPop(true);
                        }
                      }}
                    >
                      <img src={unarchiveImg} alt="unarc" /> Unarchive account
                    </Button>
                  ) : (isApproved || isDennied || isPending) && shutViewAll ? (
                    <Button
                      className="ass_arc"
                      style={{
                        color: "#fefefe",
                        background: "#63ba74 0% 0% no-repeat padding-box",
                      }}
                      onClick={() => {
                        setAchivedOpenPop(!achivedOpenPop);
                      }}
                    >
                      <img src={archiveImg} alt="arc" />
                      Archive Account
                    </Button>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : (
        <div
          className="dividepan pan_Details"
          style={{
            visibility: "hidden",
          }}
        ></div>
      )}
    </div>
  );
};
export default CreateBeneAccPanDetails;
