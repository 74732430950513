import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import NavXpentraLogo from "../../../assets/landingPage/NavXpentraLogo.svg";
import NavXpentraLogoMob from "../../../assets/landingPage/NavXpentraLogoMob.svg";
import FooterXpentraLogo from "../../../assets/landingPage/FooterXpentraLogo.svg";
import LinkdIn from "../../../assets/landingPage/LinkedIn.svg";
import ArrowRight from "../../../assets/landingPage/Arrowright.svg";
import ArrowRightWhite from "../../../assets/landingPage/HoverArrowRight.svg";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import ISO2700 from "../../../assets/landingPage/ISO27001.png";
import ISO9001 from "../../../assets/landingPage/ISO9001.png";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (route) => location.pathname === route;
  const currentYear = new Date().getFullYear();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const isBookDemoPage = location.pathname === "/book_demo";
  const [demoArrow, setDemoArrow] = useState(
    isBookDemoPage ? ArrowRight : ArrowRightWhite
  );

  const heightClasses = {
    "/contact_us": "h-[71vh]",
    "/terms_conditions": "h-[75vh]",
    "/book_demo": "h-[60vh]",
  };

  const heightClass = heightClasses[location.pathname] || "h-auto";

  useEffect(() => {
    setDemoArrow(
      isBookDemoPage
        ? ArrowRight
        : ArrowRightWhite || !isBookDemoPage
        ? ArrowRight
        : ArrowRightWhite
    );
  }, [isBookDemoPage]);

  const showToast = () => {
    toast.info("Feature coming soon!", toast_position);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <>
      <div>
        {isMobile ? (
          <>
            <div className="bg-[#031F4F]">
              <div className="flex flex-col justify-center pb-5 bg-[#031F4F]">
                <div className="mx-auto py-5">
                  <img src={FooterXpentraLogo} alt="loader-new" />
                </div>
                <div className="text-[#F9F9F9]">
                  <p className="text-[24px] text-center font-semibold pb-2.5">
                    See Xpentra in action
                  </p>
                  <p className="text-[12px] text-center">
                    Request a personalised demo to explore how it can <br />{" "}
                    streamline your operations!
                  </p>
                </div>
                <div className="mt-[20px] mx-auto">
                  <div
                    onClick={() => {
                      if (!isBookDemoPage) {
                        navigate("/book_demo");
                      }
                    }}
                    onMouseEnter={() => {
                      if (!isBookDemoPage) {
                        setDemoArrow(ArrowRightWhite);
                      }
                    }}
                    onMouseLeave={() => {
                      if (!isBookDemoPage) {
                        setDemoArrow(ArrowRight);
                      }
                    }}
                    className={`cursor-pointer rounded-[5px] w-[215px] text-center text-[12px] font-semibold py-[10px] flex gap-2.5 items-center justify-center text-[#1D3A6D] bg-[#F9F9F9] hover:bg-[#506994] hover:text-[#F9F9F9]   ${
                      isBookDemoPage
                        ? "text-[#1D3A6D] bg-[#707070] hover:bg-[#707070] hover:text-[#1D3A6D] cursor-not-allowed"
                        : "text-[#1D3A6D] bg-[#F9F9F9] hover:bg-[#506994] hover:text-[#F9F9F9]"
                    }`}
                  >
                    Click here to request a demo
                    <img
                      className="h-[12px]"
                      src={demoArrow}
                      alt="arrow-right-white"
                    />
                  </div>
                </div>
              </div>
              <div className="border-t-[1px] border-t-[#dadada] mx-[30px] py-[30px] flex flex-col gap-[30px]">
                <div className="flex flex-col gap-4 items-center">
                  <div>
                    <img src={NavXpentraLogoMob} alt="logo" />
                  </div>
                  <div
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/xpentra",
                        "_blank"
                      )
                    }
                    className="cursor-pointer"
                  >
                    <img src={LinkdIn} alt="linkdin" />
                  </div>
                </div>
                <div className="text-[12px] text-[#F9F9F9] font-normal text-center flex flex-col gap-3">
                  <div
                    className={`inline-block px-2 ${
                      isActive("/") ? "border-b-[#31E5E5] text-[#31E5E5]" : ""
                    }`}
                  >
                    <span onClick={() => navigate("/")}>Home</span>
                  </div>
                  <div
                    className={`inline-block px-2 py-1 
                    ${
                      isActive("/bank_statement") ||
                      isActive("/view_all_balances")
                        ? "border-b-[#31E5E5] text-[#31E5E5]"
                        : ""
                    }`}
                  >
                    <span
                      className={`${
                        isActive("/bank_statement") ||
                        isActive("/view_all_balances")
                          ? "border-b-[#31E5E5] text-[#31E5E5]"
                          : ""
                      }`}
                      onClick={() => navigate("/bank_statement")}
                    >
                      Product
                    </span>
                  </div>
                  <div
                    className={`inline-block px-2 py-1 ${
                      isActive("/aboutus") || isActive("/advisors")
                        ? "border-b-[#31E5E5] text-[#31E5E5]"
                        : ""
                    }`}
                  >
                    <span
                      className={`${
                        isActive("/aboutus") || isActive("/advisors")
                          ? "border-b-[#31E5E5] text-[#31E5E5]"
                          : ""
                      }`}
                      onClick={() => navigate("/aboutus")}
                    >
                      About Us
                    </span>
                  </div>
                  <div
                    className={`inline-block px-2 py-1 ${
                      isActive("/pricing")
                        ? "border-b-[#31E5E5] text-[#31E5E5]"
                        : ""
                    }`}
                  >
                    <span onClick={() => navigate("/pricing")}>Pricing</span>
                  </div>
                  <div
                    className={`inline-block px-2 py-1 ${
                      isActive("/contactus")
                        ? "border-b-[#31E5E5] text-[#31E5E5]"
                        : ""
                    }`}
                  >
                    <span onClick={() => navigate("/contactus")}>
                      Contact Us
                    </span>
                  </div>
                  <div
                    className={`inline-block px-2 py-1 ${
                      isActive("/faq")
                        ? "border-b-[#31E5E5] text-[#31E5E5]"
                        : ""
                    }`}
                  >
                    <span
                      onClick={() => {
                        showToast();
                      }}
                    >
                      FAQ
                    </span>{" "}
                  </div>
                </div>
                <div className="flex flex-col  items-center text-[#F9F9F9] text-[12px]">
                  <div className="flex items-center gap-4 h-[30px]">
                    <div className="w-[30px]">
                      <a
                        id="fC4seLa5xl"
                        href="https://www.entrust.net/customer/profile.cfm?domain=q2pay.in&amp;lang=en"
                        target="_blank"
                      >
                        <img
                          src="https://seal.entrust.net/en/current/entrust_site_seal_small.png"
                          alt="Secured By Entrust, SSL (Secure Sockets Layer). Verify"
                        />
                      </a>
                    </div>
                    <div className="w-[30px] py-5">
                      <img src={ISO9001} alt="ISO9001" />
                    </div>
                    <div className="w-[30px] py-5">
                      <img src={ISO2700} alt="ISO2700" />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center gap-2.5 text-center text-[#F9F9F9] text-[12px]">
                  <div
                    className="cursor-pointer w-max border-b border-current hover:border-[#31E5E5] hover:text-[#31E5E5]"
                    onClick={() => navigate("/terms_conditions")}
                  >
                    Terms and Conditions
                  </div>
                  <div
                    className="cursor-pointer w-max border-b border-current hover:border-[#31E5E5] hover:text-[#31E5E5]"
                    onClick={() => navigate("/privacy_policy")}
                  >
                    Privacy Policy
                  </div>
                  <span>
                    © Copyright {currentYear} Q2Pay Technologies Pvt Ltd. All
                    rights reserved
                  </span>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={`bg-[#031F4F] ${heightClass}`}>
            <div className="max-w-[1024px] mx-auto pt-[30px] flex flex-col gap-4">
              <div className="flex flex-col justify-center pb-8">
                <div className="mx-auto">
                  <img src={FooterXpentraLogo} alt="loader-new" />
                </div>
                <div className="text-[#F9F9F9] mb-[30px]">
                  <p className="text-[24px] text-center font-semibold mb-2.5">
                    See Xpentra in action
                  </p>
                  <p className="text-[12px] text-center">
                    Request a personalised demo to explore how it can <br />
                    streamline your operations!
                  </p>
                </div>
                <div className="mx-auto">
                  <div
                    onClick={() => {
                      if (!isBookDemoPage) {
                        navigate("/book_demo");
                      }
                    }}
                    onMouseEnter={() => {
                      if (!isBookDemoPage) {
                        setDemoArrow(ArrowRightWhite);
                      }
                    }}
                    onMouseLeave={() => {
                      if (!isBookDemoPage) {
                        setDemoArrow(ArrowRight);
                      }
                    }}
                    className={`cursor-pointer rounded-[5px] w-[215px] text-center text-[12px] font-semibold py-[10px] flex gap-2.5 items-center justify-center 
          ${
            isBookDemoPage
              ? "text-[#1D3A6D] bg-[#707070] cursor-not-allowed"
              : "text-[#1D3A6D] bg-[#F9F9F9] hover:bg-[#506994] hover:text-[#F9F9F9]"
          }
        `}
                  >
                    Click here to request a demo
                    <img
                      className="h-[12px]"
                      src={demoArrow}
                      alt="arrow-right-white"
                    />
                  </div>
                </div>
              </div>

              <div className="flex justify-between">
                <div className="flex flex-col gap-4">
                  <div>
                    <img
                      src={NavXpentraLogo}
                      alt="NavXpentraLogo"
                      className="ml-[-8px]"
                    />
                  </div>
                  <div className="flex gap-2.5 ">
                    <div
                      onClick={() =>
                        window.open(
                          "https://www.linkedin.com/company/xpentra",
                          "_blank"
                        )
                      }
                      className="cursor-pointer"
                    >
                      <img src={LinkdIn} alt="linkedin" />
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-[15px] text-[#F9F9F9] text-[12px] pt-2">
                  <div className="flex justify-start gap-[50px]">
                    <div className={`w-[75px]`}>
                      <span
                        className={`hover:text-[#31E5E5] cursor-pointer ${
                          isActive("/")
                            ? "text-[#31E5E5] border-b-[#31E5E5] border-b border-current"
                            : ""
                        }`}
                        onClick={() => navigate("/")}
                      >
                        Home
                      </span>
                    </div>
                    <div className={`w-[75px]`}>
                      <span
                        className={`hover:text-[#31E5E5] cursor-pointer ${
                          isActive("/bank_statement") ||
                          isActive("/view_all_balances")
                            ? "border-b-[#31E5E5] text-[#31E5E5] border-b border-current"
                            : ""
                        }`}
                        onClick={() => navigate("/bank_statement")}
                      >
                        Product
                      </span>
                    </div>

                    <div className={`w-[75px]`}>
                      <span
                        className={`hover:text-[#31E5E5] cursor-pointer ${
                          isActive("/aboutus") || isActive("/advisors")
                            ? "text-[#31E5E5] border-b-[#31E5E5] border-b border-current"
                            : ""
                        }`}
                        onClick={() => navigate("/aboutus")}
                      >
                        About Us
                      </span>
                    </div>
                    {/* <div className={`w-[75px]`}>
                      <span
                        className={`hover:text-[#31E5E5] cursor-pointer ${
                          isActive("/pricing")
                            ? "text-[#31E5E5] border-b-[#31E5E5] border-b border-current"
                            : ""
                        }`}
                        onClick={() => navigate("/pricing")}
                      >
                        Pricing
                      </span>
                    </div> */}
                  </div>
                  <div className="flex justify-start gap-[50px]">
                    <div className={`w-[75px]`}>
                      <span
                        className={`hover:text-[#31E5E5] cursor-pointer ${
                          isActive("/pricing")
                            ? "text-[#31E5E5] border-b-[#31E5E5] border-b border-current"
                            : ""
                        }`}
                        onClick={() => navigate("/pricing")}
                      >
                        Pricing
                      </span>
                    </div>
                    <div className={`w-[75px]`}>
                      <span
                        className={`hover:text-[#31E5E5] cursor-pointer ${
                          isActive("/contactus")
                            ? "text-[#31E5E5] border-b-[#31E5E5] border-b border-current"
                            : ""
                        }`}
                        onClick={() => navigate("/contactus")}
                      >
                        Contact Us
                      </span>
                    </div>
                    <div className={`w-[75px] `}>
                      <span
                        className={`hover:text-[#31E5E5] cursor-pointer ${
                          isActive("/faq")
                            ? "text-[#31E5E5] border-b-[#31E5E5] border-b border-current"
                            : ""
                        }`}
                        onClick={() => showToast()}
                      >
                        FAQs
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center text-[#F9F9F9] text-[12px] -mt-4">
                <div className="flex gap-2">
                  <div className="w-[30px] py-5">
                    <a
                      id="fC4seLa5xl"
                      href="https://www.entrust.net/customer/profile.cfm?domain=q2pay.in&amp;lang=en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="https://seal.entrust.net/en/current/entrust_site_seal_small.png"
                        alt="Secured By Entrust, SSL (Secure Sockets Layer). Verify"
                      />
                    </a>
                  </div>
                  <div className="w-[30px] py-5">
                    <img src={ISO9001} alt="ISO9001" />
                  </div>
                  <div className="w-[30px] py-5">
                    <img src={ISO2700} alt="ISO2700" />
                  </div>
                </div>

                <div className="flex flex-grow gap-2.5 justify-center">
                  <div
                    className={`border-b border-current cursor-pointer ${
                      isActive("/terms_conditions")
                        ? "text-[#31E5E5] border-b-[#31E5E5]"
                        : "hover:text-[#31E5E5] hover:border-[#31E5E5]"
                    }`}
                    onClick={() => navigate("/terms_conditions")}
                  >
                    Terms and Conditions
                  </div>
                  <div
                    className={`border-b border-current cursor-pointer ${
                      isActive("/privacy_policy")
                        ? "text-[#31E5E5] border-b-[#31E5E5]"
                        : "hover:text-[#31E5E5] hover:border-[#31E5E5]"
                    }`}
                    onClick={() => navigate("/privacy_policy")}
                  >
                    Privacy Policy
                  </div>
                  <span>
                    © Copyright {currentYear} Q2Pay Technologies Pvt Ltd. All
                    rights reserved
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Footer;
