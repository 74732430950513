import React, { useEffect } from "react";
import "./index.css";
import settingImg from "../../../assets/Graphics/User settings.svg";
import user from "../../../assets/UserProfileIcons/Users.svg";
import arrowRight from "../../../assets/ArrowsIcons/arrow_right.svg";
import statistics from "../../../assets/Settings/Dashboard/statistics.svg";
import din from "../../../assets/VerificationIcons/din_verification.svg";
import cin from "../../../assets/VerificationIcons/cin_verification.svg";
import ifsc from "../../../assets/IfscIcons/Ifsc.svg";
import pan from "../../../assets/VerificationIcons/pan_verification.svg";
import test_matrix from "../../../assets/GeneralIcons/payment_matrix.svg";
import gst from "../../../assets/VerificationIcons/gst_verification.svg";
import device from "../../../assets/GeneralIcons/devices_with_white_circle.svg";
import notification from "../../../assets/GeneralIcons/notification.svg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toast_position } from "../../../redux/features/Settings/Authorization";
import { useDispatch, useSelector } from "react-redux";
import { checkUsertoken } from "../../../redux/features/login/login";
import {
  resetGetCinData,
  resetGetDinData,
  resetGetPanData,
} from "../../../redux/features/verifyPAN/verifyPAN";

const SettingsDashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetGetCinData());
    dispatch(resetGetDinData());
    dispatch(resetGetPanData());
  }, []);
  const navigate = useNavigate();
  const services = useSelector((state) => state.refreshToken.services);

  const handleNavigate = (type) => {
    if (type === "Users") {
      // navigate("/settings/Users");
      if (services && services.includes("Admin")) {
        if (!services.includes("MimicEntity")) {
          dispatch(checkUsertoken());
          navigate("/feature_not_assigned");
        } else {
          dispatch(checkUsertoken());
          navigate("/settings/Users");
        }
      } else {
        if (services && !services.includes("VEmp")) {
          dispatch(checkUsertoken());
          navigate("/feature_not_assigned");
        } else {
          dispatch(checkUsertoken());
          navigate("/settings/Users");
        }
      }
    } else if (type === "IFSC") {
      if (!services?.includes("VIFSC")) {
        dispatch(checkUsertoken());
        navigate("/feature_not_assigned");
      } else {
        dispatch(checkUsertoken());
        navigate("/settings/IFSC");
      }
    } else if (type === "GST") {
      if (!services?.includes("VGSTIN")) {
        dispatch(checkUsertoken());
        navigate("/feature_not_assigned");
      } else {
        dispatch(checkUsertoken());
        navigate("/settings/GST_Verification");
      }
    } else if (type === "PAN") {
      if (!services?.includes("VPAN")) {
        dispatch(checkUsertoken());
        navigate("/feature_not_assigned");
      } else {
        dispatch(checkUsertoken());
        navigate("/settings/PAN_Verification");
      }
    } else if (type === "DIN") {
      if (!services?.includes("VDIN")) {
        dispatch(checkUsertoken());
        navigate("/feature_not_assigned");
      } else {
        dispatch(checkUsertoken());
        navigate("/settings/DIN_Verification");
      }
    } else if (type === "CIN") {
      if (!services?.includes("VCIN")) {
        dispatch(checkUsertoken());
        navigate("/feature_not_assigned");
      } else {
        dispatch(checkUsertoken());
        navigate("/settings/CIN_Verification");
      }
    }
  };

  return (
    <div className="setting_top_main_div">
      <div className="setting_main_1024_div">
        <div className="setting_enterpriseData">
          <h4>ENTERPRISE DATA</h4>
          <div className="inner_setting_enterpriseData">
            <Button
              className="inner_setting_enterpriseData_1"
              style={{ borderBottom: "1px solid #dadada" }}
              onClick={() => {
                toast.info("Coming Soon", toast_position);
              }}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={statistics} />
                <h6>Statistics</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_enterpriseData_1"
              style={{
                opacity:
                  services?.includes("VEmp") ||
                    services?.includes("Admin")
                    ? "1"
                    : "0.5",
                borderBottom: "1px solid #dadada",
                cursor: services?.includes("VEmp") ||
                  services?.includes("Admin") ? "pointer" : "not-allowed"
              }}
              onClick={() => handleNavigate("Users")}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={user} />
                <h6>Users</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_enterpriseData_1"
              style={{ borderBottom: "1px solid #dadada" }}
              onClick={() => {
                toast.info("Coming Soon", toast_position);
              }}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={test_matrix} />
                <h6>Test Matrix</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_enterpriseData_1"
              style={{
                opacity:
                  services?.includes("Sup_VDevice") ||
                    services?.includes("Admin")
                    ? "1"
                    : "0.5",
                borderBottom: "1px solid #dadada",
                cursor: services?.includes("Sup_VDevice") ||
                  services?.includes("Admin") ? "pointer" : "not-allowed"
              }}
              onClick={() => {
                navigate("/settings/Devices");
              }}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={device} />
                <h6>Devices</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_enterpriseData_1"
              onClick={() => {
                toast.info("Coming Soon", toast_position);
              }}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={notification} />
                <h6>Notification Center</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
          </div>
        </div>
        <div className="setting_verification">
          <h4>VERIFICATION</h4>
          <div className="inner_setting_verification">
            <Button
              className="inner_setting_verification_1"
              style={{
                opacity:
                  services?.includes("VCIN") ||
                    services?.includes("Admin")
                    ? "1"
                    : "0.5",
                borderBottom: "1px solid #dadada",
                cursor: services?.includes("VCIN") ||
                  services?.includes("Admin") ? "pointer" : "not-allowed"
              }}
              onClick={() => handleNavigate("CIN")}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={cin} />
                <h6>CIN verification</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_verification_1"
              style={{
                opacity:
                  services?.includes("VDIN") ||
                    services?.includes("Admin")
                    ? "1"
                    : "0.5",
                borderBottom: "1px solid #dadada",
                cursor: services?.includes("VDIN") ||
                  services?.includes("Admin") ? "pointer" : "not-allowed"
              }}
              onClick={() => handleNavigate("DIN")}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={din} />
                <h6>DIN verification</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_verification_1"
              style={{
                opacity:
                  services?.includes("VPAN") ||
                    services?.includes("Admin")
                    ? "1"
                    : "0.5",
                borderBottom: "1px solid #dadada",
                cursor: services?.includes("VPAN") ||
                  services?.includes("Admin") ? "pointer" : "not-allowed"
              }}
              onClick={() => handleNavigate("PAN")}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={pan} />
                <h6>PAN verification</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_verification_1"
              style={{
                opacity:
                  services?.includes("VGSTIN") ||
                    services?.includes("Admin")
                    ? "1"
                    : "0.5",
                borderBottom: "1px solid #dadada",
                cursor: services?.includes("VGSTIN") ||
                  services?.includes("Admin") ? "pointer" : "not-allowed"
              }}
              onClick={() => handleNavigate("GST")}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={gst} />
                <h6>GST verification</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
            <Button
              className="inner_setting_verification_1"
              style={{
                opacity:
                  services?.includes("VIFSC") ||
                    services?.includes("Admin")
                    ? "1"
                    : "0.5",
                cursor: services?.includes("VIFSC") ||
                  services?.includes("Admin") ? "pointer" : "not-allowed"
              }}
              onClick={() => handleNavigate("IFSC")}
            >
              <div className="inner_setting_enterpriseData_1_left">
                <img alt="user" src={ifsc} />
                <h6>IFSC verification</h6>
              </div>
              <img alt="arrowright" src={arrowRight} />
            </Button>
          </div>
        </div>
        <div className="setting_img">
          <img alt="setting" src={settingImg} />
        </div>
      </div>
    </div>
  );
};

export default SettingsDashboard;
