import React from "react";
import "./index.css";
import LoaderIcon from "../../../assets/LoaderIcons/Loader.gif";
import Checkmark from "../../../assets/CheckmarksIcons/select_green.svg";

const BalanceLoader = ({ loader, setAccId, type, ownerDetails }) => {
  if (!loader) {
    setTimeout(() => {
      setAccId("");
    }, 1000);
  }

  return (
    <div>
      {loader ? (
        <img
          src={LoaderIcon}
          className="loader-icon"
          style={{
            width:
              type === "createTxn"
                ? "25px"
                : ownerDetails === "BalanceownerDetails"
                ? "40px"
                : null,
            height:
              type === "createTxn"
                ? "25px"
                : ownerDetails === "BalanceownerDetails"
                ? "40px"
                : type === "owner-dsb"
                ? "18px"
                : null,
          }}
        />
      ) : (
        <img
          src={Checkmark}
          className="checkmark-icon"
          style={{
            marginLeft:
              type === "createTxn"
                ? "0px"
                : ownerDetails === "BalanceownerDetails"
                ? "0px"
                : "4px",
            width:
              type === "createTxn"
                ? "15px"
                : ownerDetails === "BalanceownerDetails"
                ? "40px"
                : "15px",
            height:
              type === "createTxn"
                ? "15px"
                : ownerDetails === "BalanceownerDetails"
                ? "40px"
                : "15px",
          }}
        />
      )}
    </div>
  );
};

export default BalanceLoader;
