import React, { useState, useEffect } from "react";
import "./index.css";
import Footer from "../../../../components/LandingPage/Footer";
import Email from "../../../../assets/landingPage/Email.svg";
import Location from "../../../../assets/landingPage/Location.svg";
import { Tooltip, tooltipClasses } from "@mui/material";
import BackToTop from "../../../../assets/landingPage/TopArrow.svg";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";

const ContactUs = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isVisible, setIsVisible] = useState(false);
  const [scrollPercent, setScrollPercent] = useState(0);
  const addresses = [
    `Mumbai - <span style={{"whiteSpace: nowrap;}}">7<sup>th</sup></span> floor A wing, Mumbai Educational Trust (MET, Bhujbal Knowledge City, opposite Lilavati Hospital, Reclamation, Bandra West, Mumbai, Maharashtra 400050`,
    `Pune - Awfis  <span style={{"whiteSpace: nowrap;}}">4<sup>th</sup></span>  floor S No 52, Baner Business Bay Baner-Sus Road, Behind Audi, Off Mumbai Banglore Highway, Pune, Maharashtra 411021`,
  ];

  const { mobilestatus, webstatus } = useSelector(
    (state) => state.landingpagestate
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20 || document.documentElement.scrollTop > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let scrollTop = window.scrollY;
      let docHeight = document.documentElement.scrollHeight;
      let winHeight = window.innerHeight;
      let scrollPercentt = scrollTop / (docHeight - winHeight);
      let scrollPercentRounded = Math.round(scrollPercentt * 100);
      setScrollPercent(scrollPercentRounded);
    });
  }, [scrollPercent]);

  useEffect(() => {
    window.onload = function () {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 50);
      setScrollPercent(0);
    };
  }, []);

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          size="59px"
          sx={{ color: "#31E5E5" }}
          variant="determinate"
          {...props}
        />
        <Box
          sx={{
            zIndex: 999,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: "text.secondary" }}
          >
            <img
              src={BackToTop}
              alt="back to top"
              width="50"
              style={{ right: "45px", position: "fixed", bottom: "36px" }}
            />
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <div>
      <div>
        {isVisible && (
          <div
            onClick={scrollToTop}
            className="fixed bottom-[25px] right-[40px] cursor-pointer z-20"
          >
            <CircularProgressWithLabel value={scrollPercent} />
          </div>
        )}
        {isMobile ? (
          <div>
            <div
              style={{
                overflowY: mobilestatus && "hidden",
                height: "100vh",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
              }}
              className="bg-[#031f4f]"
            >
              <div className="flex flex-col gap-2.5 text-[#F9F9F9] bg-[#1d3a6d]">
                <div className="flex flex-col justify-center relative mt-[88px] px-4">
                  <div className="text-left">
                    <h2 className="text-[16px] font-semibold pb-2 text-[#F9F9F9]">
                      Get in <span className="text-[#31E5E5]">touch</span>
                    </h2>
                    <p className="text-[#DADADA] text-sm font-semibold">
                      Contact us for a quote or any help if needed.
                    </p>
                  </div>

                  <div className="h-full max-w-[1440px] w-full flex justify-center items-center mx-auto relative">
                    <div className="w-full flex flex-col items-center py-5">
                      <div className="flex flex-col md:flex-row justify-center gap-4">
                        <div className="w-full sm:w-[475px] h-auto sm:h-[150px] flex flex-col items-center justify-center border border-[#31E5E5] rounded-xl text-[#F9F9F9] p-4">
                          <img
                            src={Location}
                            alt="Location"
                            className="w-6 h-6 sm:w-8 sm:h-6 mb-2"
                          />
                          {addresses.map((address, index) => {
                            const [city, ...rest] = address.split(" - ");
                            const formattedCity = city
                              .replace(
                                "Mumbai",
                                `<span style="color: #31E5E5; font-weight: 600;">Mumbai</span>`
                              )
                              .replace(
                                "Pune",
                                `<span style="color: #31E5E5; font-weight: 600;">Pune</span>`
                              );
                            const plainAddress = address.replace(
                              /<\/?[^>]+(>|$)/g,
                              ""
                            );

                            return (
                              <Tooltip
                                key={index}
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      zIndex: "2147483647 !important",
                                    },
                                  },
                                }}
                                title={plainAddress}
                              >
                                <p
                                  className="line-clamp-3 text-center text-xs mb-2"
                                  dangerouslySetInnerHTML={{
                                    __html: `${formattedCity} - ${rest.join(
                                      " - "
                                    )}`,
                                  }}
                                />
                              </Tooltip>
                            );
                          })}
                        </div>
                        <div className="w-full sm:w-[475px] h-auto sm:h-[150px] flex flex-col items-center justify-center border border-[#31E5E5] rounded-xl text-[#F9F9F9] p-4 pt-[-2px]">
                          <img
                            src={Email}
                            alt="Email"
                            className="w-6 h-6 sm:w-6 sm:h-7 mb-2"
                          />
                          <p className="text-xs sm:text-[10px] mb-1 sm:mb-3">
                            <a
                              href="https://mail.google.com/mail/?view=cm&fs=1&to=support@q2pay.in"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="cursor-pointer border-b border-current hover:border-[#31E5E5] hover:text-[#31E5E5]"
                            >
                              support@q2pay.in
                            </a>
                          </p>
                          <p className="text-xs sm:text-[10px] mb-3 sm:mb-5">
                            <a
                              href="https://mail.google.com/mail/?view=cm&fs=1&to=sales@q2pay.in"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="cursor-pointer border-b border-current hover:border-[#31E5E5] hover:text-[#31E5E5]"
                            >
                              sales@q2pay.in
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col justify-center items-center mb-8">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d120680.60678923476!2d72.74717597959366!3d19.05190861741612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3be7c9e5d90b55e5%3A0x5405fcb4888919ab!2sSeventh%20floor%20A%20wing%2C%20Mumbai%20Educational%20Trust%20(MET%2C%20Bhujbal%20Knowledge%20City%2C%20opposite%20Lilavati%20Hospital%2C%20Reclamation%2C%20Bandra%20West%2C%20Mumbai%2C%20Maharashtra%20400050!3m2!1d19.051926899999998!2d72.8295777!5e0!3m2!1sen!2sin!4v1730283969237!5m2!1sen!2sin"
                      width="100%"
                      height="400"
                      className="rounded-lg sm:w-[1024px] sm:h-[500px]"
                      style={{ border: 0 }}
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>

              <Footer />
            </div>
          </div>
        ) : (
          <>
            <div
              style={{
                overflow: webstatus ? "hidden" : "",
                height: "100vh",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
                WebkitOverflowScrolling: "touch",
              }}
            >
              <div className="flex flex-col justify-center items-center relative mt-[88px]">
                <div className="text-center">
                  <h2 className="text-[28px] pb-2 text-[#F9F9F9] font-semibold">
                    Get in <span className="text-[#31E5E5]">touch</span>
                  </h2>
                  <p className="text-[#DADADA] text-xs">
                    Contact us for a quote or any help if needed.
                  </p>
                </div>

                <div className="h-full max-w-[1440px] w-screen flex justify-center mx-auto relative">
                  <div className="w-full flex flex-col items-center py-5">
                    <div className="flex justify-center gap-4">
                      <div className="w-[510px] h-[150px] flex flex-col items-center justify-center border border-[#31E5E5] rounded-xl text-[#F9F9F9] ">
                        <img
                          src={Location}
                          alt="Location"
                          className="w-8 h-6 mb-2"
                        />
                        {addresses.map((address, index) => {
                          const [city, ...rest] = address.split(" - ");
                          const formattedCity = city
                            .replace(
                              "Mumbai",
                              `<span style="color: #31E5E5; font-weight: 600;">Mumbai</span>`
                            )
                            .replace(
                              "Pune",
                              `<span style="color: #31E5E5; font-weight: 600;">Pune</span>`
                            );

                          const plainAddress = address.replace(
                            /<\/?[^>]+(>|$)/g,
                            ""
                          );

                          return (
                            <Tooltip
                              key={index}
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    zIndex: "2147483647 !important",
                                  },
                                },
                              }}
                              title={plainAddress}
                            >
                              <p
                                className="line-clamp-3 truncated-container"
                                dangerouslySetInnerHTML={{
                                  __html: `${formattedCity} - ${rest.join(
                                    " - "
                                  )}`,
                                }}
                              />
                            </Tooltip>
                          );
                        })}
                      </div>

                      <div className="border-r-[1px] border-[#31E5E5]"></div>
                      <div className="w-[475px] h-[150px] flex flex-col items-center justify-center border border-[#31E5E5] rounded-xl text-[#F9F9F9]">
                        <img
                          src={Email}
                          alt="Email"
                          className="w-6 h-7 mb-2 mt-[-15px]"
                        />
                        <p className="text-[10px] mb-1">
                          <a
                            href="https://mail.google.com/mail/?view=cm&fs=1&to=support@q2pay.in"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cursor-pointer border-b border-current hover:border-[#31E5E5] hover:text-[#31E5E5]"
                          >
                            support@q2pay.in
                          </a>
                        </p>
                        <p className="text-[10px] mb-3">
                          <a
                            href="https://mail.google.com/mail/?view=cm&fs=1&to=sales@q2pay.in"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="cursor-pointer border-b border-current hover:border-[#31E5E5] hover:text-[#31E5E5]"
                          >
                            sales@q2pay.in
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col justify-center items-center mb-8">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d120680.60678923476!2d72.74717597959366!3d19.05190861741612!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3be7c9e5d90b55e5%3A0x5405fcb4888919ab!2sSeventh%20floor%20A%20wing%2C%20Mumbai%20Educational%20Trust%20(MET%2C%20Bhujbal%20Knowledge%20City%2C%20opposite%20Lilavati%20Hospital%2C%20Reclamation%2C%20Bandra%20West%2C%20Mumbai%2C%20Maharashtra%20400050!3m2!1d19.051926899999998!2d72.8295777!5e0!3m2!1sen!2sin!4v1730283969237!5m2!1sen!2sin"
                    width="1024"
                    height="400"
                    style={{ border: 0, borderRadius: "10px" }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>

              <Footer />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
