import React, { useEffect, useState } from "react";
import "./RequireApproval.css";
import aprovaldenied from "../../../../assets/RejectIcons/reject_icon_red_with_white_circle.svg";
import aprovalaccept from "../../../../assets/CheckmarksIcons/approve_circle_green_checkmark.svg";
import xpentraloader from "../../../../assets/Beneficiary Icons/lodemore.gif";
import { ConvertBefiDate } from "../../../../utils/ConvertBefiDate";
import { beneRequiredApproval } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { beneWaitingApproved } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { useDispatch, useSelector } from "react-redux";
import DeclinedPopup from "./DeclinedPopup";
import InfiniteScroll from "react-infinite-scroll-component";
import NoAccountFound from "../../../../assets/No transaction found.svg";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import { Bounce, toast } from "react-toastify";
import { tooltipClasses, Tooltip } from "@mui/material";
import SkipVerification from "../../../../assets/ErrorIcons/error_with_white_outlined_circle.svg";
import { checkUsertoken } from "../../../../redux/features/login/login";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import RejectingTransactionPopup from "./RejectingTransactionPopup/RejectingTransactionPopup";
import arrowleft from "../../../../assets/ArrowsIcons/ChevronLeftWhite.svg";
import { useNavigate } from "react-router-dom";
import RefreshToast from "../../../../components/_utils/RefreshToast";
import { getRefreshTokenNew } from "../../../../redux/features/refreshToken/refreshToken";

const RequireApproval = () => {
  const dispatch = useDispatch();
  const [allAccounts, setAllAccounts] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [deniedId, SetdeniedId] = useState(null);
  const [loading, setLoading] = useState({});
  const [BeneWaitingStatus, SetBeneWaitingStatus] = useState(true);
  const [ErrorMessage, SetErrorMessage] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [allBeneTxnAccountsLoading, setAllBeneTxnAccountsLoading] =
    useState(false);
  const [approvedCount, setApprovedCount] = useState(0);
  const [preventMultiEnter, setPreventMultiEnter] = useState(false);
  // const [showRejectTnxPopup, setRejectTxnPopup] = useState(false);

  const navigate = useNavigate();

  const updateAllAccounts = (updatedAccounts) => {
    setAllAccounts(updatedAccounts);
  };

  let approvedpadCount;
  let deniedCount = 0;

  const { beneRequiredApprovalData } = useSelector(
    (state) => state.beneAllVerification
  );

  const { beneWaitingApprovalData } = useSelector((state) => {
    return state.beneAllVerification;
  });
  const services = useSelector((state) => state.refreshToken.services);

  useEffect(() => {
    if (currentPage === 1) {
      setAllBeneTxnAccountsLoading(true);
    }
    dispatch(checkUsertoken());
    dispatch(
      beneRequiredApproval({
        page_no: currentPage,
      })
    )
      .then((response) => {
        setAllAccounts((prevAccounts) => {
          const newData =
            (response?.payload?.beneficiary_accounts &&
              response?.payload?.beneficiary_accounts?.map((account) => ({
                ...account,
                loading: false,
                error: "false",
                message: "",
              }))) ||
            [];
          // setAllAccounts((prevAccounts) =>
          //   currentPage === 1 ? newData : [...prevAccounts, ...newData]
          // );
          return [...prevAccounts, ...newData];
        });
        setAllBeneTxnAccountsLoading(false);
      })
      .catch(() => {
        setAllBeneTxnAccountsLoading(false);
      });
  }, [dispatch, currentPage]);
  const handleOpenPopup = (id) => {
    setShowPopup(true);
    SetdeniedId(id);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    // setRejectTxnPopup(false);
  };

  const handleApprovalToastSuccess = () => {
    setApprovedCount((prevCount) => prevCount + 1);
    toast.success("Account approved successfully!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "colored",
    });
  };

  const handleApproval = async (id) => {
    setLoading((prevLoadingStates) => ({
      ...prevLoadingStates,
      [id]: true,
    }));
    RefreshToast("approvalaccountsRefresh");

    dispatch(checkUsertoken());
    const data = await dispatch(beneWaitingApproved(id));

    if (data?.payload?.errors?.user_unauthorized?.length > 0) {
      navigate("/feature_not_assigned", {
        state: {
          unavailableService: "BAAccount",
        },
      });
      return;
    }

    let errorMessage = "";
    if (data) {
      SetBeneWaitingStatus(data?.payload?.status);
      const errors = data?.payload?.errors;
      if (errors?.beneficiary_account?.[0]) {
        SetErrorMessage(errors.beneficiary_account[0]);
        errorMessage = errors.beneficiary_account[0];
      } else if (errors?.bank_account_number?.[0]) {
        SetErrorMessage(errors.bank_account_number[0]);
        errorMessage = errors.bank_account_number[0];
      } else {
        SetErrorMessage("An unknown error occurred.");
        errorMessage = "An unknown error occurred.";
      }
    }

    if (data) {
      const updatedData = allAccounts.map((item) => {
        if (item.id === id) {
          let lifecycleStatus = "";

          if (data.payload.status === true) {
            lifecycleStatus = "Approved";
          }
          if (data.payload.status === false) {
            lifecycleStatus = "error";
          }

          let message = "";
          if (data.payload.status === false) {
            const errors = data?.payload?.errors;
            if (errors?.beneficiary_account?.[0]) {
              message = errors.beneficiary_account[0];
            } else if (errors?.bank_account_number?.[0]) {
              message = errors.bank_account_number[0];
            }
          }

          return {
            ...item,
            approval_status:
              item.approval_status === "pending" ? lifecycleStatus : "pending",
            error:
              item.error === "false" && data?.payload.status === false
                ? "true"
                : "false",
            message:
              item.message === "" && data.payload.status === false
                ? message
                : "",
          };
        }

        return item;
      });

      setAllAccounts(updatedData);

      if (data?.payload.status === false) {
        toast.error(errorMessage, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    }

    setTimeout(() => {
      setLoading((prevLoadingStates) => ({
        ...prevLoadingStates,
        [id]: false,
      }));
    }, 2000);

    setTimeout(() => {
      if (data.payload.status === true) {
        handleApprovalToastSuccess();
      }
    }, 2000);
  };

  const AllAprovalAcountCount =
    beneRequiredApprovalData?.summary?.pending_accounts_count ?? "00";

  allAccounts?.forEach((account) => {
    if (account.approval_status === "rejected" && account.error !== "true") {
      deniedCount++;
    }
  });

  approvedpadCount = approvedCount.toString().padStart(2, "0");
  deniedCount = deniedCount.toString().padStart(2, "0");

  const fetchMoreData = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <>
      {showPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
          <DeclinedPopup
            onClose={handleClosePopup}
            accountId={deniedId}
            allAccounts={allAccounts}
            updateAllAccounts={updateAllAccounts}
            status={"Benificiary"}
            setPreventMultiEnter={setPreventMultiEnter}
            // showRejectTnxPopup={showRejectTnxPopup}
            // setRejectTxnPopup={setRejectTxnPopup}
          />
        </div>
      )}
      <div
        id="webviewd"
        style={{
          height: showPopup ? "100vh" : "",
          overflowX: "hidden",
          overflowY: showPopup ? "hidden" : "",
        }}
      >
        <div id="RequireApprovalPage">
          <div className="ApprovalNavbar">
            <div className="Approvalsec1">
              <div className="Approvalsec2">
                <div className="approvalAcountTopbar">
                  <div
                    className="firstheaderApproval"
                    style={{ paddingLeft: "30px" }}
                  >
                    <div className="approvalParentdiv">
                      <p className="approvalchild1">
                        Σ {AllAprovalAcountCount}
                      </p>
                      <p className="approvalchild2">Total accounts</p>
                    </div>
                  </div>
                  <div className="SecondheaderApproval">
                    <div className="navbarleft">
                      <p className="approvedtag1">{approvedpadCount}</p>
                      <p className="approvedtag2"> Approved</p>
                    </div>
                    <div className="navbarright">
                      <p className="approvedtag3">{deniedCount}</p>
                      <p className="approvedtag4">Rejected</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="viewAllScrollContainerBar">
            {allBeneTxnAccountsLoading ? (
              <div className="loader-container m-auto justify-center items-center flex">
                <img src={dataLoading} alt="" />
              </div>
            ) : beneRequiredApprovalData?.summary?.pending_accounts_count ===
              undefined ? (
              <div className="BeneAccount_NoAccountFound">
                <div className="BeneAccount_RightTextContentNoAccount">
                  <img src={NoAccountFound} alt="" />
                </div>
                <div className="BeneAccount_LeftTextContentNoAccount">
                  <span>No pending accounts found.</span>
                  <p>There are no more accounts awaiting your approval.</p>
                </div>
              </div>
            ) : allAccounts?.length > 0 ? (
              <div className="scroll flex flex-col items-center justify-center w-full">
                {/* {showPopup && (
                  <DeclinedPopup
                    onClose={handleClosePopup}
                    accountId={deniedId}
                    allAccounts={allAccounts}
                    updateAllAccounts={updateAllAccounts}
                    status={"Benificiary"}
                  />
                )} */}
                <div
                  className={`scroll flex flex-col items-center justify-center w-full`}
                >
                  <InfiniteScroll
                    style={{ width: "inherit" }}
                    dataLength={allAccounts.length || []}
                    next={fetchMoreData}
                    hasMore={
                      currentPage <
                      Math.ceil(
                        beneRequiredApprovalData?.summary
                          ?.pending_accounts_count / pageSize
                      )
                    }
                    loader={
                      <div className="loader-container justify-center items-center flex">
                        <img src={dataLoading} alt="" />{" "}
                      </div>
                    }
                    scrollableTarget="viewAllScrollContainerBar"
                    scrollThreshold={0.8}
                  >
                    {allAccounts?.map((account, index) => (
                      <div className="approvedAcoutDetails" key={index}>
                        <div className="ApprovalAcdiv1">
                          <div className="Approvalsection1">
                            <div className="approvaltext1">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                    textTransform: "capitalize",
                                  },
                                }}
                                title={account.name.toLowerCase()}
                              >
                                <p>{account.name}</p>
                              </Tooltip>
                              <span>{account.contact_no}</span>
                            </div>
                            <div className="approvaltext2">
                              <p className="z1"> Name</p>
                              <span className="z2">Contact number</span>
                            </div>
                            <div className="approvaltext3">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                placement="bottom-start"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                                title={account.email_id}
                              >
                                <p>{account?.email_id.toLowerCase()}</p>
                              </Tooltip>
                              <span>Email ID</span>
                            </div>
                            <div className="approvaltext4">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={
                                  account.category
                                    ? account.category
                                    : "Not Assigned"
                                }
                                placement="bottom-start"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                              >
                                <p>
                                  {account?.category
                                    ? account.category
                                    : account.category || "Not Assigned"}
                                </p>
                              </Tooltip>

                              <span>Assigned categories</span>
                            </div>
                          </div>
                        </div>

                        <div className="ApprovalAcdiv2 ">
                          <div className="Approvalsection2 p-[5px]  ">
                            <div className="approvalDiv1">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={account.bank_account_holder_full_name.toLowerCase()}
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                    textTransform: "capitalize",
                                  },
                                }}
                              >
                                <p>
                                  {account.bank_account_holder_full_name.toLowerCase()}
                                </p>
                              </Tooltip>

                              <span className="approvalDiv1span mr-[5px]">
                                {account.pan_no}
                              </span>
                            </div>
                            <div className="approvalDiv2">
                              <span className="n1">Account holder name </span>
                              <span className="n2">PAN number</span>
                            </div>
                            <div className="approvalDiv3">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={account.bank_acc_no}
                              >
                                <p>{account.bank_acc_no}</p>
                              </Tooltip>
                              <span
                                style={{
                                  marginRight: account.gstin_detail
                                    ? "62px"
                                    : "17px",
                                }}
                              >
                                {account.gstin_detail
                                  ? account.gstin_detail
                                  : "Not given"}{" "}
                              </span>
                            </div>
                            <div className="approvalDiv4">
                              <p>Account number</p>
                              <span>GSTIN</span>
                            </div>

                            <div className="approvalDiv5">
                              <div className="b">
                                <p>{account.ifsc_code}</p>
                                <span>IFSC code</span>
                              </div>
                              <div className="b1">
                                <img
                                  src={require("../../../../assets/IfscLogo/" +
                                    IfscCodeMapper(account.ifsc_code))}
                                  alt="bank"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="ApprovalAcdiv3">
                          <div className="Approvalsection3">
                            <div className="AprovalpageContainx1">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={
                                  account.created_by_name
                                    ? account.created_by_name.toLowerCase()
                                    : "Not given"
                                }
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                    textTransform: "capitalize",
                                  },
                                }}
                              >
                                <p>{account.created_by_name.toLowerCase()}</p>
                              </Tooltip>

                              <span>{ConvertBefiDate(account.created_on)}</span>
                            </div>
                            <div className="AprovalpageContainx2">
                              <span className="b1">Requested by</span>
                              <span className="b2">Created on</span>
                            </div>

                            <div className="logo">
                              <div className="logo1">
                                {account.approval_status === "pending" &&
                                  !loading[account.id] && (
                                    <>
                                      <button
                                        className="Approvalleftside"
                                        onClick={() => {
                                          handleOpenPopup(account.id);
                                        }}
                                      >
                                        <img src={aprovaldenied} alt="" />
                                        <p>Reject</p>
                                      </button>

                                      <div className="approvaldivider">
                                        <p className="divider-sec"></p>
                                      </div>
                                      <button
                                        className="Approvalrightside"
                                        onClick={() => {
                                          handleApproval(account.id);
                                        }}
                                      >
                                        <img src={aprovalaccept} alt="" />
                                        <p>Approve</p>
                                      </button>
                                    </>
                                  )}

                                {loading[account.id] && (
                                  <div className="w-8 h-7 m-auto">
                                    <img src={xpentraloader} alt="Loading..." />
                                  </div>
                                )}

                                {account.approval_status === "Approved" &&
                                  account.error === "false" &&
                                  beneWaitingApprovalData &&
                                  !loading[account.id] && (
                                    <div
                                      className="Approvedpage"
                                      id={account.id}
                                    >
                                      <div className="Approvedpage1">
                                        <img src={aprovalaccept} alt="" />
                                      </div>
                                      <div className="Approvedpage2">
                                        <span>Approved!</span>
                                        <p>Account has been approved.</p>
                                      </div>
                                    </div>
                                  )}

                                {account.approval_status === "error" &&
                                  account.error === "true" &&
                                  !loading[account.id] && (
                                    <div className="Unknownpage flex items-center">
                                      <div className="Unknownpage1 w-[40px] flex-shrink-0">
                                        <img
                                          src={SkipVerification}
                                          alt=""
                                          className="w-[40px] h-auto"
                                        />
                                      </div>

                                      <div className="Unknownpage2 flex-1 pl-11">
                                        <span>Failed to take action!</span>
                                        {account.message &&
                                        account.message.length >= 10 ? (
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={account?.message}
                                          >
                                            <p className="line-clamp-2 whitespace-wrap overflow-ellipsis text-[14px] text-[#FCFCFC] opacity-100">
                                              {account?.message}
                                            </p>
                                          </Tooltip>
                                        ) : (
                                          <p className="h-[50px] overflow-hidden whitespace-wrap overflow-ellipsis text-[14px] text-[#FCFCFC] opacity-100">
                                            {account?.message}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  )}

                                {account.approval_status === "rejected" &&
                                  account.error === "false" &&
                                  !loading[account.id] && (
                                    <div className="Declinedpage">
                                      <div className="Declinedpage1">
                                        <img src={aprovaldenied} alt="" />
                                      </div>
                                      <div className="Declinedpage2">
                                        <span>Rejected!</span>
                                        <p>Account has been Rejected.</p>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div
        id="mobileviewd"
        style={{
          height: showPopup ? "100vh" : "",
          overflowX: "hidden",
          overflowY: showPopup ? "hidden" : "",
        }}
      >
        <div className="bene_approval_heder_containerm">
          <div className="allBeneapprovalheadertext">
            <div
              className="allBeneapprovalheaderarrow"
              onClick={() => navigate(-1)}
            >
              <img src={arrowleft} alt="" />
            </div>
            <div className="allBeneapprovalheadertextm">
              <div className="headingAccountDetail text-[#ffffff]">
                Beneficiary
              </div>
              <div className="headertextbottomv text-[#c9c9c9] -mt-1.5">
                Accounts approval
              </div>
            </div>
          </div>
        </div>
        <div className="bene_approval_containerm">
          <div className="allBeneapprovaltext">
            <p>
              Total accounts :{" "}
              <span style={{ color: "#FAFAFA", fontWeight: "bold" }}>
                Σ {AllAprovalAcountCount}
              </span>
            </p>
          </div>
          <div className="SecondheaderApprovalm">
            <div className="">
              <p className="approvedtag2">
                {" "}
                Approved :{" "}
                <span style={{ color: "#3cb371", fontWeight: "bold" }}>
                  {approvedpadCount}
                </span>
              </p>
            </div>
            <div className="">
              <p className="approvedtag4 ml-4">
                Rejected :{" "}
                <span style={{ color: "#ff5860", fontWeight: "bold" }}>
                  {deniedCount}
                </span>
              </p>
            </div>
          </div>
        </div>

        <div id="BeneviewAllScrollContainerBarbene">
          {allBeneTxnAccountsLoading ? (
            // <div className="loader-container m-auto justify-center items-center flex mt-20">
            <img
              src={dataLoading}
              alt=""
              className="w-[50px] h-[50px] mx-auto"
            />
          ) : // </div>
          beneRequiredApprovalData?.summary?.pending_accounts_count ===
            undefined ? (
            <div className="BeneAccount_NoAccountFound">
              <div className="BeneAccount_LeftTextContentNoAccount">
                <span>No pending accounts found.</span>
                <p>There are no more accounts awaiting your approval.</p>
              </div>
              <div className="BeneAccount_RightTextContentNoAccount">
                <img src={NoAccountFound} alt="" />
              </div>
            </div>
          ) : allAccounts?.length > 0 ? (
            <div className="scroll flex flex-col items-center justify-center w-full">
              <div
                className={`scroll flex flex-col items-center justify-center w-full`}
              >
                <InfiniteScroll
                  style={{ width: "inherit" }}
                  dataLength={allAccounts.length || []}
                  next={fetchMoreData}
                  hasMore={
                    currentPage <
                    Math.ceil(
                      beneRequiredApprovalData?.summary
                        ?.pending_accounts_count / pageSize
                    )
                  }
                  loader={
                    <img
                      className="w-[50px] h-[50px] mx-auto"
                      src={dataLoading}
                      alt="Loading..."
                    />
                  }
                  scrollableTarget="BeneviewAllScrollContainerBarbene"
                  scrollThreshold={0.8}
                >
                  {allAccounts?.map((account, index) => (
                    <div className="approvedAcoutDetailsm" key={index}>
                      <div className="ApprovalAcdivm1">
                        <div className="Approvalsection1">
                          <div className="flex justify-between items-start px-5 pt-6 pb-2">
                            <div className="flex flex-col approvaltext1m ">
                              {/* <p className="w-[90%] h-[19px] overflow-hidden whitespace-nowrap text-left text-[14px] leading-[19px] font-normal text-[#fcfcfc] capitalize truncate"> */}
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                placement="bottom-end"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                    textTransform: "capitalize",
                                  },
                                }}
                                title={account.name.toLowerCase()}
                              >
                                <p>{account.name}</p>
                              </Tooltip>
                              {/* </p> */}
                              <p className="mt-[5px] text-left text-xs text-[#c9c9c9]">
                                Name
                              </p>
                            </div>
                            <div className="flex flex-col items-end">
                              <p className="text-right text-[#fcfcfc] text-[14px]">
                                {account.contact_no}
                              </p>
                              <p className="mt-[5px] text-right text-xs text-[#c9c9c9]">
                                Contact number
                              </p>
                            </div>
                          </div>
                          <div className="mt-[-5px] whitespace-nowrap overflow-hidden">
                            <p className="w-[90%] h-[19px] mt-5 ml-5 overflow-hidden whitespace-nowrap text-left text-[14px] leading-[19px] font-normal text-[#fcfcfc] opacity-100 truncate">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                placement="bottom-start"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                                title={account.email_id}
                              >
                                <p>{account?.email_id.toLowerCase()}</p>
                              </Tooltip>
                            </p>
                            <p className="mt-[5px] ml-5 text-left text-[12px] leading-[17px] font-normal text-[#c9c9c9] opacity-100">
                              Email ID
                            </p>
                          </div>

                          <div className="approvalCategorym">
                            {/* <p className="w-[90%] h-[19px] mt-5 ml-5 overflow-hidden whitespace-nowrap text-left text-[14px] leading-[19px] font-normal text-[#fcfcfc] opacity-100 truncate"> */}
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={
                                account.category
                                  ? account.category
                                  : "Not Assigned"
                              }
                              placement="bottom-start"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <p>
                                {account?.category
                                  ? account.category
                                  : account.category || "Not Assigned"}
                              </p>
                            </Tooltip>
                            {/* </p> */}
                            <p className="ml-5 mt-[5px] text-left text-[12px] leading-[17px] font-normal text-[#c9c9c9] opacity-100">
                              Assigned categories
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="w-full h-full text-white border-b-2 border-[#506994]">
                        <div className="w-full h-full mb-5">
                          <div className="flex justify-between px-5">
                            <div className="flex-1 w-[80%] approvalDiv1m">
                              {/* <p className="mt-5 text-left text-[14px] leading-[19px] font-normal text-[#fcfcfc] capitalize truncate... overflow-hidden whitespace-nowrap"> */}
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={account.bank_account_holder_full_name.toLowerCase()}
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                    textTransform: "capitalize",
                                  },
                                }}
                              >
                                <p>
                                  {account.bank_account_holder_full_name.toLowerCase()}
                                </p>
                              </Tooltip>
                              {/* </p> */}
                              <p className="h-[17px] mt-1 text-left text-[12px] leading-[17px] font-normal text-[#c9c9c9]">
                                Account holder name
                              </p>
                            </div>
                            <div className="flex-1 text-right">
                              <p className="h-[19px] mt-5 text-[14px] leading-[19px] font-normal text-[#fcfcfc]">
                                {account.pan_no}
                              </p>
                              <p className="h-[17px] mt-1 text-[12px] leading-[17px] font-normal text-[#c9c9c9]">
                                PAN number
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-between mx-5 mt-5">
                            <div className="flex-1">
                              <p className="h-[19px] overflow-hidden text-ellipsis text-[14px] leading-[19px] font-normal text-[#fcfcfc]">
                                {account.bank_acc_no}
                              </p>
                              <p className="h-[17px] mt-1 text-left text-[12px] leading-[17px] font-normal text-[#c9c9c9]">
                                Account number
                              </p>
                            </div>
                            <div className="flex-1 text-right">
                              <p
                                className="h-[19px] text-[14px] leading-[19px] font-normal text-[#fcfcfc]"
                                style={{
                                  marginRight: account.gstin_detail ? "" : "",
                                }}
                              >
                                {account.gstin_detail || "Not given"}
                              </p>
                              <p className="h-[17px] mt-1 text-[12px] leading-[17px] font-normal text-[#c9c9c9] text-right">
                                GSTIN
                              </p>
                            </div>
                          </div>
                          <div className="flex justify-between mt-5">
                            <div className="ml-5 flex flex-col">
                              <p className="text-[14px] leading-[19px] font-normal text-[#fcfcfc]">
                                {account.ifsc_code}
                              </p>
                              <p className="mt-1 text-[12px] leading-[17px] font-normal text-[#c9c9c9]">
                                IFSC code
                              </p>
                            </div>
                            <div className="w-[100px] h-[35px] mr-5 bg-[#fafafa] rounded-[22px] flex justify-center items-center">
                              <img
                                className="w-[80px] h-[20px]"
                                src={require("../../../../assets/IfscLogo/" +
                                  IfscCodeMapper(account.ifsc_code))}
                                alt="bank"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full h-full">
                        <div className="flex justify-between text-sm px-5 mt-4">
                          <div className="flex flex-col items-start approvalRequestedm">
                            {/* <p className="truncate capitalize text-[#fcfcfc] w-[150px]"> */}
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={
                                account.created_by_name
                                  ? account.created_by_name.toLowerCase()
                                  : "Not given"
                              }
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                  textTransform: "capitalize",
                                },
                              }}
                            >
                              <p className="approvalRequestedmChild">
                                {account.created_by_name.toLowerCase()}
                              </p>
                            </Tooltip>
                            {/* </p> */}
                            <p className="mt-1 text-[12px] leading-[17px] font-normal text-[#c9c9c9]">
                              Requested by
                            </p>
                          </div>

                          <div className="flex flex-col items-end">
                            <p className="text-[#fcfcfc]">
                              {ConvertBefiDate(account.created_on)}
                            </p>
                            <p className="mt-1 text-[12px] leading-[17px] font-normal text-[#c9c9c9]">
                              Created on
                            </p>
                          </div>
                        </div>

                        <div className="mt-10">
                          <div
                            className={`bg-[#506994] rounded-[20px] flex justify-between items-center w-full min-h-[70px] ${
                              account.approval_status === "pending"
                                ? "py-4"
                                : ""
                            }`}
                          >
                            {account.approval_status === "pending" &&
                              !loading[account.id] && (
                                <>
                                  <button
                                    className="flex flex-col items-center justify-center gap-1 w-1/2"
                                    onClick={() => {
                                      handleOpenPopup(account.id);
                                    }}
                                  >
                                    <img
                                      src={aprovaldenied}
                                      alt="Reject"
                                      className="w-10 h-10"
                                    />
                                    <p className="text-[#fcfcfc] text-xs">
                                      Reject
                                    </p>
                                  </button>
                                  <div
                                    className=""
                                    style={{
                                      border: "1px solid #1d3a6d",
                                      height: "60px",
                                    }}
                                  ></div>
                                  <button
                                    className="flex flex-col items-center justify-center gap-1 w-1/2"
                                    onClick={() => {
                                      handleApproval(account.id);
                                    }}
                                  >
                                    <img
                                      src={aprovalaccept}
                                      alt="Approve"
                                      className="w-10 h-10"
                                    />
                                    <p className="text-[#fcfcfc] text-xs">
                                      Approve
                                    </p>
                                  </button>
                                </>
                              )}

                            {loading[account.id] && (
                              <div className="flex justify-center w-full">
                                <img
                                  src={xpentraloader}
                                  className="w-10 h-10"
                                  alt="Loading..."
                                />
                              </div>
                            )}

                            {account.approval_status === "Approved" &&
                              account.error === "false" &&
                              beneWaitingApprovalData &&
                              !loading[account.id] && (
                                <div className="flex items-center bg-[#3cb371] text-white w-full rounded-[20px] py-5 px-5 min-h-[70px]">
                                  <img
                                    src={aprovalaccept}
                                    alt="Approved"
                                    className="w-10 h-10 mr-3"
                                  />
                                  <div>
                                    <p className="font-semibold text-sm">
                                      Approved!
                                    </p>
                                    <p className="text-xs">
                                      Account has been approved.
                                    </p>
                                  </div>
                                </div>
                              )}

                            {account.approval_status === "error" &&
                              account.error === "true" &&
                              !loading[account.id] && (
                                <div className="flex items-center bg-[#bb5353] text-white w-full rounded-lg py-5 px-5 min-h-[70px]">
                                  <img
                                    src={SkipVerification}
                                    alt="Failed"
                                    className="w-10 h-10 mr-3"
                                  />
                                  <div className="w-[90%]">
                                    <span>Failed to take action!</span>
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={
                                        account.message
                                          ? account.message
                                          : "Not given"
                                      }
                                      placement="bottom"
                                      PopperProps={{
                                        style: {
                                          maxWidth: "none",
                                          textTransform: "capitalize",
                                        },
                                      }}
                                    >
                                      <p className="text-xs truncate failed-action-message">
                                        {account.message}
                                      </p>
                                    </Tooltip>
                                  </div>
                                </div>
                              )}

                            {account.approval_status === "rejected" &&
                              account.error === "false" &&
                              !loading[account.id] && (
                                <div className="flex items-center bg-[#ff5860] text-white w-full rounded-[20px] py-5 px-5 min-h-[70px]">
                                  <img
                                    src={aprovaldenied}
                                    alt="Rejected"
                                    className="w-10 h-10 mr-4"
                                  />
                                  <div>
                                    <p className="font-semibold text-sm">
                                      Rejected!
                                    </p>
                                    <p className="text-xs">
                                      Account has been rejected.
                                    </p>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};

export default RequireApproval;
