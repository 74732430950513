import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { resetInEdit } from "../../../redux/features/verifyPAN/verifyPAN";
import { useNavigate, useParams } from "react-router-dom";
import Disc from "../../../assets/Beneficiary Icons/discEdit.png";
import { resetEditBankBeneficiary } from "../../../redux/features/accounts/BeneficiaryAccount/createAccount";
import {
  getRefreshTokenNew,
  resetServices,
} from "../../../redux/features/refreshToken/refreshToken";

const useStyles = makeStyles({
  button: {
    backgroundColor: "#506994",
    color: "white",
    borderRadius: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    padding: "17px 17px 17px 25px",
    textTransform: "none !important",
    fontFamily: "'Open Sans', sans-serif",
    fontSize: "16px",
    lineHeight: "25px",
    fontWeight: 400,
    "&:hover": {
      backgroundColor: "#667CA3",
      color: "#fff",
    },
  },
  activeButton: {
    backgroundColor: "white",
    color: "#153064",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: "white",
      color: "#153064",
    },
  },
});

const BeneEditModal = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState(null);
  const [category, setCategory] = useState("");
  const [panNumber, setPanNumber] = useState({
    pan_no: "",
    gstin: "",
  });
  const [bankDetails, setBankDetails] = useState({
    name: "",
    bankAccountNumber: "",
    ifscCode: "",
  });
  const [previousContactDetails, setPreviousContactDetails] = useState({
    legalName: "",
    contact: "",
    email: "",
    gstin: "",
    category: null,
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const { viewAccountDetailData } = useSelector((state) => state.verify);

  useEffect(() => {
    if (
      viewAccountDetailData &&
      viewAccountDetailData.beneficiary_accounts &&
      viewAccountDetailData.beneficiary_accounts.length > 0
    ) {
      const { pan_legal_name, contact_no, email_id } =
        viewAccountDetailData.beneficiary_accounts[0];
      setPreviousContactDetails({
        legalName: pan_legal_name,
        contact: contact_no,
        email: email_id,

        category: category || null,
      });
    }
  }, [viewAccountDetailData]);

  useEffect(() => {
    if (
      viewAccountDetailData &&
      viewAccountDetailData.beneficiary_accounts &&
      viewAccountDetailData.beneficiary_accounts.length > 0
    ) {
      const { category } = viewAccountDetailData.beneficiary_accounts[0];
      setCategory(category);
    }
  }, [viewAccountDetailData]);

  useEffect(() => {
    if (
      viewAccountDetailData &&
      viewAccountDetailData.beneficiary_accounts &&
      viewAccountDetailData.beneficiary_accounts.length > 0
    ) {
      const { pan_no, gstin } = viewAccountDetailData.beneficiary_accounts[0];
      setPanNumber({ pan_no, gstin: gstin });
    }
  }, [viewAccountDetailData]);

  useEffect(() => {
    if (
      viewAccountDetailData &&
      viewAccountDetailData.beneficiary_accounts &&
      viewAccountDetailData.beneficiary_accounts.length > 0
    ) {
      const { bank_acc_no, name, ifsc_code } =
        viewAccountDetailData.beneficiary_accounts[0];
      setBankDetails({
        bankHolderName: name,
        bankAccountNumber: bank_acc_no,
        ifscCode: ifsc_code,
      });
    }
  }, [viewAccountDetailData]);

  const handleClose = () => {
    props.onClose();
  };

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const handleEditClick = () => {
    dispatch(resetServices());
    dispatch(getRefreshTokenNew());
    switch (activeButton) {
      case "IFSC Code":
        break;
      case "Contact number,Email":
        navigate(`/accounts/beneficiary_account/edit/Contact_Details/${id}`, {
          state: previousContactDetails,
        });
        break;
      case "Assigned categories":
        navigate(`/accounts/beneficiary_account/edit/categories/${id}`, {
          state: previousContactDetails,
        });
        break;
      case "PAN Number,GST":
        dispatch(resetInEdit());
        navigate(`/accounts/beneficiary_account/edit/PAN_Details/${id}`);
        break;
      case "Bank account number":
        navigate(`/accounts/beneficiary_account/edit/Bank_Details/${id}`, {
          state: bankDetails,
        });
        dispatch(resetEditBankBeneficiary());
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-screen bg-black opacity-80 z-[9998]"></div>
      <div
        className="flex justify-center items-center fixed top-1/2 left-1/2 z-[9999] p-3"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className="h-fit w-60% flex flex-col bg-[#102C5D] rounded-2xl pb-8"
          style={{
            width: window.innerWidth <= 768 ? "95vw" : "50vw",
            maxWidth: "600px",
            padding: "20px 30px",
          }}
        >
          <div className="text-left">
            <div className="flex justify-between mb-5">
              <h6 className="text-custom-orange md:font-bold text-[18px] text-center ml-1">
                Select the option you would like to edit:
              </h6>
            </div>

            <div className="w-full flex flex-col mb-3">
              <div className=" flex flex-col gap-4">
                <Button
                  className={`${classes.button} ${
                    activeButton === "IFSC Code" ? classes.activeButton : ""
                  }`}
                  size="medium"
                  onClick={() => handleButtonClick("IFSC Code")}
                >
                  IFSC code
                </Button>
                <Button
                  className={`${classes.button} ${
                    activeButton === "Contact number,Email"
                      ? classes.activeButton
                      : ""
                  }`}
                  size="medium"
                  onClick={() => handleButtonClick("Contact number,Email")}
                >
                  Contact number,Email
                </Button>
                <Button
                  className={`${classes.button} ${
                    activeButton === "Assigned categories"
                      ? classes.activeButton
                      : ""
                  }`}
                  size="medium"
                  onClick={() => handleButtonClick("Assigned categories")}
                >
                  Assigned categories
                </Button>
                <Button
                  className={`${classes.button} ${
                    activeButton === "PAN Number,GST"
                      ? classes.activeButton
                      : ""
                  }`}
                  size="medium"
                  onClick={() => handleButtonClick("PAN Number,GST")}
                >
                  PAN number,GST
                </Button>
                <Button
                  className={`${classes.button} ${
                    activeButton === "Bank account number"
                      ? classes.activeButton
                      : ""
                  }`}
                  size="medium"
                  onClick={() => handleButtonClick("Bank account number")}
                >
                  Bank account number
                </Button>
              </div>
              {activeButton && (
                <div className="mt-5">
                  <ul
                    style={{
                      columns: activeButton !== "IFSC Code" ? "2" : "1",
                      listStylePosition: "inside",
                      color: "#C9C9C9",
                      fontSize: "12px",
                    }}
                  >
                    {activeButton === "IFSC Code" && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Disc}
                          alt="editCategoryMode"
                          className="w-2 h-2"
                        />
                        <li
                          style={{
                            listStyle: "none",
                            marginLeft: "15px",
                            fontSize: "14px",
                          }}
                        >
                          Please contact an Xpentra administrator to change the
                          IFSC detail.
                        </li>
                      </div>
                    )}
                    {activeButton === "Contact number,Email" && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Disc}
                          alt="editCategoryMode"
                          className="w-2 h-2"
                        />
                        <li
                          style={{
                            listStyle: "none",
                            marginLeft: "8px",
                            fontSize: "14px",
                          }}
                        >
                          Require approval
                        </li>
                      </div>
                    )}
                    {activeButton === "Assigned categories" && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Disc}
                          alt="editCategoryMode"
                          className="w-2 h-2"
                        />
                        <li
                          style={{
                            listStyle: "none",
                            marginLeft: "8px",
                            fontSize: "14px",
                          }}
                        >
                          Require approval
                        </li>
                      </div>
                    )}
                    {activeButton === "PAN Number,GST" && (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={Disc}
                          alt="editCategoryMode"
                          className="w-2 h-2"
                        />
                        <li
                          style={{
                            listStyle: "none",
                            marginLeft: "8px",
                            fontSize: "14px",
                          }}
                        >
                          Require approval
                        </li>
                      </div>
                    )}
                    {activeButton === "Bank account number" && (
                      <>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={Disc}
                            alt="editCategoryMode"
                            className="w-2 h-2"
                          />
                          <li
                            style={{
                              listStyle: "none",
                              marginLeft: "8px",
                              fontSize: "14px",
                            }}
                          >
                            Require approval
                          </li>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={Disc}
                            alt="editCategoryMode"
                            className="w-2 h-2"
                          />
                          <li
                            style={{
                              listStyle: "none",
                              marginLeft: "8px",
                              fontSize: "14px",
                            }}
                          >
                            Create a new account
                          </li>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={Disc}
                            alt="editCategoryMode"
                            className="w-2 h-2"
                          />
                          <li
                            style={{
                              listStyle: "none",
                              marginLeft: "8px",
                              fontSize: "14px",
                            }}
                          >
                            Archive the old account
                          </li>
                        </div>
                      </>
                    )}
                  </ul>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "6%",
                  margin: "0px 50px",
                  width: "100%",
                }}
                className="px-4 pt-10 mt-4"
              >
                <Button
                  sx={{
                    borderColor: "none",
                    color: "#fff",
                    textTransform: "capitalize",
                    textDecoration: "underline",
                    fontFamily: "'Open Sans', sans-serif",
                    fontSize: "16px",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  className="font-semibold"
                  sx={{
                    borderRadius: "20px",
                    color: "#fff",
                    boxShadow: "0px 3px 6px #00000033",
                    textTransform: "capitalize",
                    fontFamily: "'Open Sans', sans-serif",
                    fontSize: "16px",
                    backgroundColor:
                      activeButton === null || activeButton === "IFSC Code"
                        ? "#707070"
                        : "#69B76F",
                    border: "1px solid transparent",
                    marginRight: "40px",
                    width: "110px",
                    pointerEvents:
                      activeButton === "IFSC Code" ? "none" : "auto",
                    "&:hover": {
                      backgroundColor:
                        activeButton === "IFSC Code" ? "#707070" : "#69B76F",
                    },
                    "&.Mui-disabled": {
                      backgroundColor: "#707070",
                      color: "#FFFFFF",
                    },
                  }}
                  onClick={handleEditClick}
                  disabled={!activeButton || activeButton === "IFSC Code"}
                >
                  Edit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneEditModal;
