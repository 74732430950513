import React, { useState, useEffect, useRef } from "react";
import "./index.css";
import { Button } from "@mui/material";
import addstatus from "../../../../assets/ErrorIcons/error_with_white_circle.svg";
import { useDispatch, useSelector } from "react-redux";
import BeneficiaryModal from "../../../../components/BeneficiaryModal";
import { useNavigate } from "react-router-dom";
import ViewDetails from "../../../../assets/GeneralIcons/detail_page_edit detail.svg";
import InfoDetails from "../../../../assets/InfoIcons/Info.svg";
import BeneEditBankDetail from "../../../../components/BeneEditAccount/BeneEditBankDetail";
import Validation from "../../../../utils/validation";
import unarchiveImg from "../../../../assets/ArchieveUnArchieveIcons/unarchive.svg";
import archiveImg from "../../../../assets/ArchieveUnArchieveIcons/archive.svg";

const BeneBankEdit = ({
  beneLookLoading,
  setBankLookupNext,
  bankLookupNext,
  BenEnableNextButton,
  payload,
  setPayload,
  activeStep,
  panNumber,
  bankIsAchived,
  setBankIsArchived,
  bankIsApproved,
  setBankIsApproved,
  handleNext,
  setBankIsDennied,
  setBankIsRejected,
  bankIsDennied,
  bankIsRejected,
  bankIsPending,
  setBankIsPending,
  setBankShutViewAll,
  bankShutViewAll,
  bankDisableCreateNext,
  setBankDisableCreateNext,
  bankNextButtonEnabled,
  bankAccountType,
  setBankAccountType,
  active,
  setActive,
  newBankMessage,
  setBankNewMessage,
  hideBankBlock,
  setBankHideBlock,
  hideBankCreate,
  setBankHideCreate,
  previousBankID,
  handleNextBank,
  createNewedit,
  setCreateNewEdit,
}) => {
  const benebankLookData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );

  const isBankError = useSelector(
    (state) => state.beneAllVerification.isBankError
  );

  const { verifyBankisLoading, verifyBank, bankMessage } = useSelector(
    (state) => state.beneAllVerification
  );

  const [virtualAccount, setVirtualAccount] = useState(false);
  const [bankNonArchive, setBankNonArchive] = useState(false);
  const [bankUnachivedOpenPop, setBankUnachivedOpenPop] = useState(false);
  const [bankAchivedOpenPop, setBankAchivedOpenPop] = useState(false);
  const [isBankLinkedAccount, setIsBankLinkedAccount] = useState(false);
  const [accountAction, setAccountAction] = useState(false);
  const [showAccInfo, setShowAccInfo] = useState(false);
  const navigate = useNavigate();
  const [timer, setTimer] = useState(false);
  const associatedAccountRef = useRef(null);

  let haveAcheived = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "archived";
  });
  let haveApproved = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });
  let haveDennied = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "denied";
  });
  let haveRejected = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "rejected";
  });
  let havePending = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending";
  });
  let haveBlock = benebankLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "blocked";
  });

  useEffect(() => {
    if (benebankLookData?.status) {
      setAccountAction(true);
    }
  }, [benebankLookData]);

  const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
  const isIFSCValid =
    payload?.ifsc_code?.length === 11 && ifscRegex.test(payload?.ifsc_code);

  useEffect(() => {
    setTimer(false);
    const foundAccount = benebankLookData?.beneficiary_accounts?.find((val) => {
      if (!verifyBankisLoading) {
        if (
          payload?.old_bank_account === val?.bank_acc_no &&
          payload?.old_ifsc_code === val?.ifsc_code
        ) {
          BenEnableNextButton(true);
        }
        if (
          payload?.bank_account.length >= 9 &&
          payload?.ifsc_code.length >= 11
        ) {
          if (
            payload?.old_bank_account === payload?.bank_account &&
            payload?.old_ifsc_code === payload?.ifsc_code
          ) {
            BenEnableNextButton(true);
          } else if (
            Validation.validateBankAcc(payload?.bank_account) &&
            Validation.validateIfsc(payload?.ifsc_code)
          ) {
            BenEnableNextButton(false);
          }
        }
      }

      if (
        payload?.pan_no === val?.pan_no &&
        payload?.old_bank_account !== val?.bank_acc_no &&
        (payload?.old_ifsc_code === val?.ifsc_code ||
          payload?.old_ifsc_code) !== val?.ifsc_code
      ) {
        setIsBankLinkedAccount(true);
        BenEnableNextButton(true);
        return true;
      }

      setTimeout(() => {
        setTimer(true);
      }, 1000);
      return false;
    });

    if (!foundAccount) {
      setIsBankLinkedAccount(false);
    }
  }, [benebankLookData, payload]);

  useEffect(() => {
    if (bankAchivedOpenPop || bankUnachivedOpenPop) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [bankAchivedOpenPop, bankUnachivedOpenPop]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      event.stopPropagation();
      if (
        associatedAccountRef.current &&
        !associatedAccountRef.current.contains(event.target)
      ) {
        setShowAccInfo(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [associatedAccountRef]);
  return (
    <div className="beneBank_Main">
      {bankAchivedOpenPop && (
        <BeneficiaryModal
          close={() => setBankAchivedOpenPop(false)}
          id={bankAccountType.id}
          type={"archived"}
          setBankAccountType={setBankAccountType}
          bankAccountType={bankAccountType}
          message={"Are you sure you want to archive this account?"}
          note="The account will become inactive."
          panNumber={panNumber}
          setBankIsArchived={setBankIsArchived}
          payload={payload}
          setBankShutViewAll={setBankShutViewAll}
          createFlow={"bank"}
          title={"Archive account"}
        />
      )}
      {bankUnachivedOpenPop && (
        <BeneficiaryModal
          close={() => setBankUnachivedOpenPop(false)}
          id={bankAccountType.id}
          type={"unarchived"}
          setBankAccountType={setBankAccountType}
          bankAccountType={bankAccountType}
          message={"Are you sure you want to unarchive this account?"}
          note="The account will become active."
          panNumber={panNumber}
          setBankIsArchived={setBankIsArchived}
          payload={payload}
          setBankShutViewAll={setBankShutViewAll}
          createFlow={"bank"}
          title={"Unarchive account"}
        />
      )}

      <div className="dividebank_edit">
        {bankAccountType.type === "blocked" &&
        !isBankError &&
        !virtualAccount ? (
          <div className="bene_block_message_text">
            <img src={addstatus} alt="info" />
            <p>To unblock this account, please contact customer support</p>
          </div>
        ) : isBankLinkedAccount && isIFSCValid ? (
          <div className="bank_new_message_text_block_nonarchive">
            <img src={addstatus} alt="info" />
            <p>
              Looks like this bank account is already linked to the PAN
              provided. Please change the provided bank details to proceed.
            </p>
          </div>
        ) : hideBankCreate &&
          !isBankError &&
          !newBankMessage &&
          isIFSCValid &&
          !virtualAccount ? (
          <div className="bene_new_message_text_block">
            <img src={addstatus} alt="info" />
            <p>
              Creating a new account will transform this account into a joint
              account since there are several PANs associated with it and adds
              it to the active accounts for the provided Bank details.
            </p>
          </div>
        ) : createNewedit &&
          !isBankError &&
          isIFSCValid &&
          !virtualAccount &&
          timer ? (
          <div className="bene_new_message_text">
            <img src={addstatus} alt="info" />
            <p>
              Updating the bank details will transform this account into a joint
              account since there are several PANs associated with it and adds
              it to the active accounts for the provided bank details.
            </p>
          </div>
        ) : bankNonArchive && !isBankError && isIFSCValid && !createNewedit ? (
          <div className="Bank_new_message_text_block_nonarchive">
            <img src={addstatus} alt="info" />
            <p>
              Archiving this account is disabled. As you're currently editing
              this account, archiving cannot be performed.
            </p>
          </div>
        ) : null}
        {virtualAccount && isIFSCValid && createNewedit ? (
          <div className="bene_new_message_text">
            <img src={addstatus} alt="info" />
            <p>
              <span>Virtual Account Detected</span> <br />
              <ul className="list-disc px-2 py-2">
                <li>Account could not be verified as it is virtual account</li>
                <li>Please self verify and proceed with cause</li>
              </ul>
            </p>
          </div>
        ) : null}
        <div className="w-1/2 h-fit bankright">
          <BeneEditBankDetail
            payload={payload}
            handleNextBank={handleNextBank}
            setPayload={setPayload}
            BenEnableNextButton={BenEnableNextButton}
            activeStep={activeStep}
            benebankLookData={benebankLookData}
            title="Beneficiary's Bank Details"
            setCreateNewEdit={setCreateNewEdit}
            setBankNewMessage={setBankNewMessage}
            setIsBankLinkedAccount={setIsBankLinkedAccount}
            isBankLinkedAccount={isBankLinkedAccount}
            setAccountAction={setAccountAction}
            setVirtualAccount={setVirtualAccount}
            newBankMessage={newBankMessage}
            setShowAccInfo={setShowAccInfo}
            showAccInfo={showAccInfo}
            virtualAccount={virtualAccount}
            isIFSCValid={isIFSCValid}
            createNewedit={createNewedit}
          />
        </div>
      </div>
      {
        <div
          className="dividebank bank_Details_edit_pop"
          style={{
            marginBottom: "100px",
            display:
              !beneLookLoading &&
              benebankLookData?.status === undefined &&
              !isBankError &&
              isIFSCValid &&
              !virtualAccount
                ? ""
                : "none",
          }}
        >
          <div className="bank-no-assacc-found">
            <p className="bene_top_account">
              Associated Accounts{""}
              <img
                ref={associatedAccountRef}
                src={InfoDetails}
                alt="info"
                onClick={() => {
                  setShowAccInfo(!showAccInfo);
                }}
              />
              {showAccInfo ? (
                <>
                  <div className="associate-acc-info-container">
                    <p className="associate-acc-info">
                      Associated accounts include all active (approved, pending)
                      and inactive (rejected, archived, blocked) accounts linked
                      to the provided Bank details. Click on the accounts to
                      manage them, or create a new account to add it to the
                      active ones.
                    </p>
                  </div>
                </>
              ) : null}
            </p>
            <div className="bank_hidden_accounts_edit">
              <p style={{ fontSize: "15px" }}>No associated accounts found</p>
              <p className="mt-[20px]">
                There are no existing accounts that are associated with the bank
                details provided.You can proceed to update the bank details.
              </p>
            </div>
          </div>
        </div>
      }

      {!beneLookLoading ? (
        <div
          className="dividebank_edit bank_Details_Edit"
          style={{
            visibility:
              benebankLookData?.status && isIFSCValid && !isBankError
                ? "visible"
                : "hidden",
            height:
              bankIsAchived ||
              ((bankIsApproved ||
                bankIsDennied ||
                bankIsPending ||
                bankIsRejected) &&
                bankShutViewAll &&
                isIFSCValid)
                ? "481px"
                : "390px",
          }}
        >
          <div className="bank_ass_top">
            <p className="bene_top_acc">
              Associated Accounts{""}
              <img
                ref={associatedAccountRef}
                src={InfoDetails}
                alt="info"
                onClick={() => {
                  setShowAccInfo(!showAccInfo);
                }}
              />
              {showAccInfo ? (
                <>
                  <div className="associate-acc-info-container">
                    <p className="associate-acc-info">
                      Associated accounts include all active (approved, pending)
                      and inactive (rejected, archived, blocked) accounts linked
                      to the provided PAN details. Click on the accounts to
                      manage them, or continue updating your bank details.
                    </p>
                  </div>
                </>
              ) : null}
            </p>
            <p className="bene_top_con">
              The following existing accounts are associated with the bank
              provided
            </p>
            <div className="bene_swap_butt">
              <Button
                className="bene_swap_butt_1"
                onClick={() => {
                  setActive(false);
                  setBankIsDennied(false);
                  setBankIsRejected(false);
                  setBankIsApproved(false);
                  setBankIsArchived(false);
                  setBankIsPending(false);
                  setBankAccountType({
                    type: "",
                    id: "",
                  });
                  setAccountAction(true);
                  setBankNonArchive(false);
                  setBankNewMessage(true);
                  setShowAccInfo(false);
                }}
                style={{
                  backgroundColor: !active ? "#ffffff" : "#242424",
                  color: !active ? "#1d1d1d" : "#f9f9f9",
                }}
              >
                Active
              </Button>
              <Button
                className="bene_swap_butt_2"
                onClick={() => {
                  setActive(true);
                  setBankIsDennied(false);
                  setBankIsRejected(false);
                  setBankIsApproved(false);
                  setBankIsArchived(false);
                  setBankIsPending(false);
                  setBankAccountType({
                    type: "",
                    id: "",
                  });
                  setAccountAction(true);
                  setBankNonArchive(false);
                  setShowAccInfo(false);
                  setBankNewMessage(true);
                }}
                style={{
                  backgroundColor: active ? "#ffffff" : "#242424",
                  color: active ? "#1d1d1d" : "#f9f9f9",
                }}
              >
                Inactive
              </Button>
            </div>
            {active ? (
              <div className="bank_scroll_All_approval">
                {haveAcheived || haveDennied || haveBlock || haveRejected ? (
                  <>
                    {(haveDennied || haveRejected) && (
                      <div className="bank_achived_main">
                        <p style={{ whiteSpace: "nowrap" }}>
                          Rejected accounts
                        </p>

                        <div className="bene_achived_scroll">
                          {benebankLookData?.beneficiary_accounts.map(
                            (val, i) => {
                              if (
                                val?.approval_status === "rejected" ||
                                val?.approval_status === "denied"
                              ) {
                                return (
                                  <Button
                                    style={{
                                      outline: isBankLinkedAccount
                                        ? payload?.pan_no == val?.pan_no &&
                                          payload?.bank_account ==
                                            val?.bank_acc_no &&
                                          payload?.ifsc_code == val?.ifsc_code
                                          ? "2px solid #CE720F"
                                          : "none"
                                        : null,
                                    }}
                                    sx={{
                                      backgroundColor:
                                        bankAccountType.id == val?.id
                                          ? "#7185A8 !important"
                                          : "#1d3a6d",
                                      opacity:
                                        previousBankID === val?.id
                                          ? "0.4"
                                          : "1",
                                      "&:hover": {
                                        backgroundColor: "#7185A8 !important",
                                      },
                                    }}
                                    className="bene_child_scroll"
                                    key={i}
                                    onClick={() => {
                                      setBankDisableCreateNext(false);
                                      setBankShutViewAll(true);
                                      setBankIsPending(false);
                                      setBankIsApproved(false);
                                      setBankIsArchived(false);
                                      setBankLookupNext(false);
                                      setBankHideBlock(false);
                                      setBankHideCreate(false);
                                      if (val?.id === bankAccountType.id) {
                                        setBankAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setBankIsDennied(false);
                                        setBankIsRejected(false);
                                        setAccountAction(true);
                                      } else {
                                        setBankAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                        setBankIsDennied(true);
                                        setBankIsRejected(true);

                                        setAccountAction(false);
                                      }
                                      if (previousBankID === val?.id) {
                                        setBankNonArchive(
                                          (prevState) => !prevState
                                        );
                                        setAccountAction(false);
                                      } else {
                                        setBankNonArchive(false);
                                      }
                                    }}
                                  >
                                    <div className="bank_client_name">
                                      <p
                                        className="bank_name_client2"
                                        style={{
                                          color: "#FF5860",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {val?.name.toLowerCase()}
                                      </p>
                                      <p className="bene_bank_client2">
                                        {val?.pan_no}
                                        <span className="bene_bank_no">
                                          - PAN number
                                        </span>
                                      </p>
                                    </div>
                                    <div
                                      className="bank_viewProfile"
                                      style={{
                                        cursor:
                                          previousBankID === val?.id
                                            ? "default"
                                            : "pointer",
                                      }}
                                      onClick={(e) => {
                                        if (previousBankID !== val?.id) {
                                          e.stopPropagation();
                                          navigate(
                                            `/accounts/beneficiary_account/details/${val?.id}`
                                          );
                                        }
                                      }}
                                    >
                                      <button>
                                        <img
                                          style={{
                                            cursor:
                                              previousBankID === val?.id
                                                ? "default"
                                                : "pointer",
                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    )}
                    {haveBlock && (
                      <div className="bank_achived_main">
                        <p style={{ whiteSpace: "nowrap" }}>Blocked accounts</p>
                        <div className="bene_achived_scroll">
                          {benebankLookData?.beneficiary_accounts.map(
                            (val, i) => {
                              if (val?.approval_status === "blocked") {
                                return (
                                  <Button
                                    style={{
                                      outline: isBankLinkedAccount
                                        ? payload?.pan_no == val?.pan_no &&
                                          payload?.bank_account ==
                                            val?.bank_acc_no &&
                                          payload?.ifsc_code == val?.ifsc_code
                                          ? "2px solid #CE720F"
                                          : "none"
                                        : null,
                                    }}
                                    sx={{
                                      backgroundColor:
                                        bankAccountType.id == val?.id
                                          ? "#7185A8 !important"
                                          : "#1d3a6d",

                                      "&:hover": {
                                        backgroundColor: "#7185A8 !important",
                                      },
                                    }}
                                    className="bene_child_scroll"
                                    key={i}
                                    onClick={() => {
                                      setBankIsDennied(false);
                                      setBankIsRejected(false);

                                      setBankIsPending(false);
                                      setBankIsApproved(false);
                                      setBankIsArchived(false);
                                      setBankLookupNext(false);
                                      setBankHideCreate(false);
                                      setBankHideBlock(!hideBankBlock);
                                      if (bankAccountType.type === "blocked") {
                                        setBankNewMessage(true);
                                        setBankHideBlock(false);
                                      } else {
                                        setBankHideBlock(true);
                                      }
                                      if (val?.id === bankAccountType.id) {
                                        setBankAccountType({
                                          type: "",
                                          id: "",
                                        });
                                      } else {
                                        setBankAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                      }
                                      if (previousBankID === val?.id) {
                                        setBankNonArchive(true);
                                      } else {
                                        setBankNonArchive(false);
                                      }
                                    }}
                                  >
                                    <div className="bank_client_name">
                                      <p
                                        className="bank_name_client2"
                                        style={{
                                          color: "#FF5860",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {val?.name.toLowerCase()}
                                      </p>
                                      <p className="bene_bank_client2">
                                        {val?.pan_no}
                                        <span className="bene_bank_no">
                                          - PAN number
                                        </span>
                                      </p>
                                    </div>
                                    <div className="bank_viewProfile">
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigate(
                                            `/accounts/beneficiary_account/details/${val?.id}`
                                          );
                                        }}
                                      >
                                        <img
                                          src={ViewDetails}
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    )}
                    {haveAcheived && (
                      <div className="bank_achived_main">
                        <p>Archived accounts</p>
                        <div className="bene_achived_scroll">
                          {benebankLookData?.beneficiary_accounts.map(
                            (val, i) => {
                              if (val?.approval_status === "archived") {
                                return (
                                  <Button
                                    className="bene_child_scroll"
                                    style={{
                                      outline: isBankLinkedAccount
                                        ? payload?.pan_no == val?.pan_no &&
                                          payload?.bank_account ==
                                            val?.bank_acc_no &&
                                          payload?.ifsc_code == val?.ifsc_code
                                          ? "2px solid #CE720F"
                                          : "none"
                                        : null,

                                      "&:hover": {
                                        backgroundColor: "#7185A8 !important",
                                      },
                                    }}
                                    sx={{
                                      backgroundColor:
                                        bankAccountType.id == val?.id
                                          ? "#7185A8 !important"
                                          : "#1d3a6d",
                                      "&:hover": {
                                        backgroundColor: "#7185A8 !important",
                                      },
                                    }}
                                    key={i}
                                    id={val?.id}
                                    onClick={() => {
                                      setBankLookupNext(false);
                                      setBankDisableCreateNext(false);
                                      setBankIsDennied(false);
                                      setBankIsRejected(false);
                                      setBankIsPending(false);
                                      setBankHideBlock(false);
                                      setBankHideCreate(false);
                                      setBankIsApproved(false);
                                      if (val?.id === bankAccountType.id) {
                                        setBankAccountType({
                                          type: "",
                                          id: "",
                                        });

                                        setBankIsArchived(false);
                                        setAccountAction(true);
                                      } else {
                                        setBankAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                        setBankIsArchived(true);
                                        setAccountAction(false);
                                      }
                                      if (previousBankID === val?.id) {
                                        setBankNonArchive(true);
                                        setAccountAction(true);
                                      } else {
                                        setBankNonArchive(false);
                                      }
                                    }}
                                  >
                                    <div className="bank_client_name">
                                      <p
                                        className="bank_name_client2"
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {val?.name.toLowerCase()}
                                      </p>
                                      <p className="bene_bank_client2">
                                        {val?.pan_no}
                                        <span className="bene_bank_no">
                                          - PAN number
                                        </span>
                                      </p>
                                    </div>
                                    <div className="bank_viewProfile">
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          navigate(
                                            `/accounts/beneficiary_account/details/${val.id}`
                                          );
                                        }}
                                      >
                                        <img
                                          src={ViewDetails}
                                          style={{
                                            width: "25px",
                                            height: "25px",
                                          }}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="hidden_accounts">
                    <p>
                      <span>No inactive associated accounts found</span> <br />{" "}
                      There are no existing inactive accounts that are
                      associated with the bank provided.
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div className="bank_scroll_All_approval">
                {haveApproved || havePending ? (
                  <>
                    {haveApproved && (
                      <div className="bank_achived_main">
                        <p style={{ whiteSpace: "nowrap" }}>
                          Approved accounts
                        </p>
                        <div className="bene_achived_scroll">
                          {benebankLookData?.beneficiary_accounts.map(
                            (val, i) => {
                              if (val?.approval_status === "approved") {
                                return (
                                  <Button
                                    style={{
                                      outline: isBankLinkedAccount
                                        ? payload?.pan_no == val?.pan_no &&
                                          payload?.bank_account ==
                                            val?.bank_acc_no &&
                                          payload?.ifsc_code == val?.ifsc_code
                                          ? "2px solid #CE720F"
                                          : "none"
                                        : null,
                                    }}
                                    sx={{
                                      backgroundColor:
                                        bankAccountType.id == val?.id
                                          ? "#7185A8 !important"
                                          : "#1d3a6d",
                                      opacity:
                                        previousBankID === val?.id
                                          ? "0.4"
                                          : "1",
                                      "&:hover": {
                                        backgroundColor: "#7185A8 !important",
                                      },
                                    }}
                                    className="bene_child_scroll"
                                    key={i}
                                    onClick={() => {
                                      setBankShutViewAll(true);
                                      setBankIsArchived(false);
                                      setBankLookupNext(false);
                                      setBankIsDennied(false);
                                      setBankIsRejected(false);

                                      setBankIsPending(false);
                                      setBankHideBlock(false);
                                      setBankHideCreate(false);
                                      setBankDisableCreateNext(false);
                                      if (val?.id === bankAccountType.id) {
                                        setBankAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setBankIsApproved(false);
                                        setAccountAction(true);
                                      } else {
                                        setBankAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                        setBankIsApproved(true);
                                        setAccountAction(false);
                                        setBankNewMessage(true);
                                      }
                                      if (previousBankID === val?.id) {
                                        setBankNonArchive(
                                          (prevState) => !prevState
                                        );
                                        setBankNewMessage(false);
                                      } else {
                                        setBankNonArchive(false);
                                      }
                                    }}
                                  >
                                    <div className="bank_client_name">
                                      <p
                                        className="bank_name_client2"
                                        style={{
                                          color: "#4DDD37",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {val?.name.toLowerCase()}
                                      </p>
                                      <p className="bene_bank_client2">
                                        {val?.pan_no}
                                        <span className="bene_bank_no">
                                          - PAN number
                                        </span>
                                      </p>
                                    </div>
                                    <div
                                      className="bank_viewProfile"
                                      style={{
                                        cursor:
                                          previousBankID === val?.id
                                            ? "default"
                                            : "pointer",
                                      }}
                                      onClick={(e) => {
                                        if (previousBankID !== val?.id) {
                                          e.stopPropagation();
                                          navigate(
                                            `/accounts/beneficiary_account/details/${val?.id}`
                                          );
                                        }
                                      }}
                                    >
                                      <button>
                                        <img
                                          style={{
                                            cursor:
                                              previousBankID === val?.id
                                                ? "default"
                                                : "pointer",
                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    )}
                    {havePending && (
                      <div className="bank_achived_main">
                        <p style={{ whiteSpace: "nowrap" }}>Pending accounts</p>
                        <div className="bene_achived_scroll">
                          {benebankLookData?.beneficiary_accounts.map(
                            (val, i) => {
                              if (val?.approval_status === "pending") {
                                return (
                                  <Button
                                    style={{
                                      outline: isBankLinkedAccount
                                        ? payload?.pan_no == val?.pan_no &&
                                          payload?.bank_account ==
                                            val?.bank_acc_no &&
                                          payload?.ifsc_code == val?.ifsc_code
                                          ? "2px solid #CE720F"
                                          : "none"
                                        : null,
                                    }}
                                    sx={{
                                      backgroundColor:
                                        bankAccountType.id == val?.id
                                          ? "#7185A8 !important"
                                          : "#1d3a6d",
                                      opacity:
                                        previousBankID === val?.id
                                          ? "0.4"
                                          : "1",
                                      "&:hover": {
                                        backgroundColor: "#7185A8 !important",
                                      },
                                    }}
                                    className="bene_child_scroll"
                                    key={i}
                                    onClick={() => {
                                      setBankShutViewAll(true);
                                      setBankIsApproved(false);
                                      setBankIsArchived(false);
                                      setBankLookupNext(false);
                                      setBankIsDennied(false);
                                      setBankIsRejected(false);

                                      setBankHideBlock(false);
                                      setBankHideCreate(false);
                                      setBankDisableCreateNext(false);
                                      if (val?.id === bankAccountType.id) {
                                        setBankAccountType({
                                          type: "",
                                          id: "",
                                        });
                                        setBankIsPending(false);
                                        setAccountAction(true);
                                      } else {
                                        setBankAccountType({
                                          type: val?.approval_status,
                                          id: val?.id,
                                        });
                                        setBankIsApproved(true);
                                        setAccountAction(false);
                                        setBankNewMessage(true);
                                      }
                                      if (previousBankID === val?.id) {
                                        setBankNonArchive(
                                          (prevState) => !prevState
                                        );
                                        setBankNewMessage(false);
                                      } else {
                                        setBankNonArchive(false);
                                      }
                                    }}
                                  >
                                    <div className="bank_client_name">
                                      <p
                                        className="bank_name_client2"
                                        style={{
                                          color: "#00FFF5",
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {val?.name.toLowerCase()}
                                      </p>
                                      <p className="bene_bank_client2">
                                        {val?.pan_no}
                                        <span className="bene_bank_no">
                                          - PAN number
                                        </span>
                                      </p>
                                    </div>
                                    <div
                                      className="bank_viewProfile"
                                      style={{
                                        cursor:
                                          previousBankID === val?.id
                                            ? "default"
                                            : "pointer",
                                      }}
                                      onClick={(e) => {
                                        if (previousBankID !== val?.id) {
                                          e.stopPropagation();
                                          navigate(
                                            `/accounts/beneficiary_account/details/${val?.id}`
                                          );
                                        }
                                      }}
                                    >
                                      <button>
                                        <img
                                          style={{
                                            cursor:
                                              previousBankID === val?.id
                                                ? "default"
                                                : "pointer",
                                            width: "25px",
                                            height: "25px",
                                          }}
                                          src={ViewDetails}
                                          alt="viewdetails"
                                        />
                                      </button>
                                      <p>View details</p>
                                    </div>
                                  </Button>
                                );
                              }
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="bank_hidden_accounts">
                    <p>
                      <span>No active associated accounts found</span> <br />{" "}
                      There are no existing active accounts that are associated
                      with the bank provided.
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>

          {(bankAccountType.id &&
            bankAccountType.type !== "blocked" &&
            isBankLinkedAccount) ||
          !accountAction ? (
            <div
              className="bank_ass_bottom_editFlow"
              style={{
                display: `${
                  bankIsAchived ||
                  ((bankIsApproved ||
                    bankIsDennied ||
                    bankIsPending ||
                    bankIsRejected) &&
                    bankShutViewAll &&
                    isIFSCValid)
                    ? "block"
                    : "none"
                }`,
              }}
            >
              <p>Account action</p>
              {bankIsAchived ? (
                <Button
                  className="bank_ass_next"
                  onClick={() => {
                    if (bankAccountType.type === "archived") {
                      setBankUnachivedOpenPop(true);
                      setAccountAction(true);
                    }
                  }}
                >
                  <img src={unarchiveImg} alt="unarc" /> Unarchive account
                </Button>
              ) : (bankIsApproved ||
                  bankIsDennied ||
                  bankIsPending ||
                  bankIsRejected) &&
                bankShutViewAll ? (
                <Button
                  className="bank_ass_arc"
                  style={{
                    color: bankLookupNext ? "#031F4F" : "#fefefe",
                    background: "#63ba74 0% 0% no-repeat padding-box",
                    opacity: previousBankID === bankAccountType.id ? 0.5 : 1,
                  }}
                  disabled={previousBankID === bankAccountType.id}
                  onClick={() => {
                    setAccountAction(true);
                    setBankAchivedOpenPop(!bankAchivedOpenPop);
                  }}
                >
                  <img src={archiveImg} alt="arc" />
                  Archive account
                </Button>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : (
        <div
          className="dividepan bank_Details_Edit"
          style={{
            visibility: "hidden",
          }}
        ></div>
      )}
    </div>
  );
};
export default BeneBankEdit;
