import React, { useEffect, useState } from "react";
import "./index.css";
import { InputAdornment, TextField, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import Validation from "../../../utils/validation";
import { updateEmail } from "../../../redux/features/users";
import { updateMobNo } from "../../../redux/features/users";
import { updateAlias } from "../../../redux/features/users";
import { checkUsertoken } from "../../../redux/features/login/login";
import checkMark from "../../../assets/CreateTransactionIcons/checkmark.png";
import invalid from "../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import inputLoader from "../../../assets/Beneficiary Icons/inputloder.gif";
import { getRefreshTokenNew } from "../../../redux/features/refreshToken/refreshToken";

const EditPopUp = ({
  refreshData,
  onClose,
  updateRefreshData,
  isEditModalOpen,
}) => {
  const dispatch = useDispatch();
  const [isblur, setisblur] = useState(false);
  const [contactNumberValidation, setContactNumberValidation] = useState(true);
  const [nameValidation, setNameValidation] = useState(true);
  const [emailValidation, setEmailValidation] = useState(true);
  const [aliasName, setAliasName] = useState("");
  const [email, setEmail] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [focusState, setFocusState] = useState({});
  const [aliasUpdateDisabled, setAliasUpdateDisabled] = useState(true);
  const [emailUpdateDisabled, setEmailUpdateDisabled] = useState(true);
  const [mobNoUpdateDisabled, setMobNoUpdateDisabled] = useState(true);

  const [aliasEdited, setAliasEdited] = useState(false);
  const [emailEdited, setEmailEdited] = useState(false);
  const [mobNoEdited, setMobNoEdited] = useState(false);

  const aliasIsLoading = useSelector((state) => state.user.aliasIsLoading);
  const aliasError = useSelector((state) => state.user.aliasError);
  const aliasData = useSelector((state) => state.user.aliasData);

  const emailIsLoading = useSelector((state) => state.user.emailIsLoading);
  const emailError = useSelector((state) => state.user.emailError);
  const emailData = useSelector((state) => state.user.emailData);

  const mobNoIsLoading = useSelector((state) => state.user.mobNoIsLoading);
  const mobNoError = useSelector((state) => state.user.mobNoError);
  const mobNoData = useSelector((state) => state.user.mobNoData);

  const [customErrors, setCustomErrors] = useState({});

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  const aliaNameRegex = /^[a-zA-Z0-9-_]+$/;

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]+$/;

  const widthA = 100;

  useEffect(() => {
    if (refreshData.employees) {
      setAliasName(refreshData.employees.alias_name);
      setEmail(refreshData.employees.email);
      setMobNo(refreshData.employees.mob_no);
      setMobNoUpdateDisabled(true);
      setEmailUpdateDisabled(true);
      setAliasUpdateDisabled(true);
    }
  }, [refreshData]);

  useEffect(() => {
    if (isEditModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isEditModalOpen]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const updateMob = () => {
    dispatch(checkUsertoken());
    dispatch(
      updateMobNo({
        id: refreshData?.employees?.id,
        mob_no: mobNo,
      })
    ).then(() => {
      updateRefreshData({
        ...refreshData,
        employees: { ...refreshData.employees, mob_no: mobNo },
      });
      // dispatch(getRefreshTokenNew());
      setMobNoEdited(true);
    });
    setMobNoUpdateDisabled(true);
  };
  const updateEmailId = () => {
    dispatch(checkUsertoken());
    dispatch(
      updateEmail({
        id: refreshData?.employees?.id,
        email: email,
      })
    ).then(() => {
      updateRefreshData({
        ...refreshData,
        employees: { ...refreshData.employees, email: email },
      });
      // dispatch(getRefreshTokenNew());
      setEmailEdited(true);
    });
    setEmailUpdateDisabled(true);
  };
  const updateAliasName = () => {
    dispatch(checkUsertoken());
    dispatch(
      updateAlias({
        id: refreshData?.employees?.id,
        alias_name: aliasName,
      })
    ).then(() => {
      updateRefreshData({
        ...refreshData,
        employees: { ...refreshData.employees, alias_name: aliasName },
      });
      // dispatch(getRefreshTokenNew());
      setAliasEdited(true);
    });
    setAliasUpdateDisabled(true);
  };

  const validateAndRecordError = (name, value) => {
    let errors = { ...customErrors };
    if (name === "email_id")
      errors = Validation.validateEmail(name, value, errors);
    if (name === "contact_no")
      errors = Validation.validateContactNum(name, value, errors);
    if (name === "alias_name")
      errors = Validation.validateName(name, value, errors);
    setCustomErrors(errors);
  };

  useEffect(() => {
    if (mobNo.length <= 9) {
      setMobNoUpdateDisabled(true);
    } else if (mobNo !== refreshData?.employees?.mob_no) {
      setMobNoUpdateDisabled(false);
    } else {
      setMobNoUpdateDisabled(true);
    }
  }, [mobNo, refreshData]);

  useEffect(() => {
    if (aliasName === refreshData?.employees?.alias_name) {
      setAliasUpdateDisabled(true);
      setNameValidation(true);
    } else if (aliasName.length <= 3) {
      setAliasUpdateDisabled(true);
      setNameValidation(false);
    } else if (aliasError) {
      setNameValidation(false);
    }
  }, [aliasName, aliasError, refreshData]);

  useEffect(() => {
    if (email === refreshData?.employees?.email) {
      setEmailUpdateDisabled(true);
    }
  }, [email, refreshData]);

  const modifyValue = (type, value) => {
    if (type === "name") {
      return value?.replace(/(\w)(\w*)/g, function (g1, g2) {
        return g1.toUpperCase() + g2.toLowerCase();
      });
    }
    return value.trim();
  };

  const allowedValue = (name, value) => {
    if (name === "contact_no") return Validation.allowedContactNum(value);
    if (name === "alias_name") return Validation.allowedName(value);
    if (name === "email_id") return Validation.allowedEmail(value);
    return true;
  };

  const handleUpdate = () => {
    dispatch(getRefreshTokenNew());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let val = value.replaceAll(" ", "");
    const newValue = modifyValue(name, val);

    if (allowedValue(name, val)) {
      validateAndRecordError(name, newValue);

      if (name === "contact_no") {
        setMobNo(newValue);
        if (newValue.length === 0 || newValue.length < 10) {
          setContactNumberValidation(false);
        } else {
          setContactNumberValidation(true);
        }
      }
      if (name === "email_id") {
        setEmail(newValue);
        if (newValue.length === 0 || !emailRegex.test(newValue)) {
          setEmailValidation(false);
          setEmailUpdateDisabled(true);
        } else if (!emailRegex.test(value)) {
          setEmailValidation(true);
        } else {
          setEmailValidation(true);
          setEmailUpdateDisabled(false);
        }
      }
      if (name === "alias_name") {
        const regex = /^[a-zA-Z0-9-_]+$/;
        let sanitizedValue = newValue;
        if (!regex.test(newValue)) {
          // only allow dashes and underscores
          sanitizedValue = newValue.replace(/[^a-zA-Z0-9-_]/g, "");
        }
        setAliasName(sanitizedValue);
        if (!aliaNameRegex.test(sanitizedValue)) {
          setNameValidation(false);
          setAliasUpdateDisabled(true);
        } else {
          if (
            sanitizedValue !== refreshData?.employees?.alias_name ||
            aliasData
          ) {
            setAliasUpdateDisabled(sanitizedValue.length <= 2);
          } else {
            setAliasUpdateDisabled(true);
          }
          setNameValidation(true);
        }
      }
    }
  };

  const getAliasInputElementDiv = (
    name,
    status,
    label,
    value,
    maxLength,
    widthA
  ) => {
    const getColor = () => {
      if (value?.length > 0) {
        if (status) return "#00FF14";
        if (!status) return "#FF5860";
      }
      if (focusState[name]) return "#31E5E5";
      return "#DADADA";
    };
    const color = getColor();

    const handleFocus = () => {
      setFocusState((prev) => ({ ...prev, [name]: true }));
    };

    const handleBlur = () => {
      setFocusState((prev) => ({ ...prev, [name]: false }));
      setisblur(true);
    };

    const handlePaste = (e) => {
      e.preventDefault();
    };

    return (
      <div className="flex flex-row items-center">
        <TextField
          className={`w-full antialiased text-xl ${
            isMobile ? "w-auto" : "w-[340px]"
          }`}
          id={`outlined-size-small-${name}`}
          size="small"
          variant="outlined"
          sx={{
            width: "340px",
            "@media (max-width: 1024px)": {
              width: "210px",
            },
            "@media (max-width: 340px)": {
              width: "150px",
            },
            "& label.Mui-focused": {
              color: color,
            },
            "& label": {
              color: color,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color,
                outline: "none !important",
              },
              "&:hover fieldset": {
                borderColor: color,
              },
              "&.Mui-focused fieldset": {
                borderColor: color,
              },
            },
          }}
          inputProps={{
            style: { color: "#DADADA" },
            maxLength: maxLength,
            onPaste: handlePaste,
            disabled: aliasEdited,
          }}
          InputLabelProps={{ style: { color: color } }}
          required
          label={label}
          name={name}
          onBlurCapture={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  const getContactInputElementDiv = (
    name,
    status,
    label,
    value,
    maxLength,
    skip
  ) => {
    const getColor = () => {
      if (value?.length > 0) {
        if (status) return "#00FF14";
        if (!status) return "#FF5860";
      }
      if (focusState[name]) return "#31E5E5";
      return "#DADADA";
    };

    const color = getColor();

    const handleFocus = () => {
      setFocusState((prev) => ({ ...prev, [name]: true }));
    };

    const handleBlur = () => {
      setFocusState((prev) => ({ ...prev, [name]: false }));
      setisblur(true);
    };

    return (
      <div className="flex flex-row items-center self-center  text-center gap-4">
        <TextField
          className={`antialiased text-xl ${isMobile ? "w-auto" : "w-[340px]"}`}
          id={`outlined-size-small-${name}`}
          size="small"
          variant="outlined"
          sx={{
            width: "340px",
            "@media (max-width: 1024px)": {
              width: "210px",
            },
            "@media (max-width: 340px)": {
              width: "150px",
            },
            "& label.Mui-focused": {
              color: color,
            },
            "& label": {
              color: color,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color,
                outline: "none !important",
              },
              "&:hover fieldset": {
                borderColor: color,
              },
              "&.Mui-focused fieldset": {
                borderColor: color,
              },
            },
          }}
          inputProps={{
            style: { color: "#DADADA" },
            maxLength: maxLength,
            disabled: mobNoEdited,
          }}
          InputLabelProps={{ style: { color: color } }}
          InputProps={{
            startAdornment: name === "contact_no" && (
              <InputAdornment position="start">
                <div className="text-[#dadada]">+91</div>
              </InputAdornment>
            ),
          }}
          required
          label={label}
          name={name}
          onBlurCapture={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  const getEmailInputElementDiv = (
    name,
    status,
    label,
    value,
    maxLength,
    skip
  ) => {
    const getColor = () => {
      if (value?.length > 0) {
        if (status) return "#00FF14";
        if (!status) return "#FF5860";
      }
      if (focusState[name]) return "#31E5E5";
      return "#DADADA";
    };

    const color = getColor();

    const handleFocus = () => {
      setFocusState((prev) => ({ ...prev, [name]: true }));
    };

    const handleBlur = () => {
      setFocusState((prev) => ({ ...prev, [name]: false }));
      setisblur(true);
    };

    return (
      <div className="flex flex-row items-center self-center text-center gap-4">
        <TextField
          className={`antialiased text-xl ${isMobile ? "w-auto" : "w-[340px]"}`}
          id={`outlined-size-small-${name}`}
          size="small"
          variant="outlined"
          sx={{
            width: "340px",
            "@media (max-width: 1024px)": {
              width: "210px",
            },
            "@media (max-width: 340px)": {
              width: "150px",
            },
            "& label.Mui-focused": {
              color: color,
            },
            "& label": {
              color: color,
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: color,
                outline: "none !important",
              },
              "&:hover fieldset": {
                borderColor: color,
              },
              "&.Mui-focused fieldset": {
                borderColor: color,
              },
            },
          }}
          inputProps={{
            style: { color: "#DADADA" },
            maxLength: maxLength,
            disabled: emailEdited,
          }}
          InputLabelProps={{ style: { color: color } }}
          required
          label={label}
          name={name}
          onBlurCapture={handleBlur}
          onFocus={handleFocus}
          onChange={(e) => {
            if (e.target.value != " ") {
              handleChange(e);
            }
          }}
          value={value}
          autoComplete="off"
        />
      </div>
    );
  };

  return (
    <>
      {!isMobile && (
        <div className="fixed w-screen top-0 left-0 h-screen z-[9999]  bg-black bg-opacity-80 overflow-hidden">
          <div
            className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[9999] p-3"
            style={{ transform: "translate(-50%, -50%)" }}
          >
            <div
              className="h-fit w-70% lg:w-[565px]  flex flex-col bg-black gap-6 rounded-2xl"
              style={{
                width: "auto",
                height: "290px",
                backgroundColor: "#1D3A6D",
                padding: "20px 37px 27px 30px",
              }}
            >
              <div className="flex">
                <h6
                  className=" text-custom-orange  w-[94%] pb-1 md:font-bold"
                  // style={{   width: "94%" }}
                >
                  Edit Profile
                </h6>
                <IconButton
                  onClick={() => {
                    onClose();
                    handleUpdate();
                  }}
                  style={{
                    marginTop: "4px",
                    backgroundColor: "#031F4F",
                    borderRadius: "5px",
                    height: "22px",
                    width: "22px",
                  }}
                >
                  <Close className="text-white   hover:bg-[#667CA3] hover:rounded-lg" />
                </IconButton>
              </div>
              <div className="flex  ">
                {getAliasInputElementDiv(
                  "alias_name",
                  nameValidation,
                  "Alias",
                  aliasName,
                  50
                )}
                <div
                  className="flex items-center justify-center"
                  onClick={() => {
                    if (!aliasUpdateDisabled) {
                      updateAliasName();
                    }
                  }}
                  disabled={aliasUpdateDisabled}
                  style={{
                    backgroundColor: "#1d3a6d",
                    cursor: aliasUpdateDisabled ? "not-allowed" : "pointer",
                  }}
                >
                  {aliasIsLoading ? (
                    <div className="loadingImg">
                      <img src={inputLoader} alt="loading" />
                    </div>
                  ) : aliasData ? (
                    <div className="successImg">
                      <img src={checkMark} alt="Success" />
                    </div>
                  ) : aliasError ? (
                    <div className="failImg">
                      <img src={invalid} alt="Error" />
                    </div>
                  ) : !aliasUpdateDisabled ? (
                    <div className="UpdateButton">
                      <p>Update</p>
                    </div>
                  ) : (
                    <div className="defaultButton ">
                      <p>Update</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex ">
                {getEmailInputElementDiv(
                  "email_id",
                  emailValidation,
                  "Email-ID",
                  email,
                  50
                )}
                <div
                  className="flex items-center justify-center"
                  onClick={() => {
                    if (!emailUpdateDisabled) {
                      updateEmailId();
                    }
                  }}
                  disabled={emailUpdateDisabled}
                  style={{
                    backgroundColor: "#1d3a6d",
                    cursor: emailUpdateDisabled ? "not-allowed" : "pointer",
                  }}
                >
                  {emailIsLoading ? (
                    <div className="loadingImg">
                      <img src={inputLoader} alt="loading" />
                    </div>
                  ) : emailData ? (
                    <div className="successImg">
                      <img src={checkMark} alt="Success" />
                    </div>
                  ) : emailError ? (
                    <div className="failImg">
                      <img src={invalid} alt="Error" />
                    </div>
                  ) : !emailUpdateDisabled ? (
                    <div className="UpdateButton">
                      <p>Update</p>
                    </div>
                  ) : (
                    <div className="defaultButton">
                      <p>Update</p>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex ">
                {getContactInputElementDiv(
                  "contact_no",
                  contactNumberValidation,
                  "Contact number",
                  mobNo,
                  10
                )}
                <div
                  className="flex items-center justify-center"
                  onClick={() => {
                    if (!mobNoUpdateDisabled) {
                      updateMob();
                    }
                  }}
                  disabled={mobNoUpdateDisabled}
                  style={{
                    backgroundColor: "#1d3a6d",
                    cursor: mobNoUpdateDisabled ? "not-allowed" : "pointer",
                  }}
                >
                  {mobNoIsLoading ? (
                    <div className="loadingImg">
                      <img src={inputLoader} alt="loading" />
                    </div>
                  ) : mobNoData ? (
                    <div className="successImg">
                      <img src={checkMark} alt="Success" />
                    </div>
                  ) : mobNoError ? (
                    <div className="failImg">
                      <img src={invalid} alt="Error" />
                    </div>
                  ) : !mobNoUpdateDisabled ? (
                    <div className="UpdateButton">
                      <p> Update</p>
                    </div>
                  ) : (
                    <div className="defaultButton">
                      <p>Update</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isMobile && (
        <>
          <div
            className="fixed  w-screen top-0 left-0 h-screen z-[9999] bg-black bg-opacity-80 overflow-hidden"
            style={{
              overflowY: isEditModalOpen ? "hidden" : "scroll",
            }}
          >
            <div
              className="flex justify-center relative items-center  m-auto top-1/2 left-1/2  z-[9999] p-3"
              style={{ transform: "translate(-50%, -50%)" }}
            >
              <div
                className="flex flex-col gap-6 bg-black rounded-2xl"
                style={{
                  padding: "20px 15px",
                  backgroundColor: "#1D3A6D",
                  width: "100%",
                  maxWidth: "400px",
                }}
              >
                <div className="flex justify-between">
                  <h6
                    className={`text-custom-orange ${
                      isMobile ? "w-[100px] whitespace-nowrap" : "w-[94%]"
                    } pb-1 font-bold  w-full lg:w-[94%]`}
                  >
                    Edit Profile
                  </h6>
                  <IconButton
                    onClick={() => {
                      onClose();
                      handleUpdate();
                    }}
                    className=" "
                    style={{
                      marginTop: "4px",
                      backgroundColor: "#031F4F",
                      borderRadius: "5px",
                      height: "22px",
                      width: "22px",
                    }}
                  >
                    <Close className="text-white hover:bg-[#667CA3] hover:rounded-lg" />
                  </IconButton>
                </div>

                <div className="flex flex-row justify-between gap-4">
                  {getAliasInputElementDiv(
                    "alias_name",
                    nameValidation,
                    "Alias",
                    aliasName,
                    50,
                    widthA
                  )}
                  <div
                    className="flex items-center justify-center w-[94px] lg:w-auto"
                    onClick={() => {
                      if (!aliasUpdateDisabled) {
                        updateAliasName();
                      }
                    }}
                    disabled={aliasUpdateDisabled}
                    style={{
                      backgroundColor: "#1d3a6d",

                      cursor: aliasUpdateDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    {aliasIsLoading ? (
                      <div className="loadingImg_responsive">
                        <img src={inputLoader} alt="loading" />
                      </div>
                    ) : aliasData ? (
                      <div className="successImg_responsive">
                        <img src={checkMark} alt="Success" />
                      </div>
                    ) : aliasError ? (
                      <div className="failImg_responsive">
                        <img src={invalid} alt="Error" />
                      </div>
                    ) : !aliasUpdateDisabled ? (
                      <div
                        className={` ${
                          isMobile ? "UpdateButton_responsive" : "UpdateButton"
                        }`}
                      >
                        <p>Update</p>
                      </div>
                    ) : (
                      <div className="defaultButton_responsive ">
                        <p>Update</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-row justify-between gap-4">
                  {getEmailInputElementDiv(
                    "email_id",
                    emailValidation,
                    "Email-ID",
                    email,
                    50
                  )}
                  <div
                    className="flex items-center justify-center w-[94px] lg:w-auto"
                    onClick={() => {
                      if (!emailUpdateDisabled) {
                        updateEmailId();
                      }
                    }}
                    disabled={emailUpdateDisabled}
                    style={{
                      backgroundColor: "#1d3a6d",
                      cursor: emailUpdateDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    {emailIsLoading ? (
                      <div className="loadingImg_responsive">
                        <img src={inputLoader} alt="loading" />
                      </div>
                    ) : emailData ? (
                      <div className="successImg_responsive img">
                        <img src={checkMark} alt="Success" />
                      </div>
                    ) : emailError ? (
                      <div className="failImg_responsive">
                        <img src={invalid} alt="Error" />
                      </div>
                    ) : !emailUpdateDisabled ? (
                      <div
                        className={`${
                          isMobile ? "UpdateButton_responsive" : "UpdateButton"
                        }`}
                      >
                        <p>Update</p>
                      </div>
                    ) : (
                      <div className=" defaultButton_responsive ">
                        <p>Update</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-row justify-between gap-4">
                  {getContactInputElementDiv(
                    "contact_no",
                    contactNumberValidation,
                    "Contact number",
                    mobNo,
                    10
                  )}
                  <div
                    className="flex items-center justify-center w-[94px] lg:w-auto"
                    onClick={() => {
                      if (!mobNoUpdateDisabled) {
                        updateMob();
                      }
                    }}
                    disabled={mobNoUpdateDisabled}
                    style={{
                      backgroundColor: "#1d3a6d",
                      cursor: mobNoUpdateDisabled ? "not-allowed" : "pointer",
                    }}
                  >
                    {mobNoIsLoading ? (
                      <div className="loadingImg_responsive">
                        <img src={inputLoader} alt="loading" />
                      </div>
                    ) : mobNoData ? (
                      <div className="successImg_responsive">
                        <img src={checkMark} alt="Success" />
                      </div>
                    ) : mobNoError ? (
                      <div className="failImg_responsive">
                        <img src={invalid} alt="Error" />
                      </div>
                    ) : !mobNoUpdateDisabled ? (
                      <div
                        className={`${
                          isMobile ? "UpdateButton_responsive" : "UpdateButton"
                        }`}
                      >
                        <p> Update</p>
                      </div>
                    ) : (
                      <div className=" defaultButton_responsive  ">
                        <p>Update</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default EditPopUp;
